import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { BinaryChoiceAddOnButtonsProps } from './BinaryChoiceAddOnButtons.js';
import { BinaryChoiceAddOnButtons } from './BinaryChoiceAddOnButtons.js';

export interface BinaryChoiceAddOnButtonsFormFieldProps<SD extends AnyStoreDef>
  extends BinaryChoiceAddOnButtonsProps<SD>,
    Omit<FormFieldProps, 'children' | 'warning' | 'noExclamationTriangleIfWarning'> {}

export function BinaryChoiceAddOnButtonsFormField<SD extends AnyStoreDef>({
  label,
  $,
  horizontal,
  ...props
}: BinaryChoiceAddOnButtonsFormFieldProps<SD>): JSX.Element {
  const warning = useFormFieldWarning($);
  return (
    <FormField
      label={label}
      warning={warning}
      noExclamationTriangleIfWarning
      horizontal={horizontal}
    >
      <BinaryChoiceAddOnButtons $={$} {...props} />
    </FormField>
  );
}
