import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { documentEventListenerWrapper } from '../../../utils/index.js';

export interface BaseModalDialogProps {
  readonly children: React.ReactNode;
  readonly zIndex?: number;
  // max-desktop and max-widescreen refers to https://bulma.io/documentation/layout/container/#overview
  readonly size?: 'standard' | 'max-desktop' | 'max-widescreen' | 'fullscreen';
  readonly style?: React.CSSProperties;
}

export interface ModalDialogProps<SD extends AnyStoreDef> extends BaseModalDialogProps {
  readonly onClose?: NoArgActionCallback<SD>;
  readonly $active: StoreStateSelector<SD, boolean>;
}

export function ModalDialog<SD extends AnyStoreDef>({
  $active,
  onClose,
  children,
  zIndex,
  size,
  style,
}: ModalDialogProps<SD>): JSX.Element {
  const active = useGetState($active);

  const closeModalHandler = useActionCallback(
    async ({ globalActionDispatch, actionDispatch }): Promise<void> => {
      // Close the dialog
      actionDispatch.setValue(false);
      // Run the trigger if provided
      if (onClose) {
        await globalActionDispatch.execCallback(onClose);
      }
    },
    [onClose],
    $active
  );

  return (
    <ModalDialogWithoutDispatch
      size={size}
      zIndex={zIndex}
      isActive={active !== false && active !== undefined && active !== null}
      closeHandler={closeModalHandler}
      style={style}
    >
      {children}
    </ModalDialogWithoutDispatch>
  );
}

export interface ModalDialogWithoutDispatchProps extends BaseModalDialogProps {
  readonly closeHandler: () => Promise<void>;
  readonly isActive: boolean;
}

export function ModalDialogWithoutDispatch({
  isActive,
  closeHandler,
  children,
  zIndex,
  size = 'standard',
  style,
}: ModalDialogWithoutDispatchProps): JSX.Element {
  useEffect((): (() => void) => {
    function eventListener(e: KeyboardEvent): void {
      if (e.key === 'Escape' && isActive) {
        e.preventDefault();
        e.stopImmediatePropagation();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        closeHandler();
      }
    }
    // Register a click listener
    documentEventListenerWrapper.addEventListener('keydown', eventListener);
    return (): void => {
      // Unregister the click listener
      documentEventListenerWrapper.removeEventListener('keydown', eventListener);
    };
  }, [closeHandler, isActive]);

  return (
    <>
      {isActive && (
        <>
          {createPortal(
            <div
              style={zIndex ? { zIndex } : {}}
              className={`modal${size === 'fullscreen' ? ' is-fullscreen' : ''}${size === 'max-desktop' ? ' is-max-desktop' : ''}${size === 'max-widescreen' ? ' is-max-widescreen' : ''} is-active`}
            >
              <div
                className="modal-background"
                role="button"
                onClick={closeHandler}
                onKeyUp={closeHandler}
                tabIndex={-100}
                aria-label="Close"
              />
              <div className="modal-content" style={style}>
                {children}
              </div>
            </div>,
            document.body
          )}
        </>
      )}
    </>
  );
}
