import React from 'react';

interface Props {
  readonly children: React.ReactNode;
  readonly colspan?: number;
  readonly rowspan?: number;
  readonly centered?: boolean;
}

export function GridCellBox({ children, colspan, rowspan, centered }: Props): JSX.Element {
  return (
    <div
      className={`cell box has-radius-normal${colspan !== undefined ? ` is-col-span-${colspan}` : ''}${rowspan !== undefined ? ` is-row-span-${rowspan}` : ''}${centered === true ? ` has-text-centered` : ''} disable-bulma-child-margin-bottom`}
    >
      {children}
    </div>
  );
}
