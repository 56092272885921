import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import type { GlobalStoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppComponent, AppProps } from '@stimcar/libs-uitoolkit';
import { Logger } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { RegisteredAppStore } from '../state/typings/store.js';
import { ProtectedRoute } from '../../lib/components/misc/ProtectedRoute.js';
import { activateCurrentWindowSessionAction } from '../actions/windowSessionActivation.js';
import { REGISTER_FULL_PATH, REGISTER_RELATIVE_PATH } from '../constants.js';
import { Register } from './authentication/Register.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

/**
 * Sets the window title and manages the
 * active status (SSE).
 */
function useActiveWindowManager<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>($gs: GlobalStoreStateSelector<SD>): void {
  const isActiveWindow = useGetState($gs.$session.$isActiveWindow);
  const siteName = useGetState($gs.$session.$infos.optChaining().$site.$name);
  const isRegistered = useGetState($gs.$session.$isRegistered);

  // Set page title
  useEffect((): void => {
    if (siteName) {
      document.title = `${isActiveWindow ? '' : '⏸️ '}${siteName}${isActiveWindow ? '' : ' (inactive)'}`;
    }
  }, [isActiveWindow, siteName]);

  const activateCurrentSessionActionCallback = useActionCallback(
    activateCurrentWindowSessionAction,
    [],
    $gs
  );

  useEffect(() => {
    async function activateCallback() {
      if (!isActiveWindow && isRegistered) {
        await activateCurrentSessionActionCallback();
      }
    }
    // Listening to focus event allows to activate the current session in
    // most cases. But if a on has more than one tab/window opened on the
    // same company/site, we can't be sure that the one which will be active
    // is the one that has the focus. In such situation we may have a
    // focused tab/window (no more focus event while the user stays in that
    // tab/window) and the tab/window will remain inactive. To avoid
    // or limit that situation we also listen to click events
    /* eslint-disable @typescript-eslint/no-misused-promises */
    window.addEventListener('focus', activateCallback);
    window.addEventListener('click', activateCallback);
    /* eslint-enable @typescript-eslint/no-misused-promises */
    return () => {
      /* eslint-disable @typescript-eslint/no-misused-promises */
      window.removeEventListener('focus', activateCallback);
      window.removeEventListener('click', activateCallback);
      /* eslint-enable @typescript-eslint/no-misused-promises */
    };
  }, [activateCurrentSessionActionCallback, isActiveWindow, isRegistered]);
}

interface RegisteredAppWrapperProps<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
> extends AppProps<SD> {
  readonly registeredAppComponent: AppComponent<SD>;
  readonly registeredAppRoute: string;
}

export function RegisteredAppWrapper<
  IS_LIGHT_ENVIRONMENT extends boolean,
  SD extends RegisteredAppStore<IS_LIGHT_ENVIRONMENT>,
>({
  $gs,
  registeredAppComponent: RegisteredAppComponent,
  registeredAppRoute,
}: RegisteredAppWrapperProps<IS_LIGHT_ENVIRONMENT, SD>): JSX.Element {
  const isRegistered = useGetState($gs.$session.$isRegistered);
  useActiveWindowManager($gs);
  return (
    <>
      <Routes>
        <Route
          path={REGISTER_RELATIVE_PATH}
          element={
            <ProtectedRoute redirectTo={registeredAppRoute} giveWay={!isRegistered}>
              <Register $gs={$gs} />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute redirectTo={REGISTER_FULL_PATH} giveWay={isRegistered}>
              <RegisteredAppComponent $gs={$gs} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}
