import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AttributeType, Kanban } from '@stimcar/libs-base';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { ImageModalState } from '@stimcar/libs-uitoolkit';
import { i18nHelpers } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { PictureEditionToolkit } from '../attachments/KanbanIdentityPictureComponent.js';
import type { ComputeAttachmentUrlCallback } from '../attachments/typings/attachment.js';
import { KanbanIdentityPictureComponent } from '../attachments/KanbanIdentityPictureComponent.js';
import type { GeneralInformationsComputedData } from './displayInformationsUtils.js';
import { computeGeneralInformationsDatas } from './displayInformationsUtils.js';

interface Props<SD extends AnyStoreDef> {
  readonly kanban: Kanban;
  readonly attributesToDisplay?: Record<string, AttributeType>;
  readonly color?: string;
  readonly $expanded: StoreStateSelector<SD, boolean>;
  readonly computeUrlCallback: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly pictureEditionToolkit?: PictureEditionToolkit<SD>;
  readonly isOnline: boolean;
}

export function KanbanMobileGeneralInformations<SD extends AnyStoreDef>({
  kanban,
  color,
  isOnline,
  computeUrlCallback,
  $imageModal,
  pictureEditionToolkit,
  attributesToDisplay = {},
  $expanded,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  const expanded = useGetState($expanded);

  const toggleActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      actionDispatch.setValue(!getState());
    },
    [],
    $expanded
  );

  const dataToDisplay = useMemo((): GeneralInformationsComputedData[] => {
    const data = computeGeneralInformationsDatas(t, kanban, attributesToDisplay);
    return [
      {
        key: 'color',
        label: t('kanbanGeneralInformations.color'),
        value: isTruthyAndNotEmpty(kanban.infos.color)
          ? kanban.infos.color
          : t('kanbanGeneralInformations.notFilled'),
      },
      ...data,
    ];
  }, [t, kanban, attributesToDisplay]);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <div className="box" style={color ? { backgroundColor: color } : {}}>
      <div className="columns is-mobile is-vcentered p-xs" onClick={toggleActionCallback}>
        <div className="column is-narrow">
          <KanbanIdentityPictureComponent
            $imageModal={$imageModal}
            kanbanId={kanban.id}
            computeAttachmentUrl={computeUrlCallback}
            isOnline={isOnline}
            pictureEditionToolkit={pictureEditionToolkit}
          />
        </div>
        <div className="column">
          <p className="has-text-weight-bold">
            {`${kanban.infos.license} - ${i18nHelpers.displayStringOrPlaceholder(
              t,
              kanban.infos.brand
            )}`}
          </p>
          <p className="has-text-weight-bold">{kanban.infos.model}</p>
        </div>
        <div className="column is-narrow">
          <FaIcon id={`caret-${expanded ? 'down' : 'right'}`} />
        </div>
      </div>
      {expanded &&
        dataToDisplay.map((data): JSX.Element => {
          return (
            <p key={data.key}>
              <strong>{`${data.label}: `}</strong>
              {data.value}
            </p>
          );
        })}
    </div>
  );
}
