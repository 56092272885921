/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import type { AnyStoreDef, NoArgActionCallback } from '@stimcar/libs-uikernel';
import type { InputProps } from '@stimcar/libs-uitoolkit';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { FaIcon, Input } from '@stimcar/libs-uitoolkit';
import { NumericKeyboard } from '../../../components/misc/NumericKeyboard.js';

export interface InputWithNumericKeyboardSpecificProps<SD extends AnyStoreDef> {
  readonly isKeyboardOnOneLine?: boolean;
  readonly clearFieldAdditionalAction?: NoArgActionCallback<SD>;
}

export type InputWithNumericKeyboardProps<SD extends AnyStoreDef> = InputProps<SD> &
  InputWithNumericKeyboardSpecificProps<SD>;

export function InputWithNumericKeyboard<SD extends AnyStoreDef>({
  $,
  readOnly,
  isKeyboardOnOneLine,
  clearFieldAdditionalAction,
  ...rest
}: InputWithNumericKeyboardProps<SD>): JSX.Element {
  const clearAction = useActionCallback(
    async ({ actionDispatch }) => {
      actionDispatch.setValue('');
      if (isTruthy(clearFieldAdditionalAction)) {
        await actionDispatch.execCallback(clearFieldAdditionalAction);
      }
    },
    [clearFieldAdditionalAction],
    $
  );
  return (
    <>
      <div className="columns">
        <div className="column">
          <Input $={$} readOnly disabled={readOnly} {...rest} />
        </div>
        {!readOnly && (
          <div className="column is-narrow">
            <button type="button" className="button is-rounded is-light" onClick={clearAction}>
              <FaIcon id="times" />
            </button>
          </div>
        )}
      </div>
      {!readOnly && (
        <div className="columns p-b-sm">
          <div className="column" />
          <div className="column is-narrow">
            <NumericKeyboard $={$} isOneLine={isKeyboardOnOneLine} />
          </div>
          <div className="column" />
        </div>
      )}
    </>
  );
}
