import React, { useEffect } from 'react';
import type { Kanban, KanbanCustomer, KanbanSearchResult, KanbanSummary } from '@stimcar/libs-base';
import type { KanbanInfos } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, globalHelpers, kanbanHelpers } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';
import { useComponentWithMountTracking } from '../../lib/hooks/useComponentWithMountTracking.js';
import { updateDetailsViewStateFromSSEAction } from '../details/KanbanDetails.js';
import type { ArchivesViewState } from './typings/store.js';
import { DesktopArchivesViewComponent } from './components/DesktopArchivesViewComponent.js';
import { MobileArchivesViewComponent } from './components/MobileArchivesViewComponent.js';

function doesKanbanMatchSearchText(
  infos: KanbanInfos,
  customer: KanbanCustomer,
  searchText: string
): boolean {
  const { brand, license, model, vin } = infos;
  const { company, firstName, lastName, city } = customer;
  const fieldsToSearch = [brand, license, model, vin, company, firstName, lastName, city];
  return (
    isTruthyAndNotEmpty(searchText) &&
    fieldsToSearch.some((field) =>
      globalHelpers
        .replaceNonAlphaNumChar(field)
        .toLowerCase()
        .includes(globalHelpers.replaceNonAlphaNumChar(searchText).toLowerCase())
    )
  );
}

async function searchKanbanAction(
  {
    kanbanRepository,
    actionDispatch,
    httpClient,
    getState,
    getGlobalState,
  }: ActionContext<Store, ArchivesViewState>,
  activePage = 1
): Promise<void> {
  const { searchText } = getState();
  const { session } = getGlobalState();

  const searchTextToLower = searchText.trim().toLowerCase();
  let kanbans: readonly KanbanSummary[] = [];
  let totalFound = 0;
  let pageCount = 0;
  if (searchTextToLower.length >= 2) {
    if (session.isOnline) {
      const searchResult = await httpClient.httpGetAsJson<KanbanSearchResult>(
        CoreBackendRoutes.LIST_ARCHIVED_KANBAN_SUMMARIES(encodeURI(searchText), activePage - 1)
      );
      kanbans = searchResult.kanbans;
      totalFound = searchResult.totalFound;
      pageCount = Math.ceil(totalFound / searchResult.pageSize);
    } else {
      // Retrieve local entities
      const localKanbans = await kanbanRepository.getAllEntities();
      kanbans = kanbanHelpers.convertToSummary(
        ...localKanbans.filter(({ infos, customer }): boolean => {
          return doesKanbanMatchSearchText(infos, customer, searchTextToLower);
        })
      );
      totalFound = kanbans.length;
    }
  }
  actionDispatch.reduce((initial: ArchivesViewState): ArchivesViewState => {
    return {
      ...initial,
      filteredKanbans: [...kanbans].sort((k1, k2) =>
        k1.infos.license.localeCompare(k2.infos.license)
      ),
      totalFound,
      pageCount,
      activePage,
      searchShouldBeRerun: false,
    };
  });
}

export async function udpateSearchViewStateFromSSEAction(
  { actionDispatch, getState }: ActionContext<Store, ArchivesViewState>,
  kanban: Kanban
): Promise<void> {
  const { detailsState, filteredKanbans, searchText, componentIsMounted } = getState();
  // Only update the state if the component is mounted
  if (!componentIsMounted) {
    return;
  }
  const { selectedKanban } = detailsState;
  if (selectedKanban && selectedKanban.id === kanban.id) {
    await actionDispatch
      .scopeProperty('detailsState')
      .exec(updateDetailsViewStateFromSSEAction, kanban);
  }
  const searchTextToLower = searchText.trim().toLowerCase();
  if (doesKanbanMatchSearchText(kanban.infos, kanban.customer, searchTextToLower)) {
    const newFilteredKanbans: readonly KanbanSummary[] =
      filteredKanbans.find((k) => k.id === kanban.id) === undefined
        ? [...filteredKanbans, ...kanbanHelpers.convertToSummary(kanban)]
        : filteredKanbans.map((filteredKanban): KanbanSummary => {
            if (filteredKanban.id === kanban.id) {
              return kanbanHelpers.convertToSummary(kanban)[0];
            }
            return filteredKanban;
          });
    actionDispatch.reduce((initial: ArchivesViewState): ArchivesViewState => {
      return {
        ...initial,
        filteredKanbans: newFilteredKanbans,
      };
    });
  }
}

export function SearchArchivesView({ $gs }: AppProps<Store>): JSX.Element {
  const { $archivesView } = $gs;

  const isMobile = useScreenIsBulmaMobile($gs.$window);

  // Allows to track if the view is mounted (through a boolean in the state)
  useComponentWithMountTracking($archivesView);

  const searchKanbanActionCallback = useActionCallback(searchKanbanAction, [], $archivesView);

  const isOnline = useGetState($gs.$session.$isOnline);

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    searchKanbanActionCallback();
  }, [searchKanbanActionCallback, /* reload kanbans when switching online/offline */ isOnline]);

  const runSearchActionCallback = useActionCallback(
    async ({ actionDispatch }, page: number): Promise<void> => {
      await actionDispatch.exec(searchKanbanAction, page);
    },
    [],
    $archivesView
  );

  return (
    <>
      {isMobile ? (
        <MobileArchivesViewComponent $gs={$gs} runSearchActionCallback={runSearchActionCallback} />
      ) : (
        <DesktopArchivesViewComponent $gs={$gs} runSearchActionCallback={runSearchActionCallback} />
      )}
    </>
  );
}
