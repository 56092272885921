import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SiteKey } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, KANBAN_ATTRIBUTES, PACKAGE_DEAL_ATTRIBUTES } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { RadioButtonsFormField } from '../../lib/bulma/form/RadioButtonsFormField.js';
import type {
  DelegatePackageDealFormData,
  DelegatePackageDealModalState,
  SiteRef,
} from './typings/store.js';
import { EMPTY_DELEGATE_PACKAGE_DEAL_FORM } from './typings/store.js';

export async function toggleDelegatePackageDeal(
  {
    actionDispatch,
    kanbanRepository,
    httpClient,
  }: ActionContext<Store, DelegatePackageDealModalState>,
  kanbanId: string,
  packageDealId: string
): Promise<void> {
  // Save package deal id
  const selectedKanban = await kanbanRepository.getEntity(kanbanId);
  const delegationSite = nonnull(selectedKanban).attributes[KANBAN_ATTRIBUTES.DELEGATION_SITE];
  if (!delegationSite) {
    const { company, site } = httpClient.getBrowserInfos();
    const otherSiteKeys = (
      await httpClient.httpGetAsJson<readonly SiteKey[]>(CoreBackendRoutes.GET_ALL_SITES)
    )
      .filter(({ companyId, siteId }) => companyId !== company.id && site.id !== siteId)
      .map(({ companyId, siteId }): SiteRef => ({ companyId, id: siteId }));
    // Update state
    actionDispatch.setProperty('formData', EMPTY_DELEGATE_PACKAGE_DEAL_FORM);
    actionDispatch.setProperty('availableSites', otherSiteKeys);
    actionDispatch.setProperty('kanbanId', kanbanId);
    actionDispatch.setProperty('packageDealId', packageDealId);
    // Open popup
    actionDispatch.setProperty('active', true);
  } else {
    const packageDealIsDelagatedAttribute = selectedKanban?.packageDeals.find(
      ({ id }) => id === packageDealId
    )?.attributes[PACKAGE_DEAL_ATTRIBUTES.DELEGATED];
    const packageDealIsDelegated =
      typeof packageDealIsDelagatedAttribute === 'boolean'
        ? packageDealIsDelagatedAttribute
        : false;
    await kanbanRepository.updateEntityFromPayload({
      entityId: kanbanId,
      payload: {
        packageDeals: [
          {
            id: packageDealId,
            attributes: {
              // Only mark package deal as delegated (delegation site already set)
              [PACKAGE_DEAL_ATTRIBUTES.DELEGATED]: !packageDealIsDelegated,
            },
          },
        ],
      },
    });
  }
}

async function submitForm({
  getState,
  actionDispatch,
  kanbanRepository,
}: ActionContext<Store, DelegatePackageDealModalState>): Promise<void> {
  const { formData, kanbanId, packageDealId } = getState();
  const { delegationSite } = formData;
  await kanbanRepository.updateEntityFromPayload({
    entityId: kanbanId,
    payload: {
      // Set delegation site...
      attributes: {
        [KANBAN_ATTRIBUTES.DELEGATION_SITE]: delegationSite,
      },
      packageDeals: [
        {
          id: packageDealId,
          attributes: {
            // ...and mark package deal as delegated
            [PACKAGE_DEAL_ATTRIBUTES.DELEGATED]: true,
          },
        },
      ],
    },
  });
  actionDispatch.setProperty('active', false);
}

const mandatoryFields: (keyof DelegatePackageDealFormData)[] = ['delegationSite'];

interface Props {
  readonly $: StoreStateSelector<Store, DelegatePackageDealModalState>;
}

export function DelegatePackageDealModal({ $ }: Props): JSX.Element {
  const [t] = useTranslation('details');
  const formWarning = useGetState($.$formWarning);
  const availableSites = useGetState($.$availableSites);
  const submitValidDataAction = useActionCallback(submitForm, [], $);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    DelegatePackageDealModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });
  const delegationSitesEntries = useMemo(
    (): readonly FormFieldEntry<string>[] =>
      availableSites.map((site) => ({ id: `${site.companyId}/${site.id}`, label: site.id })),
    [availableSites]
  );
  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('tabs.packageDeals.delegateModal.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <RadioButtonsFormField
        id="delegationSite"
        $={$formDataWithChangeTrigger.$delegationSite}
        entries={delegationSitesEntries}
        label={t('tabs.packageDeals.delegateModal.delegationSite')}
        horizontal={{
          labelFlexGrow: 1,
          bodyFlexGrow: 2,
        }}
        radioGroupLayout="vertical"
      />
    </ModalCardDialog>
  );
}
