import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { RadioButtonsProps } from './RadioButtons.js';
import { RadioButtons } from './RadioButtons.js';

export type RadioButtonsFormFieldProps<
  SD extends AnyStoreDef,
  T extends string | number | boolean,
> = Omit<FormFieldProps, 'children' | 'noExclamationTriangleIfWarning' | 'warning'> &
  RadioButtonsProps<SD, T>;

export function RadioButtonsFormField<SD extends AnyStoreDef, T extends string | number | boolean>({
  id,
  entries,
  $,
  label,
  disabled,
  horizontal,
  sortEntries,
  radioGroupLayout,
}: RadioButtonsFormFieldProps<SD, T>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning
    >
      <RadioButtons
        id={id}
        disabled={disabled}
        $={$}
        entries={entries}
        sortEntries={sortEntries}
        radioGroupLayout={radioGroupLayout}
      />
    </FormField>
  );
}
