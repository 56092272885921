import React from 'react';
import { Route, Routes } from 'react-router-dom';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { ShareStore } from './state/typings/store.js';
import { Share } from './Share.js';

// Create context

export function ShareApp({ $gs }: AppProps<ShareStore>): JSX.Element {
  return (
    <Routes>
      <Route path="share/:shareId/:defaultTab?" element={<Share $gs={$gs} />} />
    </Routes>
  );
}
