import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  SubcontractorKanban,
  SubcontractorOperation,
  SubcontractorPackageDeal,
} from '@stimcar/libs-base';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { SubcontractorStore } from '../state/typings/store.js';
import { ClickableElement } from '../../lib/bulma/elements/ClickableElement.js';
import { ColorBoxSpan } from '../../lib/bulma/elements/ColorBoxSpan.js';
import { KanbanIdentityPictureComponent } from '../../lib/components/attachments/KanbanIdentityPictureComponent.js';
import { TruncableP } from '../../lib/components/misc/TruncableP.js';
import { kanbanPriorityLevelHelpers } from '../../lib/utils/kanbanPriorityLevelHelpers.js';
import { useComputeSubcontractorAttachmentUrl } from '../utils/useSubcontractorComputeAttachmentUrl.js';

export function computeSubcontractorOperationDisplayedLabel(
  uiOperation: SubcontractorUIOperation
): string {
  return `${uiOperation.operationLabel}${
    isTruthy(uiOperation.carElement) ? ` - ${uiOperation.carElement.label}` : ''
  }`;
}

export type SubcontractorUIOperation = Omit<
  SubcontractorPackageDeal,
  'operations' | 'id' | 'label'
> &
  Omit<SubcontractorOperation, 'id' | 'label'> & {
    readonly packageDealId: string;
    readonly packageDealLabel: string;
    readonly operationId: string;
    readonly operationLabel: string;
  };

interface UsePrepareDataForSubcontractorKanbanCardResult {
  readonly operationsToDo: readonly SubcontractorUIOperation[];
  readonly spareParts: readonly string[];
  readonly allOperationsAreFinished: boolean;
}

export function extractOperationsAndSpareParts(kanban: SubcontractorKanban) {
  const operationsToDo: SubcontractorUIOperation[] = [];
  const uniqueSpareParts: Set<string> = new Set();
  let allOperationsAreFinished = true;
  kanban.packageDealsToHandle.forEach(({ id, label, ...pck }) => {
    pck.spareParts.forEach((sp) => {
      uniqueSpareParts.add(`${sp.label} (${label})`);
    });
    pck.operations.forEach(({ id: oId, label: oLabel, ...o }) => {
      operationsToDo.push({
        ...pck,
        ...o,
        packageDealId: id,
        packageDealLabel: label,
        operationId: oId,
        operationLabel: oLabel,
      });
      if (!o.completionDate) {
        allOperationsAreFinished = false;
      }
    });
  });

  return {
    operationsToDo,
    spareParts: [...uniqueSpareParts],
    allOperationsAreFinished,
  };
}

export function usePrepareDataForSubcontractorKanbanCard(
  kanban: SubcontractorKanban
): UsePrepareDataForSubcontractorKanbanCardResult {
  return useMemo(() => {
    return extractOperationsAndSpareParts(kanban);
  }, [kanban]);
}

interface SubcontractorKanbanCardProps extends AppProps<SubcontractorStore> {
  readonly kanban: SubcontractorKanban;
  readonly additionalCardCssClasses?: string;
  readonly footerElements: readonly SubcontractorFooterElement[];
  readonly children: ReactNode;
}

export function SubcontractorKanbanCard({
  $gs,
  kanban,
  footerElements,
  additionalCardCssClasses,
  children,
}: SubcontractorKanbanCardProps): JSX.Element {
  return (
    <div
      className={`card m-b-md ${
        isTruthyAndNotEmpty(additionalCardCssClasses) ? additionalCardCssClasses : ''
      }`}
    >
      <div className="card-content p-sm">
        <SubcontractorKanbanCardHeader kanban={kanban} $gs={$gs} />
        {children}
      </div>
      <SubcontractorKanbanCardFooter footerElements={footerElements} />
    </div>
  );
}

interface SubcontractorKanbanCardHeaderProps extends AppProps<SubcontractorStore> {
  readonly kanban: SubcontractorKanban;
}

function SubcontractorKanbanCardHeader({
  $gs,
  kanban,
}: SubcontractorKanbanCardHeaderProps): JSX.Element {
  const attachmentUrl = useComputeSubcontractorAttachmentUrl($gs);

  const colorationCssClass = kanbanPriorityLevelHelpers.getCssIdFromKanbanPriorityLevel(
    kanban.priority
  );

  const [hasAMessage, requestMessageClassName] = useMemo(() => {
    let message = kanban.messages.find((m) => m.type === 'request' && m.status === 'pending');
    if (!message) {
      message = kanban.messages.find((m) => m.type === 'request' && m.status === 'rejected');
    }
    return [
      isTruthy(message),
      isTruthy(message) ? kanbanPriorityLevelHelpers.getKanbanMessageColorClassName(message) : '',
    ];
  }, [kanban]);

  return (
    <div className="media" style={{ marginBottom: '0.5rem' }}>
      <div className="media-left">
        <figure className="image m-t-sm">
          <KanbanIdentityPictureComponent
            isOnline
            thumbnailDisplayWidth={48}
            computeAttachmentUrl={attachmentUrl}
            $imageModal={$gs.$imageModal}
            kanbanId={kanban.id}
          />
        </figure>
      </div>
      <div className="media-content" style={{ overflowY: 'hidden' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <h5
              className="has-text-black has-text-weight-bold is-size-5 m-b-none"
              style={{ flexGrow: 1 }}
            >
              {kanban.infos.license}
            </h5>
            {hasAMessage && (
              <FaIcon
                additionalClass={`${requestMessageClassName} m-r-sm`}
                id={SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID}
              />
            )}
            {kanban.iconId && <FaIcon id={kanban.iconId} size="small" additionalClass="m-r-sm" />}
            <ColorBoxSpan colorOrCssClass={colorationCssClass} withBorder />
          </div>
          <TruncableP className="subtitle is-6" style={{ width: undefined }}>
            {kanban.infos.model}
          </TruncableP>
        </div>
      </div>
    </div>
  );
}

export interface SubcontractorFooterElement {
  readonly buttonLabel: string;
  readonly handlerCallback: () => void | Promise<void>;
  readonly isDisabled: boolean;
}

interface SubcontractorKanbanCardFooterProps {
  readonly footerElements: readonly SubcontractorFooterElement[];
}

function SubcontractorKanbanCardFooter({
  footerElements,
}: SubcontractorKanbanCardFooterProps): JSX.Element {
  /* eslint-disable jsx-a11y/interactive-supports-focus */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      <footer className="card-footer" style={{ borderTop: '1px solid rgb(219, 219, 219)' }}>
        {footerElements.map((f, i) => (
          <ClickableElement
            key={f.buttonLabel}
            clickHandler={f.handlerCallback}
            isDisabled={f.isDisabled}
            centerContent
            additionalStyle={
              i < footerElements.length ? { borderRight: '1px solid rgb(219, 219, 219)' } : {}
            }
            elementClassName="has-text-weight-semibold"
          >
            <a className="card-footer-item" role="button">
              {f.buttonLabel}
            </a>
          </ClickableElement>
        ))}
      </footer>
    </>
  );
}

interface SubcontractorKanbanCardStandTitleProps {
  readonly standLabel: string;
}
export function SubcontractorKanbanCardStandTitle({
  standLabel,
}: SubcontractorKanbanCardStandTitleProps): JSX.Element {
  return (
    <p className="title is-6" style={{ marginBottom: '0.5rem' }}>
      {`${standLabel}:`}
    </p>
  );
}

interface SubcontractorKanbanCardSparePartsProps {
  readonly spareParts: readonly string[];
}
export function SubcontractorKanbanCardSpareParts({
  spareParts,
}: SubcontractorKanbanCardSparePartsProps): JSX.Element {
  const [t] = useTranslation('subcontractor');
  return (
    <>
      {spareParts &&
        spareParts.map((sp) => <div key={sp}>{t('kanbanItem.sparePartElement', { sp })}</div>)}
    </>
  );
}
