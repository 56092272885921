import React from 'react';
import { isTruthy } from '@stimcar/libs-kernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { TruncableProps } from './TruncableP.js';

export interface TruncableLiProps extends TruncableProps {
  readonly actionToolbox?: {
    readonly action: () => void | Promise<void>;
    readonly icon?: string;
    readonly className?: string;
    readonly isDisplayed?: boolean;
    readonly isDisabled?: boolean;
  };
}

export function TruncableLi({
  children,
  style,
  tooltip,
  className,
  actionToolbox,
}: TruncableLiProps): JSX.Element {
  return (
    <li className={className}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            ...style,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={tooltip}
        >
          {children}
        </div>
        {isTruthy(actionToolbox) &&
          (actionToolbox.isDisabled === undefined || actionToolbox.isDisplayed) && (
            <Button
              additionalClass={`button ${actionToolbox.className}`}
              iconId={actionToolbox.icon || 'info-circle'}
              onClick={actionToolbox.action}
              disabled={actionToolbox.isDisabled}
            />
          )}
      </div>
    </li>
  );
}
