import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { enumerate, packageDealHelpers, workflowHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { computeOperatePath } from '../coreConstants.js';
import { START_KANBAN_HANDLING_WARNING_MODAL_EMPTY_STATE } from '../state/store.js';

export function StartKanbanHandlingWarningModal({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('refitit');

  const { $startKanbanHandlingWarningModal } = $gs;

  const stands = useGetState($gs.$siteConfiguration.$stands);

  const requestedOnStand = useGetState($startKanbanHandlingWarningModal.$requestedOnStand);

  const isExpertise = useMemo(() => {
    const stand = stands.find(({ id }) => id === requestedOnStand);
    return isTruthy(stand) && workflowHelpers.isExpertiseStand(stand);
  }, [stands, requestedOnStand]);

  const standsExpectedAt = useGetState($startKanbanHandlingWarningModal.$standsExpectedAt);
  const atWrongStand = !standsExpectedAt.includes(requestedOnStand);

  const packageDeals = useGetState(
    $startKanbanHandlingWarningModal.$kanban.optChaining().$packageDeals
  );
  const unachievablePkgCodes = isTruthy(packageDeals)
    ? packageDeals.filter((p) => !packageDealHelpers.isPackageDealAchievable(p)).map((p) => p.code)
    : [];
  const okClickHandlerCallback = useActionCallback(
    ({ actionDispatch, getState, navigate }) => {
      const { kanban, requestedOnStand } = getState().startKanbanHandlingWarningModal;
      actionDispatch.scopeProperty('operatorView').applyPayload({ startHandling: true });
      navigate(computeOperatePath(nonnull(kanban).id, requestedOnStand));
      actionDispatch.setProperty(
        'startKanbanHandlingWarningModal',
        START_KANBAN_HANDLING_WARNING_MODAL_EMPTY_STATE
      );
    },
    [],
    $gs
  );
  const alreadyHandledOnPosts = useGetState(
    $startKanbanHandlingWarningModal.$alreadyHandledOnPosts
  );

  return (
    <ModalCardDialog
      $active={$startKanbanHandlingWarningModal.$isActive}
      title={t('startHandlingWarningModal.title')}
      onOkClicked={okClickHandlerCallback}
    >
      {atWrongStand && (
        <p>
          {t('startHandlingWarningModal.atWrongStand', {
            standId: requestedOnStand,
            standsExpectedAt: enumerate(standsExpectedAt),
          })}
        </p>
      )}
      {alreadyHandledOnPosts && alreadyHandledOnPosts.length > 0 && (
        <p>
          {t('startHandlingWarningModal.alreadyHandledElsewhere', {
            post: enumerate(alreadyHandledOnPosts),
          })}
        </p>
      )}
      {isExpertise && unachievablePkgCodes.length > 0 && (
        <p>
          {t('startHandlingWarningModal.containsUnachievablePkgsMsg')}
          {unachievablePkgCodes.map((pkgCode): JSX.Element => {
            const pkg = nonnull(packageDeals).find((p) => p.code === pkgCode);
            return (
              <li>
                {t('startHandlingWarningModal.unachievablePkgMsg', {
                  pkgCode,
                  unachievableReason: nonnull(pkg).unachievableReason,
                })}
              </li>
            );
          })}
          {t('startHandlingWarningModal.unachievablePkgConfirmationMsg')}
        </p>
      )}
      <p>{t('startHandlingWarningModal.confirmation')}</p>
    </ModalCardDialog>
  );
}
