import i18next from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CarElement } from '@stimcar/libs-base';
import type { ActionContext, ReadOnlyActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  AvailablePermissionPaths,
  carElementHelpers,
  enumerate,
  globalHelpers,
  hasModifyPermission,
  mergeArrayItems,
  shortDayMonthYearHourFormatOptions,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { TableToolbarConfiguration } from '../../../lib/bulma/elements/table/Table.js';
import type { ColumnDesc } from '../../../lib/bulma/elements/table/typings/store.js';
import type { Store } from '../../state/typings/store.js';
import { applyAndFilterTableItemsAction } from '../../../lib/bulma/elements/table/filterAndSortUtils.js';
import { Table } from '../../../lib/bulma/elements/table/Table.js';
import { useComponentWithMountTracking } from '../../../lib/hooks/useComponentWithMountTracking.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import { getCarViewCategoryLabel } from '../../../utils/carViewCategoriesTranslationHooks.js';
import type { AdminCarElementsState, DeleteCarElementModalState } from './typings/store.js';
import { ByCarElementsFilter } from './ByCarElementsFilter.js';
import {
  carElementUpdateNotificationInEditDialogAction,
  EditCarElementDialog,
  openCreateDialogAction,
  openEditDialogAction,
} from './EditCarElementDialog.js';
import { openSortCarElementAction, SortCarElementsDialog } from './SortCarElementsDialog.js';
import { EMPTY_ADMIN_CAR_ELEMENTS_STATE } from './typings/store.js';

export function openDeleteDialogAction(
  { actionDispatch }: ActionContext<Store, DeleteCarElementModalState>,
  id: string
): void {
  actionDispatch.setProperty('active', true);
  actionDispatch.setProperty('carElementId', id);
}

const computeColumnDescs = (
  isEditionForbidden: boolean
): readonly ColumnDesc<Store, AdminCarElementsState, CarElement>[] => {
  return [
    {
      columnLabel: i18next.t('adminCarElements:table.columns.index'),
      columnType: 'display',
      id: 'index',
      propertyType: 'number',
      getPropertyValue: (ce): number => ce.index,
      columnStyle: { width: '7em' },
      isDisplayedByDefault: true,
      getDisplayedValue(ce): string {
        return carElementHelpers.getCarElementIndexToDisplayWithPadding(ce);
      },
    },
    {
      columnLabel: i18next.t('adminCarElements:table.columns.category'),
      columnType: 'display',
      id: 'category',
      propertyType: 'string',
      isDisplayedByDefault: true,
      getPropertyValue: (ce): string => getCarViewCategoryLabel(i18next.t, ce.category),
    },
    {
      columnLabel: i18next.t('adminCarElements:table.columns.label'),
      columnType: 'display',
      id: 'label',
      propertyType: 'string',
      isDisplayedByDefault: true,
      getPropertyValue: (ce): string => ce.label,
    },
    {
      columnLabel: i18next.t('adminCarElements:table.columns.childCarElementCount'),
      columnType: 'display',
      id: 'carElementCount',
      propertyType: 'number',
      isDisplayedByDefault: true,
      getPropertyValue: (ce): number => ce.shapes.length,
    },
    {
      columnLabel: i18next.t('adminCarElements:table.columns.lastModified'),
      columnType: 'display',
      id: 'lastModified',
      propertyType: 'date',
      getPropertyValue: (ce): number => ce.timestamp,
      isDisplayedByDefault: true,
      getDisplayedValue(ce): string {
        return globalHelpers.convertTimestampToDateString(
          ce.timestamp,
          shortDayMonthYearHourFormatOptions
        );
      },
    },
    {
      columnType: 'action',
      id: 'update',
      columnLabel: i18next.t('adminCarElements:table.editButton'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (): boolean => isEditionForbidden,
      getCellIconId: (): string => {
        return 'edit';
      },
      cellTooltip: i18next.t('adminCarElements:table.editButton'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, ce): Promise<void> => {
        await dispatch.scopeProperty('editCarElementDialog').exec(openEditDialogAction, ce.id);
      },
    },
    {
      columnType: 'action',
      id: 'delete',
      columnLabel: i18next.t('adminCarElements:table.deleteButton'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      disabled: (): boolean => isEditionForbidden,
      getCellIconId: (): string => {
        return 'trash';
      },
      cellTooltip: i18next.t('adminCarElements:table.deleteButton'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      action: async (dispatch, ce): Promise<void> => {
        await dispatch.scopeProperty('deleteCarElementDialog').exec(openDeleteDialogAction, ce.id);
      },
    },
  ];
};

const carElementsTableContentProvider = async ({
  carElementRepository,
  getState,
}: ReadOnlyActionContext<Store, AdminCarElementsState>): Promise<readonly CarElement[]> => {
  const carElements = await carElementRepository.getAllEntities();
  const { category, shapeId } = getState().searchFilter;
  return (
    carElements
      // Filter by category
      .filter((ce) => (isTruthyAndNotEmpty(category) ? ce.category === category : true))
      // Filter by shape
      .filter((ce) => (isTruthyAndNotEmpty(shapeId) ? ce.shapes.includes(shapeId) : true))
  );
};

export async function updateAdminCarElementsStateFromSSEAction(
  ctx: ActionContext<Store, AdminCarElementsState>,
  updatedCarElements: CarElement[],
  removedCarElementIds: readonly string[]
): Promise<void> {
  const { actionDispatch, getState, getGlobalState } = ctx;
  // Only update the state if the component is mounted
  if (!getState().componentIsMounted) {
    return;
  }

  // Merge actual items with SSE updates
  const { items } = getState();
  const newCarElements = mergeArrayItems(items, updatedCarElements, removedCarElementIds);

  // Compute ColmunDescs
  const permissions = getGlobalState().session?.user?.permissions;
  let isEditionForbidden = true;
  if (permissions) {
    isEditionForbidden = hasModifyPermission(
      permissions,
      AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('carElement')
    );
  }
  const columnDescs = computeColumnDescs(isEditionForbidden);

  // Update table items
  applyAndFilterTableItemsAction(ctx, newCarElements, columnDescs);

  const modalActionDispatch = actionDispatch.scopeProperty('editCarElementDialog');
  await Promise.all(
    newCarElements.map(
      async (ce): Promise<void> =>
        modalActionDispatch.exec(carElementUpdateNotificationInEditDialogAction, ce)
    )
  );
}

async function deleteCarElementAction({
  actionDispatch,
  carElementRepository,
  packageDealDescRepository,
  globalActionDispatch,
  getState,
}: ActionContext<Store, DeleteCarElementModalState>): Promise<void> {
  const { carElementId } = getState();
  const entities = (await packageDealDescRepository.getAllEntities()).filter(({ carElementIds }) =>
    carElementIds.includes(carElementId)
  );
  if (entities.length > 0) {
    globalActionDispatch.setProperty(
      'message',
      String(
        i18next.t('adminCarElements:deleteModal.elementIsUsedByPackageDeals', {
          value: enumerate(entities.map((e) => e.code)),
        })
      )
    );
  } else {
    await carElementRepository.archiveEntity(carElementId);
  }
  actionDispatch.setProperty('active', false);
}

function preInitializeAdminCarElementsTableAction({
  actionDispatch,
}: ActionContext<Store, AdminCarElementsState>) {
  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      sortsMenuState: {
        sorts: [
          {
            id: 'index',
            direction: 'DOWN',
          },
        ],
        active: false,
      },
    };
  });
}

export function AdminCarElements({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation(['adminCarElements', 'globals']);
  const $ = $gs.$adminView.$adminCarElements;
  const { $searchFilter } = $;

  // Allows to track if the view is mounted (through a boolean in the state)
  useComponentWithMountTracking($, undefined, EMPTY_ADMIN_CAR_ELEMENTS_STATE);

  const isOnline = useGetState($gs.$session.$isOnline);

  const { $editCarElementDialog, $deleteCarElementDialog } = $gs.$adminView.$adminCarElements;
  const isEditionForbidden = !useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.REPOSITORY_ACCESS_PERMISSION('carElement')
  );

  const openCreateDialogActionCallback = useActionCallback(
    openCreateDialogAction,
    [],
    $editCarElementDialog
  );

  const openSortCarElementActionCallback = useActionCallback(openSortCarElementAction, [], $);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(isEditionForbidden);
  }, [isEditionForbidden]);

  const loadAndFilterTableItemsActionCallback = useActionCallback(
    async (ctx) => {
      const items = await carElementsTableContentProvider(ctx);
      applyAndFilterTableItemsAction(ctx, items, columnDescs);
    },
    [columnDescs],
    $
  );

  const sortTableItemsActionCallback = useActionCallback(
    (ctx) =>
      applyAndFilterTableItemsAction(
        ctx,
        ctx.getState().items /* no need to reload items from IndexedDb */,
        columnDescs
      ),
    [columnDescs],
    $
  );

  const toolbarConf = useMemo((): TableToolbarConfiguration => {
    return {
      showSorts: true,
      filters: { show: true },
      itemCount: { show: true },
      showColumnSelection: true,
      textualSearch: {
        show: true,
        customPlaceholder: t('refititCommonComponents:carElementsFilter.textField'),
      },
      localStorageKey: LocalStorageKeys.ADMIN_CAR_ELEMENTS_COLUMNS_PREFERENCE,
    };
  }, [t]);

  const deleteActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, DeleteCarElementModalState>): Promise<void> =>
      await actionDispatch.exec(deleteCarElementAction),
    [],
    $deleteCarElementDialog
  );

  const preInitializeAdminCarElementsTableActionCallback = useActionCallback(
    preInitializeAdminCarElementsTableAction,
    [],
    $
  );
  return (
    <>
      <div className="level">
        <div className="level-left">
          <div>
            <p className="title is-2">{t('title')}</p>
            <p className="subtitle is-4">{t('subtitle')}</p>
          </div>
        </div>
        <div className="level-right">
          <div className="buttons">
            <button
              type="button"
              className="button"
              onClick={openSortCarElementActionCallback}
              disabled={isEditionForbidden}
            >
              <span>{t('buttons.reorderCarElements')}</span>
            </button>
            <button
              type="button"
              className="button"
              onClick={openCreateDialogActionCallback}
              disabled={isEditionForbidden}
            >
              <span>{t('buttons.new')}</span>
            </button>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-2">
          <ByCarElementsFilter
            $={$searchFilter}
            applyFilterAction={loadAndFilterTableItemsActionCallback}
            filterMiscCategory
          />
        </div>
        <div className="column">
          <Table
            $={$}
            columnDescs={columnDescs}
            contentProvider={carElementsTableContentProvider}
            preInitActionCallback={preInitializeAdminCarElementsTableActionCallback}
            isOnline={isOnline}
            isTruncable
            tableClassName="table is-bordered is-narrow is-hoverable is-fullwidth"
            toolbar={toolbarConf}
            isScrollable
            showRepositoryStatus
          />
        </div>
      </div>
      <EditCarElementDialog $gs={$gs} />
      <ModalCardDialog
        $active={$.$deleteCarElementDialog.$active}
        title={t('deleteModal.title')}
        onOkClicked={deleteActionCallback}
      >
        <p>{t('deleteModal.message')}</p>
      </ModalCardDialog>
      <SortCarElementsDialog
        $gs={$gs}
        sortTableItemsActionCallback={sortTableItemsActionCallback}
      />
    </>
  );
}
