/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DropResult } from '@hello-pangea/dnd';
import type { Kanban, WorkshopPostCategory } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import {
  chunkArrayInNParts,
  mapRecordValues,
  OPERATION_ATTRIBUTES,
  packageDealHelpers,
  sortingHelpers,
  transverseHelpers,
} from '@stimcar/libs-base';
import { applyPayload, isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { Button, FaIcon, TruncableTableTh } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../app/state/typings/store.js';
import { COLOR_PALETTE } from '../../utils/constants.js';
import { useGetIconForWorkshopPost } from '../workshop/implantation/useGetIconForWorkshopPost.js';
import type {
  DispatchOperationsState,
  InternalOperationsDispatchData,
  OperationsCell,
} from './typings/store.js';
import { ColorBlockRows } from './components/ColorBlockRows.js';
import { OperationOnStandList } from './components/OperationOnStandList.js';
import {
  DEFAULT_DISPATCH_PIXEL_PER_HOUR,
  DEFAULT_POST_DISPATCH_TABLE_ROW_HEIGHT_IN_PIXEL,
  DEFAULT_TECHNICIAN_ROW_HEIGHT_IN_PIXEL,
} from './constants.js';
import { prepareDataForTubeBalancing } from './operationsDispatchUtils.js';

function getKanbanWithMovedOperations(
  initialKanban: Kanban,
  operations: Record<string, InternalOperationsDispatchData[]>,
  implantationId: string,
  toPost: string,
  movedOperationsIds: readonly string[]
): Kanban {
  if (movedOperationsIds.length === 0) {
    // Nothing to do
    return initialKanban;
  }

  // New value to indicate the post the operations will be attached to
  const newPostAttributeValue = `${implantationId}:${toPost}`;

  // Retrieve all operations from the Record structure
  const allOperations = mapRecordValues(operations, (values) => values).flatMap((values) => values);

  let newKanban = initialKanban;
  movedOperationsIds.forEach((opeId) => {
    // Retrieve operation data from ID
    const operation = allOperations.find((ope) => ope.operationId === opeId);

    // Modify the attribute "post" to move the operation
    if (operation) {
      const payload: DeepPartial<Kanban> = {
        packageDeals: [
          {
            id: operation.packageDealId,
            operations: [
              {
                id: operation.operationId,
                attributes: {
                  [OPERATION_ATTRIBUTES.WORKSHOP_POST]: newPostAttributeValue,
                },
              },
            ],
          },
        ],
      };

      newKanban = applyPayload(newKanban, payload);
    }
  });
  return newKanban;
}

const EMPTY_STRING_ARRAY: readonly string[] = [];

interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, DispatchOperationsState>;
  readonly implantationId: string;
  readonly sortedCategories: readonly WorkshopPostCategory[];
  readonly filterDoneOperationsBlocksDisplay?: boolean;
  readonly validationPostDnDCallback?: (
    kanban: Kanban,
    categoryIds: readonly string[]
  ) => Promise<void> | void;
  readonly currentCategoryId: string;
  readonly standId: string;
}

export function OperationsDispatchComponent({
  $,
  $gs,
  sortedCategories,
  implantationId,
  filterDoneOperationsBlocksDisplay = false,
  validationPostDnDCallback,
  currentCategoryId,
  standId,
}: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const kanbanWithOperationsDispatched = useGetState($.$kanbanWithOperationsDispatched);

  const sortedCategoryIds = useMemo(() => {
    return sortedCategories.map((c) => c.id);
  }, [sortedCategories]);

  const chunkedCategories = useMemo(() => {
    if (sortedCategories.length > 3) {
      return chunkArrayInNParts([...sortedCategories], 2);
    }
    return [sortedCategories];
  }, [sortedCategories]);

  const operations = useMemo(() => {
    const datas: Record<string, InternalOperationsDispatchData[]> = {};
    sortedCategoryIds.forEach((id) => {
      datas[id] = [];
    });
    const packageDeals = isTruthy(kanbanWithOperationsDispatched)
      ? kanbanWithOperationsDispatched.packageDeals
          .filter(packageDealHelpers.buildPackageDealFilter('achievable'))
          .sort(sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction('DOWN'))
      : [];

    packageDeals.forEach((pd) =>
      packageDealHelpers.getUnfinishedOperationsForStandId(pd, standId).forEach((o) => {
        const qualifiedPostAttribute = o.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST];

        const categoryId = isTruthy(qualifiedPostAttribute)
          ? transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
              String(qualifiedPostAttribute)
            ).categoryId
          : currentCategoryId;
        const operation: InternalOperationsDispatchData = {
          operationId: o.id,
          operationLabel: packageDealHelpers.getOperationDisplayedLabel(o, pd.variables),
          operationWorkload: o.workload,
          packageDealId: pd.id,
          packageDealLabel: packageDealHelpers.getPackageDealDisplayedLabel(pd),
          carElementLabel: pd.carElement?.label,
        };
        if (categoryId && datas[String(categoryId)] !== undefined) {
          datas[String(categoryId)].push(operation);
        } else {
          datas[currentCategoryId].push(operation);
        }
      })
    );
    return datas;
  }, [currentCategoryId, kanbanWithOperationsDispatched, sortedCategoryIds, standId]);

  /**
   * This callback moves the specified operations to the specified post.
   * If no operationsIds have been specified in the arguments, we use the operations selected in the state
   */
  const moveOperationsCallback = useActionCallback(
    function moveOperationsAction(
      { getState, actionDispatch },
      toPostId: string,
      movedOperationsIds?: readonly string[]
    ) {
      // If the moved operations are not passed as arguments, we move the operations that are selected in the state
      const movedFromSelection = movedOperationsIds === undefined;

      const { selectedOperationsIds } = getState();

      const operationsIdsToBeMoved = movedFromSelection
        ? selectedOperationsIds
        : nonnull(movedOperationsIds);

      if (operationsIdsToBeMoved.length > 0) {
        // Compute the kanban with the moved operations
        const newKanban: Kanban = getKanbanWithMovedOperations(
          nonnull(kanbanWithOperationsDispatched),
          operations,
          implantationId,
          toPostId,
          operationsIdsToBeMoved
        );

        // Update the modified kanban
        actionDispatch.setProperty('kanbanWithOperationsDispatched', newKanban);

        // Deselect the operations if the moved operations were the selected ones
        if (movedFromSelection) {
          actionDispatch.setProperty('selectedOperationsIds', []);
        }

        if (validationPostDnDCallback) {
          // Validate if a callback was specified
          const asyncValidation = async (): Promise<void> => {
            await validationPostDnDCallback(newKanban, sortedCategoryIds);
          };
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          asyncValidation();
        }
      }
    },
    [
      kanbanWithOperationsDispatched,
      operations,
      implantationId,
      sortedCategoryIds,
      validationPostDnDCallback,
    ],
    $
  );

  const onDragEnd = async (result: DropResult): Promise<void> => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      return;
    }

    await moveOperationsCallback(destination.droppableId, [result.draggableId]);
  };

  const operationsPerCategoryColumn = useMemo(() => {
    return prepareDataForTubeBalancing(standId, sortedCategoryIds, kanbanWithOperationsDispatched);
  }, [standId, sortedCategoryIds, kanbanWithOperationsDispatched]);

  const colorInfos = useMemo(() => {
    const map = new Map<string, string>();
    if (!isTruthy(kanbanWithOperationsDispatched)) {
      return map;
    }
    let colorIndex = 0;
    const dispatchedOperations = packageDealHelpers.getAllOperations(
      kanbanWithOperationsDispatched.packageDeals
    );
    operationsPerCategoryColumn.forEach((cell) => {
      const blocks = cell?.blocks ?? [];
      blocks.forEach((o) => {
        const foundOp = dispatchedOperations.find((dispOp) => dispOp.id === o.operationId);
        if (foundOp && !foundOp.completionDate) {
          map.set(o.operationId, COLOR_PALETTE[colorIndex]);
          colorIndex = colorIndex >= COLOR_PALETTE.length - 1 ? 0 : colorIndex + 1;
        }
      });
    });
    return map;
    // We really want to recompute this map only if the kanban to dispatch has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operationHeaderLabel = useMemo(() => {
    if (kanbanWithOperationsDispatched) {
      return t('operationsDispatch.operationTitle', {
        license: kanbanWithOperationsDispatched.infos.license,
        model: kanbanWithOperationsDispatched.infos.model,
      });
    }
    return t('operationsDispatch.operationTitle_noSelected');
  }, [kanbanWithOperationsDispatched, t]);

  const nbSelectedOperations = useGetState($.$selectedOperationsIds).length;

  const clearSelectCallback = useSetCallback($.$selectedOperationsIds, EMPTY_STRING_ARRAY);

  useEffect(() => {
    return (): void => {
      // Empty the selected operations array when the dialog is closed
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      clearSelectCallback();
    };
  }, [clearSelectCallback]);

  let msgNbSelectedOperations = '';
  if (nbSelectedOperations === 0) {
    msgNbSelectedOperations = t('operationsDispatch.noSelectedOperation');
  } else if (nbSelectedOperations === 1) {
    msgNbSelectedOperations = t('operationsDispatch.oneSelectedOperation');
  } else {
    msgNbSelectedOperations = t('operationsDispatch.severalSelectedOperations', {
      nbOperations: nbSelectedOperations,
    });
  }

  return (
    <>
      <div className="is-display-flex is-align-items-center m-b-sm" style={{ minHeight: '30px' }}>
        <span className="p-sm has-text-dark">{msgNbSelectedOperations}</span>
        {nbSelectedOperations > 0 && (
          <Button
            tooltip={t('operationsDispatch.clearSelection')}
            onClick={clearSelectCallback}
            size="small-xs"
            iconId="times"
          />
        )}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="columns">
          {chunkedCategories.map(
            (categories, i): JSX.Element => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="column" key={i}>
                <table className="table is-bordered is-narrow">
                  <thead>
                    <tr>
                      <th style={{ width: '1%' }}>{t('operationsDispatch.postTitle')}</th>
                      <TruncableTableTh colspan={2}>{operationHeaderLabel}</TruncableTableTh>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((category, index): JSX.Element => {
                      const operationsColorRowCell = operationsPerCategoryColumn.find(
                        (o) => o?.categoryId === category.id
                      );
                      return (
                        <DispatchTableRow
                          key={category.id}
                          $gs={$gs}
                          tubeStandId={standId}
                          categoryId={category.id}
                          categoryLabel={category.id}
                          colorInfos={colorInfos}
                          rowIndex={index}
                          technicianRowHeightInPixel={DEFAULT_TECHNICIAN_ROW_HEIGHT_IN_PIXEL}
                          pixelsPerHour={DEFAULT_DISPATCH_PIXEL_PER_HOUR}
                          operations={operations}
                          operationsColorRowCell={operationsColorRowCell}
                          filterDoneOperationsBlocksDisplay={filterDoneOperationsBlocksDisplay}
                          $selectedOperationsIds={$.$selectedOperationsIds}
                          moveOperationsCallback={moveOperationsCallback}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      </DragDropContext>
    </>
  );
}

interface DispatchTableRowProps extends AppProps<Store> {
  readonly tubeStandId: string;
  readonly categoryId: string;
  readonly categoryLabel: string;
  readonly colorInfos: Map<string, string>;
  readonly rowIndex: number;
  readonly operationsColorRowCell: OperationsCell | undefined;
  readonly pixelsPerHour: number;
  readonly technicianRowHeightInPixel: number;
  readonly operations: Record<string, readonly InternalOperationsDispatchData[]>;
  readonly filterDoneOperationsBlocksDisplay: boolean;
  readonly $selectedOperationsIds: StoreStateSelector<Store, readonly string[]>;
  readonly moveOperationsCallback: (toPostId: string) => Promise<void>;
}

function DispatchTableRow({
  $gs,
  tubeStandId,
  categoryId,
  categoryLabel,
  rowIndex,
  colorInfos,
  pixelsPerHour,
  technicianRowHeightInPixel,
  operations,
  operationsColorRowCell,
  filterDoneOperationsBlocksDisplay,
  $selectedOperationsIds,
  moveOperationsCallback,
}: DispatchTableRowProps): JSX.Element {
  const computeStyle = (isDraggingOver: boolean): string => {
    if (isDraggingOver) {
      return 'has-background-info';
    }
    return '';
  };
  const selectedOperationsIds = useGetState($selectedOperationsIds);

  const setAllCheckboxCallback = useActionCallback(
    ({ actionDispatch }, postId: string, active: boolean) => {
      const operationsOnPost = operations[postId];
      if (operationsOnPost !== undefined && operationsOnPost.length > 0) {
        if (!active) {
          actionDispatch.setValue([]);
        } else {
          const operationsIdsOnPost = operations[postId].map((ope) => ope.operationId);
          actionDispatch.setValue(operationsIdsOnPost);
        }
      }
    },
    [operations],
    $selectedOperationsIds
  );

  return (
    <Droppable key={categoryId} droppableId={categoryId} isDropDisabled={false}>
      {(provided, snapshot): JSX.Element => {
        const operationsIdsOnPost = operations[categoryId].map((ope) => ope.operationId);
        // this boolean is used to know if post is empty
        const hasOperations = operationsIdsOnPost.length > 0;

        const allOperationsOnPostAreSelected = operationsIdsOnPost.every((id) =>
          selectedOperationsIds.includes(id)
        );

        const noOperationOnPostIsSelected = operationsIdsOnPost.every(
          (id) => !selectedOperationsIds.includes(id)
        );

        const isDispatchActivated = selectedOperationsIds.length > 0 && noOperationOnPostIsSelected;

        return (
          <tr
            // Beautiful-react-dnd API returns an any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={(e): any => provided.innerRef(e)}
            className={computeStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            <PostBloc
              tubeStandId={tubeStandId}
              categoryId={categoryId}
              categoryLabel={categoryLabel}
              isCheckAllActivated={hasOperations && !allOperationsOnPostAreSelected}
              isUncheckAllActivated={hasOperations && !noOperationOnPostIsSelected}
              isDispatchActivated={isDispatchActivated}
              setAllCheckboxCallback={setAllCheckboxCallback}
              moveOperationsCallback={moveOperationsCallback}
              $gs={$gs}
            />
            <td style={{ width: '60%' }}>
              <div
                style={{
                  height: `${DEFAULT_POST_DISPATCH_TABLE_ROW_HEIGHT_IN_PIXEL}px`,
                  overflow: 'auto',
                }}
              >
                <OperationOnStandList
                  operationDatas={operations}
                  qualifiedPostId={categoryId}
                  colorInfos={colorInfos}
                  $={$selectedOperationsIds}
                />
                {provided.placeholder}
              </div>
            </td>

            {operationsColorRowCell && (
              <PostCell
                // We don't have any informations able to tell prevent React to re-render this element
                // eslint-disable-next-line react/no-array-index-key
                key={rowIndex}
                cell={operationsColorRowCell}
                technicianRowHeightInPixel={technicianRowHeightInPixel}
                colorInfos={colorInfos}
                pixelsPerHour={pixelsPerHour}
                filterDoneOperationsBlocksDisplay={filterDoneOperationsBlocksDisplay}
              />
            )}
          </tr>
        );
      }}
    </Droppable>
  );
}

interface PostCellProps {
  readonly cell: OperationsCell | undefined;
  readonly technicianRowHeightInPixel: number;
  readonly colorInfos: Map<string, string>;
  readonly pixelsPerHour: number;
  readonly filterDoneOperationsBlocksDisplay: boolean;
}

function PostCell({
  cell,
  technicianRowHeightInPixel,
  colorInfos,
  pixelsPerHour,
  filterDoneOperationsBlocksDisplay,
}: PostCellProps): JSX.Element {
  return (
    <td
      className={cell ? '' : 'has-background-grey-lighter'}
      style={{
        paddingTop: '4px',
      }}
    >
      <ColorBlockRows
        height={technicianRowHeightInPixel}
        colorInfos={colorInfos}
        blockWidthPerTenthOfHour={pixelsPerHour / 10}
        filterDoneOperationsBlocks={filterDoneOperationsBlocksDisplay}
        cell={cell}
      />
    </td>
  );
}

interface PostBlocProps extends AppProps<Store> {
  readonly tubeStandId: string;
  readonly categoryId: string;
  readonly categoryLabel: string;
  readonly isCheckAllActivated: boolean;
  readonly isUncheckAllActivated: boolean;
  readonly isDispatchActivated: boolean;
  readonly setAllCheckboxCallback: (postId: string, active: boolean) => Promise<void>;
  readonly moveOperationsCallback: (toPostId: string) => Promise<void>;
}

function PostBloc({
  $gs,
  tubeStandId,
  categoryId,
  categoryLabel,
  isCheckAllActivated,
  isUncheckAllActivated,
  isDispatchActivated,
  setAllCheckboxCallback,
  moveOperationsCallback,
}: PostBlocProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const postIcon = useGetIconForWorkshopPost($gs, tubeStandId, categoryId);

  const checkAllCalback = useCallback(async (): Promise<void> => {
    await setAllCheckboxCallback(categoryId, true);
  }, [setAllCheckboxCallback, categoryId]);

  const uncheckAllCalback = useCallback(async (): Promise<void> => {
    await setAllCheckboxCallback(categoryId, false);
  }, [setAllCheckboxCallback, categoryId]);

  const moveSelectedOperationsCallback = useCallback(async (): Promise<void> => {
    await moveOperationsCallback(categoryId);
  }, [moveOperationsCallback, categoryId]);

  return (
    <td
      className="has-text-centered"
      style={{
        paddingTop: '4px',
      }}
    >
      <div className="columns is-vcentered">
        <div className="column">
          <FaIcon id={postIcon} tooltip={categoryId} size="medium-2x" />
        </div>
        <div className="column">
          <b>{categoryLabel}</b>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button iconId="r/check-square" onClick={checkAllCalback} disabled={!isCheckAllActivated} />
        <Button iconId="r/square" onClick={uncheckAllCalback} disabled={!isUncheckAllActivated} />
      </div>
      <div style={{ marginTop: '10px' }}>
        <Button
          tooltip={t('operationsDispatch.dispatchButtonTooltip')}
          iconId="sign-in-alt"
          additionalClass="is-primary"
          additionalIconClass="fa-rotate-90"
          onClick={moveSelectedOperationsCallback}
          isFullWidth
          disabled={!isDispatchActivated}
        />
      </div>
    </td>
  );
}
