import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Kanban,
  WorkshopPost,
  WorkshopPostCategory,
  WorkshopPostLevel,
  WorkshopStandImplantation,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  chunkArrayInPartsOfLength,
  globalHelpers,
  kanbanHelpers,
  transverseHelpers,
  WORKSHOP_IMPLANTATION_ANOMALY_POST_CATEGORY_ID,
  workshopHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type {
  WorkshopActionProviderType,
  WorkshopImplantationViewState,
} from '../typings/store.js';
import { Select } from '../../../bulma/form/Select.js';
import { EditAnomalyMessageModal } from './EditAnomalyMessageModal.js';
import { KanbanEjectionModal } from './KanbanEjectionModal.js';
import { MoveForwardModal } from './MoveForwardModal.js';
import { OperationsReDispatchModal } from './OperationsReDispatchModal.js';
import { SelectKanbanWizard } from './SelectKanbanWizard.js';
import { SendKanbanToAnomalyModal } from './SendKanbanToAnomalyModal.js';
import {
  computeAnomalyPostQualifiedCategoryId,
  createSortWorkshopPostKanbansByRecency,
  initializeRedispatchOperationsAfterKanbanMovingModal,
  openKanbanSelectionWizard,
} from './workshopImplantationUtils.js';
import { WorkshopPostOperationsModal } from './WorkshopPostOperationsModal.js';
import {
  AnomalyPostViewer,
  WorkshopPostQueueElementComponent,
  WorkshopPostViewerWithOperationsOverview,
} from './WorkshopPostViewerComponents.js';

function openShowOperationsModalAction(
  { actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  kanbanId: string | undefined,
  qualifiedPostId: string,
  standId: string,
  allowOperationsToggling: boolean
): void {
  actionDispatch.reduce((initial): WorkshopImplantationViewState => {
    return {
      ...initial,
      workshopPostOperationsModal: {
        ...initial.workshopPostOperationsModal,
        active: true,
        kanbanId,
        expendedOperationIds: [],
        qualifiedPostId,
        standId,
        allowOperationsToggling,
      },
    };
  });
}

interface WorkshopPostLevelUIProps extends AppProps<Store> {
  readonly level: WorkshopPostLevel;
  readonly isOnline: boolean;
  readonly userPostId: string;
  readonly implantation: WorkshopStandImplantation;
  readonly viewKanbans: readonly Kanban[];
  readonly standId: string;
  readonly username: string;
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
  readonly showAddToLevelQueueAction?: boolean;
  readonly postActionsProvider?: WorkshopActionProviderType;
  readonly queueActionsProvider?: WorkshopActionProviderType;
  readonly mirrorEffect: boolean;
}

function WorkshopPostLevelUI({
  level,
  isOnline,
  userPostId,
  implantation,
  viewKanbans,
  standId,
  $,
  $gs,
  username,
  showAddToLevelQueueAction = false,
  postActionsProvider,
  queueActionsProvider,
  mirrorEffect,
}: WorkshopPostLevelUIProps): JSX.Element {
  const [t] = useTranslation('workshop');

  const hasOpenAndon = useMemo(() => {
    const postIds = level.posts.map((p) =>
      globalHelpers.computeQualifiedWorkshopPostId(
        implantation.id,
        level.id,
        workshopHelpers.getImplantationPostName(p)
      )
    );
    for (const kanban of viewKanbans) {
      const isOnOneOdPosts = kanbanHelpers.isKanbanCurrentlyOnOneOfWorkshopPosts(
        kanban,
        standId,
        postIds
      );
      if (isOnOneOdPosts && kanbanHelpers.hasOpenAndon(kanban)) {
        return true;
      }
    }
    return false;
  }, [implantation.id, level.id, level.posts, standId, viewKanbans]);

  const loadingQueueKanbans = useMemo(() => {
    const qualifiedLevelId = globalHelpers.computeQualifiedWorkshopPostId(
      implantation.id,
      level.id
    );
    const kanbans = viewKanbans
      .filter((k) => kanbanHelpers.isKanbanCurrentlyOnWorkshopPost(k, standId, qualifiedLevelId))
      .sort(createSortWorkshopPostKanbansByRecency(standId, qualifiedLevelId));
    const chunkSize = 2 * workshopHelpers.getMaxPostsNumberFromSelfAndAncestors(level);
    return chunkArrayInPartsOfLength(kanbans, chunkSize);
  }, [implantation.id, level, standId, viewKanbans]);

  const openWizardActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      const furtherCategories = transverseHelpers.getFurtherWorkshopCategories(
        implantation,
        level.id,
        true
      );
      const qualifiedFirstPostId = globalHelpers.computeQualifiedWorkshopPostId(
        implantation.id,
        furtherCategories[0].id,
        workshopHelpers.getImplantationPostName(furtherCategories[0].posts[0])
      );
      const { categoryId, workshopPostId } =
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(qualifiedFirstPostId);
      await actionDispatch.exec(
        openKanbanSelectionWizard,
        implantation,
        standId,
        furtherCategories,
        nonnull(workshopPostId),
        [username],
        nonnull(categoryId),
        true
      );
    },
    [implantation, level.id, standId, username],
    $
  );

  const displayedPosts = useMemo(() => {
    let doReflection = false;
    if (mirrorEffect === undefined) {
      const storedMirrorEffect = localStorage.getItem(
        LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT
      );
      doReflection = isTruthyAndNotEmpty(storedMirrorEffect)
        ? storedMirrorEffect === 'true'
        : false;
    } else {
      doReflection = mirrorEffect;
    }
    return doReflection ? level.posts.slice().reverse() : level.posts;
  }, [level, mirrorEffect]);

  const levelAncestors = useMemo(() => {
    if (!isTruthy(level?.ancestors)) {
      return [];
    }
    let doReflection = false;
    if (mirrorEffect === undefined) {
      const storedMirrorEffect = localStorage.getItem(
        LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT
      );
      doReflection = isTruthyAndNotEmpty(storedMirrorEffect)
        ? storedMirrorEffect === 'true'
        : false;
    } else {
      doReflection = mirrorEffect;
    }
    return doReflection ? level.ancestors.slice().reverse() : level.ancestors;
  }, [level, mirrorEffect]);

  function getPostId(post: string | WorkshopPost): string {
    return typeof post === 'string' ? post : post.id;
  }

  return (
    <>
      <div className="column">
        <div className={`box ${hasOpenAndon ? 'action-needed-lighter' : 'workshop-box'}`}>
          {levelAncestors.length > 0 && (
            <div className="columns is-centered">
              {levelAncestors.map(
                (l): JSX.Element => (
                  <WorkshopPostLevelUI
                    key={l.id}
                    implantation={implantation}
                    level={l}
                    isOnline={isOnline}
                    userPostId={userPostId}
                    viewKanbans={viewKanbans}
                    standId={standId}
                    $={$}
                    $gs={$gs}
                    username={username}
                    showAddToLevelQueueAction={showAddToLevelQueueAction}
                    postActionsProvider={postActionsProvider}
                    queueActionsProvider={queueActionsProvider}
                    mirrorEffect={mirrorEffect}
                  />
                )
              )}
            </div>
          )}
          {loadingQueueKanbans.map((chunk, i): JSX.Element => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="columns is-centered" style={{ flexWrap: 'wrap' }} key={i}>
                {chunk.map((k): JSX.Element => {
                  return (
                    <div key={k.id} className="column is-narrow">
                      <WorkshopPostQueueElementComponent
                        isOnline={isOnline}
                        categoryId={level.id}
                        kanban={k}
                        actionsProvider={queueActionsProvider}
                        allKanbansInImplantation={viewKanbans}
                        $={$}
                        $gs={$gs}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="columns is-centered is-vcentered" style={{ flexWrap: 'wrap' }}>
            {showAddToLevelQueueAction && (
              <div className="column is-narrow">
                <Button
                  onClick={openWizardActionCallback}
                  additionalClass="is-primary"
                  tooltip={t('implantation.loadingButton')}
                  iconId="plus-square"
                />
              </div>
            )}
            {displayedPosts.map(
              (p): JSX.Element => (
                <WorkshopPostViewerColumn
                  key={level.id + getPostId(p)}
                  category={level}
                  categoryLabel={level.id}
                  post={p}
                  implantation={implantation}
                  isOnline={isOnline}
                  userPostId={userPostId}
                  viewKanbans={viewKanbans}
                  standId={standId}
                  $={$}
                  $gs={$gs}
                  username={username}
                  allowOperationsToggling={level.toggleOperationsFromAnywhere}
                  actionsProvider={postActionsProvider}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

interface WorkshopPostViewerColumnProps extends AppProps<Store> {
  readonly category: WorkshopPostCategory;
  readonly categoryLabel: string;
  readonly userPostId: string;
  readonly implantation: WorkshopStandImplantation;
  readonly isOnline: boolean;
  readonly post: string | WorkshopPost;
  readonly standId: string;
  readonly username: string;
  readonly viewKanbans: readonly Kanban[];
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
  readonly allowOperationsToggling: boolean;
  readonly actionsProvider?: WorkshopActionProviderType;
}

function WorkshopPostViewerColumn({
  category,
  viewKanbans,
  post,
  categoryLabel,
  implantation,
  isOnline,
  standId,
  username,
  userPostId,
  $,
  $gs,
  allowOperationsToggling,
  actionsProvider,
}: WorkshopPostViewerColumnProps): JSX.Element {
  const postName = workshopHelpers.getImplantationPostName(post);

  const postId = globalHelpers.computeWorkshopPostId(category.id, postName);
  const postDisplayedLabel = globalHelpers.computeWorkshopPostId(categoryLabel, postName);
  const kanban = kanbanHelpers.getKanbanCurrentlyOnWorkshopPost(
    viewKanbans,
    standId,
    implantation.id,
    postId
  );

  const openShowOperationsModal = useActionCallback(
    async ({ actionDispatch }, theKanban: Kanban | undefined, qualifiedPostId: string) => {
      await actionDispatch.exec(
        openShowOperationsModalAction,
        theKanban?.id,
        qualifiedPostId,
        standId,
        allowOperationsToggling
      );
    },
    [allowOperationsToggling, standId],
    $
  );

  const initializeRedispatchOperationsAfterKanbanMovingModalCallback = useActionCallback(
    async (
      { actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
      kanbanId: string,
      destinationPostCategory: string,
      destinationPostName: string | undefined,
      originCategory: string
    ): Promise<void> => {
      await actionDispatch.exec(
        initializeRedispatchOperationsAfterKanbanMovingModal,
        standId,
        implantation,
        destinationPostCategory,
        destinationPostName,
        kanbanId,
        [username],
        originCategory
      );
    },
    [implantation, standId, username],
    $
  );

  const openActionUnavailableWhenDisconnectedModal = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch
        .scopeProperty('actionUnavailableWhenDisconnectedModal')
        .setProperty('isActive', true);
    },
    [],
    $
  );

  const isHidden = useMemo(() => {
    if (typeof post === 'string') {
      return false;
    }
    if (post.isOptional && !isTruthy(kanban) && postId !== userPostId) {
      return true;
    }
    return false;
  }, [kanban, post, postId, userPostId]);

  return (
    <div className="column is-narrow">
      {!isHidden && (
        <WorkshopPostViewerWithOperationsOverview
          implantation={implantation}
          category={category}
          postDisplayedLabel={postDisplayedLabel}
          postName={postName}
          isOnline={isOnline}
          isHighlighted={postId === userPostId}
          kanban={kanban}
          actionsProvider={actionsProvider}
          openShowOperationsModal={openShowOperationsModal}
          dropHandler={initializeRedispatchOperationsAfterKanbanMovingModalCallback}
          userName={username}
          openActionUnavailableWhenDisconnectedModal={openActionUnavailableWhenDisconnectedModal}
          allKanbansInImplantation={viewKanbans}
          standId={standId}
          $={$}
          $gs={$gs}
        />
      )}
    </div>
  );
}

interface WorkshopImplantationViewProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
  readonly standId: string;
  readonly implantation: WorkshopStandImplantation;
  readonly disableAllButtons?: boolean;
  readonly postActionsProvider?: WorkshopActionProviderType;
  readonly queueActionsProvider?: WorkshopActionProviderType;
  readonly anomalyPostActionProvider?: WorkshopActionProviderType;
  // dispatch === undefined means that the implantation can not be changed
  readonly $selectImplantation: StoreStateSelector<Store, string> | undefined;
}

export function WorkshopImplantationComponent({
  $,
  $gs,
  standId,
  implantation,
  disableAllButtons = false,
  postActionsProvider,
  queueActionsProvider,
  anomalyPostActionProvider,
  $selectImplantation,
}: WorkshopImplantationViewProps): JSX.Element {
  const [t] = useTranslation('workshop');
  const username = useGetState($gs.$session.$user.optChaining().$login) ?? '';
  const postQualifiedId = useGetState($gs.$session.$infos.optChaining().$label) ?? '';
  const { workshopPostId } =
    transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(postQualifiedId);

  const {
    $actionUnavailableWhenDisconnectedModal,
    $postEjectionModal,
    $sendKanbanToAnomalyModal,
    $editAnomalyMessageModal,
    $moveForwardModal,
    $operationsRedispatchModal,
    $selectKanbanWizardState,
    $workshopPostOperationsModal,
  } = $;

  const postCategoryId = WORKSHOP_IMPLANTATION_ANOMALY_POST_CATEGORY_ID;

  const kanbans = useGetState($.$kanbans);
  const kanbanInAnomalyIds = useGetState($.$kanbanInAnomalyIds);
  const mirrorEffect = useGetState($.$mirrorEffect);

  const kanbansInAnomaly = useMemo(() => {
    if (isTruthy(implantation)) {
      const anomalyQualifiedId = computeAnomalyPostQualifiedCategoryId(implantation.id);
      return kanbans
        .filter((k) => kanbanInAnomalyIds.includes(k.id))
        .sort(createSortWorkshopPostKanbansByRecency(standId, anomalyQualifiedId));
    }
    return [];
  }, [implantation, standId, kanbanInAnomalyIds, kanbans]);

  const toggleMirrorEffectCallback = useActionCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    ({ actionDispatch }) => {
      const storedMirrorEffect = localStorage.getItem(
        LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT
      );
      if (storedMirrorEffect === null) {
        localStorage.setItem(
          LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT,
          String(true)
        );
        actionDispatch.setProperty('mirrorEffect', true);
      } else {
        // eslint-disable-next-line no-extra-boolean-cast
        const boolValue = storedMirrorEffect === 'true';
        const newValue = !boolValue;
        localStorage.setItem(
          LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT,
          String(newValue)
        );
        actionDispatch.setProperty('mirrorEffect', newValue);
      }
    },
    [],
    $
  );

  const stands = useGetState($gs.$siteConfiguration.$stands);
  const implantationIds = workshopHelpers.getImplantationsFromStands(stands);
  const isOnline = useGetState($gs.$session.$isOnline);
  const selectKanbanStateWizardIsActive = useGetState($.$selectKanbanWizardState.$isActive);

  // This is a workaround to reduce perf issue caused by the render of all the app on each interaction
  // This is not intended to last, and just reduce the amount of component to render in this screen
  // (by removing from the dom components behind the modal)
  if (useGetState($operationsRedispatchModal.$isActive)) {
    return (
      <div>
        <OperationsReDispatchModal
          implantation={implantation}
          standId={standId}
          $={$operationsRedispatchModal}
          $gs={$gs}
        />
      </div>
    );
  }
  if (selectKanbanStateWizardIsActive) {
    return (
      <div>
        <SelectKanbanWizard $={$selectKanbanWizardState} $gs={$gs} />
      </div>
    );
  }
  return (
    <div>
      <div className="columns is-centered">
        <div className="column is-narrow">
          <Button
            onClick={toggleMirrorEffectCallback}
            additionalClass="is-primary"
            tooltip={t('implantation.spinButtonTooltip')}
            iconId="exchange-alt"
          />
        </div>
        {implantation.definition.map(
          (l): JSX.Element => (
            <WorkshopPostLevelUI
              key={l.id}
              level={l}
              userPostId={workshopPostId ?? ''}
              implantation={implantation}
              isOnline={isOnline}
              viewKanbans={kanbans}
              standId={standId}
              username={username}
              $={$}
              $gs={$gs}
              showAddToLevelQueueAction={!disableAllButtons}
              postActionsProvider={postActionsProvider}
              queueActionsProvider={queueActionsProvider}
              mirrorEffect={mirrorEffect ?? false}
            />
          )
        )}
        <div className=" column is-narrow has-text-centered">
          <div className="m-b-sm">
            {$selectImplantation && keysOf(implantationIds).length > 1 && (
              <Select entries={keysOf(implantationIds)} $={$selectImplantation} isFullWidth />
            )}
          </div>
          {kanbansInAnomaly.map((k): JSX.Element => {
            return (
              <AnomalyPostViewer
                key={k.id}
                kanban={k}
                standId={standId}
                implantationId={implantation.id}
                categoryId={postCategoryId}
                isOnline={isOnline}
                actionsProvider={anomalyPostActionProvider}
                allKanbansInImplantation={kanbans}
                $={$}
                $gs={$gs}
              />
            );
          })}
        </div>
      </div>
      <SendKanbanToAnomalyModal $={$sendKanbanToAnomalyModal} $gs={$gs} />
      <EditAnomalyMessageModal $={$editAnomalyMessageModal} $gs={$gs} />
      <KanbanEjectionModal $={$postEjectionModal} />
      <WorkshopPostOperationsModal $={$workshopPostOperationsModal} $gs={$gs} kanbans={kanbans} />
      <MoveForwardModal
        standId={standId}
        user={username}
        implantation={implantation}
        $={$moveForwardModal}
      />
      <ModalCardDialog
        $active={$actionUnavailableWhenDisconnectedModal.$isActive}
        title={t('implantation.actionUnavailableWhenDisconnectedModal.title')}
      >
        <p>{t('implantation.actionUnavailableWhenDisconnectedModal.message')}</p>
      </ModalCardDialog>
    </div>
  );
}
