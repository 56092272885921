/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import type { FaIconProps } from './FaIcon.js';
import { FaIcon } from './FaIcon.js';

interface Props extends Omit<FaIconProps, 'additionalClass'> {
  readonly clickHandler:
    | (() => Promise<void> | void)
    | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => Promise<void> | void);
  readonly elementClassName?: string;
  readonly isDisabled?: boolean;
}

export function ClickableIcon({
  clickHandler,
  isDisabled = false,
  elementClassName,
  size = 'small-xs',
  ...props
}: Props): JSX.Element {
  const colorClass = isDisabled
    ? 'clickable-icon-default-disabled-color'
    : 'clickable-icon-default-color';
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={elementClassName}
      role="button"
      tabIndex={0}
      style={{
        cursor: `${isDisabled ? 'not-allowed' : 'pointer'}`,
        outline: 'none',
      }}
      onClick={!isDisabled ? clickHandler : undefined}
    >
      <FaIcon size={size} additionalClass={colorClass} {...props} />
    </a>
  );
}
