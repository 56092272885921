import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CarViewCategory } from '@stimcar/libs-base';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { SelectFormField } from '../../../lib/bulma/form/SelectFormField.js';
import { CarViewsSwitch } from '../../../lib/components/carviews/CarViewsSwitch.js';
import { useCategoryEntries } from '../../utils/useCategoryEntries.js';

export type ByCarElementsFilterState = {
  readonly category: CarViewCategory | '';
  readonly shapeId: string;
};

interface Props {
  readonly applyFilterAction: NoArgActionCallback<Store>;
  readonly additionalCleaningAction?: NoArgActionCallback<Store>;
  readonly middleElementsToDisplay?: JSX.Element;
  readonly bottomElementsToDisplay?: JSX.Element;
  readonly filterMiscCategory?: boolean;
  readonly $: StoreStateSelector<Store, ByCarElementsFilterState>;
}

export function ByCarElementsFilter({
  $,
  applyFilterAction,
  additionalCleaningAction,
  middleElementsToDisplay,
  bottomElementsToDisplay,
  filterMiscCategory = false,
}: Props): JSX.Element {
  const [t] = useTranslation(['refititCommonComponents', 'globals']);

  const onCategoryChangedActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, ByCarElementsFilterState>): Promise<void> => {
      actionDispatch.setProperty('shapeId', '');
      await actionDispatch.execCallback(applyFilterAction);
    },
    [applyFilterAction],
    $
  );

  const $categoryWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$category,
    onCategoryChangedActionCallback
  );
  const shapeId = useGetState($.$shapeId);

  const onShapeClickedActionCallback = useActionCallback(
    async (
      { actionDispatch }: ActionContext<Store, ByCarElementsFilterState>,
      id: string
    ): Promise<void> => {
      actionDispatch.setProperty('shapeId', id);
      await actionDispatch.execCallback(applyFilterAction);
    },
    [applyFilterAction],
    $
  );

  const selectedShapeIds = useMemo(() => {
    return shapeId ? [shapeId] : [];
  }, [shapeId]);

  const clearFilterHandler = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, ByCarElementsFilterState>): Promise<void> => {
      actionDispatch.applyPayload({
        category: '',
        shapeId: '',
      });
      if (additionalCleaningAction) {
        await actionDispatch.execCallback(additionalCleaningAction);
      }
      await actionDispatch.execCallback(applyFilterAction);
    },
    [additionalCleaningAction, applyFilterAction],
    $
  );
  const categoryEntries = useCategoryEntries(filterMiscCategory);
  const category = useGetState($.$category);

  return (
    <>
      {middleElementsToDisplay}
      <SelectFormField
        label={t('carElementsFilter.categoryField')}
        entries={categoryEntries}
        isEmptyValueAllowed
        $={$categoryWithChangeTrigger}
        isFullWidth
        sortEntries
      />
      {category && category !== 'MISC' && (
        <FormField label={t('carElementsFilter.shapesField')}>
          <CarViewsSwitch
            category={category}
            selectedShapes={selectedShapeIds}
            shapeClicked={onShapeClickedActionCallback}
          />
        </FormField>
      )}
      {bottomElementsToDisplay}
      <div className="level-right">
        <button type="button" className="button is-pulled-right" onClick={clearFilterHandler}>
          {t('carElementsFilter.clearAll')}
        </button>
      </div>
    </>
  );
}
