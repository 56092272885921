import { type TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Civility,
  Customer,
  InvoiceInfo,
  Kanban,
  PurchaseOrder,
  RepositoryEntityPayload,
  SpecificFields,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  nonDeleted,
  purchaseOrderHelpers,
  sortingHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ScrollableTableComponent } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { Checkbox } from '../../../lib/bulma/form/Checkbox.js';
import { EditCustomerDialog } from '../../../lib/components/customer/EditCustomerDialog.js';
import { DisplayContentOrPlaceholder } from '../../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { TableSortableHeaderComponent } from '../../../lib/components/TableSortableHeaderComponent.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import type {
  InvoiceDetail,
  KanbanInvoiceTabState,
  PurchaseOrderCustomerAddEditModalState,
} from './typings/store.js';
import {
  AddInvoiceReferenceModal,
  openAddInvoiceReferenceModalAction,
} from './AddInvoiceReferenceModal.js';
import { DeleteInvoiceInfosModal } from './DeleteInvoiceInfosModal.js';
import { InvoiceDetailItem } from './InvoiceDetailItem.js';
import { getExistingPurchaseOrder } from './invoicingUtils.js';
import {
  openPurchaseOrderAddEditModalAction,
  PurchaseOrderAddEditModal,
} from './PurchaseOrderAddEditModal.js';
import { PurchaseOrderDeleteModal } from './PurchaseOrderDeleteModal.js';
import { RefundInvoiceModal } from './RefundInvoiceModal.js';

// We do not need to provide contrats if EditCustomerDialog field is hidden
const NO_CONTRACT_CODES: readonly string[] = [];

/**
 * Build a link to the accounting software for the provided invoide
 * @param invoiceId
 * @param invoiceFirmId
 * @param t
 * @returns
 */
function getInvoiceLink(
  invoiceId: number | null,
  invoiceFirmId: number | null,
  t: TFunction
): string | null {
  if (isTruthy(invoiceId) && isTruthy(invoiceFirmId)) {
    return t('tabs.invoice.invoiceLinkPattern', { invoiceId, invoiceFirmId });
  }
  return null;
}

/**
 * Return the reference from the invoice id of a refund
 * - ID is a number generated by the accounted software
 * - reference is a string generated with a prefix, the current year and an incremented ID
 *
 * This function search among all the known InvoiceInfos instances for the id of a refunded invoice
 * It can then return the reference which is stored on the found InvoiceInfos instance
 * @param refundedInvoiceId
 * @param invoiceInfos
 * @returns
 */
function getRefundedInvoiceReference(
  refundedInvoiceId: number | null,
  invoiceInfos: readonly InvoiceInfo[]
): string | null {
  if (isTruthy(refundedInvoiceId)) {
    // Search for reference among all the known invoice infos
    const foundInvoiceInfos = invoiceInfos.find(({ invoiceId }) => invoiceId === refundedInvoiceId);
    return foundInvoiceInfos?.reference ?? null;
  }
  return null;
}

/**
 * Return an ID to be used for an InvoiceDetail instance
 * - if both a purchaseOrder and an invoice infos, id will be '<purchaseOrderId> - <invoiceInfoId>'
 * - if only one is provided, resulting id will be the id of the non null or undefined object
 * - if none is provided, return an empty string (this case should never happen by construction)
 * @param purchaseOrderId technical ID of related purchase order (can be null, but not if invoiceInfosId is also null)
 * @param invoiceInfosId technical ID of related invoice infos object (can be null, but not if purchaseOrderId is also null)
 * @returns
 */
function getInvoiceDetailId(purchaseOrderId: string | null, invoiceInfosId: string | null): string {
  return [purchaseOrderId, invoiceInfosId].filter(isTruthy).join(' - ');
}

function createInvoiceDetail(
  purchaseOrder: PurchaseOrder,
  invoiceInfo: InvoiceInfo | null,
  t: TFunction
): InvoiceDetail {
  const invoiceInfoId = invoiceInfo?.id ?? null;
  const purchaseOrderId = purchaseOrder.id;
  const reference = invoiceInfo?.reference ?? null;
  const amount = invoiceInfo?.amount ?? null;
  const invoiceId = invoiceInfo?.invoiceId ?? null;
  const invoiceFirmId = invoiceInfo?.invoiceFirmId ?? null;
  const isRefund = invoiceInfo?.isRefund ?? null;
  const refundedInvoiceId = invoiceInfo?.refundedInvoiceId ?? null;

  const allInvoiceInfos = purchaseOrder.invoiceInfos.filter(nonDeleted) ?? [];
  // Looking for an InvoiceInfos corresponding to a refund for the current invoice
  const refundingInvoice = allInvoiceInfos.find(
    (info) => info.isRefund && info.refundedInvoiceId === invoiceId
  );
  const refundingInvoiceId = refundingInvoice?.invoiceId ?? null;

  return {
    invoiceDetailId: getInvoiceDetailId(purchaseOrderId, invoiceInfoId),
    purchaseOrderId,
    purchaseOrderLabel: purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(purchaseOrder),
    purchaseOrderCustomerLabel: isTruthy(purchaseOrder.customer)
      ? purchaseOrderHelpers.getPurchaseOrderDisplayedCustomer(purchaseOrder.customer)
      : '',
    reference,
    amount,
    invoiceInfoId,
    invoiceId,
    invoiceFirmId,
    link: getInvoiceLink(invoiceId, invoiceFirmId, t),
    isRefund,
    refundedInvoiceReference: getRefundedInvoiceReference(refundedInvoiceId, allInvoiceInfos),
    refundingInvoiceId,
  };
}

function getInvoiceDetailsToDisplay(
  purchaseOrders: readonly PurchaseOrder[],
  t: TFunction
): readonly InvoiceDetail[] {
  const invoiceDetailsToDisplay: InvoiceDetail[] = purchaseOrders
    .filter(nonDeleted)
    .flatMap((purchaseOrder) => {
      if (purchaseOrder.invoiceInfos.filter(nonDeleted).length === 0) {
        // No invoice info yet on this purchase order
        return createInvoiceDetail(purchaseOrder, null, t);
      }
      return purchaseOrder.invoiceInfos
        .filter(nonDeleted)
        .map((invoiceInfo) => createInvoiceDetail(purchaseOrder, invoiceInfo, t));
    });

  // Default sort
  return [...invoiceDetailsToDisplay].sort(
    (
      { purchaseOrderLabel: label1, invoiceId: invoiceId1 }: InvoiceDetail,
      { purchaseOrderLabel: label2, invoiceId: invoiceId2 }: InvoiceDetail
    ) => {
      // Sort by purchase order label
      const labelCompare = (label1 ?? '').localeCompare(label2 ?? '');
      if (labelCompare === 0) {
        // Sort by invoice Id
        return (invoiceId1 ?? -1) - (invoiceId2 ?? -1);
      }
      return labelCompare;
    }
  );
}

type InvoiceDetailsComponentProps = {
  readonly kanban: Kanban;
  readonly purchaseOrders: readonly PurchaseOrder[];
  readonly isPurchaseOrderMandatory: boolean;
  readonly $: StoreStateSelector<Store, KanbanInvoiceTabState>;
} & AppProps<Store>;

export function InvoiceDetailsComponent({
  $,
  $gs,
  kanban,
  purchaseOrders,
  isPurchaseOrderMandatory,
}: InvoiceDetailsComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const canModifyInvoicing = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_ADMIN_INVOICING
  );

  const canEditPurchaseOrders = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_EDIT_PURCHASE_ORDERS
  );

  const canEditPurchaseOrderCustomers = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_EDIT_PURCHASE_ORDERS_CUSTOMER
  );

  const {
    $purchaseOrderDeletionModalState,
    $invoiceReferenceAddModalState,
    $invoiceRefundModalState,
    $invoiceInfoDeletionModalState,
  } = $;

  const invoiceDetails = useMemo(
    (): readonly InvoiceDetail[] => getInvoiceDetailsToDisplay(purchaseOrders, t),
    [purchaseOrders, t]
  );

  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);

  const sortedInvoiceDetails = useMemo((): readonly InvoiceDetail[] => {
    const sortedDatas = invoiceDetails.slice();
    let sortFunction: (i1: InvoiceDetail, i2: InvoiceDetail) => number;
    switch (sortBy) {
      case 'reference':
      case 'refundedInvoiceReference':
      case 'purchaseOrderLabel':
      case 'purchaseOrderCustomerLabel':
      case 'link':
        sortFunction = sortingHelpers.createSortByStringField(sortDirection, sortBy);
        break;
      case 'isRefund':
        sortFunction = sortingHelpers.createSortByBooleanField(sortDirection, sortBy);
        break;
      case 'amount':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      default:
        return sortedDatas;
    }
    return sortedDatas.sort(sortFunction);
  }, [invoiceDetails, sortBy, sortDirection]);

  const showTechnicalId = useGetState($.$showTechnicalId);

  const openAddPurchaseOrderDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openPurchaseOrderAddEditModalAction);
    },
    [],
    $.$purchaseOrderAddModalState
  );
  const openAddReferenceDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openAddInvoiceReferenceModalAction);
    },
    [],
    $invoiceReferenceAddModalState
  );
  const purchaseOrderAddModalOnFormSubmitCallback = useActionCallback(
    async ({ actionDispatch, httpClient, kanbanRepository, getState }) => {
      const { purchaseNumber, label } = getState().formData;
      // TODO Do we want to create purchaseOrders automatically ?
      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          purchaseOrders: [
            {
              id: httpClient.getBrowserSequence().next(),
              purchaseNumber,
              label: isTruthyAndNotEmpty(label) ? label : null,
              invoiceInfos: [],
            },
          ],
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
      actionDispatch.setProperty('isActive', false);
    },
    [kanban],
    $.$purchaseOrderAddModalState
  );
  const purchaseOrderEditModalOnFormSubmitCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository, getState }) => {
      const { purchaseOrderId, formData } = getState();
      const { purchaseNumber, label } = formData;
      const currentPurchaseOrder = await getExistingPurchaseOrder(
        kanbanRepository,
        kanban.id,
        purchaseOrderId
      );
      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          purchaseOrders: [
            {
              id: purchaseOrderId,
              purchaseNumber,
              label: isTruthyAndNotEmpty(label) ? label : null,
              invoiceInfos: currentPurchaseOrder?.invoiceInfos ?? [],
            },
          ],
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
      actionDispatch.setProperty('isActive', false);
    },
    [kanban],
    $.$purchaseOrderEditModalState
  );
  const submitAddCustomerActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
    }: ActionContext<Store, PurchaseOrderCustomerAddEditModalState>): Promise<void> => {
      const { purchaseOrderId, editCustomerDialogState } = getState();
      const { formData } = editCustomerDialogState;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { warnings, type, ...formDataWithoutWarnings } = formData;
      const newCustomer: SpecificFields<Customer> = {
        ...formDataWithoutWarnings,
        invoiceId: Number.parseInt(formDataWithoutWarnings.invoiceId, 10),
        contract: formDataWithoutWarnings.contractCode,
        civility: formDataWithoutWarnings.civility as Civility,
        individual: type === 'individual',
      };

      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          purchaseOrders: [
            {
              id: purchaseOrderId,
              customer: newCustomer,
            },
          ],
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
      actionDispatch.scopeProperty('editCustomerDialogState').setProperty('active', false);
    },
    [kanban],
    $.$purchaseOrderCustomerAddModalState
  );

  const submitEditCustomerActionCallback = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
    }: ActionContext<Store, PurchaseOrderCustomerAddEditModalState>): Promise<void> => {
      const { purchaseOrderId, editCustomerDialogState } = getState();
      const { formData } = editCustomerDialogState;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { warnings, type, ...formDataWithoutWarnings } = formData;
      const newCustomer: SpecificFields<Customer> = {
        ...formDataWithoutWarnings,
        invoiceId: Number.parseInt(formDataWithoutWarnings.invoiceId, 10),
        contract: formDataWithoutWarnings.contractCode,
        civility: formDataWithoutWarnings.civility as Civility,
        individual: type === 'individual',
      };

      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          purchaseOrders: [
            {
              id: purchaseOrderId,
              customer: newCustomer,
            },
          ],
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
      actionDispatch.scopeProperty('editCustomerDialogState').setProperty('active', false);
    },
    [kanban],
    $.$purchaseOrderCustomerEditModalState
  );

  return (
    <DisplayContentOrPlaceholder
      isScrollable
      displayCondition={invoiceDetails.length > 0}
      placeholder={t('tabs.invoice.emptyPlaceholder')}
    >
      <>
        <div className="is-flex is-justify-content-space-between">
          <Checkbox $={$.$showTechnicalId} text={t('tabs.invoice.id')} />
          <div>
            <Button
              iconId="plus"
              size="small"
              onClick={openAddPurchaseOrderDialogCallback}
              label={t('tabs.invoice.actions.addPurchaseOrder')}
            />
            {canModifyInvoicing && (
              <Button
                iconId="plus"
                size="small"
                onClick={openAddReferenceDialogCallback}
                label={t('tabs.invoice.actions.addInvoice')}
              />
            )}
          </div>
        </div>
        <ScrollableTableComponent tableClassName="table is-narrow is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              {showTechnicalId && <th>{t('tabs.idTitle')}</th>}
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="purchaseOrderLabel"
                content={t('tabs.invoice.purchaseOrder')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="purchaseOrderCustomerLabel"
                content={t('tabs.invoice.purchaseOrderCustomer')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="isRefund"
                content={t('tabs.invoice.type')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="reference"
                content={t('tabs.invoice.reference')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="amount"
                content={t('tabs.invoice.amount')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="link"
                content={t('tabs.invoice.link')}
              />
            </tr>
          </thead>
          <tbody>
            {sortedInvoiceDetails.map((invoiceDetail) => (
              <InvoiceDetailItem
                key={invoiceDetail.invoiceDetailId}
                kanban={kanban}
                invoiceDetail={invoiceDetail}
                canModifyInvoicing={canModifyInvoicing}
                canEditPurchaseOrders={canEditPurchaseOrders}
                canEditPurchaseOrderCustomers={canEditPurchaseOrderCustomers}
                showTechnicalId={showTechnicalId}
                $={$}
              />
            ))}
          </tbody>
        </ScrollableTableComponent>
        <PurchaseOrderAddEditModal
          $={$.$purchaseOrderAddModalState}
          title={t('tabs.invoice.purchaseOrderAddEditModal.addTitle')}
          kanbanId={kanban.id}
          onFormSubmitCallback={purchaseOrderAddModalOnFormSubmitCallback}
        />
        <PurchaseOrderAddEditModal
          $={$.$purchaseOrderEditModalState}
          title={t('tabs.invoice.purchaseOrderAddEditModal.editTitle')}
          kanbanId={kanban.id}
          onFormSubmitCallback={purchaseOrderEditModalOnFormSubmitCallback}
        />
        <PurchaseOrderDeleteModal $={$purchaseOrderDeletionModalState} kanbanId={kanban.id} />
        <EditCustomerDialog
          $={$.$purchaseOrderCustomerAddModalState.$editCustomerDialogState}
          submitValidDataAction={submitAddCustomerActionCallback}
          hideContractIdField
          contractCodes={NO_CONTRACT_CODES}
        />
        <EditCustomerDialog
          $={$.$purchaseOrderCustomerEditModalState.$editCustomerDialogState}
          submitValidDataAction={submitEditCustomerActionCallback}
          hideContractIdField
          contractCodes={NO_CONTRACT_CODES}
        />
        <AddInvoiceReferenceModal
          $={$invoiceReferenceAddModalState}
          kanbanId={kanban.id}
          purchaseOrders={purchaseOrders}
          isPurchaseOrderMandatory={isPurchaseOrderMandatory}
        />
        <RefundInvoiceModal $={$invoiceRefundModalState} kanbanId={kanban.id} />
        <DeleteInvoiceInfosModal $={$invoiceInfoDeletionModalState} kanbanId={kanban.id} />
      </>
    </DisplayContentOrPlaceholder>
  );
}
