import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SparePartsProvider } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormFieldContentActions } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminSparePartsProvidersState,
  EditSparePartsProviderData,
  EditSparePartsProviderDialogState,
  OnSparePartProviderChangeActionCallback,
} from './typings/store.js';
import { EMPTY_EDIT_SPAREPARTS_PROVIDER_DIALOG_STATE } from './typings/store.js';

const HORIZONTAL_BODY_FLEX_GROW = 5;
const HORIZONTAL_LABEL_FLEX_GROW = 1;

export function openCreateSparePartsProviderModalAction({
  actionDispatch,
}: ActionContext<Store, EditSparePartsProviderDialogState>): void {
  actionDispatch.setValue({
    ...EMPTY_EDIT_SPAREPARTS_PROVIDER_DIALOG_STATE,
    active: true,
    mode: 'create',
  });
}

export function openEditSparePartsProviderModalAction(
  { actionDispatch }: ActionContext<Store, EditSparePartsProviderDialogState>,
  { id, sparePartMarginPercentage }: SparePartsProvider
): void {
  actionDispatch.setValue({
    ...EMPTY_EDIT_SPAREPARTS_PROVIDER_DIALOG_STATE,
    active: true,
    mode: 'update',
    formData: {
      ...EMPTY_EDIT_SPAREPARTS_PROVIDER_DIALOG_STATE.formData,
      id,
      sparePartMargin: isTruthy(sparePartMarginPercentage)
        ? String((sparePartMarginPercentage * 100).toFixed(0))
        : '',
    },
  });
}

async function formSubmitSparePartsProviderAction(
  {
    getState,
    httpClient,
    actionDispatch,
    getGlobalState,
  }: ActionContext<Store, AdminSparePartsProvidersState>,
  onSparePartProviderChangeActionCallback: OnSparePartProviderChangeActionCallback
): Promise<void> {
  const { site } = nonnull(getGlobalState().session.infos);
  const { mode, formData } = getState().editSparePartsProviderDialogState;
  const { id, sparePartMargin } = formData;
  const provider: SparePartsProvider = { id };
  const providerWithSparePartMarginPercentage: SparePartsProvider = isTruthyAndNotEmpty(
    sparePartMargin
  )
    ? { ...provider, sparePartMarginPercentage: Number.parseInt(sparePartMargin, 10) / 100 }
    : provider;

  if (mode === 'create') {
    await httpClient.httpPostAsJSON(CoreBackendRoutes.CREATE_SPAREPARTS_PROVIDER, {
      siteId: site.id,
      providerToCreate: providerWithSparePartMarginPercentage,
    });
  } else {
    await httpClient.httpPostAsJSON(CoreBackendRoutes.UPDATE_SPAREPARTS_PROVIDER(provider.id), {
      siteId: site.id,
      providerToUpdate: providerWithSparePartMarginPercentage,
    });
  }

  actionDispatch.setProperty(
    'editSparePartsProviderDialogState',
    EMPTY_EDIT_SPAREPARTS_PROVIDER_DIALOG_STATE
  );
  await actionDispatch.execCallback(
    onSparePartProviderChangeActionCallback,
    [providerWithSparePartMarginPercentage],
    []
  );
}

const checkFieldContentActions: CheckFormFieldContentActions<
  Store,
  EditSparePartsProviderDialogState,
  ['create' | 'update', readonly SparePartsProvider[]]
> = {
  id: ({ value, t }, mode, existingProviders): string | undefined => {
    if (mode === 'create') {
      const existingIds = existingProviders.map(({ id }) => id);
      if (existingIds.includes(value)) {
        return t('warning.idAlreadyExists', { providerId: value });
      }
    }
    return undefined;
  },
};

const mandatoryFields: (keyof EditSparePartsProviderData)[] = ['id'];

interface EditSparePartsProviderModalProps {
  readonly $: StoreStateSelector<Store, AdminSparePartsProvidersState>;
  readonly onSparePartProviderChangeActionCallback: OnSparePartProviderChangeActionCallback;
}

export function EditSparePartsProviderModal({
  $,
  onSparePartProviderChangeActionCallback,
}: EditSparePartsProviderModalProps): JSX.Element {
  const [t] = useTranslation('adminSparePartsProviders');
  const { $editSparePartsProviderDialogState } = $;
  const existingProviders = useGetState($.$items);
  const mode = useGetState($editSparePartsProviderDialogState.$mode);
  const formWarning = useGetState($editSparePartsProviderDialogState.$formWarning);

  const isIdInputDisabled = useMemo(() => mode === 'update', [mode]);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        formSubmitSparePartsProviderAction,
        onSparePartProviderChangeActionCallback
      );
    },
    [onSparePartProviderChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditSparePartsProviderDialogState,
    ['create' | 'update', readonly SparePartsProvider[]]
  >(
    {
      $: $editSparePartsProviderDialogState,
      mandatoryFields,
      checkFieldContentActions,
      checkFormConsistencyAction: undefined,
      submitValidDataAction,
      t,
    },
    mode,
    existingProviders
  );

  return (
    <ModalCardDialog
      $active={$editSparePartsProviderDialogState.$active}
      titleIconId="user-plus"
      title={t('editSparePartsProviderDialog.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('formData.id')}
        disabled={isIdInputDisabled}
        placeholder={t('formData.id')}
        $={$formDataWithChangeTrigger.$id}
        horizontal={{
          labelFlexGrow: HORIZONTAL_LABEL_FLEX_GROW,
          bodyFlexGrow: HORIZONTAL_BODY_FLEX_GROW,
        }}
      />
      <InputFormField
        type="number"
        $={$formDataWithChangeTrigger.$sparePartMargin}
        label={t('editSparePartsProviderDialog.sparePartsPriceMargin')}
        horizontal={{
          labelFlexGrow: HORIZONTAL_LABEL_FLEX_GROW,
          bodyFlexGrow: HORIZONTAL_BODY_FLEX_GROW,
        }}
      />
    </ModalCardDialog>
  );
}
