import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { useGetState } from '@stimcar/libs-uikernel';
import { ProtectedRoute } from '../lib/components/misc/ProtectedRoute.js';
import type { SubcontractorStore } from './state/typings/store.js';
import { SubcontractorLoginComponent } from './authentication/SubcontractorLoginComponent.js';
import { SubcontractorOperate } from './operate/SubcontractorOperate.js';
import { SubcontractorSelectKanban } from './selectKanban/SubcontractorSelectKanban.js';
import {
  SUBCONTRACTOR_LOGIN_FULL_PATH,
  SUBCONTRACTOR_OPERATE_KANBAN_FULL_PATH,
  SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH,
} from './subcontractorConstants.js';
import { SubcontractorNavBar } from './SubcontractorNavBar.js';

export function SubcontractorApp({ $gs }: AppProps<SubcontractorStore>): JSX.Element {
  const user = useGetState($gs.$session.$user);

  return (
    <Routes>
      <Route
        path={SUBCONTRACTOR_LOGIN_FULL_PATH}
        element={
          <ProtectedRoute
            redirectTo={SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH}
            giveWay={user === undefined}
          >
            <SubcontractorLoginComponent $gs={$gs} />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute redirectTo={SUBCONTRACTOR_LOGIN_FULL_PATH} giveWay={user !== undefined}>
            <>
              <SubcontractorNavBar $gs={$gs} />
              <div className="container is-fluid app">
                <Routes>
                  <Route
                    path={SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH}
                    element={<SubcontractorSelectKanban $gs={$gs} />}
                  />
                  <Route
                    path={SUBCONTRACTOR_OPERATE_KANBAN_FULL_PATH}
                    element={<SubcontractorOperate $gs={$gs} />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH} />}
                  />
                </Routes>
              </div>
            </>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
