import React, { useEffect } from 'react';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { useActionCallback } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';
import {
  initializeWorkshopView,
  WorkshopImplantationViewFromProvidedState,
} from '../../lib/components/workshop/implantation/WorkshopImplantationViewFromProvidedState.js';

export function WorkshopImplantationDisplay({ $gs }: AppProps<Store>): JSX.Element {
  const $ = $gs.$displayView.$workshopImplantationView;

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(initializeWorkshopView);
    },
    [],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  return <WorkshopImplantationViewFromProvidedState isInteractive={false} $={$} $gs={$gs} />;
}
