import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PriceableKanban, Workflow } from '@stimcar/libs-base';
import { packageDealHelpers, workflowProgressHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import type { DrawNodePrefs } from './GraphicWorkflow.js';
import { GraphicWorkflow } from './GraphicWorkflow.js';

interface Props {
  readonly workflow: Workflow;
  readonly kanban: PriceableKanban;
}

export function GraphicWorkflowWithProgress({ kanban, workflow }: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const progress = useMemo(() => {
    return workflowProgressHelpers.computeProgress(workflow.definition, kanban);
  }, [workflow, kanban]);
  return (
    <GraphicWorkflow
      workflowNode={workflow.definition}
      getDrawPrefs={(id: string): DrawNodePrefs | undefined => {
        const standProgress = progress.progressByStand[id];
        if (!standProgress) {
          return undefined;
        }
        /**
         * Color computation
         */
        const {
          operationCount,
          operationDone,
          operationTotalWorkload,
          operationDoneWorkload,
          sparePartCount,
          sparePartReceived,
        } = standProgress;
        let fillColor = 'white';
        if (operationCount === 0 && sparePartCount === 0) {
          fillColor = 'LightGray';
        } else if (operationCount === operationDone && sparePartCount === sparePartReceived) {
          fillColor = '#b0f2b6'; // $refitit-green-light
        } else if (operationDone > 0 || sparePartReceived > 0) {
          fillColor = '#f2d197'; // $refitit-orange-light
        }
        /**
         * Fill ratio computation
         */
        let fillRatio = 1;
        if (operationTotalWorkload > 0 || operationCount > 0 || sparePartCount > 0) {
          fillRatio = packageDealHelpers.computePackageDealProgressPercentage(standProgress);
        }
        /**
         * Border color
         */
        let borderColor = 'grey';
        if (isTruthy(standProgress.workInProgressPost)) {
          borderColor = !progress.expectedStandIds.includes(id) ? 'red' : 'black';
        }
        return {
          fillColor,
          fillRatio,
          borderWidth:
            isTruthy(standProgress.workInProgressPost) || progress.expectedStandIds.includes(id)
              ? 4
              : 2,
          borderColor,
          boldFont: isTruthy(standProgress.workInProgressPost),
          tooltip: t('graphicalWorkflowWthProgress.tooltip', {
            opToDo: operationCount - operationDone,
            spToReceive: sparePartCount - sparePartReceived,
            remainingTime: (operationTotalWorkload - operationDoneWorkload).toFixed(2),
            totalTime: operationTotalWorkload,
          }),
        };
      }}
    />
  );
}
