import React, { useMemo } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AddOnButton, AddOnButtonsProps, FaIconSize } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { AddOnButtons } from '@stimcar/libs-uitoolkit';

export type ChoiceButtonDef = Omit<AddOnButton, 'additionalClass'> & {
  readonly selectedClass?: string;
};

// eslint-disable-next-line @typescript-eslint/promise-function-async
const NOOP = async (): Promise<void> => {
  // no op
};

export const convertChoiceButtonDefToAddOnButton = (
  { id, iconId, label, selectedClass, tooltip }: ChoiceButtonDef,
  defaultSelectedClass: string,
  value: string | undefined
): AddOnButton => {
  const theLabel = label ?? id;
  const theTooltip = tooltip ?? label;
  return {
    id,
    iconId,
    label: theLabel,
    additionalClass: value === id ? (selectedClass ?? defaultSelectedClass) : '',
    tooltip: theTooltip,
  };
};

export interface ChoiceAddOnButtonsProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, string>;
  readonly buttonsDesc: readonly ChoiceButtonDef[];
  readonly size?: FaIconSize;
  readonly disabled?: boolean;
}

export function ChoiceAddOnButtons<SD extends AnyStoreDef>({
  $,
  buttonsDesc,
  disabled = false,
  size,
}: ChoiceAddOnButtonsProps<SD>): JSX.Element {
  const value = useGetState($);
  const setValueActionCallback = useActionCallback(
    ({ actionDispatch }, newValue: string) => {
      actionDispatch.setValue(newValue);
    },
    [],
    $
  );
  const onClick = useMemo(() => {
    return disabled ? NOOP : setValueActionCallback;
  }, [disabled, setValueActionCallback]);
  const buttons = useMemo(
    (): readonly AddOnButton[] =>
      buttonsDesc.map((b): AddOnButton => {
        return convertChoiceButtonDefToAddOnButton(b, 'is-primary', value);
      }),
    [buttonsDesc, value]
  );
  return (
    <AddOnButtons
      onClick={onClick as AddOnButtonsProps['onClick']}
      buttons={buttons}
      size={size}
      disabled={disabled}
      isRounded
    />
  );
}
