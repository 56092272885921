import React from 'react';
import type { IScrollbarStyle } from './TableVerticalScrollbar.js';
import { TableHorizontalScrollbar } from './TableHorizontalScrollbar.js';
import { TableVerticalScrollbar } from './TableVerticalScrollbar.js';

interface IDimensions {
  readonly containerWidth: number;
  readonly containerHeight: number;
  readonly tableWidth: number;
  readonly tableHeight: number;
}

interface IProps extends React.PropsWithChildren {
  readonly style?: React.CSSProperties;
  readonly className?: string;
  readonly width: string;
  readonly height: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly customHeader?: Array<React.ComponentClass<any> | React.FC<any>>;
  readonly scrollbarStyle?: IScrollbarStyle;
}

interface IState {
  readonly containerWidth: number;
  readonly containerHeight: number;
  readonly tableWidth: number;
  readonly tableHeight: number;
  readonly tableMarginTop: number;
  readonly verticalPercentageScrolled: number;
  readonly tableMarginLeft: number;
  readonly horizontalPercentageScrolled: number;
  readonly isMoving: boolean;
  readonly previousSwipeClientX: number;
  readonly previousSwipeClientY: number;
}

export class TableContainer extends React.Component<IProps, IState> {
  private readonly tableId = 'main-table';

  private readonly headerTableId = 'header-table';

  private readonly headerRelatedHTMLElements = ['colgroup', 'thead'];

  private timeoutId: NodeJS.Timeout | null = null;

  private containerRef = React.createRef<HTMLDivElement>();

  private tableRef = React.createRef<HTMLTableElement>();

  private headerTableRef = React.createRef<HTMLTableElement>();

  constructor(props: IProps) {
    super(props);

    // Some of the state below could possibly be converted into instance properties, as they don't seem to directly play a role during any rendering
    this.state = {
      containerWidth: 0,
      containerHeight: 0,
      tableWidth: 0,
      tableHeight: 0,
      tableMarginTop: 0,
      verticalPercentageScrolled: 0,
      tableMarginLeft: 0,
      horizontalPercentageScrolled: 0,
      isMoving: false,
      previousSwipeClientX: 0,
      previousSwipeClientY: 0,
    };
  }

  public componentDidMount(): void {
    // Register listeners
    this.tableRef.current?.addEventListener('wheel', this.onWheel);

    this.tableRef.current?.addEventListener('touchstart', this.onTouchStart);
    this.tableRef.current?.addEventListener('touchmove', this.onTouchMove);
    this.tableRef.current?.addEventListener('touchend', this.onTouchEnd);
    this.tableRef.current?.addEventListener('touchcancel', this.onTouchEnd);

    window.addEventListener('resize', this.onWindowResize);

    // `getBoundingClientRect` can be called directly on the ref instance since it holds a DIV element instance
    const containerBoundingClientRect = this.containerRef.current?.getBoundingClientRect();
    const tableBoundingClientRect = this.tableRef.current?.getBoundingClientRect();

    // Apply initial dimensions
    this.applyDimensions({
      containerWidth: containerBoundingClientRect?.width ?? 0,
      containerHeight: containerBoundingClientRect?.height ?? 0,
      tableWidth: tableBoundingClientRect?.width ?? 0,
      tableHeight: tableBoundingClientRect?.height ?? 0,
    });

    // Refs (which aren't null at this stage) must be propagated onto the scrollbar components.
    // This could be achieved using `this.forceUpdate()` but the `this.applyDimensions` method above already triggers the required re-render.
  }

  public componentDidUpdate(): void {
    this.refreshHeaders();
    this.reevaluateDimensions();
  }

  public componentWillUnmount(): void {
    // Remove listeners
    this.tableRef.current?.removeEventListener('wheel', this.onWheel);

    this.tableRef.current?.removeEventListener('touchstart', this.onTouchStart);
    this.tableRef.current?.removeEventListener('touchmove', this.onTouchMove);
    this.tableRef.current?.removeEventListener('touchend', this.onTouchEnd);
    this.tableRef.current?.removeEventListener('touchcancel', this.onTouchEnd);

    window.removeEventListener('resize', this.onWindowResize);
  }

  private onWindowResize = (): void => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.reevaluateDimensions, 16);
  };

  // If scrolling within the table hits any boundary, propagate it onto the window object
  private setWindowScroll(
    verticalMaxScrollable: number,
    newTableMarginTop: number,
    horizontalMaxScrollable: number,
    newTableMarginLeft: number
  ): void {
    let scrollByX = 0;
    let scrollByY = 0;

    if (newTableMarginTop < 0) {
      scrollByY = newTableMarginTop;
    } else if (newTableMarginTop > verticalMaxScrollable) {
      scrollByY = newTableMarginTop - verticalMaxScrollable;
    }

    if (newTableMarginLeft < 0) {
      scrollByX = newTableMarginLeft;
    } else if (newTableMarginLeft > horizontalMaxScrollable) {
      scrollByX = newTableMarginLeft - horizontalMaxScrollable;
    }

    window.scrollBy(scrollByX, scrollByY);
  }

  // Make the header table's header cells the same width as the main table's header cells
  private refreshHeaders = (): void => {
    const headerTableHeaderRow =
      this.headerTableRef.current?.querySelector('thead > tr:first-child');
    const tableHeaderRow = this.tableRef.current?.querySelector('thead > tr:first-child');

    if (headerTableHeaderRow && tableHeaderRow) {
      const cellsWidth = [];

      // All necessary reads are done first for increased performance
      for (let i = 0; i < tableHeaderRow.children.length; i += 1) {
        cellsWidth.push(tableHeaderRow.children.item(i)?.getBoundingClientRect().width);
      }

      for (let i = 0; i < tableHeaderRow.children.length; i += 1) {
        const item = headerTableHeaderRow.children.item(i) as HTMLElement;

        item.style.boxSizing = 'border-box';
        item.style.minWidth = `${cellsWidth[i]}px`;
      }
    }
  };

  // Update dimensions if they have changed
  private reevaluateDimensions = (): void => {
    const { containerWidth, containerHeight, tableWidth, tableHeight } = this.state;

    // `getBoundingClientRect` can be called directly on the ref instance since it holds a DIV element instance
    const containerBoundingClientRect = this.containerRef.current?.getBoundingClientRect();
    const tableBoundingClientRect = this.tableRef.current?.getBoundingClientRect();

    if (containerBoundingClientRect && tableBoundingClientRect) {
      const newContainerWidth = containerBoundingClientRect.width;
      const newContainerHeight = containerBoundingClientRect.height;
      const newTableWidth = tableBoundingClientRect.width;
      const newTableHeight = tableBoundingClientRect.height;

      if (
        containerWidth !== newContainerWidth ||
        containerHeight !== newContainerHeight ||
        tableWidth !== newTableWidth ||
        tableHeight !== newTableHeight
      ) {
        this.applyDimensions({
          containerWidth: newContainerWidth,
          containerHeight: newContainerHeight,
          tableWidth: newTableWidth,
          tableHeight: newTableHeight,
        });
      }
    }
  };

  private onWheel = (event: WheelEvent): void => {
    event.preventDefault();

    const { containerWidth, containerHeight, tableWidth, tableHeight } = this.state;

    let {
      tableMarginTop,
      verticalPercentageScrolled,
      tableMarginLeft,
      horizontalPercentageScrolled,
    } = this.state;

    let { deltaY } = event;
    let { deltaX } = event;

    // Adjust if the delta values are specified in lines
    if (event.deltaMode === 1) {
      deltaY *= 10;
      deltaX *= 10;
    }

    // Get vertical properties
    const verticalMaxScrollable = Math.max(0, tableHeight - containerHeight);
    const newTableMarginTop = tableMarginTop + deltaY;

    // Get horizontal properties
    const horizontalMaxScrollable = Math.max(0, tableWidth - containerWidth);
    const newTableMarginLeft = tableMarginLeft + deltaX;

    this.setWindowScroll(
      verticalMaxScrollable,
      newTableMarginTop,
      horizontalMaxScrollable,
      newTableMarginLeft
    );

    // Set vertical properties
    tableMarginTop = Math.max(0, Math.min(newTableMarginTop, verticalMaxScrollable));
    verticalPercentageScrolled = verticalMaxScrollable ? tableMarginTop / verticalMaxScrollable : 0;

    // Set horizontal properties
    tableMarginLeft = Math.max(0, Math.min(newTableMarginLeft, horizontalMaxScrollable));
    horizontalPercentageScrolled = horizontalMaxScrollable
      ? tableMarginLeft / horizontalMaxScrollable
      : 0;

    this.setState({
      tableMarginTop,
      verticalPercentageScrolled,
      tableMarginLeft,
      horizontalPercentageScrolled,
    });
  };

  private onTouchStart = (event: TouchEvent): void => {
    this.setState({
      isMoving: true,
      previousSwipeClientX: event.changedTouches[0].clientX,
      previousSwipeClientY: event.changedTouches[0].clientY,
    });
  };

  private onTouchMove = (event: TouchEvent): void => {
    event.preventDefault();

    const { containerWidth, containerHeight, tableWidth, tableHeight, isMoving } = this.state;

    let { tableMarginTop, tableMarginLeft, previousSwipeClientX, previousSwipeClientY } =
      this.state;

    if (!isMoving) {
      return;
    }

    // Get vertical properties
    const verticalMaxScrollable = Math.max(0, tableHeight - containerHeight);
    const currentSwipeClientY = event.changedTouches[0].clientY;
    const deltaY = previousSwipeClientY - currentSwipeClientY;
    const newTableMarginTop = tableMarginTop + deltaY;

    // Get horizontal properties
    const horizontalMaxScrollable = Math.max(0, tableWidth - containerWidth);
    const currentSwipeClientX = event.changedTouches[0].clientX;
    const deltaX = previousSwipeClientX - currentSwipeClientX;
    const newTableMarginLeft = tableMarginLeft + deltaX;

    this.setWindowScroll(
      verticalMaxScrollable,
      newTableMarginTop,
      horizontalMaxScrollable,
      newTableMarginLeft
    );

    // Set vertical properties
    tableMarginTop = Math.max(0, Math.min(newTableMarginTop, verticalMaxScrollable));

    const verticalPercentageScrolled = verticalMaxScrollable
      ? tableMarginTop / verticalMaxScrollable
      : 0;

    previousSwipeClientY = currentSwipeClientY;

    // Set horizontal properties
    tableMarginLeft = Math.max(0, Math.min(newTableMarginLeft, horizontalMaxScrollable));

    const horizontalPercentageScrolled = horizontalMaxScrollable
      ? tableMarginLeft / horizontalMaxScrollable
      : 0;

    previousSwipeClientX = currentSwipeClientX;

    this.setState({
      tableMarginTop,
      verticalPercentageScrolled,
      tableMarginLeft,
      horizontalPercentageScrolled,
      previousSwipeClientY,
      previousSwipeClientX,
    });
  };

  private onTouchEnd = (): void => {
    this.setState({
      isMoving: false,
      previousSwipeClientX: 0,
      previousSwipeClientY: 0,
    });
  };

  private onVerticalScroll = (scrollTo: number): void => {
    const { containerHeight, tableHeight } = this.state;

    const maxScrollable = tableHeight - containerHeight;

    const tableMarginTop = Math.max(0, Math.min(scrollTo * maxScrollable, maxScrollable));

    this.setState({
      tableMarginTop,
      verticalPercentageScrolled: scrollTo,
    });
  };

  private onHorizontalScroll = (scrollTo: number): void => {
    const { containerWidth, tableWidth } = this.state;

    const maxScrollable = tableWidth - containerWidth;

    const tableMarginLeft = Math.max(0, Math.min(scrollTo * maxScrollable, maxScrollable));

    this.setState({
      tableMarginLeft,
      horizontalPercentageScrolled: scrollTo,
    });
  };

  // For instance, if the table gets wider, the horizontal scrollbar will remain in the same place, but the amount of percentage scrolled will be now be less
  private applyDimensions(dimensions: IDimensions): void {
    let {
      tableMarginTop,
      verticalPercentageScrolled,
      tableMarginLeft,
      horizontalPercentageScrolled,
    } = this.state;

    const verticalMaxScrollable = dimensions.tableHeight - dimensions.containerHeight;

    tableMarginTop = Math.max(0, Math.min(tableMarginTop, verticalMaxScrollable));

    verticalPercentageScrolled = verticalMaxScrollable ? tableMarginTop / verticalMaxScrollable : 0;

    const horizontalMaxScrollable = dimensions.tableWidth - dimensions.containerWidth;

    tableMarginLeft = Math.max(0, Math.min(tableMarginLeft, horizontalMaxScrollable));

    horizontalPercentageScrolled = horizontalMaxScrollable
      ? tableMarginLeft / horizontalMaxScrollable
      : 0;

    this.setState({
      containerWidth: dimensions.containerWidth,
      containerHeight: dimensions.containerHeight,
      tableWidth: dimensions.tableWidth,
      tableHeight: dimensions.tableHeight,
      tableMarginTop,
      verticalPercentageScrolled,
      tableMarginLeft,
      horizontalPercentageScrolled,
    });
  }

  public render(): JSX.Element {
    const { children, style, className, width, height, customHeader, scrollbarStyle } = this.props;
    const {
      tableMarginTop,
      verticalPercentageScrolled,
      tableMarginLeft,
      horizontalPercentageScrolled,
    } = this.state;

    const containerStyle: React.CSSProperties = {
      boxSizing: 'border-box',
      position: 'relative',
      display: 'inline-block',
      overflow: 'hidden',
      width,
      height,
    };

    const containerProps = {
      ref: this.containerRef,
      style: {
        ...style,
        ...containerStyle,
      },
      className,
    };

    // Only one direct child (i.e. <table>) is allowed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const table = React.Children.only(children) as React.ReactElement<any>;

    // Set table props
    const tableProps = {
      ...table.props,
      ref: this.tableRef,
      'data-rtc-id': this.tableId, // Useful for targeting it outside the code base (i.e. testing)
      style: {
        ...table.props.style,
        borderSpacing: 0,
        marginTop: -tableMarginTop,
        marginLeft: -tableMarginLeft,
      },
    };

    // Set header table props
    const headerTableProps = {
      ...table.props,
      ref: this.headerTableRef,
      'data-rtc-id': this.headerTableId, // Useful for targeting it outside the code base (i.e. testing)
      style: {
        ...table.props.style,
        borderSpacing: 0,
        position: 'absolute',
        top: 0,
        left: -tableMarginLeft,
        zIndex: 1,
      },
      role: 'presentation',
      'aria-hidden': 'true',
    };

    const tableChildren = React.Children.toArray(table.props.children) as Array<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      React.ReactElement<any>
    >;

    const headerRelatedItems = customHeader
      ? [...this.headerRelatedHTMLElements, ...customHeader]
      : this.headerRelatedHTMLElements;

    // Extract out header related children
    const headerRelatedChildren = tableChildren.filter(
      ({ type }) => headerRelatedItems.indexOf(type) !== -1
    );

    return (
      <div {...containerProps}>
        {/* Header Table: It has the purpose of only using header related children to stick them to the top of the container */}
        {React.cloneElement(table, headerTableProps, headerRelatedChildren)}

        {/* Main Table */}
        {React.cloneElement(table, tableProps)}

        <TableVerticalScrollbar
          style={scrollbarStyle}
          containerRef={this.containerRef}
          tableRef={this.tableRef}
          scrollTo={verticalPercentageScrolled}
          onScroll={this.onVerticalScroll}
        />

        <TableHorizontalScrollbar
          style={scrollbarStyle}
          containerRef={this.containerRef}
          tableRef={this.tableRef}
          scrollTo={horizontalPercentageScrolled}
          onScroll={this.onHorizontalScroll}
        />
      </div>
    );
  }
}
