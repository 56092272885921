import bulmaCalendar from 'bulma-calendar';
import React, { useEffect, useRef } from 'react';
import type { ActionContext, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { forEachRecordValues } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';

// FIXME : I don't understand the "dialog" mode, is it really implemented ?
export type CalendarDisplayType = 'default' | 'inline' | 'dialog';
export type DatePickerType = 'date' | 'datetime';

const isNumeric = (value: number | string): boolean => {
  return isTruthy(value) && value !== '' && !Number.isNaN(value);
};

const toNumeric = (value: number | string): number => {
  return isNumeric(value) ? Number(value) : NaN;
};

const toRequiredType = (value: number | undefined, dateAsTextMode: boolean): number | string => {
  if (dateAsTextMode) {
    if (value === undefined || Number.isNaN(value)) {
      return '';
    }
    return value.toString();
  }
  if (value === undefined) {
    return NaN;
  }
  return value;
};

function handleDateChangeListenerAction<SD extends AnyStoreDef, S extends number | string>(
  { actionDispatch, getState }: ActionContext<SD, S>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  datePickerType: DatePickerType,
  dateAsTextMode: boolean
) {
  const value = getState();
  let selectedDate: number | undefined;
  let tempDate;
  let tempTime;
  switch (datePickerType) {
    case 'date':
      selectedDate = isTruthy(data.date.start) ? data.date.start.valueOf() : undefined;
      break;
    case 'datetime':
      tempDate = isTruthy(data.date.start) ? data.date.start : undefined;
      tempTime = isTruthy(data.time.start) ? data.time.start : undefined;
      if (isTruthy(tempDate) && isTruthy(tempTime)) {
        selectedDate = new Date(
          tempDate.getFullYear(),
          tempDate.getMonth(),
          tempDate.getDate(),
          tempTime.getHours(),
          tempTime.getMinutes()
        ).valueOf();
      }
      break;
    default:
      break;
  }

  const convertedSelectedDate = toRequiredType(selectedDate, dateAsTextMode) as S;

  if (value !== convertedSelectedDate) {
    element.bulmaCalendar.save();
    actionDispatch.setValue(convertedSelectedDate);
  }
}

export interface CalendarInputProps<SD extends AnyStoreDef, T extends number | string> {
  readonly $: StoreStateSelector<SD, T>;
  readonly display?: CalendarDisplayType;
  readonly datePickerType?: DatePickerType;
  readonly weekStart?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  readonly color?: string;
  readonly showFooter?: boolean;
  readonly lang?: string;
  readonly dateFormat?: string;
  readonly minDate?: Date;
  readonly maxDate?: Date;
  readonly className?: string;
  readonly hideInputClearButton?: boolean;
  readonly isDisabled?: boolean;
  // Should be true when T is string
  // false when T is a number
  readonly dateAsTextMode?: T extends string ? true : false;
}

export function CalendarInput<SD extends AnyStoreDef, T extends number | string>({
  $,
  color = 'primary',
  datePickerType = 'date',
  display = 'dialog',
  lang = 'fr',
  showFooter = false,
  hideInputClearButton = false,
  weekStart = 1,
  dateFormat = 'dd/MM/yyyy',
  minDate,
  maxDate,
  className,
  isDisabled = false,
  dateAsTextMode = false as T extends string ? true : false,
}: CalendarInputProps<SD, T>): JSX.Element {
  const refElement = useRef<HTMLInputElement>(null);
  const value = useGetState($);
  const handleDateChangeListenerActionCallback = useActionCallback(
    handleDateChangeListenerAction,
    [],
    $
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const element = refElement.current as any;

    if (isTruthy(value) && value !== '') {
      if (dateAsTextMode && typeof value !== 'string') {
        throw Error('Value should be of type string when dateAsTextMode is true');
      } else if (!dateAsTextMode && typeof value !== 'number') {
        throw Error('Value should be of type number when dateAsTextMode is false');
      }
    }

    const valueAsNumeric = toNumeric(value);
    if (element && !element.bulmaCalendar) {
      bulmaCalendar.attach(element, {
        type: datePickerType,
        weekStart,
        color,
        startDate: !Number.isNaN(valueAsNumeric) ? new Date(valueAsNumeric) : undefined,
        dateFormat,
        showFooter,
        showClearButton: false,
        lang,
        maxDate,
        minDate,
      });
    }
    // This is a workaround to remove the clear button in the input
    // Bulma-calendar offer no other way to do it
    if (element && hideInputClearButton) {
      try {
        // Those elements are generated by bulma calendar
        // the first parent is a wrapper for the input
        // the second parent is a wrapper for the input and the clear button
        const children = element.parentElement.parentElement.children as HTMLCollection;
        forEachRecordValues(children, (v) => {
          // @ts-ignore
          if (typeof v === 'object' && v.type === 'button') {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            v.style.display = 'none';
          }
        });
      } catch {
        // Do nothing, just display the element
      }
    }
    if (element && !isDisabled && element.bulmaCalendar) {
      // React to external clear
      if (element.bulmaCalendar.date.start && Number.isNaN(valueAsNumeric)) {
        element.bulmaCalendar.clear();
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      element.bulmaCalendar.on('select', async (datepicker: any) => {
        await handleDateChangeListenerActionCallback(
          element,
          datepicker.data,
          datePickerType,
          dateAsTextMode
        );
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      element.bulmaCalendar.on('clear', async (datepicker: any) => {
        await handleDateChangeListenerActionCallback(
          element,
          datepicker.data,
          datePickerType,
          dateAsTextMode
        );
      });
    }

    return function cleanup(): void {
      if (element && element.bulmaCalendar) {
        element.bulmaCalendar.removeListeners('select');
        element.bulmaCalendar.removeListeners('clear');
      }
    };
  }, [
    color,
    dateFormat,
    lang,
    maxDate,
    minDate,
    showFooter,
    datePickerType,
    weekStart,
    isDisabled,
    hideInputClearButton,
    dateAsTextMode,
    value,
    handleDateChangeListenerActionCallback,
  ]);

  return (
    <div className="control">
      <input
        className={`input ${className ? ` ${className}` : ''}`}
        style={{ zIndex: 10 }}
        ref={refElement}
        type="date"
        data-display-mode={display}
        disabled={isDisabled}
      />
    </div>
  );
}
