import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { TextAreaProps } from './TextArea.js';
import { TextArea } from './TextArea.js';

export type TextAreaFormFieldProps<SD extends AnyStoreDef> = Omit<TextAreaProps<SD>, 'warning'> &
  Omit<FormFieldProps, 'children' | 'warning'>;

export function TextAreaFormField<SD extends AnyStoreDef>({
  label,
  $,
  preSelectContent,
  className,
  placeholder,
  noExclamationTriangleIfWarning,
  horizontal,
  ...props
}: TextAreaFormFieldProps<SD>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  const hasWarning = isTruthyAndNotEmpty(dispatchWarning);
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
    >
      <TextArea
        placeholder={!placeholder ? label : placeholder}
        className={`input${hasWarning ? ' is-info' : ''}${className ? ` ${className}` : ''}`}
        $={$}
        preSelectContent={preSelectContent}
        {...props}
      />
    </FormField>
  );
}
