import React, { useMemo } from 'react';
import type { Kanban } from '@stimcar/libs-base';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { kanbanHelpers } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../state/typings/store.js';
import { Decorators } from '../../lib/bulma/elements/table/Decorators.js';

interface PackageDealDecoratorsProps extends AppProps<Store> {
  readonly kanban: Kanban;
}

export function PackageDealDecorators({ $gs, kanban }: PackageDealDecoratorsProps): JSX.Element {
  const packageDealDecorators = useGetState($gs.$siteConfiguration.$packageDealDecorators);
  const selectedDecorators = useMemo(
    () => kanbanHelpers.getDecorators(kanban, packageDealDecorators),
    [kanban, packageDealDecorators]
  );
  return <Decorators decorators={selectedDecorators} />;
}
