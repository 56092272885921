import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { ImageModalState } from '@stimcar/libs-uitoolkit';
import { globalHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { ClickableIcon } from '@stimcar/libs-uitoolkit';
import type { PictureEditionToolkit } from '../attachments/KanbanIdentityPictureComponent.js';
import type { ComputeAttachmentUrlCallback } from '../attachments/typings/attachment.js';
import { useHTMLElementSize } from '../../hooks/useHTMLElementSize.js';
import { KanbanIdentityPictureComponent } from '../attachments/KanbanIdentityPictureComponent.js';

interface Props<SD extends AnyStoreDef> {
  readonly kanban: Kanban;
  readonly showMileageUpdateModal?: () => Promise<void>;
  readonly computeUrlCallback: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly pictureEditionToolkit?: PictureEditionToolkit<SD>;
  readonly isOnline: boolean;
}

export function KanbanOneLineGeneralInformations<SD extends AnyStoreDef>({
  kanban,
  showMileageUpdateModal,
  computeUrlCallback,
  $imageModal,
  pictureEditionToolkit,
  isOnline,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  const boxRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [boxWidth, boxHeight] = useHTMLElementSize(boxRef);

  const showMileageUpdateButton = (): JSX.Element => {
    return (
      <ClickableIcon
        clickHandler={nonnull(showMileageUpdateModal)}
        id="edit"
        elementClassName="m-l-sm"
      />
    );
  };

  return (
    <>
      <div className="level-left">
        <div className="level-item has-text-centered">
          <KanbanIdentityPictureComponent
            kanbanId={kanban.id}
            computeAttachmentUrl={computeUrlCallback}
            $imageModal={$imageModal}
            pictureEditionToolkit={pictureEditionToolkit}
            isOnline={isOnline}
          />
        </div>
      </div>
      <div className="level-item has-text-centered">
        <p className="has-text-weight-bold">{kanban.infos.license}</p>
      </div>
      <div className="level-item has-text-centered">
        <p className="has-text-weight-bold">{kanban.infos.model}</p>
      </div>
      <div className="level-item has-text-centered">
        <p className="has-text-weight-bold">{kanban.infos.vin}</p>
      </div>
      <div className="level-item has-text-centered">
        <p className="has-text-weight-bold">
          {t('kanbanGeneralInformations.mecValue', {
            value: globalHelpers.getDDmmYYYYDateOrPlaceholderFromTimestamp(
              kanban.infos.dateOfRegistration,
              t('libUtils:noValue')
            ),
          })}
        </p>
      </div>
      <div className="level-item has-text-centered">
        <p className="has-text-weight-bold">
          {t('kanbanGeneralInformations.mileageValue', { value: kanban.infos.mileage })}
          {showMileageUpdateModal && showMileageUpdateButton()}
        </p>
      </div>
    </>
  );
}
