import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, WorkshopStandImplantation } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, CheckFormConsistencyAction } from '@stimcar/libs-uitoolkit';
import { enumerate, globalHelpers, kanbanHelpers, packageDealHelpers } from '@stimcar/libs-base';
import { computePayload, isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { OperationsRedispatchModalState } from '../typings/store.js';
import {
  getCategoriesGapBetweenOriginAndDestination,
  useGetCategoriesGapBetweenOriginAndDestination,
  useGetFurtherCategories,
} from '../../../../app/utils/useGetFurtherCategories.js';
import { OperationsDispatchComponent } from '../../operationsDispatch/OperationsDispatchComponent.js';
import { OPERATIONS_REDISPATCH_MODAL_EMPTY_STATE } from '../typings/store.js';

interface Props extends AppProps<Store> {
  readonly standId: string;
  readonly implantation: WorkshopStandImplantation;
  readonly $: StoreStateSelector<Store, OperationsRedispatchModalState>;
}

export function OperationsReDispatchModal({ standId, implantation, $, $gs }: Props): JSX.Element {
  const isActive = useGetState($.$isActive);
  return (
    <>
      {isActive && (
        <InternalOperationsReDispatchModal
          standId={standId}
          implantation={implantation}
          $={$}
          $gs={$gs}
        />
      )}
    </>
  );
}

export async function reDispatchOperationsAction({
  actionDispatch,
  kanbanRepository,
  getState,
  httpClient,
}: ActionContext<Store, OperationsRedispatchModalState>): Promise<void> {
  const {
    kanbanWithOperationsDispatched,
    destinationCategory,
    implantation,
    standId,
    users,
    destinationPostLabel,
    originKanban,
    redispatchMotive,
  } = getState();

  if (redispatchMotive === 'default') {
    if (
      isTruthy(originKanban) &&
      isTruthy(kanbanWithOperationsDispatched) &&
      computePayload(originKanban, kanbanWithOperationsDispatched) !== undefined
    ) {
      await kanbanRepository.updateEntity(kanbanWithOperationsDispatched);
    }
  } else {
    const destinationQualifiedCategory = globalHelpers.computeQualifiedWorkshopPostId(
      nonnull(implantation).id,
      destinationCategory,
      destinationPostLabel
    );

    const kanbanWithClosedHandlings = kanbanHelpers.closeAllHandlingStartingWith(
      nonnull(kanbanWithOperationsDispatched),
      nonnull(implantation).id
    );
    const updatedKanban = kanbanHelpers.openNewHandlingWithInterval(
      httpClient.getBrowserSequence(),
      kanbanWithClosedHandlings,
      standId,
      destinationQualifiedCategory,
      users,
      'work'
    );

    await kanbanRepository.updateEntity(updatedKanban);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return OPERATIONS_REDISPATCH_MODAL_EMPTY_STATE;
  });
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  OperationsRedispatchModalState
> = ({ formState, t }): string | undefined => {
  const {
    kanbanWithOperationsDispatched,
    destinationCategory,
    originPostCategory,
    implantation,
    destinationPostLabel,
    standId,
  } = formState;
  if (kanbanWithOperationsDispatched) {
    const categoriesGapBetweenOriginAndDestination = getCategoriesGapBetweenOriginAndDestination(
      implantation,
      originPostCategory,
      destinationCategory
    );
    for (const cat of categoriesGapBetweenOriginAndDestination) {
      if (
        packageDealHelpers.getAllUnfinishedOperationsForWorkshopPostCategoryInStand(
          packageDealHelpers.getAvailablePackageDeals(kanbanWithOperationsDispatched.packageDeals),
          nonnull(implantation).id,
          nonnull(cat.id),
          standId
        ).length > 0
      ) {
        const categoryIds = enumerate(categoriesGapBetweenOriginAndDestination.map((c) => c.id));
        return t(
          'implantation.redispatchOperationsModal.remainingOperationsDispatchedBeforeDestinationCategoryWarning',
          { destinationCategory, destinationPostId: destinationPostLabel, categoryIds }
        );
      }
    }
  }
  return undefined;
};

function InternalOperationsReDispatchModal({ implantation, $, $gs }: Props): JSX.Element {
  const [t] = useTranslation('workshop');

  const originPostCategory = useGetState($.$originPostCategory);
  const destinationCategory = useGetState($.$destinationCategory);

  const furtherCategoriesFromDestination = useGetFurtherCategories(
    implantation,
    destinationCategory
  );

  const gapBetweenOriginCategoryAndDestinationCategory =
    useGetCategoriesGapBetweenOriginAndDestination(
      implantation,
      originPostCategory,
      destinationCategory
    );

  const furtherCategories = useMemo(() => {
    return [...gapBetweenOriginCategoryAndDestinationCategory, ...furtherCategoriesFromDestination];
  }, [furtherCategoriesFromDestination, gapBetweenOriginCategoryAndDestinationCategory]);

  const submitValidDataAction = useActionCallback(reDispatchOperationsAction, [], $);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    OperationsRedispatchModalState
  >({
    $,
    mandatoryFields: ['hasUnfinishedOperations'],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const cancelAction = useActionCallback(
    ({ actionDispatch }: ActionContext<Store, OperationsRedispatchModalState>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return OPERATIONS_REDISPATCH_MODAL_EMPTY_STATE;
      });
    },
    [],
    $
  );

  const computeModalTitle = (): string => {
    return t('implantation.redispatchOperationsModal.forCurrentCycleTitle');
  };

  const formWarning = useGetState($.$formWarning);
  const standId = useGetState($.$standId);

  const validationActionCallback = useActionCallback(
    ({ actionDispatch }, kanban: Kanban | undefined, categoryIds: readonly string[]) => {
      let hasUnfinished = false;
      for (const postId of categoryIds) {
        if (isTruthy(kanban)) {
          const unfinishedOperations =
            packageDealHelpers.getAllUnfinishedOperationsForWorkshopQualifiedPostCategoryInStand(
              kanban.packageDeals,
              postId,
              standId
            );
          if (unfinishedOperations.length > 0) {
            hasUnfinished = true;
            break;
          }
        }
      }
      actionDispatch.setProperty('hasUnfinishedOperations', hasUnfinished);
    },
    [standId],
    $formDataWithChangeTrigger
  );
  return (
    <ModalCardDialog
      size="fullscreen"
      $active={$.$isActive}
      title={computeModalTitle()}
      onOkClicked={onFormSubmit}
      okLabel={t('implantation.redispatchOperationsModal.okLabel')}
      onCancelClicked={cancelAction}
      warning={formWarning}
    >
      <OperationsDispatchComponent
        $={$}
        $gs={$gs}
        sortedCategories={furtherCategories}
        implantationId={implantation.id}
        validationPostDnDCallback={validationActionCallback}
        currentCategoryId={destinationCategory}
        standId={standId}
      />
    </ModalCardDialog>
  );
}
