import type { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, StandType } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { kanbanHelpers, packageDealHelpers } from '@stimcar/libs-base';
import { computePayload, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { OperatorViewState } from '../typings/store.js';
import {
  canCompleteOperation,
  clearOperatorViewAndNavigateToSelectionAction,
  closeKanbanHandleOnPost,
  pauseWorkAction,
  startWorkAction,
  unpauseWorkAction,
} from '../../utils/operatorUtils.js';
import { Countdown, Stopwatch } from '../../utils/Timer.js';
import {
  applyValidateExpertiseModificationsToKanban,
  computeHasLocalValidateExpertiseChanges,
  validateExpertiseRaiseWarningBeforeTerminate,
  validateExpertiseTerminateAction,
} from '../validateExpertiseUtils.js';
import {
  applySparePartsModificationsToKanban,
  computeHasLocalSparePartsChanges,
  sparePartsReferenceCheckForConsistencyWarning,
  sparePartsReferenceRaiseWarningBeforeTerminate,
  sparePartsReferenceTerminateAction,
} from './spareparts/sparePartsReferenceUtils.js';

export type OperatorCustomTerminateAction = (
  ctx: ActionContext<Store, OperatorViewState>,
  kanban: Kanban,
  standId: string
) => Promise<void>;

export type OperatorCustomRaiseWarningBeforeTerminateAction = (
  ctx: ActionContext<Store, OperatorViewState>,
  kanban: Kanban,
  t: TFunction
) => string | undefined;

export type OperatorCustomActionToPerformOnKanban = (
  ctx: ActionContext<Store, OperatorViewState>,
  kanban: Kanban
) => Promise<Kanban> | Kanban;

export type OperatorCustomCheckForConsistencyWarningAction = (
  operatorViewState: OperatorViewState,
  t: TFunction
) => string | undefined;

type OperatorTimerType = 'countdown' | 'stopwatch';

interface LocalChangeToolkit {
  readonly computeHasLocalChanges: (
    state: OperatorViewState,
    initialKanban: Kanban | undefined
  ) => boolean;
  readonly preventNavigationIfViewDirty: boolean;
  readonly applyStateModificationsToKanban: OperatorCustomActionToPerformOnKanban;
  readonly checkForConsistencyWarning?: OperatorCustomCheckForConsistencyWarningAction;
}

async function generalOperatorTerminateAction(
  ctx: ActionContext<Store, OperatorViewState>,
  kanbanToTerminate: Kanban,
  standId: string
): Promise<void> {
  const { actionDispatch, getGlobalState, kanbanRepository } = ctx;

  const { session } = getGlobalState();
  const userPermissions = session.user?.permissions ?? {};

  const stoppedKanban = closeKanbanHandleOnPost(
    nonnull(session.infos?.id),
    kanbanToTerminate,
    userPermissions,
    standId,
    session.user?.login ?? null
  );
  await kanbanRepository.updateEntity(stoppedKanban);
  await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
}

interface OperatorCountdownProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly kanban: Kanban;
  readonly postId: string;
  readonly standId: string;
}

interface InternalOperatorCountdownProps extends OperatorCountdownProps {
  readonly timerType: OperatorTimerType;
  readonly localChangeToolkit?: LocalChangeToolkit;
  readonly operatorCustomRaiseWarningBeforeTerminateAction?: OperatorCustomRaiseWarningBeforeTerminateAction;
  readonly operatorCustomTerminateAction: OperatorCustomTerminateAction;
  readonly isStandWithLocalChanges?: boolean;
  readonly customTerminateButtonLabelKey?: string;
}

interface StandOperatorCountdownProps extends OperatorCountdownProps {
  readonly timerType: OperatorTimerType;
  readonly operatorCustomRaiseWarningBeforeTerminateAction?: OperatorCustomRaiseWarningBeforeTerminateAction;
  readonly operatorCustomTerminateAction: OperatorCustomTerminateAction;
  readonly hasLocalChanges: boolean;
  readonly applyStateModificationsToKanban?: OperatorCustomActionToPerformOnKanban;
  readonly customTerminateButtonLabelKey?: string;
  readonly checkForConsistencyWarning?: OperatorCustomCheckForConsistencyWarningAction;
}

type StopButtonProps = Omit<
  StandOperatorCountdownProps,
  'customTerminateButtonLabelKey' | 'timerType'
> & {
  readonly customLabelKey?: string;
};
type StartButtonProps = Omit<
  StopButtonProps,
  | 'operatorCustomRaiseWarningBeforeTerminateAction'
  | 'operatorCustomTerminateAction'
  | 'customLabelKey'
>;

function isThereWarningSetToGlobalState(
  ctx: ActionContext<Store, OperatorViewState>,
  t: TFunction,
  checkForConsistencyWarning?: OperatorCustomCheckForConsistencyWarningAction
): boolean {
  if (checkForConsistencyWarning) {
    const operatorViewState = ctx.getState();
    const warning = checkForConsistencyWarning(operatorViewState, t);
    if (isTruthy(warning)) {
      const { globalActionDispatch } = ctx;
      globalActionDispatch.setProperty('message', warning);
      return true;
    }
  }
  return false;
}

function disableButtonRegardingConsistencyWarning(
  operatorViewState: OperatorViewState,
  t: TFunction,
  checkForConsistencyWarning?: OperatorCustomCheckForConsistencyWarningAction
): boolean {
  if (checkForConsistencyWarning) {
    const consistencyWarning = checkForConsistencyWarning(operatorViewState, t);
    return isTruthy(consistencyWarning);
  }
  return false;
}

export function OperatorCountdown({
  kanban,
  $,
  $gs,
  postId,
  standId,
}: OperatorCountdownProps): JSX.Element {
  const stands = useGetState($gs.$siteConfiguration.$stands);

  const standType = useMemo((): StandType | undefined => {
    const theStand = nonnull(stands.find((s) => s.id === standId));
    return theStand.type;
  }, [standId, stands]);

  const [
    localChangeToolkit,
    operatorCustomRaiseWarningBeforeTerminateAction,
    operatorCustomTerminateAction,
    isStandWithLocalChanges,
    customTerminateButtonLabelKey,
    timerType,
  ] = useMemo(() => {
    switch (standType) {
      case 'sparepartsReference':
        return [
          {
            computeHasLocalChanges: computeHasLocalSparePartsChanges,
            applyStateModificationsToKanban: applySparePartsModificationsToKanban,
            checkForConsistencyWarning: sparePartsReferenceCheckForConsistencyWarning,
            preventNavigationIfViewDirty: true,
          },
          sparePartsReferenceRaiseWarningBeforeTerminate,
          sparePartsReferenceTerminateAction,
          true,
          undefined,
          'stopwatch' as OperatorTimerType,
        ];
      case 'expertiseValidation':
        return [
          {
            computeHasLocalChanges: computeHasLocalValidateExpertiseChanges,
            applyStateModificationsToKanban: applyValidateExpertiseModificationsToKanban,
            preventNavigationIfViewDirty: true,
          },
          validateExpertiseRaiseWarningBeforeTerminate,
          validateExpertiseTerminateAction,
          true,
          'terminateExpertiseValidation',
          'stopwatch' as OperatorTimerType,
        ];
      default:
        return [
          undefined,
          undefined,
          generalOperatorTerminateAction,
          false,
          undefined,
          'countdown' as OperatorTimerType,
        ];
    }
  }, [standType]);

  return (
    <InternalOperatorCountdown
      kanban={kanban}
      $={$}
      $gs={$gs}
      standId={standId}
      postId={postId}
      localChangeToolkit={localChangeToolkit}
      operatorCustomRaiseWarningBeforeTerminateAction={
        operatorCustomRaiseWarningBeforeTerminateAction
      }
      operatorCustomTerminateAction={operatorCustomTerminateAction}
      isStandWithLocalChanges={isStandWithLocalChanges}
      customTerminateButtonLabelKey={customTerminateButtonLabelKey}
      timerType={timerType}
    />
  );
}

export function InternalOperatorCountdown({
  kanban,
  $,
  $gs,
  postId,
  standId,
  localChangeToolkit,
  operatorCustomRaiseWarningBeforeTerminateAction,
  operatorCustomTerminateAction,
  isStandWithLocalChanges = false,
  customTerminateButtonLabelKey,
  timerType,
}: InternalOperatorCountdownProps): JSX.Element {
  // FIXME pulling too much state at once (could probably cause react re-renders)
  const state = useGetState($);

  const hasLocalChanges = useMemo(() => {
    return isTruthy(localChangeToolkit)
      ? localChangeToolkit.computeHasLocalChanges(state, kanban)
      : false;
  }, [kanban, localChangeToolkit, state]);

  const asyncEffect = useActionCallback(
    ({ globalActionDispatch }) => {
      if (localChangeToolkit?.preventNavigationIfViewDirty) {
        globalActionDispatch.setProperty('preventNavigation', hasLocalChanges);
      }
    },
    [hasLocalChanges, localChangeToolkit?.preventNavigationIfViewDirty],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  return (
    <>
      {isStandWithLocalChanges ? (
        <StandWithLocalChangesOperatorCountdown
          kanban={kanban}
          $={$}
          $gs={$gs}
          standId={standId}
          postId={postId}
          hasLocalChanges={hasLocalChanges}
          applyStateModificationsToKanban={localChangeToolkit?.applyStateModificationsToKanban}
          checkForConsistencyWarning={localChangeToolkit?.checkForConsistencyWarning}
          operatorCustomRaiseWarningBeforeTerminateAction={
            operatorCustomRaiseWarningBeforeTerminateAction
          }
          operatorCustomTerminateAction={operatorCustomTerminateAction}
          customTerminateButtonLabelKey={customTerminateButtonLabelKey}
          timerType={timerType}
        />
      ) : (
        <StandOperatorCountdown
          kanban={kanban}
          $={$}
          $gs={$gs}
          standId={standId}
          postId={postId}
          hasLocalChanges={hasLocalChanges}
          applyStateModificationsToKanban={localChangeToolkit?.applyStateModificationsToKanban}
          checkForConsistencyWarning={localChangeToolkit?.checkForConsistencyWarning}
          operatorCustomRaiseWarningBeforeTerminateAction={
            operatorCustomRaiseWarningBeforeTerminateAction
          }
          operatorCustomTerminateAction={operatorCustomTerminateAction}
          customTerminateButtonLabelKey={customTerminateButtonLabelKey}
          timerType={timerType}
        />
      )}
    </>
  );
}

function StartButton({
  kanban,
  $,
  standId,
  postId,
  hasLocalChanges,
  applyStateModificationsToKanban,
  checkForConsistencyWarning,
}: StartButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  const startPauseUnpauseClickHandler = useActionCallback(
    async (ctx: ActionContext<Store, OperatorViewState>): Promise<void> => {
      const { getState, getGlobalState, actionDispatch, kanbanRepository, httpClient } = ctx;
      const { workStatus } = getState();
      const { session, siteConfiguration } = getGlobalState();
      const userLogin = session.user?.login ?? null;
      const sequence = httpClient.getBrowserSequence();

      let kanbanToUpdate = nonnull(kanban);
      if (hasLocalChanges && applyStateModificationsToKanban) {
        if (isThereWarningSetToGlobalState(ctx, t, checkForConsistencyWarning)) {
          return;
        }
        kanbanToUpdate = await applyStateModificationsToKanban(ctx, kanbanToUpdate);
      }
      if (standId === 'RFPR') {
        kanbanToUpdate = kanbanHelpers.computeSparePartReferenceOperationState(
          kanbanToUpdate,
          userLogin,
          siteConfiguration,
          sequence
        );
      }

      if (computePayload(kanban, kanbanToUpdate) !== undefined) {
        await kanbanRepository.updateEntity(kanbanToUpdate);
      }
      if (workStatus === 'unstarted') {
        await actionDispatch.exec(startWorkAction, kanbanToUpdate, standId);
      } else if (workStatus === 'started') {
        await actionDispatch.exec(pauseWorkAction, kanbanToUpdate);
      } else if (workStatus === 'paused') {
        await actionDispatch.exec(unpauseWorkAction, kanbanToUpdate);
      }
    },
    [
      checkForConsistencyWarning,
      hasLocalChanges,
      kanban,
      applyStateModificationsToKanban,
      standId,
      t,
    ],
    $
  );

  // FIXME pulling too much state at once (could probably cause react re-renders)
  const state = useGetState($);

  const isStartButtonDisabled = useMemo((): boolean => {
    if (
      state.workStatus === 'finished' &&
      kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId) === undefined
    ) {
      return true;
    }
    return disableButtonRegardingConsistencyWarning(state, t, checkForConsistencyWarning);
  }, [state, kanban, postId, t, checkForConsistencyWarning]);

  const [startButtonLabel, startIcondId] = useMemo((): [string, string] => {
    if (state.workStatus === 'paused') {
      return [t('timer.unpause'), 'play'];
    }
    if (state.workStatus === 'started') {
      if (hasLocalChanges) {
        return [t('timer.saveAndPause'), 'pause'];
      }
      return [t('timer.pause'), 'pause'];
    }
    return [t('timer.start'), 'play'];
  }, [hasLocalChanges, state.workStatus, t]);

  return (
    <Button
      tooltip="update"
      iconId={startIcondId}
      label={startButtonLabel}
      onClick={startPauseUnpauseClickHandler}
      disabled={isStartButtonDisabled}
      isFullWidth
    />
  );
}

function StopButton({
  kanban,
  $,
  standId,
  postId,
  hasLocalChanges,
  applyStateModificationsToKanban,
  operatorCustomRaiseWarningBeforeTerminateAction,
  operatorCustomTerminateAction,
  customLabelKey,
  checkForConsistencyWarning,
}: StopButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  // FIXME pulling too much state at once (could probably cause react re-renders)
  const state = useGetState($);

  const finishButtonLabel = useMemo((): string => {
    if (hasLocalChanges) {
      return isTruthyAndNotEmpty(customLabelKey)
        ? t(`timer.saveAnd${customLabelKey}`)
        : t('timer.saveAndFinish');
    }
    return isTruthyAndNotEmpty(customLabelKey) ? t(`timer.${customLabelKey}`) : t('timer.finish');
  }, [customLabelKey, hasLocalChanges, t]);

  const stopWorkActionCallback = useActionCallback(
    async (ctx: ActionContext<Store, OperatorViewState>): Promise<void> => {
      const { getGlobalState, httpClient } = ctx;
      const { session, siteConfiguration } = getGlobalState();
      const userLogin = session.user?.login ?? null;
      const sequence = httpClient.getBrowserSequence();

      let kanbanToUpdate: Kanban = nonnull(kanban);
      if (hasLocalChanges && applyStateModificationsToKanban) {
        if (isThereWarningSetToGlobalState(ctx, t, checkForConsistencyWarning)) {
          return;
        }
        kanbanToUpdate = await applyStateModificationsToKanban(ctx, kanbanToUpdate);
      }

      // On finish, we always need to recompute the spare part reference operation state
      if (standId === 'RFPR') {
        kanbanToUpdate = kanbanHelpers.computeSparePartReferenceOperationState(
          kanbanToUpdate,
          userLogin,
          siteConfiguration,
          sequence
        );
      }
      if (operatorCustomRaiseWarningBeforeTerminateAction) {
        const warningBeforeTerminate = operatorCustomRaiseWarningBeforeTerminateAction(
          ctx,
          kanbanToUpdate,
          t
        );
        if (isTruthy(warningBeforeTerminate)) {
          ctx.globalActionDispatch.setProperty('message', warningBeforeTerminate);
          return;
        }
      }
      if (computePayload(kanban, kanbanToUpdate) !== undefined) {
        await ctx.kanbanRepository.updateEntity(kanbanToUpdate);
      }
      await operatorCustomTerminateAction(ctx, kanbanToUpdate, standId);
    },
    [
      kanban,
      hasLocalChanges,
      applyStateModificationsToKanban,
      operatorCustomRaiseWarningBeforeTerminateAction,
      operatorCustomTerminateAction,
      standId,
      t,
      checkForConsistencyWarning,
    ],
    $
  );

  const isFinishButtonDisabled = useMemo((): boolean => {
    if (kanban === undefined) {
      return true;
    }
    if (
      state.workStatus !== 'finished' &&
      kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId) === undefined
    ) {
      return true;
    }
    return disableButtonRegardingConsistencyWarning(state, t, checkForConsistencyWarning);
  }, [kanban, state, postId, t, checkForConsistencyWarning]);

  return (
    <Button
      tooltip="finish"
      onClick={stopWorkActionCallback}
      disabled={isFinishButtonDisabled}
      label={finishButtonLabel}
      iconId="check"
      isFullWidth
    />
  );
}

function InterruptButton({
  kanban,
  $,
  postId,
  standId,
  hasLocalChanges,
  applyStateModificationsToKanban,
  checkForConsistencyWarning,
}: StartButtonProps): JSX.Element {
  const [t] = useTranslation('operators');

  // FIXME pulling too much state at once (could probably cause react re-renders)
  const state = useGetState($);

  const interruptClickHandler = useActionCallback(
    async (ctx: ActionContext<Store, OperatorViewState>): Promise<void> => {
      const { actionDispatch, kanbanRepository, getGlobalState, httpClient } = ctx;
      const { session, siteConfiguration } = getGlobalState();
      const userLogin = session.user?.login ?? null;
      const sequence = httpClient.getBrowserSequence();

      let kanbanToUpdate = nonnull(kanban);
      if (hasLocalChanges && applyStateModificationsToKanban) {
        if (isThereWarningSetToGlobalState(ctx, t, checkForConsistencyWarning)) {
          return;
        }
        kanbanToUpdate = await applyStateModificationsToKanban(ctx, kanbanToUpdate);
      }
      if (standId === 'RFPR') {
        kanbanToUpdate = kanbanHelpers.computeSparePartReferenceOperationState(
          kanbanToUpdate,
          userLogin,
          siteConfiguration,
          sequence
        );
      }

      kanbanToUpdate = closeKanbanHandleOnPost(
        nonnull(session.infos?.id),
        kanbanToUpdate,
        // no operations will be finished for interrupt action
        {},
        undefined,
        session.user?.login ?? null
      );
      if (computePayload(kanban, kanbanToUpdate) !== undefined) {
        await kanbanRepository.updateEntity(kanbanToUpdate);
      }
      await actionDispatch.exec(clearOperatorViewAndNavigateToSelectionAction);
    },
    [
      standId,
      checkForConsistencyWarning,
      hasLocalChanges,
      kanban,
      applyStateModificationsToKanban,
      t,
    ],
    $
  );

  const isInterruptButtonDisabled = useMemo((): boolean => {
    if (
      state.workStatus === 'finished' &&
      kanbanHelpers.getOpenOperatorHandleForPost(kanban, postId) === undefined
    ) {
      return true;
    }
    return disableButtonRegardingConsistencyWarning(state, t, checkForConsistencyWarning);
  }, [state, kanban, postId, t, checkForConsistencyWarning]);

  const [interruptButtonLabel, interruptTooltip] = useMemo((): string[] => {
    if (hasLocalChanges) {
      return [t('timer.saveAndInterrupt'), t('timer.saveAndInterruptTooltip')];
    }
    return [t('timer.interrupt'), t('timer.interrupt')];
  }, [hasLocalChanges, t]);

  return (
    <Button
      tooltip={interruptTooltip}
      onClick={interruptClickHandler}
      disabled={isInterruptButtonDisabled}
      iconId="stop"
      label={interruptButtonLabel}
      isFullWidth
    />
  );
}

interface OperatorTimerProps {
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly type: 'countdown' | 'stopwatch';
}

function OperatorTimer({ type, $ }: OperatorTimerProps): JSX.Element {
  const consumedTimeInSeconds = useGetState($.$consumedTimeInSeconds);
  const totalTimeInSeconds = useGetState($.$totalTimeInSeconds);
  const workStatus = useGetState($.$workStatus);
  if (type === 'countdown') {
    return (
      <Countdown
        consumedTime={consumedTimeInSeconds}
        totalTime={totalTimeInSeconds}
        started={workStatus === 'started'}
      />
    );
  }
  if (type === 'stopwatch') {
    return <Stopwatch consumedTime={consumedTimeInSeconds} started={workStatus === 'started'} />;
  }
  return <></>;
}

export function StandWithLocalChangesOperatorCountdown({
  kanban,
  $,
  $gs,
  postId,
  standId,
  applyStateModificationsToKanban,
  operatorCustomRaiseWarningBeforeTerminateAction,
  checkForConsistencyWarning,
  operatorCustomTerminateAction,
  hasLocalChanges,
  customTerminateButtonLabelKey,
  timerType,
}: StandOperatorCountdownProps): JSX.Element {
  return (
    <div className="columns is-gap-medium is-multiline">
      <div className="column is-full">
        <OperatorTimer type={timerType} $={$} />
      </div>
      <div className="column is-full">
        <StartButton
          $={$}
          $gs={$gs}
          kanban={kanban}
          standId={standId}
          postId={postId}
          applyStateModificationsToKanban={applyStateModificationsToKanban}
          hasLocalChanges={hasLocalChanges}
          checkForConsistencyWarning={checkForConsistencyWarning}
        />
      </div>
      <div className="column is-full">
        <StopButton
          $={$}
          $gs={$gs}
          kanban={kanban}
          standId={standId}
          postId={postId}
          hasLocalChanges={hasLocalChanges}
          operatorCustomRaiseWarningBeforeTerminateAction={
            operatorCustomRaiseWarningBeforeTerminateAction
          }
          operatorCustomTerminateAction={operatorCustomTerminateAction}
          applyStateModificationsToKanban={applyStateModificationsToKanban}
          checkForConsistencyWarning={checkForConsistencyWarning}
          customLabelKey={customTerminateButtonLabelKey}
        />
      </div>
      <div className="column is-full">
        <InterruptButton
          $={$}
          $gs={$gs}
          kanban={kanban}
          standId={standId}
          postId={postId}
          applyStateModificationsToKanban={applyStateModificationsToKanban}
          hasLocalChanges={hasLocalChanges}
          checkForConsistencyWarning={checkForConsistencyWarning}
        />
      </div>
    </div>
  );
}

export function StandOperatorCountdown({
  kanban,
  $,
  $gs,
  postId,
  standId,
  applyStateModificationsToKanban,
  checkForConsistencyWarning,
  operatorCustomRaiseWarningBeforeTerminateAction,
  operatorCustomTerminateAction,
  hasLocalChanges,
  customTerminateButtonLabelKey,
  timerType,
}: StandOperatorCountdownProps): JSX.Element {
  const permissions = useGetState($gs.$session.$user.$permissions);
  const everyOperationsAreCompleted = useMemo(() => {
    const unfinishedOperationsToDo = packageDealHelpers.getAllUnfinishedOperationsForStandId(
      kanban.packageDeals,
      standId
    );

    const authorizedUnfinishedOperationToDo = unfinishedOperationsToDo.filter((o) =>
      canCompleteOperation(o, permissions)
    );

    return authorizedUnfinishedOperationToDo.length === 0;
  }, [kanban.packageDeals, standId, permissions]);

  return (
    <>
      <OperatorTimer type={timerType} $={$} />
      <div>
        <div className="mb-1">
          <StartButton
            $={$}
            $gs={$gs}
            kanban={kanban}
            standId={standId}
            postId={postId}
            applyStateModificationsToKanban={applyStateModificationsToKanban}
            hasLocalChanges={hasLocalChanges}
            checkForConsistencyWarning={checkForConsistencyWarning}
          />
        </div>
        <div>
          {everyOperationsAreCompleted ? (
            <StopButton
              $={$}
              $gs={$gs}
              kanban={kanban}
              standId={standId}
              postId={postId}
              hasLocalChanges={hasLocalChanges}
              operatorCustomRaiseWarningBeforeTerminateAction={
                operatorCustomRaiseWarningBeforeTerminateAction
              }
              operatorCustomTerminateAction={operatorCustomTerminateAction}
              applyStateModificationsToKanban={applyStateModificationsToKanban}
              customLabelKey={customTerminateButtonLabelKey}
              checkForConsistencyWarning={checkForConsistencyWarning}
            />
          ) : (
            <InterruptButton
              $={$}
              $gs={$gs}
              kanban={kanban}
              standId={standId}
              postId={postId}
              applyStateModificationsToKanban={applyStateModificationsToKanban}
              hasLocalChanges={hasLocalChanges}
              checkForConsistencyWarning={checkForConsistencyWarning}
            />
          )}
        </div>
      </div>
    </>
  );
}
