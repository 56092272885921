import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DropResult } from '@hello-pangea/dnd';
import type { Entity } from '@stimcar/libs-kernel';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AnyTableStoreDef } from '@stimcar/libs-uitoolkit';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { filterReject, sortingHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, ScrollableContainer } from '@stimcar/libs-uitoolkit';
import { MenuButton } from '../../../components/MenuButton.js';
import type {
  Column,
  ColumnDesc,
  ColumnSelectionState,
  SerializableColumn,
  TableState,
} from './typings/store.js';

interface SelectColumnsMenuProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
> {
  readonly buttonLabel?: string;
  readonly $: StoreStateSelector<SD, ColumnSelectionState>;
  readonly columnDescs: readonly ColumnDesc<SD, SC, O, SO>[];
  readonly localStorageKey?: string;
}

export function SelectColumnsMenu<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
>({
  buttonLabel,
  $,
  columnDescs,
  localStorageKey,
}: SelectColumnsMenuProps<SD, SC, O, SO>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const columns = useGetState($.$columns);
  const hiddenColumnsCount = columns.filter((c) => !c.isDisplayed).length;

  const onDragEnd = useActionCallback(
    async ({ actionDispatch }, result: DropResult): Promise<void> => {
      const { destination, source } = result;
      if (!destination) {
        return;
      }
      if (destination.index === source.index) {
        return;
      }

      const mutableArray = columns.slice();
      // Remove Element from origin array
      const removedElement = mutableArray.splice(source.index, 1)[0];
      // Insert removed element in new position
      mutableArray.splice(destination.index, 0, removedElement);

      await actionDispatch.exec(
        ({ keyValueStorage, actionDispatch }: ActionContext<SD, ColumnSelectionState>) => {
          actionDispatch.reduce((initial: ColumnSelectionState) => {
            return {
              ...initial,
              columns: mutableArray,
            };
          });
          if (localStorageKey) {
            const storedPrefs = keyValueStorage.getObjectItem(localStorageKey);
            keyValueStorage.setObjectItem(localStorageKey, {
              ...storedPrefs,
              columns: mutableArray
                .filter((c) => c.isDisplayed)
                .map((c): SerializableColumn => {
                  return {
                    id: c.id,
                  };
                }),
            });
          }
        }
      );
    },
    [localStorageKey, columns],
    $
  );

  const [displayedColumn, otherSortedColumns] = useMemo(() => {
    const { filtered, rejected } = filterReject(
      columns,
      (c1) => c1.isDisplayed && c1.type !== 'action'
    );
    const sortedRejected = [...rejected].sort((c1, c2) =>
      sortingHelpers.compareStrings(c1.label, c2.label, 'UP')
    );
    return [filtered, sortedRejected];
  }, [columns]);

  return (
    <MenuButton
      label={buttonLabel || t('selectColumnsMenu.buttonLabel', { count: hiddenColumnsCount })}
      $active={$.$active}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <ScrollableContainer className="dropdown-content" isNarrowUntilMaxHeight>
          <>
            <Droppable droppableId="droppable">
              {(provided): JSX.Element => (
                <div
                  // Beautiful-react-dnd API returns an any
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ref={(e): any => provided.innerRef(e)}
                  {...provided.droppableProps}
                >
                  {displayedColumn.map((col, i): JSX.Element => {
                    return (
                      <DraggableColumnItem
                        key={col.id}
                        $={$}
                        column={col}
                        columnDescs={columnDescs}
                        localStorageKey={localStorageKey}
                        index={i}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {otherSortedColumns.map((col): JSX.Element => {
              return (
                <ColumnItem
                  key={col.id}
                  $={$}
                  column={col}
                  columnDescs={columnDescs}
                  localStorageKey={localStorageKey}
                />
              );
            })}
          </>
        </ScrollableContainer>
      </DragDropContext>
    </MenuButton>
  );
}

interface ColumnItemProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
> {
  readonly column: Column;
  readonly columnDescs: readonly ColumnDesc<SD, SC, O, SO>[];
  readonly $: StoreStateSelector<SD, ColumnSelectionState>;
  readonly localStorageKey?: string;
}

interface InternalColumnItemProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
> extends ColumnItemProps<SD, SC, O, SO> {
  readonly children?: JSX.Element;
}
interface DraggableColumnItemProps<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
> extends ColumnItemProps<SD, SC, O, SO> {
  readonly index: number;
}

function DraggableColumnItem<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
>({
  column,
  columnDescs,
  $,
  localStorageKey,
  index,
}: DraggableColumnItemProps<SD, SC, O, SO>): JSX.Element {
  return (
    <Draggable draggableId={column.id} index={index} isDragDisabled={!column.isDisplayed}>
      {(provided): JSX.Element => (
        <div
          className="dropdown-item"
          // Beautiful-react-dnd API returns an any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={(e): any => provided.innerRef(e)}
          {...provided.draggableProps}
        >
          <InternalColumnItem
            column={column}
            columnDescs={columnDescs}
            $={$}
            localStorageKey={localStorageKey}
          >
            <div {...provided.dragHandleProps}>
              <FaIcon id="grip-vertical" />
            </div>
          </InternalColumnItem>
        </div>
      )}
    </Draggable>
  );
}

function ColumnItem<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
>({ column, columnDescs, $, localStorageKey }: ColumnItemProps<SD, SC, O, SO>): JSX.Element {
  return (
    <div className="dropdown-item">
      <InternalColumnItem
        column={column}
        columnDescs={columnDescs}
        $={$}
        localStorageKey={localStorageKey}
      />
    </div>
  );
}

function InternalColumnItem<
  SD extends AnyTableStoreDef,
  SC extends TableState<O>,
  O extends Entity,
  SO extends Entity,
>({
  column,
  columnDescs,
  $,
  localStorageKey,
  children,
}: InternalColumnItemProps<SD, SC, O, SO>): JSX.Element {
  const columnDesc = useMemo(() => {
    return columnDescs.find((cd) => cd.id === column.id);
  }, [column.id, columnDescs]);

  const toggleColumnSelectionCallback = useActionCallback(
    ({ actionDispatch, keyValueStorage, getState }) => {
      actionDispatch.reduce((initial: ColumnSelectionState) => {
        const newColumns = getState().columns.map((c: Column): Column => {
          if (c.id === column.id) {
            return {
              ...c,
              isDisplayed: !c.isDisplayed,
            };
          }
          return c;
        });
        const { filtered, rejected } = filterReject(
          newColumns,
          (c: Column) => c.isDisplayed && c.type !== 'action'
        );
        return {
          ...initial,
          columns: [...filtered, ...rejected],
        };
      });
      if (localStorageKey) {
        const storedPrefs = keyValueStorage.getObjectItem(localStorageKey);
        keyValueStorage.setObjectItem(localStorageKey, {
          ...storedPrefs,
          columns: [...getState().columns]
            .filter((c) => c.isDisplayed)
            .map((c: Column): SerializableColumn => {
              return {
                id: c.id,
              };
            }),
        });
      }
    },
    [column.id, localStorageKey],
    $
  );

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr minmax(10px, auto)',
        gridColumnGap: '10px',
        alignItems: 'center',
      }}
    >
      <div>
        <input
          id={column.id}
          type="checkbox"
          className="m-r-xs switch"
          checked={column.isDisplayed}
          disabled={columnDesc?.isNotHideable}
          onChange={toggleColumnSelectionCallback}
        />
        <label htmlFor={column.id}>{columnDesc?.columnLabel ?? ''}</label>
      </div>
      {children}
    </div>
  );
}
