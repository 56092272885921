import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { CarViewProps } from './car-view-common.js';
import { createStyleAndIdBuilder } from './car-view-common.js';
import { CarViewCanvas } from './CarViewCanvas.js';

export function CarMechanicalView<SD extends AnyStoreDef>(props: CarViewProps<SD>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedShapes, ...canvasProps } = props;
  const styleAndId = createStyleAndIdBuilder('MECA', props);
  return (
    <CarViewCanvas {...canvasProps} showCarBackground>
      <rect
        width="17.410652"
        height="31.378359"
        x="11.325727"
        y="43.391285"
        ry="0"
        {...styleAndId('WHEEL_FL')}
      />
      <rect
        ry="0"
        y="43.391285"
        x="170.10147"
        height="31.378359"
        width="17.410652"
        {...styleAndId('WHEEL_FR')}
      />
      <rect
        ry="0"
        y="157.69841"
        x="11.325727"
        height="31.378359"
        width="17.410652"
        {...styleAndId('WHEEL_BL')}
      />
      <rect
        width="17.410652"
        height="31.378359"
        x="170.10147"
        y="157.69841"
        ry="0"
        {...styleAndId('WHEEL_BR')}
      />
      <rect
        width="44.708096"
        height="35.871384"
        x="73.074112"
        y="41.144772"
        ry="0"
        {...styleAndId('MOTOR')}
      />
      <path
        d="m 121.11867,77.909655 19.772,-8.270375 V 48.055621 l -19.772,-8.068658 z"
        {...styleAndId('GEARBOX')}
      />
      <rect
        width="37.666843"
        height="4.6250057"
        x="32.079254"
        y="56.800125"
        {...styleAndId('AXLE_FL')}
      />
      <rect
        y="56.890923"
        x="143.83208"
        height="4.7504478"
        width="23.123798"
        {...styleAndId('AXLE_FR')}
      />
      <rect
        y="170.41869"
        x="32.142555"
        height="4.6254625"
        width="42.318024"
        {...styleAndId('AXLE_BL')}
      />
      <rect
        width="43.698174"
        height="4.6152029"
        x="122.98801"
        y="170.41356"
        {...styleAndId('AXLE_BR')}
      />
      <path
        d="M 93.912685,80.713048 V 164.76941 H 77.993246 v 14.71435 H 119.71731 V 164.76941 H 104.20095 V 80.713048 Z"
        {...styleAndId('DRIVE_SHAFT')}
      />
      <path
        d="m 75.977568,80.112579 h 5.643806 v 6.550845 l 3.728942,0.100783 -0.100783,44.041833 h -3.527377 v 8.66727 h -6.046933 l 0.10078,-8.56649 -7.861014,-0.10078 0.100782,-44.243398 h 7.961797 z"
        {...styleAndId('EXHAUST')}
      />
      <path
        d="M 313.58203,686.85742 A 76.688534,76.688534 0 0 0 297.69922,733.5 76.688534,76.688534 0 0 0 374.38867,810.18945 76.688534,76.688534 0 0 0 451.07617,733.5 76.688534,76.688534 0 0 0 435.14062,686.85742 Z m 62.19531,7.16211 a 38.688877,38.688877 0 0 1 38.68946,38.68945 38.688877,38.688877 0 0 1 -38.68946,38.6875 38.688877,38.688877 0 0 1 -38.68945,-38.6875 38.688877,38.688877 0 0 1 38.68945,-38.68945 z"
        transform="scale(0.26458333)"
        {...styleAndId('SPARE_WHEEL', 7)}
      />
    </CarViewCanvas>
  );
}
