import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ContractAttributeDesc,
  PriceableKanban,
  PriceablePackageDeal,
  User,
} from '@stimcar/libs-base';
import {
  nonDeleted,
  packageDealHelpers,
  shortDayMonthYearDateFormatOptions,
  sortingHelpers,
  splitElementsIntoEqualParts,
} from '@stimcar/libs-base';
import { CustomerInformations } from '../../displayInformations/CustomerInformations.js';
import { CarInfo } from '../DocumentGenerationCommonComponents.js';

export interface SinglePackageDealColumnProps<P extends PriceablePackageDeal> {
  readonly partsOfPackageDeals: readonly P[];
}

export function SinglePackageDealColumn<P extends PriceablePackageDeal>({
  partsOfPackageDeals,
}: SinglePackageDealColumnProps<P>): JSX.Element {
  return (
    <div className="column " style={{ padding: '0' }}>
      {partsOfPackageDeals.map((pkg): JSX.Element => {
        const content = `${
          pkg.carElement ? `${pkg.carElement.label}-` : ''
        }${packageDealHelpers.getPackageDealDisplayedLabel(pkg)}`;
        return <p key={pkg.id}>{content}</p>;
      })}
    </div>
  );
}

export interface DeliveryComponentProps {
  readonly kanban: PriceableKanban;
  readonly attributeDescs: readonly ContractAttributeDesc[];
  readonly user?: Pick<User, 'firstName' | 'lastName'>;
}

export function DeliveryComponent({
  kanban,
  attributeDescs,
  user,
}: DeliveryComponentProps): JSX.Element {
  const [t] = useTranslation('libComponents');

  const sortedAndDividedAvailablePackageDeals = useMemo(() => {
    return splitElementsIntoEqualParts<PriceablePackageDeal>(
      packageDealHelpers
        .getAvailablePackageDeals(kanban.packageDeals)
        .slice()
        .sort(sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction('DOWN')),
      3
    );
  }, [kanban.packageDeals]);

  const sortedAndDividedCanceledPackageDeals = useMemo(() => {
    return splitElementsIntoEqualParts<PriceablePackageDeal>(
      packageDealHelpers
        .getCanceledPackageDeals(kanban.packageDeals)
        .slice()
        .sort(sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction('DOWN')),
      3
    );
  }, [kanban.packageDeals]);

  const sortedAndDividedUnachievablePackageDeals = useMemo(() => {
    return splitElementsIntoEqualParts<PriceablePackageDeal>(
      kanban.packageDeals
        .filter(nonDeleted)
        .filter(packageDealHelpers.buildPackageDealFilter('available'))
        .filter((pd) => !packageDealHelpers.isPackageDealAchievable(pd))
        .sort(sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction('DOWN')),
      2
    );
  }, [kanban.packageDeals]);

  const qualityControllerName = user ? `${user.firstName} ${user.lastName}` : '______________';
  const qualityControlDate = new Date().toLocaleDateString(
    undefined,
    shortDayMonthYearDateFormatOptions
  );

  return (
    <div style={{ lineHeight: 1.5 }}>
      <div className="columns has-text-centered">
        <div
          className="column is-fullwidth"
          style={{ border: '0.0625em solid', marginRight: '0.5em', marginLeft: '0.5em' }}
        >
          <p className="title">{t('documentGeneration.delivery.title')}</p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-8">
          <CarInfo attributeDescs={attributeDescs} kanban={kanban} />
        </div>
        <div className="column">
          <CustomerInformations customer={kanban.customer} />
        </div>
      </div>
      <table className="table is-narrow is-fullwidth is-bordered  is-striped">
        <thead>
          <tr>
            <td>
              <strong>{t('documentGeneration.delivery.packageDealsList')}</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div
                className="columns is-multiline"
                style={{ padding: '0.5em', paddingTop: '1em', paddingBottom: '1em' }}
              >
                {sortedAndDividedAvailablePackageDeals.map(
                  (partsOfPackageDeals, i): JSX.Element => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <SinglePackageDealColumn key={i} partsOfPackageDeals={partsOfPackageDeals} />
                    );
                  }
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {sortedAndDividedUnachievablePackageDeals.length > 0 && (
        <table className="table is-bordered">
          <div
            className="columns is-multiline"
            style={{ padding: '0.75em', paddingTop: '1em', paddingBottom: '1em' }}
          >
            {sortedAndDividedUnachievablePackageDeals.map((partsOfPackageDeals): JSX.Element => {
              return (
                <table>
                  <thead>
                    <tr>
                      <th style={{ padding: '0', paddingLeft: '0.5em' }}>
                        {t('documentGeneration.delivery.unachievablePackageDeal')}
                      </th>
                      <th style={{ padding: '0', paddingLeft: '0.5em' }}>
                        {t('documentGeneration.delivery.unachievableReason')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {partsOfPackageDeals.map((pkg): JSX.Element => {
                      return (
                        <tr key={pkg.id}>
                          <td style={{ padding: '0', paddingLeft: '0.5em' }}>
                            {packageDealHelpers.getPackageDealDisplayedLabel(pkg)}
                          </td>
                          <td style={{ padding: '0', paddingLeft: '0.5em' }}>
                            {pkg.unachievableReason}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              );
            })}
          </div>
        </table>
      )}
      {sortedAndDividedCanceledPackageDeals.length > 0 && (
        <table className="table is-narrow is-fullwidth is-bordered  is-striped">
          <thead>
            <tr>
              <td>
                <strong>{t('documentGeneration.delivery.unvalidatedpackageDealsList')}</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className="columns is-multiline"
                  style={{ padding: '0.5em', paddingTop: '1em', paddingBottom: '1em' }}
                >
                  {sortedAndDividedCanceledPackageDeals.map(
                    (partsOfPackageDeals, i): JSX.Element => {
                      return (
                        <SinglePackageDealColumn
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          partsOfPackageDeals={partsOfPackageDeals}
                        />
                      );
                    }
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="columns" style={{ marginBottom: '1em' }}>
        <div className="column is-three-fifths">
          <table className="table is-narrow is-bordered is-fullwidth">
            <tbody>
              <tr>
                <td style={{ width: '7em', height: '12em' }}>
                  {t('documentGeneration.delivery.listReservations')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table className="table is-narrow is-fullwidth">
          <tbody>
            <tr style={{ border: 0 }}>
              <td>
                <div className="column is-one-quarter columns">
                  <div className="column is-narrow">
                    <p className="has-text-weight-bold">
                      {`${t('documentGeneration.delivery.qualityControlDoneBy')}:`}
                    </p>
                    <p className="has-text-weight-bold">
                      {`${t('documentGeneration.fillableDateField')}:`}
                    </p>
                    <p className="has-text-weight-bold">{t('documentGeneration.delivery.sign')}</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                  <div className="column is-narrow">
                    <p className="has-text-weight-bold">{` ${qualityControllerName}`}</p>
                    <p className="has-text-weight-bold">{` ${qualityControlDate}`}</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ border: 0 }}>
              <td>
                <div className="column is-one-quarter columns">
                  <div className="column is-narrow">
                    <p className="has-text-weight-bold">
                      {`${t('documentGeneration.delivery.acceptedBy')}:`}
                    </p>
                    <p className="has-text-weight-bold">
                      {`${t('documentGeneration.fillableDateField')}:`}
                    </p>
                    <p className="has-text-weight-bold">{t('documentGeneration.delivery.sign')}</p>
                  </div>
                  <div className="column is-narrow">
                    <p className="has-text-weight-bold"> ______________</p>
                    <p className="has-text-weight-bold"> __/__/____</p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
