import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { InputFormField } from '@stimcar/libs-uitoolkit';
import type { LoginForm } from './typings/store.js';

export const LOGIN_MANDATORY_FIELDS: (keyof LoginForm)[] = ['login', 'password'];
export const LOGIN_FROM_WORKSHOP_MANDATORY_FIELDS: (keyof LoginForm)[] = ['login'];

interface LoginFieldsProps<SD extends AnyStoreDef> {
  readonly loginIsFixed: boolean;
  readonly $login: StoreStateSelector<SD, string>;
  readonly $password: StoreStateSelector<SD, string>;
  readonly onEnterKeyPressHandler: NoArgActionCallback<SD>;
}

export function LoginFields<SD extends AnyStoreDef>({
  loginIsFixed,
  $login,
  $password,
  onEnterKeyPressHandler,
}: LoginFieldsProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');
  const executeSubmitOnEnterPressHandler = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        await onEnterKeyPressHandler();
      }
    },
    [onEnterKeyPressHandler]
  );
  return (
    <>
      <InputFormField
        label={t('login.login')}
        $={$login}
        disabled={loginIsFixed}
        onKeyPress={executeSubmitOnEnterPressHandler}
      />
      <InputFormField
        label={t('login.password')}
        type="password"
        $={$password}
        onKeyPress={executeSubmitOnEnterPressHandler}
      />
    </>
  );
}
