import type { CSSProperties } from 'react';
import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { CarViewProps } from './car-view-common.js';
import { createStyleAndIdBuilder, TRANSPARENT_VIEW_NODE_STYLE } from './car-view-common.js';
import { CarViewCanvas } from './CarViewCanvas.js';

const NUMBERPLATE_TEXT_STYLE: CSSProperties = {
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontSize: '5.64444447px',
  fontFamily: 'sans-serif',
  textAlign: 'center',
  textAnchor: 'middle',
  strokeWidth: '0.26458332px',
  fill: 'black',
};
export function CarBumpersView<SD extends AnyStoreDef>(props: CarViewProps<SD>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedShapes, ...canvasProps } = props;
  const styleAndId = createStyleAndIdBuilder('BUMP', props);
  return (
    <CarViewCanvas {...canvasProps} showCarBackground={false}>
      <g>
        {/* Back elements */}
        <g transform="translate(0,16.404171)">
          <rect
            ry="2.6357925"
            y="158.35828"
            x="31.750006"
            height="39.6875"
            width="21.166658"
            id="BackWheelLeft"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
          <rect
            id="BackWheelRight"
            width="21.166662"
            height="39.6875"
            x="148.16667"
            y="158.35828"
            ry="2.6357925"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
          <path
            id="upperCarShape"
            style={TRANSPARENT_VIEW_NODE_STYLE}
            d="m 42.333333,111.2625 10.583333,-15.875008 7.9375,-2.64583 h 79.375004 l 7.93749,2.64583 L 158.75,111.2625 153.45833,119.2 H 47.624999 Z"
          />
          <g {...styleAndId('CANNULA_BL')}>
            <path d="m 41.010416,177.9375 7.9375,7.9375 h 14.552083 l -2.645833,-5.29167 H 47.624999 Z" />
            <ellipse
              cx="54.239582"
              cy="183.22917"
              rx="5.2916665"
              ry="1.3229166"
              style={TRANSPARENT_VIEW_NODE_STYLE}
            />
          </g>
          <g {...styleAndId('CANNULA_BR')}>
            <path d="m 160.07291,177.9375 -6.61458,2.64583 h -13.22916 l -5.29167,5.29167 h 17.19791 z" />
            <ellipse
              ry="1.3229166"
              rx="5.2916665"
              cy="183.1353"
              cx="145.66751"
              style={TRANSPARENT_VIEW_NODE_STYLE}
            />
          </g>
          <g {...styleAndId('NUMBERPLATE_B')} transform="translate(0,73.554162)">
            <rect width="37.041668" height="7.9374905" x="82.020836" y="82.6875" />
            <text x="100.72939" y="88.524857">
              <tspan x="100.72939" y="88.524857" style={NUMBERPLATE_TEXT_STYLE}>
                XX-000-XX
              </tspan>
            </text>
          </g>
          <path
            {...styleAndId('UPPER_BUMPER_BL', 6)}
            d="m 160,484.51953 -40,40 -10,90 80,-20 v -40 H 151.11133 C 144.9558,554.51953 140,549.56373 140,543.4082 v -17.77734 c 0,-6.15553 4.9558,-11.11133 11.11133,-11.11133 H 182.5 Z"
            transform="matrix(0.26458333,0,0,0.26458333,0,-16.404171)"
          />
          <path
            {...styleAndId('UPPER_BUMPER_BR', 6)}
            d="m 600,484.51953 -22.5,30 h 31.38867 c 6.15553,0 11.11133,4.9558 11.11133,11.11133 v 17.77734 c 0,6.15553 -4.9558,11.11133 -11.11133,11.11133 H 570 v 40 l 80,20 -10,-90 z"
            transform="matrix(0.26458333,0,0,0.26458333,0,-16.404171)"
          />
          <path
            {...styleAndId('MIDDLE_BUMPER_B', 6)}
            d="m 190,594.51953 -80,20 v 20 l 40,30 40,10 h 120 v -22 h 140 v 22 h 120 l 40,-10 40,-30 v -20 l -80,-20 -40,40 H 230 Z"
            transform="matrix(0.26458333,0,0,0.26458333,0,-16.404171)"
          />
          <path
            {...styleAndId('LOWER_BUMPER_B', 6)}
            d="m 110,634.51953 v 60 l 20,30 50,20 h 50 l 20,-10 h 260 l 20,10 h 50 l 50,-20 20,-40 v -50 l -40,30 -40,10 H 450 v 8 H 310 v -8 H 190 l -40,-10 z m 20,50 40,10 v 30 l -40,-20 z m 500,0 v 20 l -40,20 v -30 z"
            transform="matrix(0.26458333,0,0,0.26458333,0,-16.404171)"
          />
          <rect
            {...styleAndId('HEADLIGHT_BR')}
            width="23.8125"
            height="10.583333"
            x="140.22917"
            y="119.72916"
            ry="2.9398029"
          />
          <rect
            {...styleAndId('HEADLIGHT_BL')}
            width="23.8125"
            height="10.583333"
            x="37.041668"
            y="119.72916"
            ry="2.9398029"
          />
          <path
            {...styleAndId('DEFLECTOR_B')}
            d="M 63.499999,185.875 H 134.9375 l 5.29167,-5.29167 -5.29167,-2.64583 H 66.145832 l -5.291666,2.64583 z"
          />
          <g {...styleAndId('COUPLING')}>
            <rect ry="2.2048523" y="185.875" x="92.604164" height="6.6145835" width="15.875001" />
            <circle r="1.984375" cy="189.1823" cx="100.51102" style={TRANSPARENT_VIEW_NODE_STYLE} />
          </g>
          <path
            {...styleAndId('ANTI_FOG_BR')}
            d="m 156.10416,175.29167 v -7.9375 l 10.58334,-2.64584 V 170 Z"
          />
          <path
            {...styleAndId('ANTI_FOG_BL')}
            d="M 34.395833,164.70833 V 170 l 10.583333,5.29167 v -7.9375 z"
          />
        </g>
        {/* Front elements */}
        <g>
          <rect
            id="FrontWeelLeft"
            width="21.166666"
            height="39.6875"
            x="31.75"
            y="66.8125"
            ry="2.6357925"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
          <rect
            id="FrontWheelRight"
            ry="2.6357925"
            y="66.8125"
            x="148.16667"
            height="39.6875"
            width="21.166662"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
          <path
            d="m 39.6875,45.645834 21.166666,-2.645833 h 79.375004 l 21.16666,2.645833 -21.16666,10.583333 -79.375004,3e-6 z"
            id="upperCarShape1"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
          <path
            d="m 37.041669,45.645834 23.8125,-23.812501 h 79.375001 l 23.81249,23.812501 h -2.64583 l -21.16666,10.583333 -79.375001,3e-6 -21.166666,-10.583336 z"
            id="upperCarShape1"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
          <path
            {...styleAndId('UPPER_BUMPER_F', 6)}
            d="m 140,172.51953 -20,10 -10,60 30,30 60,20 h 360 l 60,-20 30,-30 -10,-60 -20,-10 h -10 v 40 l -60,20 -20,-20 h -10 v 20 l -10,10 H 250 l -10,-10 v -20 h -10 l -20,20 -60,-20 v -40 z m 109.92578,84.91992 h 260.14844 l 10.00586,7.78711 v 15.57227 l -10.00586,7.78711 H 249.92578 l -10.00586,-7.78711 v -15.57227 z"
            transform="scale(0.26458333)"
          />
          <path
            {...styleAndId('LOWER_BUMPER_F', 6)}
            d="m 110,242.51953 v 60 l 30,30 60,10 h 110 v -10 h -60 l -10,-10 v -10 l 10,-10 h 260 l 10,10 v 10 l -10,10 h -60 v 10 h 110 l 60,-10 30,-30 v -60 l -30,30 -60,20 H 200 l -60,-20 z m 45,50 a 15,15 0 0 1 15,15 15,15 0 0 1 -15,15 15,15 0 0 1 -15,-15 15,15 0 0 1 15,-15 z m 450,0 a 15,15 0 0 1 15,15 15,15 0 0 1 -15,15 15,15 0 0 1 -15,-15 15,15 0 0 1 15,-15 z"
            transform="scale(0.26458333)"
          />
          <path
            {...styleAndId('UPPER_GRID_F')}
            d="m 63.499999,56.229167 74.083331,3e-6 v 5.291664 L 134.9375,64.16667 H 66.145832 l -2.645833,-2.645836 z"
          />
          <path
            {...styleAndId('MIDDLE_GRID_F')}
            d="m 66.106637,68.135417 -2.647341,2.060155 v 4.12031 l 2.647341,2.060151 H 134.9375 l 2.64733,-2.060151 v -4.12031 l -2.64733,-2.060155 z"
          />
          <path
            {...styleAndId('LOWER_GRID_F', 6)}
            d="m 250,302.51953 -10,10 v 10 l 10,10 h 60 v -20 h 140 v 20 h 60 l 10,-10 v -10 l -10,-10 z"
            transform="scale(0.26458333)"
          />
        </g>
        <g {...styleAndId('NUMBERPLATE_F')}>
          <rect y="82.6875" x="82.020836" height="7.9374905" width="37.041668" />
          <text y="88.524857" x="100.72939">
            <tspan y="88.524857" x="100.72939" style={NUMBERPLATE_TEXT_STYLE}>
              XX-000-XX
            </tspan>
          </text>
          <circle {...styleAndId('ANTI_FOG_FL')} cx="160.07292" cy="81.364578" r="3.96875" />
          <circle {...styleAndId('ANTI_FOG_FR')} cx="41.010418" cy="81.364578" r="3.96875" />
          <path
            {...styleAndId('HEADLIGHT_FR')}
            d="M 60.854166,56.22917 55.562499,61.520834 39.6875,56.22917 V 45.645834 Z"
          />
          <path
            {...styleAndId('HEADLIGHT_FL')}
            d="m 140.22917,56.229167 5.29166,5.291667 15.875,-5.291667 V 45.645834 Z"
          />
          <g {...styleAndId('DEFLECTOR_F')}>
            <path d="m 52.916666,90.62499 5.291667,5.291677 84.666667,-8e-6 5.29166,-5.291669 z" />
            <rect
              y="91.947914"
              x="75.40625"
              height="2.6458333"
              width="23.8125"
              style={TRANSPARENT_VIEW_NODE_STYLE}
            />
            <rect
              y="91.947914"
              x="101.86458"
              height="2.6458333"
              width="23.8125"
              style={TRANSPARENT_VIEW_NODE_STYLE}
            />
            <rect
              y="91.947914"
              x="59.53125"
              height="2.6458333"
              width="13.229166"
              style={TRANSPARENT_VIEW_NODE_STYLE}
            />
            <rect
              y="91.947914"
              x="128.32292"
              height="2.6458333"
              width="13.229166"
              style={TRANSPARENT_VIEW_NODE_STYLE}
            />
          </g>
        </g>
      </g>
    </CarViewCanvas>
  );
}
