import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { RegisteredBrowserInfos } from '@stimcar/core-libs-repository';
import type {
  ActionContext,
  StoreStateSelector,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { i18nHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  HORIZONTAL_FORM_SMALL_LABEL,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type {
  HandledKanbanActions,
  LogoutDialogState,
  LogoutForm,
  Store,
} from '../state/typings/store.js';
import { RadioButtonsFormField } from '../../lib/bulma/form/RadioButtonsFormField.js';
import { logoutAction } from '../state/actions/authenticate.js';
import { closeOrPauseHandledKanbansAction } from '../utils/postConfigurationUtils.js';

async function logoutUserAction({
  getState,
  globalActionDispatch,
}: ActionContext<Store, LogoutDialogState>): Promise<void> {
  const { actionToPerformOnLogout } = getState().formData;
  await globalActionDispatch.exec(
    closeOrPauseHandledKanbansAction,
    actionToPerformOnLogout as HandledKanbanActions
  );
  await globalActionDispatch.scopeProperty('session').exec(logoutAction);
}

export function LogoutWithWIPModal({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('refitit');

  const { $navbar } = $gs;
  const { $logoutDialogState } = $navbar;

  const browserInfos = useGetState($gs.$session.$infos);

  const submitValidDataAction = useActionCallback(logoutUserAction, [], $logoutDialogState);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    LogoutDialogState,
    [RegisteredBrowserInfos]
  >(
    {
      $: $logoutDialogState,
      mandatoryFields: [],
      checkFieldContentActions: undefined, // No field content check
      checkFormConsistencyAction: undefined, // No specific form consistency check
      submitValidDataAction,
      t,
    },
    // Additional validation argument
    nonnull(browserInfos)
  );

  const onCancelClickedHandler = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.reduce((initial: LogoutDialogState): LogoutDialogState => {
        return {
          ...initial,
          formData: {
            ...initial.formData,
            actionToPerformOnLogout: 'close',
          },
        };
      });
    },
    [],
    $logoutDialogState
  );

  const handledKanbanInfos = useGetState($navbar.$handledKanban.optChaining().$infos);

  const logoutModalLabel = useMemo((): string => {
    if (isTruthy(handledKanbanInfos)) {
      return t('logoutModal.oneHandledKanban', {
        brand: i18nHelpers.displayStringOrPlaceholder(t, handledKanbanInfos.brand),
        model: handledKanbanInfos.model,
        license: handledKanbanInfos.license,
      });
    }
    return t('logoutModal.defaultContent');
  }, [handledKanbanInfos, t]);

  const formWarning = useGetState($logoutDialogState.$formWarning);

  return (
    <ModalCardDialog
      warning={formWarning}
      $active={$logoutDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('logoutModal.title')}
      onOkClicked={onFormSubmit}
      onCancelClicked={onCancelClickedHandler}
    >
      <div>{logoutModalLabel}</div>
      <br />
      <div className="control">
        <CloseHandledKanban
          browserInfos={nonnull(browserInfos)}
          horizontal={HORIZONTAL_FORM_SMALL_LABEL}
          $formData={$formDataWithChangeTrigger}
        />
      </div>
    </ModalCardDialog>
  );
}

interface CloseHandledKanbanProps {
  readonly browserInfos: RegisteredBrowserInfos;
  readonly $formData: StoreStateSelector<Store, WithFormValidationWarnings<LogoutForm>>;
  readonly horizontal?: boolean | HorizontalFormFieldProps;
}

function CloseHandledKanban({ $formData, horizontal }: CloseHandledKanbanProps): JSX.Element {
  const [t] = useTranslation('refitit');
  const actions = useMemo(
    (): FormFieldEntry<HandledKanbanActions>[] => [
      {
        label: t('logoutModal.quitAndClose'),
        id: 'close',
      },
      {
        label: t('logoutModal.quitAndPause'),
        id: 'pause',
      },
    ],
    [t]
  );

  return (
    <RadioButtonsFormField
      id="handledKanbanAction"
      label={t('switchRoleDialog.kanbanHandledTitle')}
      entries={actions}
      $={$formData.$actionToPerformOnLogout}
      horizontal={horizontal}
      radioGroupLayout="vertical"
    />
  );
}
