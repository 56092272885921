import React, { useCallback } from 'react';
import type { FaIconSize } from '../icons/FaIcon.js';
import { convertFaIconSizeToFontSize, FaIcon } from '../icons/FaIcon.js';

export interface ButtonProps {
  readonly iconId?: string;
  readonly label?: string;
  readonly tooltip?: string;
  readonly onClick: () => Promise<void> | void;
  readonly isFullWidth?: boolean;
  readonly additionalClass?: string;
  readonly additionalIconClass?: string;
  readonly additionalStyle?: React.CSSProperties;
  readonly size?: FaIconSize;
  readonly disabled?: boolean;
}

export function Button({
  onClick,
  label,
  tooltip,
  iconId,
  isFullWidth = false,
  additionalClass,
  additionalIconClass,
  additionalStyle = {},
  size = 'standard',
  disabled = false,
}: ButtonProps): JSX.Element {
  const [isButtonDisabled, setDisableButton] = React.useState(false);

  const onClickProtectedCallback = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      // Disable button to prevent double click that will create two validation code
      setDisableButton(true);
      // If the user has double clicked, there is many chances that the modal had been
      // closed, leaving him without the validation modal and the button disabled
      // So reactivate it in 500 ms
      setTimeout(() => {
        setDisableButton(false);
      }, 500);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onClick();
    },
    [onClick]
  );

  const buttonSize = size === 'standard' ? undefined : size.replace(/-.*$/g, '');

  return (
    <button
      type="button"
      className={`button${isFullWidth ? ' is-fullwidth' : ''}${
        buttonSize ? ` is-${buttonSize}` : ''
      }${additionalClass ? ` ${additionalClass}` : ''}`}
      title={!tooltip ? label : tooltip}
      onClick={onClickProtectedCallback}
      disabled={isButtonDisabled || disabled}
      style={additionalStyle}
    >
      {iconId ? (
        <FaIcon
          id={iconId}
          tooltip={!tooltip ? label : tooltip}
          label={label}
          size={size}
          additionalClass={additionalIconClass}
        />
      ) : (
        <span className={`is-size-${convertFaIconSizeToFontSize(size)}`} title={tooltip}>
          {label}
        </span>
      )}
    </button>
  );
}
