import React from 'react';
import { useClientRect } from '../../hooks/useRemainingVerticalSpace.js';
import type { ScrollableContainerProps } from './ScrollableContainer.js';
import { ScrollableContainer } from './ScrollableContainer.js';

export interface WithFooterProps extends Omit<ScrollableContainerProps, 'bottomPlaceholderSize'> {
  readonly footerComponent: JSX.Element;
}

export function ScrollableContainerWithFooter({
  children,
  height,
  isPrintable = false,
  isNarrowUntilMaxHeight,
  className,
  footerComponent,
}: WithFooterProps): JSX.Element {
  const [footerHeight, footerRef] = useClientRect(footerComponent);

  return (
    <>
      <ScrollableContainer
        bottomPlaceholderSize={footerHeight}
        isNarrowUntilMaxHeight={isNarrowUntilMaxHeight}
        height={height}
        isPrintable={isPrintable}
        className={className}
      >
        {children}
      </ScrollableContainer>
      <footer className="footer" style={{ borderTop: '1px solid #dbdbdb' }} ref={footerRef}>
        {footerComponent}
      </footer>
    </>
  );
}
