import React from 'react';
import type { WithFooterProps } from './ScrollableContainerWithFooter.js';
import { ScrollableContainerWithFooter } from './ScrollableContainerWithFooter.js';

export interface WithHeaderProps extends WithFooterProps {
  readonly headerComponent: JSX.Element;
}

export function ScrollableContainerWithHeaderAndFooter({
  children,
  height,
  isPrintable = false,
  footerComponent,
  isNarrowUntilMaxHeight,
  className,
  headerComponent,
}: WithHeaderProps): JSX.Element {
  return (
    <>
      {headerComponent}
      <ScrollableContainerWithFooter
        height={height}
        isPrintable={isPrintable}
        footerComponent={footerComponent}
        isNarrowUntilMaxHeight={isNarrowUntilMaxHeight}
        className={className}
      >
        {children}
      </ScrollableContainerWithFooter>
    </>
  );
}
