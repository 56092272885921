import React from 'react';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';

interface DisplayMultilineStringProps {
  readonly value: string | undefined;
  readonly placeholder?: string;
}

export function DisplayMultilineString({
  value,
  placeholder,
}: DisplayMultilineStringProps): JSX.Element {
  return (
    <>
      {isTruthyAndNotEmpty(value)
        ? value.split('\n').map(
            (line, i): JSX.Element => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            )
          )
        : placeholder || ''}
    </>
  );
}
