import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RegisteredBrowserInfos } from '@stimcar/core-libs-repository';
import type { Kanban } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { kanbanHelpers, transverseHelpers, userHelpers } from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { WorkshopImplantationViewState } from '../../../lib/components/workshop/typings/store.js';
import type { Store, StoreState } from '../../state/typings/store.js';
import {
  useCreateAnomalyPostActionProvider,
  useCreatePostActionsProvider,
  useCreateQueueActionsProvider,
} from '../../../lib/components/workshop/implantation/useCreateActionsProviderForWorkshopImplantationView.js';
import { WorkshopImplantationComponent } from '../../../lib/components/workshop/implantation/WorkshopImplantationComponent.js';
import {
  computeNewKanbansHandledInWorkshop,
  getIdsOfKanbansInAnomaly,
} from '../../../lib/components/workshop/implantation/workshopImplantationUtils.js';
import { useGetCurrentWorkshopOperatorImplantation } from '../../utils/useGetCurrentWorkshopOperatorImplantation.js';

export async function retrieveKanbansHandledInWorkshopInitialization(
  { globalActionDispatch, kanbanRepository, runWithProgressBar }: ActionContext<Store, StoreState>,
  isBrowserRegistered: boolean,
  infos: RegisteredBrowserInfos | undefined
): Promise<void> {
  await runWithProgressBar(10, async (monitor) => {
    if (
      isBrowserRegistered &&
      userHelpers.isWorkshopPostOperator(infos?.role ?? '', infos?.label)
    ) {
      monitor.setLabel(i18next.t('refitit:boot.loadWorkshopKanbans'));
      const kanbans = await kanbanRepository.getAllEntities();
      const { implantationId } =
        transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(nonnull(infos).label);
      // Workshop operators are still assigned to a stand
      const standId = nonnull(nonnull(infos).standId);
      const filteredKanbans = kanbanHelpers.getKanbansCurrentlyInImplantation(
        kanbans,
        standId,
        nonnull(implantationId)
      );
      const storedMirrorEffect =
        localStorage.getItem(LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT) === 'true';

      globalActionDispatch.scopeProperty('workshopImplantationView').reduce((initial) => {
        return {
          ...initial,
          kanbans: filteredKanbans,
          kanbanInAnomalyIds: getIdsOfKanbansInAnomaly(nonnull(implantationId), filteredKanbans),
          standId,
          implantationId: nonnull(implantationId),
          mirrorEffect: storedMirrorEffect,
        };
      });
    }
  });
}

export function updateKanbansHandledInWorkshopStateFromSSEAction(
  { getState, getGlobalState, actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  theKanbans: Kanban[]
): void {
  const { infos } = getGlobalState().session;
  if (userHelpers.isWorkshopPostOperator(infos?.role ?? '', infos?.label)) {
    const { kanbans } = getState();

    const { implantationId } = transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
      nonnull(infos).label
    );
    const newKanbans = computeNewKanbansHandledInWorkshop(
      // Workshop operators are still assigned to a stand
      nonnull(nonnull(infos).standId),
      nonnull(implantationId),
      kanbans,
      theKanbans
    );
    actionDispatch.reduce((initial) => {
      return {
        ...initial,
        kanbans: newKanbans,
        kanbanInAnomalyIds: getIdsOfKanbansInAnomaly(nonnull(implantationId), newKanbans),
      };
    });
  }
}

export function WorkshopView({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('workshop');
  const { $workshopImplantationView } = $gs;
  // Workshop operators are still assigned to a stand
  const standId = useGetState($gs.$session.$infos.optChaining().$standId) ?? '';
  const username = useGetState($gs.$session.$user.optChaining().$login) ?? '';
  const implantation = useGetCurrentWorkshopOperatorImplantation($gs);

  const queueActionsProvider = useCreateQueueActionsProvider(
    t,
    standId,
    implantation,
    username,
    false,
    $workshopImplantationView,
    $gs
  );

  const postActionsProvider = useCreatePostActionsProvider(
    t,
    standId,
    implantation,
    username,
    false,
    $workshopImplantationView,
    $gs
  );

  const anomalyPostActionProvider = useCreateAnomalyPostActionProvider(
    t,
    standId,
    implantation,
    username,
    false,
    $workshopImplantationView
  );

  if (!isTruthy(implantation)) {
    return <p className="has-text-centered">Erreur de configuration</p>;
  }

  return (
    <WorkshopImplantationComponent
      standId={standId}
      implantation={implantation}
      $={$workshopImplantationView}
      postActionsProvider={postActionsProvider}
      queueActionsProvider={queueActionsProvider}
      anomalyPostActionProvider={anomalyPostActionProvider}
      $selectImplantation={undefined}
      $gs={$gs}
    />
  );
}
