import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { AttributeType, PackageDeal, UIContract, Workflow } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
} from '@stimcar/libs-uitoolkit';
import {
  contractHelpers,
  EMPTY_CUSTOMER_SPECIFIC_FIELDS,
  EMPTY_KANBAN,
  EMPTY_KANBAN_INFOS,
  EMPTY_OPERATION,
  EMPTY_PACKAGE_DEAL,
  enumerate,
  forEachRecordValues,
  getSpecificFields,
  KANBAN_IDENTITY_PICTURE_CATEGORY,
  KANBAN_IDENTITY_PICTURE_FOLDER,
  KANBAN_IDENTITY_PICTURE_NAME,
  MARKETPLACE_SELL_PACKAGE_DEAL_CODE,
  MARKETPLACE_STAND_ID,
  nonDeleted,
  packageDealHelpers,
  purchaseOrderHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
  useSetCallback,
} from '@stimcar/libs-uikernel';
import { Button, FormWarning, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../lib/components/attachments/typings/attachment.js';
import type { Store } from '../state/typings/store.js';
import { ConfirmLocalAttachmentRemovalDialog } from '../../lib/components/attachments/ConfirmAttachmentRemovalDialog.js';
import { EMPTY_PICTURE_EDITION_TOOLKIT_STATE } from '../../lib/components/attachments/typings/store.js';
import { convertBusinessCustomerToFormData } from '../../lib/components/customer/EditCustomerDialog.js';
import {
  checkEditCustomerFormFieldContentActions,
  EditCustomerForm,
  useEditCustomerFormMandatoryFields,
} from '../../lib/components/customer/EditCustomerForm.js';
import {
  convertToAttributeExpectedType,
  DisplayAttributesComponent,
} from '../../lib/components/DisplayAttributesComponent.js';
import {
  DISPLAY_ATTRIBUTES_EMPTY_STATE,
  KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE,
} from '../../lib/components/typings/store.js';
import { computeKanbanDetailsPath } from '../coreConstants.js';
import { importAttachmentsAndOverrideAction } from '../utils/attachmentGalleryActions.js';
import {
  checkEditGeneralInformationsFormFieldContentActions,
  editGeneralInformationsFormMandatoryFields,
} from '../utils/generalInformations/EditGeneralInformationsForm.js';
import { loadKanbanLocallyOrFromServer } from '../utils/navigationActions.js';
import { useComputeAttachmentUrl } from '../utils/useComputeAttachmentUrl.js';
import { useGetContractCodes } from '../utils/useGetContract.js';
import type {
  CreateKanbanViewState,
  NewKanbanFormData,
  SelectPredefinedCustomerDialogState,
} from './typings/store.js';
import {
  CreationGeneralInformationsComponent,
  selectedTypeOrContractOrWorkflowChangeAction,
} from './components/CreationGeneralInformationsComponent.js';
import { CreationPackageDealsComponent } from './components/CreationPackageDealsComponent.js';
import {
  openSelectPredefinedCustomerDialog,
  SelectPredefinedCustomerDialog,
} from './components/SelectPredefinedCustomerDialog.js';
import {
  createKanbanFromState,
  updatePackageExpressionComputationsFromFormData,
} from './createKanbanUtils.js';
import {
  CREATE_KANBAN_VIEW_EMPTY_STATE,
  EMPTY_CREATE_KANBAN_FORM,
  EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE,
} from './typings/store.js';

async function initializeKanbanCreationState(
  {
    actionDispatch,
    kanbanRepository,
    getGlobalState,
    httpClient,
    globalActionDispatch,
  }: ActionContext<Store, CreateKanbanViewState>,
  computeAttachmentUrl: ComputeAttachmentUrlCallback,
  id: string | undefined
): Promise<void> {
  const { contracts, siteConfiguration } = getGlobalState();
  const { workflows } = siteConfiguration;

  let kanbanInfos = EMPTY_KANBAN_INFOS;
  let { customer } = EMPTY_KANBAN;
  const newAttributes: Record<string, AttributeType> = {};
  let initialAttributes: Record<string, AttributeType> = {};
  let parentKanbanId: string | null = null;
  let contract: UIContract | undefined;
  let workflow: Workflow | undefined;

  const newKanbanId = httpClient.getBrowserSequence().next();

  let identityPictureBase64: string | undefined;
  if (isTruthyAndNotEmpty(id)) {
    const kanbanOrError = await loadKanbanLocallyOrFromServer(
      kanbanRepository,
      httpClient,
      getGlobalState().session.isOnline,
      id
    );

    if (isTruthy(kanbanOrError)) {
      if (typeof kanbanOrError === 'string') {
        globalActionDispatch.setProperty('message', kanbanOrError);
      } else {
        // Get the parent identity picture if any

        const identityPictureUrl = computeAttachmentUrl(
          KANBAN_IDENTITY_PICTURE_CATEGORY,
          KANBAN_IDENTITY_PICTURE_FOLDER,
          KANBAN_IDENTITY_PICTURE_NAME,
          id
        );

        const response = await fetch(identityPictureUrl);
        if (response.status === 200) {
          const blob = await response.blob();
          identityPictureBase64 = URL.createObjectURL(blob);
        }
        // The workflow used during the first creation of this kanban might have disappear
        workflow = workflows.find((w) => w.id === kanbanOrError.workflowId);
        contract = contractHelpers.findContractByCode(contracts, kanbanOrError.contract.code);
        kanbanInfos = kanbanOrError.infos;
        customer = kanbanOrError.customer;
        initialAttributes = kanbanOrError.attributes;
        parentKanbanId = id;
      }
    }
  }
  if (!isTruthy(workflow)) {
    workflow = workflows.length === 1 ? workflows[0] : undefined;
  }

  if (isTruthy(contract)) {
    contract.attributeDescs.forEach((t) => {
      if (t.keepValueWhenCloning && isTruthy(initialAttributes[t.id])) {
        newAttributes[t.id] = initialAttributes[t.id];
      }
    });
  }

  const contractCode = contract?.code ?? '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return {
      ...CREATE_KANBAN_VIEW_EMPTY_STATE,
      formData: {
        ...EMPTY_CREATE_KANBAN_FORM,
        brand: kanbanInfos.brand,
        color: kanbanInfos.color,
        dateOfRegistration: isTruthy(kanbanInfos.dateOfRegistration)
          ? kanbanInfos.dateOfRegistration
          : NaN,
        license: kanbanInfos.license,
        model: kanbanInfos.model,
        motor: kanbanInfos.motor,
        vin: kanbanInfos.vin,
        mileage: String(kanbanInfos.mileage),
        workflowId: workflow?.id ?? '',
        ...convertBusinessCustomerToFormData({ ...customer, contract: contractCode }),
      },
      parentKanbanId,
      contract,
      attributesState: {
        ...DISPLAY_ATTRIBUTES_EMPTY_STATE,
        attributes: newAttributes,
        contractAttributeDescs: contract?.attributeDescs ?? [],
      },
      newKanbanId,
      isLoaded: true,
      pictureEditionToolkitState: {
        ...EMPTY_PICTURE_EDITION_TOOLKIT_STATE,
        base64: identityPictureBase64,
      },
    };
  });
  await actionDispatch.exec(selectedTypeOrContractOrWorkflowChangeAction);
}

async function saveKanbanAction({
  getState,
  kanbanRepository,
  actionDispatch,
  getGlobalState,
  globalActionDispatch,
  navigate,
  httpClient,
}: ActionContext<Store, CreateKanbanViewState>): Promise<void> {
  const sequence = httpClient.getBrowserSequence();

  const {
    formData,
    packageDeals,
    purchaseOrders,
    attributesState,
    parentKanbanId,
    newKanbanId,
    pictureEditionToolkitState,
  } = getState();
  const { contracts } = getGlobalState();

  // Ensure the list of purchase orders has been correctly initialized
  const newPurchaseOrders =
    purchaseOrders.filter(nonDeleted).length > 0
      ? purchaseOrders
      : purchaseOrderHelpers.getPurchaseOrdersFromString('', [], sequence);

  const newKanban = createKanbanFromState(
    contracts,
    formData,
    packageDeals,
    newPurchaseOrders,
    attributesState,
    parentKanbanId,
    newKanbanId
  );

  await kanbanRepository.createEntity(newKanban);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => CREATE_KANBAN_VIEW_EMPTY_STATE);

  // Wait until the action log is empty to avoid errors due to the fact
  // that the kanban is not known on the server side
  // Fix https://gitlab.com/refitngin/refitit/-/issues/636
  if (getGlobalState().session.isOnline) {
    await kanbanRepository.waitForEmptyActionLog(5000);
    if (
      isTruthyAndNotEmpty(pictureEditionToolkitState.base64) &&
      !pictureEditionToolkitState.hasIncorrectOrientation
    ) {
      const blob = await (await fetch(pictureEditionToolkitState.base64)).blob();
      const file = new File([blob], KANBAN_IDENTITY_PICTURE_NAME);
      await globalActionDispatch.exec(
        importAttachmentsAndOverrideAction,
        KANBAN_IDENTITY_PICTURE_CATEGORY,
        newKanbanId,
        KANBAN_IDENTITY_PICTURE_FOLDER,
        [file]
      );
    }
  }

  // Change page route
  navigate(computeKanbanDetailsPath(newKanbanId));
}

const checkFieldContentActions: CheckFormFieldContentActions<Store, CreateKanbanViewState> = {
  ...checkEditCustomerFormFieldContentActions,
  ...checkEditGeneralInformationsFormFieldContentActions,
};

const checkFormConsistencyAction: CheckFormConsistencyAction<Store, CreateKanbanViewState> = ({
  formState,
  t,
}): string | undefined => {
  const { attributesState, packageDeals } = formState;
  const { attributes, contractAttributeDescs } = attributesState;

  const hasAvailablePD =
    packageDeals.filter((pd) => !pd.deleted && packageDealHelpers.isPackageDealAvailable(pd))
      .length !== 0;

  if (!hasAvailablePD) {
    return t('creation:noAvailablePackageDeals');
  }

  const emptyMandatoryAttributes: string[] = [];
  contractAttributeDescs.forEach((ad) => {
    if (ad.mandatory) {
      let isPresent = false;
      forEachRecordValues(attributes, (value, key) => {
        if (
          key === ad.id &&
          ((typeof value === 'string' && isTruthyAndNotEmpty(value)) ||
            (typeof value !== 'string' && isTruthy(value)))
        ) {
          isPresent = true;
        }
      });
      if (!isPresent) {
        emptyMandatoryAttributes.push(ad.id);
      }
    }
  });
  if (emptyMandatoryAttributes.length === 1) {
    return t('creation:missingMandatoryAttribute', {
      attribute: `"${emptyMandatoryAttributes[0]}"`,
    });
  }
  if (emptyMandatoryAttributes.length > 1) {
    return t('creation:missingMandatoryAttributes', {
      attributes: enumerate(emptyMandatoryAttributes.map((attribute) => `"${attribute}"`)),
    });
  }
  return undefined;
};

function submitKanbanAttributeModification({
  getState,
  actionDispatch,
}: ActionContext<Store, CreateKanbanViewState>): void {
  const { attributesState } = getState();
  const { attributeKey, attributeValue } = attributesState.kanbanAttributeUpdateModalState;

  actionDispatch
    .scopeProperty('attributesState')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .reduce((initial) => {
      return {
        ...initial,
        kanbanAttributeUpdateModalState: {
          ...KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE,
        },
        attributes: {
          ...initial.attributes,
          [attributeKey]: convertToAttributeExpectedType(
            initial.attributes,
            attributeKey,
            attributeValue
          ),
        },
      };
    });
}

export function CreateKanban({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('creation');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = useParams<any>();

  const { $creationView } = $gs;
  const { $formData, $selectPredefinedCustomerDialogState } = $gs.$creationView;

  const activeContract = useGetState($creationView.$contract);
  const contractCode = useGetState($creationView.$formData.$contractCode);
  const shortName = useGetState($creationView.$formData.$shortName);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const contractCodes = useGetContractCodes($gs);

  const asyncEffect = useActionCallback(
    async function effect({ actionDispatch }): Promise<void> {
      await actionDispatch.exec(initializeKanbanCreationState, computeAttachmentUrl, id);
    },
    [id, computeAttachmentUrl],
    $creationView
  );

  const asyncCleanupEffect = useSetCallback($creationView, CREATE_KANBAN_VIEW_EMPTY_STATE);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();

    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncCleanupEffect();
    };
  }, [asyncEffect, asyncCleanupEffect]);

  const submitValidDataAction = useActionCallback(saveKanbanAction, [], $creationView);

  const customerFormMandatoryFields = useEditCustomerFormMandatoryFields($formData, true);

  const mandatoryFieldsForContract = useMemo(
    () => contractHelpers.getMandatoryFieldsForContract(activeContract?.mandatoryFields),
    [activeContract]
  );
  const mandatoryFields = useCallback(
    (formData: NewKanbanFormData): readonly (keyof NewKanbanFormData)[] => {
      return [
        ...editGeneralInformationsFormMandatoryFields(formData, false),
        ...customerFormMandatoryFields,
        ...mandatoryFieldsForContract,
      ];
    },
    [customerFormMandatoryFields, mandatoryFieldsForContract]
  );

  const [onFormSubmit, genericOnFormChange] = useFormWithValidation<Store, CreateKanbanViewState>({
    $: $creationView,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const customOnFormChange = useActionCallback(
    async ({ actionDispatch, getState, getGlobalState, httpClient }) => {
      await actionDispatch.execCallback(genericOnFormChange);

      const { contracts } = getGlobalState();
      const sequence = httpClient.getBrowserSequence();
      const {
        formData,
        packageDeals,
        purchaseOrders: newPurchaseOrders,
        attributesState,
        parentKanbanId,
      } = getState();

      let newPackageDeals: readonly PackageDeal[] = packageDeals;
      // Check that the package deals contain the marketplace package deal if needed
      if (
        getState().formData.kanbanType === 'marketplace' &&
        packageDeals.find(
          ({ deleted, code }) => !deleted && code === MARKETPLACE_SELL_PACKAGE_DEAL_CODE
        ) === undefined
      ) {
        newPackageDeals = [
          ...newPackageDeals,
          {
            ...EMPTY_PACKAGE_DEAL,
            id: sequence.next(),
            code: MARKETPLACE_SELL_PACKAGE_DEAL_CODE,
            label: t('marketplaceOnlineSell'),
            carElement: undefined,
            status: 'available',
            operations: [
              {
                ...EMPTY_OPERATION,
                id: sequence.next(),
                label: t('marketplaceOnlinePublish'),
                standId: MARKETPLACE_STAND_ID,
                type: 'MarketPlaceOnlinePublish',
              },
              {
                ...EMPTY_OPERATION,
                id: sequence.next(),
                label: t('marketplaceOnlineSell'),
                standId: MARKETPLACE_STAND_ID,
                type: 'MarketPlaceOnlineSell',
              },
            ],
          },
        ];
      }
      newPackageDeals = updatePackageExpressionComputationsFromFormData(
        contracts,
        formData,
        newPackageDeals,
        newPurchaseOrders,
        attributesState,
        parentKanbanId
      );
      actionDispatch.scopeProperty('packageDeals').setValue(newPackageDeals);
    },
    [genericOnFormChange, t],
    $creationView
  );

  const $formDataWithChangeTrigger = useSelectorWithChangeTrigger($formData, customOnFormChange);

  const submitKanbanAttributeModificationCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(submitKanbanAttributeModification);
    },
    [],
    $creationView
  );

  const removeAttachmentCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          isAttachmentRemovalDialogActive: false,
          pictureEditionToolkitState: EMPTY_PICTURE_EDITION_TOOLKIT_STATE,
        };
      });
    },
    [],
    $creationView
  );

  const openSelectedPredefinedCustomerAction = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<Store, SelectPredefinedCustomerDialogState>): Promise<void> => {
      await actionDispatch.exec(openSelectPredefinedCustomerDialog, contractCode);
    },
    [contractCode],
    $selectPredefinedCustomerDialogState
  );

  const selectPredefinedCustomerAction = useActionCallback(
    async ({
      actionDispatch,
      getState,
      customerRepository,
    }: // eslint-disable-next-line @typescript-eslint/require-await
    ActionContext<Store, CreateKanbanViewState>): Promise<void> => {
      const customer = getSpecificFields(
        await customerRepository.getEntity(
          getState().selectPredefinedCustomerDialogState.formData.customerId
        )
      );
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          selectPredefinedCustomerDialogState: EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE,
          isPredefinedCustomer: true,
          formData: {
            ...initial.formData,
            ...convertBusinessCustomerToFormData(customer),
          },
        };
      });
    },
    [],
    $creationView
  );
  const clearSelectedCustomerAction = useActionCallback(
    async ({
      actionDispatch,
    }: // eslint-disable-next-line @typescript-eslint/require-await
    ActionContext<Store, CreateKanbanViewState>): Promise<void> => {
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          isPredefinedCustomer: false,
          formData: {
            ...initial.formData,
            ...convertBusinessCustomerToFormData(EMPTY_CUSTOMER_SPECIFIC_FIELDS),
            // Restore contract code
            contractCode: initial.formData.contractCode,
          },
        };
      });
    },
    [],
    $creationView
  );

  const isLoaded = useGetState($creationView.$isLoaded);
  const isPredefinedCustomer = useGetState($creationView.$isPredefinedCustomer);
  const formWarning = useGetState($creationView.$formWarning);
  const pictureEditionToolkitState = useGetState($creationView.$pictureEditionToolkitState);

  /* We have to control that state has been loaded or Bulma Calendar is not correctly initialized */
  if (!isLoaded) {
    return <span>Loading</span>;
  }

  return (
    <div>
      <CreationGeneralInformationsComponent
        $formData={$formDataWithChangeTrigger}
        $gs={$gs}
        $={$creationView}
      />
      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="title">{t('customerInfos')}</p>
          </div>
          <div className="column has-text-right">
            {isPredefinedCustomer ? (
              <Button
                additionalClass="is-primary"
                label={t('cancelPredefinedCustomerButtonLabel', { shortName })}
                onClick={clearSelectedCustomerAction}
              />
            ) : (
              <Button
                additionalClass="is-primary"
                label={t('selectPredefinedCustomerButtonLabel')}
                onClick={openSelectedPredefinedCustomerAction}
                disabled={contractCode.trim() === ''}
                iconId="address-book"
              />
            )}
          </div>
        </div>
        <EditCustomerForm
          orientation="horizontal columns"
          disabled={isPredefinedCustomer}
          $formData={$formDataWithChangeTrigger}
          contractCodes={contractCodes}
        />
        <SelectPredefinedCustomerDialog
          submitValidDataAction={selectPredefinedCustomerAction}
          $={$selectPredefinedCustomerDialogState}
        />
      </div>
      <div className="columns">
        <div className="column">
          <CreationPackageDealsComponent $gs={$gs} />
        </div>
        <div className="column">
          <div className="box">
            <p className="title">{t('attributes')}</p>
            <DisplayAttributesComponent
              submitKanbanAttributeModificationCallback={submitKanbanAttributeModificationCallback}
              $={$creationView.$attributesState}
              isEditable
              showIsMandatory
            />
          </div>
          <div className="columns">
            <div className="column" />
            <FormWarning warning={formWarning} isNarrow />
            <div className="column is-narrow">
              <Button
                onClick={onFormSubmit}
                additionalClass="is-primary"
                label={t('creationButtonLabel')}
                disabled={isTruthyAndNotEmpty(formWarning)}
              />
            </div>
          </div>
        </div>
      </div>
      {isTruthyAndNotEmpty(pictureEditionToolkitState.base64) && (
        <ConfirmLocalAttachmentRemovalDialog
          base64={pictureEditionToolkitState.base64}
          filename={KANBAN_IDENTITY_PICTURE_NAME}
          $active={$creationView.$isAttachmentRemovalDialogActive}
          okLabel={t('confirmAttachmentRemovalDialog.okLabel')}
          onOkClicked={removeAttachmentCallback}
        >
          <p>{t('confirmAttachmentRemovalDialog.message')}</p>
        </ConfirmLocalAttachmentRemovalDialog>
      )}
    </div>
  );
}
