import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { CarViewProps } from './car-view-common.js';
import { createStyleAndIdBuilder, TRANSPARENT_VIEW_NODE_STYLE } from './car-view-common.js';
import { CarViewCanvas } from './CarViewCanvas.js';

export function CarExternalView<SD extends AnyStoreDef>(props: CarViewProps<SD>): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedShapes, ...canvasProps } = props;
  const styleAndId = createStyleAndIdBuilder('EXTE', props);
  return (
    <CarViewCanvas {...canvasProps} showCarBackground>
      <g>
        <circle
          cx="178.54588"
          cy="-173.82494"
          r="15.594946"
          transform="scale(1,-1)"
          {...styleAndId('WHEEL_BR')}
        />
        <circle
          transform="scale(1,-1)"
          r="15.594946"
          cy="-58.901024"
          cx="179.07504"
          {...styleAndId('WHEEL_FR')}
        />
        <circle
          transform="scale(-1)"
          r="15.594946"
          cy="-173.28656"
          cx="-21.482908"
          {...styleAndId('WHEEL_BL')}
        />
        <circle
          cx="-20.424574"
          cy="-59.420952"
          r="15.594946"
          transform="scale(-1)"
          {...styleAndId('WHEEL_FL')}
        />
        <path
          d="m 68.212885,97.547726 63.302185,0.494549 11.37461,-24.232867 c 0,0 -18.79283,-5.440029 -44.014805,-5.440029 -25.22196,0 -42.036603,5.934577 -42.036603,5.934577 z"
          {...styleAndId('WINDSHIELD')}
        />
        <rect
          width="63.640903"
          height="63.351234"
          x="67.966522"
          y="101.13628"
          {...styleAndId('ROOF')}
        />
        <path
          d="M 175.39356,90.142948 V 78.253201 c 0,0 -8.74246,-2.622738 -10.6658,-5.595176 -1.92334,-2.972435 -4.89578,-4.720928 -5.07063,-13.288539 -0.17485,-8.567613 4.02153,-13.288541 7.51852,-16.260979 3.49698,-2.972435 6.81912,-3.496985 6.81912,-3.496985 L 150.09923,19.944688 c 3.17305,9.636696 -7.45104,16.826617 -14.77191,10.498135 l 10.34187,41.166108 z"
          {...styleAndId('FENDER_FR')}
        />
        <path
          d="M 24.124792,90.142949 V 78.253202 c 0,0 8.74246,-2.622738 10.6658,-5.595176 1.92334,-2.972435 4.89578,-4.720928 5.07063,-13.288539 0.17485,-8.567613 -4.02153,-13.288541 -7.51852,-16.260979 -3.49698,-2.972435 -6.81912,-3.496985 -6.81912,-3.496985 L 49.419124,19.944689 c -3.173055,9.636696 7.451036,16.826617 14.771909,10.498135 L 53.849162,71.608932 Z"
          {...styleAndId('FENDER_FL')}
        />
        <path
          d="m 135.31642,168.87491 -4.89577,18.18432 -0.12142,8.02836 c 8.45753,-7.72529 21.66011,4.06838 12.8854,13.47809 l 15.56158,-5.07062 9.61671,-12.23945 c 0,0 -4.72093,-2.27304 -6.64427,-5.24548 -1.92334,-2.97243 -2.44789,-6.81911 -2.44789,-6.81911 z"
          {...styleAndId('FENDER_BR')}
        />
        <path
          d="m 63.863544,168.87491 4.895771,18.18432 0.12142,8.02836 c -8.457537,-7.72529 -21.660116,4.06838 -12.885404,13.47809 l -15.56158,-5.07062 -9.61671,-12.23945 c 0,0 4.72093,-2.27304 6.64427,-5.24548 1.92334,-2.97243 2.44789,-6.81911 2.44789,-6.81911 z"
          {...styleAndId('FENDER_BL')}
        />
        <circle
          cx="-136.05684"
          cy="202.21349"
          r="6.4350219"
          transform="scale(-1,1)"
          {...styleAndId('HEADLIGHT_BR')}
        />
        <circle cy="202.21349" cx="62.613407" r="6.4350219" {...styleAndId('HEADLIGHT_BL')} />
        <circle
          cy="23.546721"
          cx="-141.27574"
          r="6.4350219"
          transform="scale(-1,1)"
          {...styleAndId('HEADLIGHT_FR')}
        />
        <circle cx="58.388096" cy="23.546721" r="6.4350219" {...styleAndId('HEADLIGHT_FL')} />
        <path
          d="m 57.545477,70.490989 c 0,0 22.254678,-5.934581 42.283878,-5.934581 20.029205,0 42.036605,5.687306 42.036605,5.687306 L 129.74953,17.574323 70.156455,17.821597 Z"
          {...styleAndId('HOOD')}
        />
        <path
          d="M 46.41814,14.854308 H 152.25148 V 8.4251799 c 0,0 3.46183,-3.9563857 -52.422125,-3.9563857 -55.883956,0 -53.411215,5.4400308 -53.411215,5.4400308 z"
          {...styleAndId('BUMPER_F')}
        />
        <path
          d="M 47.026431,211.92686 H 152.85974 v 6.42913 c 0,0 3.46183,3.95639 -52.42212,3.95639 -55.88393,0 -53.411189,-5.44004 -53.411189,-5.44004 z"
          {...styleAndId('BUMPER_B')}
        />
        <g {...styleAndId('DOOR_FL')} transform="translate(-32.709585,0.37983467)">
          <path d="m 96.243221,134.81356 0.49455,-35.607477 -10.38551,-23.24377 -28.18925,17.309189 -1.48365,40.552958 z" />
          <path
            d="m 84.254097,82.97058 -5.612659,47.8947 h 12.347853 l 1.496708,-30.68254 z"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
        </g>
        <g transform="translate(-33.767918,0.37983467)" {...styleAndId('DOOR_BL')}>
          <path d="m 96.807015,137.71162 -40.05841,-0.49455 0.49455,15.82554 c 0,0 12.3637,0.9891 15.82554,10.88006 3.46184,9.89097 1.48365,9.89097 1.48365,9.89097 l 23.24377,-9.39642 z" />
          <path
            d="m 79.699772,140.96807 3.367596,24.6957 10.102789,-4.11595 -0.748356,-19.8314 z"
            style={TRANSPARENT_VIEW_NODE_STYLE}
          />
        </g>
        <g
          {...styleAndId('TRUNK_LEFT')}
          transform="matrix(0.96815481,0,0,1.0007168,-29.639284,1.3048118)"
        >
          <path d="m 100.01376,166.5625 5.77002,19.23341 -0.17485,21.33161 h 25.50386 v -21.33161 l 0.0706,-19.23341 z" />
          <path
            style={TRANSPARENT_VIEW_NODE_STYLE}
            d="m 104.76965,168.77972 4.86431,15.34127 18.02381,0.34127 v -15.71545 z"
          />
        </g>
        <g
          {...styleAndId('TRUNK_RIGHT')}
          transform="matrix(0.9690675,0,0,1.0007009,4.7659246,1.3071948)"
        >
          <path d="m 131.18342,166.5625 -5.77002,19.23341 0.17485,21.33161 h -25.50386 v -21.33161 l -0.0706,-19.23341 z" />
          <path
            style={TRANSPARENT_VIEW_NODE_STYLE}
            d="m 126.42753,168.77972 -4.86431,15.34127 -18.02381,0.34127 v -15.71545 z"
          />
        </g>
        <g transform="translate(-32.709585,0.37983467)" {...styleAndId('DOOR_FR')}>
          <path d="m 169.20476,134.29363 -0.49455,-35.607475 10.38551,-23.24377 28.18925,17.309189 1.48365,40.552956 z" />
          <path
            style={TRANSPARENT_VIEW_NODE_STYLE}
            d="m 180.27023,82.532205 5.61266,47.894705 h -12.34785 l -1.49671,-30.682545 z"
          />
        </g>
        <g transform="translate(-32.709585,0.37983467)" {...styleAndId('DOOR_BR')}>
          <path d="m 168.64096,137.19169 40.05841,-0.49455 -0.49455,15.82554 c 0,0 -12.3637,0.9891 -15.82554,10.88006 -3.46184,9.89097 -1.48365,9.89097 -1.48365,9.89097 l -23.24377,-9.39642 z" />
          <path
            style={TRANSPARENT_VIEW_NODE_STYLE}
            d="m 184.82455,140.5297 -3.36759,24.6957 -10.10279,-4.11595 0.74835,-19.8314 z"
          />
        </g>
      </g>
    </CarViewCanvas>
  );
}
