import React, { useEffect, useMemo } from 'react';
import type { ActionContext, AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { ImageModalState } from '../../custom/index.js';
import { LoadingObject } from '../../custom/misc/LoadingObject.js';
import { Button } from '../elements/buttons/Button.js';
import type { ModalDialogProps } from './ModalDialog.js';
import { ModalDialog } from './ModalDialog.js';

function increaseImageIndexAction<SD extends AnyStoreDef>({
  actionDispatch,
  getState,
}: ActionContext<SD, ImageModalState>): void {
  actionDispatch.setProperty('imageIndex', getState().imageIndex + 1);
}

function decreaseImageIndexAction<SD extends AnyStoreDef>({
  actionDispatch,
  getState,
}: ActionContext<SD, ImageModalState>): void {
  actionDispatch.setProperty('imageIndex', getState().imageIndex - 1);
}

export interface ImageModalDialogProps<SD extends AnyStoreDef>
  extends Omit<ModalDialogProps<SD>, 'children' | '$active'> {
  readonly $: StoreStateSelector<SD, ImageModalState>;
}

export function ImageModalDialog<SD extends AnyStoreDef>({
  onClose,
  zIndex,
  size,
  $,
}: ImageModalDialogProps<SD>): JSX.Element {
  const increaseImageIndexHandler = useActionCallback(increaseImageIndexAction, [], $);

  const decreaseImageIndexHandler = useActionCallback(decreaseImageIndexAction, [], $);

  const imageIndex = useGetState($.$imageIndex);
  const imagesUrls = useGetState($.$imagesUrls);

  const isRightButtonDisabled = useMemo(() => {
    return imageIndex >= imagesUrls.length - 1;
  }, [imageIndex, imagesUrls.length]);

  const isLeftButtonDisabled = useMemo(() => {
    return imageIndex === 0;
  }, [imageIndex]);

  const handleUserKeyPress = useActionCallback(
    async ({ actionDispatch }, event): Promise<void> => {
      const { keyCode } = event;
      if (keyCode === 39 && !isRightButtonDisabled) {
        await actionDispatch.exec(increaseImageIndexAction);
      } else if (keyCode === 37 && !isLeftButtonDisabled) {
        await actionDispatch.exec(decreaseImageIndexAction);
      }
    },
    [isLeftButtonDisabled, isRightButtonDisabled],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('keydown', handleUserKeyPress);

    return function cleanup(): void {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const closeModalHandler = useActionCallback(
    async ({ globalActionDispatch, actionDispatch }): Promise<void> => {
      // Close the dialog
      actionDispatch.setValue(false);
      // Run the trigger if provided
      if (onClose) {
        await globalActionDispatch.execCallback(onClose);
      }
    },
    [onClose],
    $.$active
  );

  return (
    <ModalDialog
      $active={$.$active}
      onClose={onClose}
      zIndex={zIndex}
      size={size}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <>
        <div className="modal-content has-text-centered" style={{ width: 'auto', height: 'auto' }}>
          <LoadingObject src={imagesUrls[imageIndex]} transparentBackground objectFit="contain" />
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={closeModalHandler}
        />
        {!isRightButtonDisabled && (
          <Button
            iconId="angle-right"
            additionalClass="nobackground-fixed-middle-right has-text-white"
            onClick={increaseImageIndexHandler}
            disabled={isRightButtonDisabled}
            size="large-3x"
          />
        )}
        {!isLeftButtonDisabled && (
          <Button
            iconId="angle-left"
            additionalClass="nobackground-fixed-middle-left has-text-white"
            onClick={decreaseImageIndexHandler}
            disabled={isLeftButtonDisabled}
            size="large-3x"
          />
        )}
      </>
    </ModalDialog>
  );
}
