import React from 'react';
import { useTranslation } from 'react-i18next';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { OperatorExpertViewState } from '../../typings/store.js';
import { deletePackageDealAction } from '../../expertiseUtils.js';

interface DeletePackageDealModalComponentProps {
  readonly $: StoreStateSelector<Store, OperatorExpertViewState>;
  readonly updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>;
}

export function DeletePackageDealModalComponent({
  $,
  updateStandRelatedPackageDealsAndOperationsActionCallback,
}: DeletePackageDealModalComponentProps): JSX.Element {
  const [t] = useTranslation('operators');
  const { $deletionModalState } = $;
  const deletePackageDealActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        deletePackageDealAction,
        updateStandRelatedPackageDealsAndOperationsActionCallback
      );
    },
    [updateStandRelatedPackageDealsAndOperationsActionCallback],
    $
  );
  const packageDealLabel = useGetState($deletionModalState.$packageDealLabel);
  return (
    <ModalCardDialog
      title={t('expertiseView.deletePackageDealModalTitle', {
        packageDealName: packageDealLabel,
      })}
      $active={$deletionModalState.$active}
      okLabel={t('expertiseView.deleteButton')}
      onOkClicked={deletePackageDealActionCallback}
    >
      {t('expertiseView.deletePackageDealModalMessage', {
        packageDealName: packageDealLabel,
      })}
    </ModalCardDialog>
  );
}
