/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { FaIcon, FormField } from '@stimcar/libs-uitoolkit';
import 'react-simple-keyboard/build/css/index.css';
import { Select } from '../../bulma/form/Select.js';
import { VirtualKeyboardInputFormField } from './VirtualKeyboard.js';

interface UserSelectComponentProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, string>;
  readonly selectEntries: readonly string[];
  readonly disableSelect: boolean;
  readonly onToggleActionCallback: NoArgActionCallback<SD>;
  readonly useSelectForUser: boolean;
}

function UserSelectComponent<SD extends AnyStoreDef>({
  $,
  selectEntries,
  disableSelect,
  useSelectForUser,
  onToggleActionCallback,
}: UserSelectComponentProps<SD>): JSX.Element {
  return (
    <div className="columns">
      <div className="column">
        <Select entries={selectEntries} $={$} disabled={disableSelect} isFullWidth />
      </div>
      <UseKnownUsersToggle
        onToggleActionCallback={onToggleActionCallback}
        selectBetweenKnowUsers={useSelectForUser}
      />
    </div>
  );
}

interface SelectListOrVirtualKeyboardInputProps<SD extends AnyStoreDef> {
  readonly $useSelectForUser: StoreStateSelector<SD, boolean>;
  readonly $: StoreStateSelector<SD, string>;
  readonly selectEntries: readonly string[];
  readonly disableSelect: boolean;
  readonly placeholder?: string;
}

type SelectListOrVirtualKeyboardInputFormFieldProps<SD extends AnyStoreDef> =
  SelectListOrVirtualKeyboardInputProps<SD> & Omit<FormFieldProps, 'children' | 'warning'>;

export function SelectListOrVirtualKeyboardInputFormField<SD extends AnyStoreDef>({
  $useSelectForUser,
  $,
  selectEntries,
  disableSelect,
  ...props
}: SelectListOrVirtualKeyboardInputFormFieldProps<SD>): JSX.Element {
  const resetInput = useSetCallback($, '');

  const useSelectForUser = useGetState($useSelectForUser);

  const onToggleActionCallback = useActionCallback(
    async ({ actionDispatch, getState }) => {
      actionDispatch.setValue(!getState());
      await actionDispatch.execCallback(resetInput);
    },
    [resetInput],
    $useSelectForUser
  );

  return (
    <>
      {selectEntries && useSelectForUser ? (
        <>
          <FormField {...props}>
            <UserSelectComponent
              disableSelect={disableSelect}
              $={$}
              onToggleActionCallback={onToggleActionCallback}
              selectEntries={selectEntries}
              useSelectForUser={useSelectForUser}
            />
          </FormField>
        </>
      ) : (
        <>
          <VirtualKeyboardInputFormField $={$} {...props}>
            <UseKnownUsersToggle
              onToggleActionCallback={onToggleActionCallback}
              selectBetweenKnowUsers={useSelectForUser}
            />
          </VirtualKeyboardInputFormField>
        </>
      )}
    </>
  );
}

interface UseKnownUsersToggleProps<SD extends AnyStoreDef> {
  readonly onToggleActionCallback: NoArgActionCallback<SD>;
  readonly selectBetweenKnowUsers: boolean;
}

function UseKnownUsersToggle<SD extends AnyStoreDef>({
  onToggleActionCallback,
  selectBetweenKnowUsers,
}: UseKnownUsersToggleProps<SD>): JSX.Element {
  return (
    <div className="column is-narrow">
      <button type="button" className="button is-rounded is-light" onClick={onToggleActionCallback}>
        <FaIcon id={selectBetweenKnowUsers ? 'keyboard' : 'users'} />
      </button>
    </div>
  );
}
