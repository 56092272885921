/* eslint-disable @typescript-eslint/await-thenable */
import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import type { AttachmentFolder, StorageCategories, SubcontractorKanban } from '@stimcar/libs-base';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { SubcontractorBackendRoutes, URL_LIST_ELEMENTS_SEPARATOR } from '@stimcar/libs-base';
import { useActionCallback, useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import type { SubcontractorStore } from '../state/typings/store.js';
import { loadAttachmentsGalleryAction } from '../../app/utils/attachmentGalleryActions.js';
import { AttachmentsGallery } from '../../lib/components/attachments/AttachmentsGallery.js';
import { useComputeSubcontractorAttachmentUrl } from '../utils/useSubcontractorComputeAttachmentUrl.js';

interface SubContractorOperateFolderTabProps extends AppProps<SubcontractorStore> {
  readonly kanban: SubcontractorKanban;
  readonly t: TFunction<'subcontractor', undefined>;
}

export function SubContractorOperateFolderTab({
  t,
  $gs,
  kanban,
}: SubContractorOperateFolderTabProps): JSX.Element {
  const $ = $gs.$operate;

  const computeSubcontractorAttachmentUrl = useComputeSubcontractorAttachmentUrl($gs);

  const { $attachmentsState } = $;

  const selectedSite = useGetState($gs.$session.$selectedSite);

  const folders: readonly AttachmentFolder[] = useMemo(
    () =>
      kanban.folders.map((f) => ({
        ...f,
        label: f.label || t(`globals:${f.id}FolderUploadLabel`),
        shortLabel: f.shortLabel || t(`globals:${f.id}ShortLabel`),
      })),
    [kanban.folders, t]
  );

  const loadAttachmentsGalleryActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        SubcontractorBackendRoutes.SUBCONTRACTOR_ATTACHMENT_FOLDER(
          selectedSite,
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [selectedSite],
    $attachmentsState
  );

  const isMobile = useScreenIsBulmaMobile($gs.$window);
  const thumbnailSize = useMemo(() => (isMobile ? 72 : 128), [isMobile]);

  if (folders.length === 0) {
    return <div className="has-text-centered">{t('operateKanban.folders.noFileToDisplay')}</div>;
  }

  return (
    <div className={`is-flex mx-4 mb-5 ${folders.length === 1 && 'pl-2'}`}>
      <AttachmentsGallery
        isOnline
        isBoxTabs
        category="kanban"
        folders={folders}
        objectId={kanban.id}
        $={$attachmentsState}
        $window={$gs.$window}
        $imageModal={$gs.$imageModal}
        thumbnailSize={thumbnailSize}
        displaySelectedFolderLabelAsTitle
        computeAttachmentUrl={computeSubcontractorAttachmentUrl}
        loadAttachmentsActionCallback={loadAttachmentsGalleryActionCallback}
      />
    </div>
  );
}
