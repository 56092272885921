/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { MenuProps } from '../bulma/elements/table/impl/FiltersMenuLink.js';
import { useDeactivateOnClickOutside } from '../hooks/useDeactivateOnClickOutside.js';

export interface MenuButtonProps<SD extends AnyStoreDef> extends MenuProps<SD> {
  readonly disabled?: boolean;
  readonly isDeactivateOnClickOutsideEnabled?: boolean;
}

export function MenuButton<SD extends AnyStoreDef>({
  label,
  children,
  disabled = false,
  additionalClasses = '',
  $active,
  isDeactivateOnClickOutsideEnabled,
}: MenuButtonProps<SD>): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);

  const active = useGetState($active);

  useDeactivateOnClickOutside({
    domElementRef: ref,
    $active,
    deactivateOnInternalClick: false,
    capture: true,
    isEnabled: isDeactivateOnClickOutsideEnabled,
  });

  const clickHandlerActionCallback = useActionCallback(
    ({ actionDispatch, getState }): void => {
      actionDispatch.setValue(!getState());
    },
    [],
    $active
  );

  return (
    <div className={`dropdown is-active ${additionalClasses}`} ref={ref} style={{ width: '100%' }}>
      <div className="dropdown-trigger" style={{ width: '100%' }}>
        <button
          type="button"
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={clickHandlerActionCallback}
          disabled={disabled}
        >
          <span>{label}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true" />
          </span>
        </button>
      </div>
      {active && (
        <div
          className="dropdown-menu"
          id="dropdown-menu"
          role="menu"
          style={{
            position: 'fixed',
            top: ref.current?.getBoundingClientRect().bottom,
            left: ref.current?.getBoundingClientRect().left,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
