import type { Decorator, SortDirection } from '@stimcar/libs-base';
import type { Entity } from '@stimcar/libs-kernel';
import type { ActionDispatch, ArrayItemStateType } from '@stimcar/libs-uikernel';
import type { AnyTableStoreDef, FormWithValidationState } from '@stimcar/libs-uitoolkit';

type ColumnType = 'action' | 'display';

export type SavedFilter = {
  readonly id: string;
  readonly isCreatedByDefault?: boolean;
  readonly filter: Filter;
};

export type SerializableColumn = {
  readonly id: string;
};
export type Column = SerializableColumn & {
  readonly label: string;
  readonly isDisplayed: boolean;
  readonly type: ColumnType;
};

export type TableSerializablePreferences = {
  readonly columns?: readonly SerializableColumn[];
  readonly filters?: readonly SavedFilter[];
};

export const PROPERTY_TYPES = ['string', 'date', 'number', 'boolean'] as const;
export type PropertyTypes = (typeof PROPERTY_TYPES)[number];

export type TableCellIcon = {
  readonly iconId: string;
  readonly showText: boolean;
};

type BaseColumnDesc<CT = ColumnType> = {
  readonly columnType: CT;
  readonly id: string;
  readonly columnLabel: string;
  readonly columnTooltip?: string;
  readonly columnIcon?: TableCellIcon;
  readonly isNotHideable?: boolean;
  readonly columnStyle?: React.CSSProperties;
  readonly isDisplayedByDefault?: boolean;
};

export interface CommonDisplayColumnDesc<O extends ArrayItemStateType> {
  readonly id: string;
  readonly propertyType: PropertyTypes;
  readonly getPropertyValue: (entity: O) => string | number | boolean | undefined | null;
  readonly getDisplayedValue?: (entity: O) => string | JSX.Element;
  readonly getCellIcon?: (entity: O) => TableCellIcon | undefined;
  readonly getDisplayedClassName?: (entity: O) => string;
  readonly getDecorators?: (entity: O) => readonly Decorator[];
}

export interface DisplayColumnDesc<
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType = Entity,
> extends BaseColumnDesc<'display'>,
    CommonDisplayColumnDesc<O> {
  readonly isNotFilterable?: boolean;
  readonly isNotSortable?: boolean;
  /**
   * Use this to customize the behavior of textual search. If not provided, use the displayed value.
   * If there is no displayed value, use the property value, but only on strings column type.
   *
   * This can be use for license plate, to perform a case insensitive search or to remove '-'
   *
   * @returns true if the entity match the provided search
   */
  readonly textualSearch?: (entity: O, search: string) => boolean;
  /**
   * This is used if we want to display sub lines of the current line. In this case
   * the TableWithSublines component must be used, or the sublines will not be displayed
   */
  readonly subCell?: SubLineDisplayColumnDesc<SO>;
}

export type SubLineDisplayColumnDesc<SO extends ArrayItemStateType> = CommonDisplayColumnDesc<SO>;

export interface ActionColumnDesc<
  SD extends AnyTableStoreDef,
  SC extends TableItemsState<O>,
  O extends ArrayItemStateType,
> extends BaseColumnDesc<'action'> {
  readonly getCellIconId: (object?: O) => string;
  readonly cellTooltip: string;
  readonly cellLabel?: string;
  readonly action: (dispatch: ActionDispatch<SD, SC>, entity: O) => void | Promise<void>;
  readonly disabled?: (entity?: O) => boolean;
}

export type ColumnDesc<
  SD extends AnyTableStoreDef,
  SC extends TableItemsState<O>,
  O extends ArrayItemStateType,
  SO extends ArrayItemStateType = O,
> = DisplayColumnDesc<O, SO> | ActionColumnDesc<SD, SC, O>;

export const FILTER_ASSOCIATION_OPERATORS = ['and', 'or'] as const;
export type FilterAssociationOperators = (typeof FILTER_ASSOCIATION_OPERATORS)[number];

export const TEXT_FILTER_OPERATORS = [
  'contains',
  'doesNotContains',
  'is',
  'isNot',
  'empty',
  'notEmpty',
] as const;
export type TextFilterOperators = (typeof TEXT_FILTER_OPERATORS)[number];

export const NUMBER_FILTER_OPERATORS = [
  'equal',
  'notEqual',
  'greater',
  'greaterOrEqual',
  'lower',
  'lowerOrEqual',
  'empty',
  'notEmpty',
] as const;
export type NumberFilterOperators = (typeof NUMBER_FILTER_OPERATORS)[number];

export const BOOLEAN_FILTER_OPERATORS = ['is', 'isNot'] as const;
export type BooleanFilterOperators = (typeof BOOLEAN_FILTER_OPERATORS)[number];

export type Operator = NumberFilterOperators | TextFilterOperators | BooleanFilterOperators;

export type FilterCondition = {
  readonly id: string;
  readonly value: string;
  readonly columnId: string;
  readonly propertyType: PropertyTypes;
  readonly operator: Operator;
};

export const EMPTY_FILTER_CONDITION: FilterCondition = {
  id: '',
  value: '',
  columnId: '',
  propertyType: 'string',
  operator: 'contains',
};

export type Filter = {
  readonly filterConditions: readonly FilterCondition[];
  readonly associationOperator: FilterAssociationOperators;
};

export const EMPTY_FILTER: Filter = {
  filterConditions: [],
  associationOperator: 'and',
};

export type Sort = {
  readonly id: string;
  readonly direction: SortDirection;
};

export type SortsMenuState = {
  readonly sorts: readonly Sort[];
  readonly active: boolean;
};

export type SavedFilterIdDialogState = FormWithValidationState<{ readonly idInput: string }> & {
  readonly isActive: boolean;
  readonly savedFilterIds: readonly string[];
};

export const SAVED_FILTER_ID_DIALOG_EMPTY_STATE: SavedFilterIdDialogState = {
  isActive: false,
  savedFilterIds: [],
  formData: {
    idInput: '',
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
};

export type FiltersMenuState = {
  readonly active: boolean;
  readonly savedFilterMenuActive: boolean;
  readonly associationOperatorMenuActive: boolean;
  /**
   * Map containing an association between filter entries and their active boolean
   */
  readonly filterFragmentMenuActive: Record<string, boolean>;
  readonly filter: Filter;
  readonly savedFilters: readonly SavedFilter[];
  readonly selectedSavedFilterId: string | undefined;
  readonly filterDeletionDialog: {
    readonly isActive: boolean;
  };
  readonly savedFilterIdDialog: SavedFilterIdDialogState;
};

export type ColumnSelectionState = {
  readonly columns: readonly Column[];
  readonly active: boolean;
};

export type TableItemsState<O extends ArrayItemStateType> = {
  readonly items: readonly O[];
};

export type TableItemsAndFiltersState<O extends ArrayItemStateType> = TableItemsState<O> & {
  readonly filtersState: FiltersMenuState;
  readonly columnSelectionState: ColumnSelectionState;
  readonly textualFilter: string;
};

export type TableState<O extends ArrayItemStateType> = TableItemsAndFiltersState<O> & {
  readonly sortsMenuState: SortsMenuState;
  readonly selectedItemId: string | undefined;
  /**
   * This is only used by the TableWithSublines component to keep track of the lines with
   * open sublines
   */
  readonly expandedLinesIds: readonly string[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TABLE_EMPTY_STATE: TableState<any> = {
  items: [],
  textualFilter: '',
  selectedItemId: undefined,
  expandedLinesIds: [],
  filtersState: {
    active: false,
    associationOperatorMenuActive: false,
    filterFragmentMenuActive: {},
    filter: EMPTY_FILTER,
    savedFilters: [],
    savedFilterMenuActive: false,
    selectedSavedFilterId: undefined,
    filterDeletionDialog: {
      isActive: false,
    },
    savedFilterIdDialog: SAVED_FILTER_ID_DIALOG_EMPTY_STATE,
  },
  columnSelectionState: {
    active: false,
    columns: [],
  },
  sortsMenuState: {
    active: false,
    sorts: [],
  },
};
