import React, { useMemo } from 'react';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useRemainingVerticalSpace } from '../../hooks/useRemainingVerticalSpace.js';

export interface ScrollableContainerProps {
  readonly children: JSX.Element | JSX.Element[];
  readonly height?: string;
  readonly isPrintable?: boolean;
  readonly bottomPlaceholderSize?: number;
  readonly isNarrowUntilMaxHeight?: boolean;
  readonly className?: string;
}

export function ScrollableContainer({
  children,
  height,
  isPrintable = false,
  bottomPlaceholderSize,
  className,
  isNarrowUntilMaxHeight = false,
}: ScrollableContainerProps): JSX.Element {
  const [listSize, measuredRef] = useRemainingVerticalSpace(bottomPlaceholderSize);

  const computedSize = useMemo(() => {
    if (height === undefined) {
      return `${listSize}PX`;
    }
    return height;
  }, [height, listSize]);

  const style = useMemo(() => {
    let myStyle: React.CSSProperties = {
      overflow: 'hidden',
      overflowY: 'scroll',
    };
    if (!isNarrowUntilMaxHeight) {
      myStyle = { ...myStyle, height: computedSize };
    } else {
      myStyle = { ...myStyle, maxHeight: computedSize };
    }
    return myStyle;
  }, [computedSize, isNarrowUntilMaxHeight]);

  const computeClassNames = (): string => {
    let classes = '';
    if (isTruthyAndNotEmpty(className)) {
      classes += className;
    }

    if (isPrintable && !classes.includes('printable-scroll')) {
      classes += ' printable-scroll';
    }
    return classes;
  };

  return (
    <div ref={measuredRef} className={computeClassNames()} style={style}>
      {children}
    </div>
  );
}

interface BoxProps extends ScrollableContainerProps {
  readonly boxStyle?: string;
}

export function ScrollableBoxContainer({
  children,
  height,
  boxStyle,
  bottomPlaceholderSize,
}: BoxProps): JSX.Element {
  const [listSize, measuredRef] = useRemainingVerticalSpace(bottomPlaceholderSize);

  const computedSize = useMemo(() => {
    if (height === undefined) {
      return `${listSize}PX`;
    }
    return height;
  }, [height, listSize]);

  return (
    <div ref={measuredRef} className={boxStyle !== undefined ? `box ${boxStyle}` : 'box'}>
      <div
        style={{
          height: `${computedSize}`,
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <div className="m-xs">{children}</div>
      </div>
    </div>
  );
}

interface BoxWithFixedHeaderProps extends ScrollableContainerProps {
  readonly boxStyle?: string;
  readonly header: JSX.Element;
}

export function ScrollableBoxWithFixedHeaderContainer({
  children,
  height,
  boxStyle,
  bottomPlaceholderSize,
  header,
}: BoxWithFixedHeaderProps): JSX.Element {
  const [listSize, measuredRef] = useRemainingVerticalSpace(bottomPlaceholderSize);

  const computedSize = useMemo(() => {
    if (height === undefined) {
      return `${listSize}PX`;
    }
    return height;
  }, [height, listSize]);

  return (
    <div className={boxStyle !== undefined ? `box ${boxStyle}` : 'box'}>
      {header}
      <div
        ref={measuredRef}
        style={{
          height: `${computedSize}`,
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <div className="m-xs">{children}</div>
      </div>
    </div>
  );
}
