import React from 'react';
import { useTranslation } from 'react-i18next';
import { globalHelpers, shortDayMonthDateFormatOptions } from '@stimcar/libs-base';
import { FaIcon } from '@stimcar/libs-uitoolkit';

const MILLIS_IN_A_DAY = 1000 * 3600 * 24;

interface Props {
  readonly estimatedDateOfReception: number | null;
}

export function SparePartEstimatedDateOfReceptionText({
  estimatedDateOfReception,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');
  const delay =
    estimatedDateOfReception !== null
      ? 1 +
        Math.floor(estimatedDateOfReception / MILLIS_IN_A_DAY) -
        Math.floor(Date.now() / MILLIS_IN_A_DAY)
      : -1;
  return (
    <>
      {estimatedDateOfReception === -1 && (
        <>
          <FaIcon id="warning" />
          {t('generalView.spareParts.table.unknown')}
        </>
      )}
      {estimatedDateOfReception === null && <>{t('generalView.spareParts.table.standard')}</>}
      {estimatedDateOfReception && estimatedDateOfReception >= 0 && (
        <>
          {`${delay} ${t(
            'generalView.spareParts.table.dayAbbreviation'
          )} (${globalHelpers.convertTimestampToDateString(
            estimatedDateOfReception,
            shortDayMonthDateFormatOptions
          )})`}
        </>
      )}
    </>
  );
}
