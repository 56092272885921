import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ScrollableTableComponent } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { DefectsTabState } from '../../typings/store.js';
import { TableSortableHeaderComponent } from '../../../../lib/components/TableSortableHeaderComponent.js';
import { DefectsTableItemComponent } from './DefectsTableItemComponent.js';
import { getDefectsToDisplay } from './defectUtils.js';
import { openAddDefectDialogAction } from './modal/AddOrUpdateDefectModal.js';

interface DefectsTableComponentProps {
  readonly $: StoreStateSelector<Store, DefectsTabState>;
  readonly $packageDeals: StoreStateSelector<Store, readonly PackageDeal[]>;
  readonly isEditable: boolean;
}

export function DefectsTableComponent({
  $,
  $packageDeals,
  isEditable,
}: DefectsTableComponentProps): JSX.Element {
  const [t] = useTranslation('operators', { keyPrefix: 'defects.table' });

  const defects = useGetState($.$defects);
  const selectedPictureId = useGetState($.$selectedPictureId);

  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);

  const sortedDefects = useMemo(
    () => getDefectsToDisplay(defects, selectedPictureId, sortBy, sortDirection),
    [defects, selectedPictureId, sortBy, sortDirection]
  );

  const openAddDefectDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openAddDefectDialogAction, selectedPictureId);
    },
    [selectedPictureId],
    $.$addOrUpdateDefectDialogState
  );

  return (
    <ScrollableTableComponent
      tableClassName="table defects-table is-striped is-hoverable"
      height="300px"
    >
      <thead>
        <tr>
          <th className="has-text-centered">{t('index')}</th>
          <TableSortableHeaderComponent
            content={t('type')}
            isTruncable
            sortedField="type"
            $sort={$.$sort}
            centerLabel={false}
          />
          <th className="has-text-centered">{t('fullyRefurbishingPrice')}</th>
          <th className="has-text-centered">{t('partiallyRefurbishingPrice')}</th>
          <th aria-label="addDefect" className="has-text-centered">
            <Button
              iconId="plus"
              onClick={openAddDefectDialogCallback}
              size="small"
              tooltip={t('actions.add')}
              disabled={!isEditable}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedDefects.map((defect) => (
          <DefectsTableItemComponent
            $={$}
            $packageDeals={$packageDeals}
            key={defect.id}
            defect={defect}
            isEditable={isEditable}
          />
        ))}
      </tbody>
    </ScrollableTableComponent>
  );
}
