import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { CoreFrontendRoutes } from '@stimcar/libs-base';
import { useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { ShareStore } from './state/typings/store.js';

interface NavBarProps extends AppProps<ShareStore> {
  readonly shareId: string;
}

export function NavBar({ $gs, shareId }: NavBarProps): JSX.Element {
  const [t] = useTranslation('share');
  const { $navBar } = $gs;

  const burgerIsActive = useGetState($navBar.$burgerIsActive);
  const toggleBurger = useSetCallback($navBar.$burgerIsActive, !burgerIsActive);
  const siteName = useGetState($gs.$context.$site.$name);

  /* eslint-disable jsx-a11y/interactive-supports-focus */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      <nav
        className="navbar is-primary is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <NavLink className="navbar-item" to={CoreFrontendRoutes.SHARE(shareId)}>
            <img src="img/logo.png" alt="stimcar" />
          </NavLink>
          {/* TODO replace by button when possible */}
          <a
            role="button"
            className={`navbar-burger burger${burgerIsActive ? ' is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={toggleBurger}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div id="mainNavbar" className={`navbar-menu ${burgerIsActive ? ' is-active' : ''}`}>
          <div className="navbar-start">{/* Append menu items if required */}</div>
          <div className="navbar-end">
            {!burgerIsActive && (
              <div className="navbar-item">
                <FaIcon id="map-marker-alt" tooltip={t('tooltips.site')} label={siteName} />
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
