import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Kanban,
  KanbanColorationCharter,
  StandHandlingStatus,
  WithProgress,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  DEFAULT_KANBAN_COLORATION_CHARTER,
  handlingHelpers,
  i18nHelpers,
  Role,
  sortingHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store, StoreState } from '../state/typings/store.js';
import { GraphicWorkflow } from '../../lib/components/GraphicWorkflow.js';
import { kanbanPriorityLevelHelpers } from '../../lib/utils/kanbanPriorityLevelHelpers.js';
import { computeKanbanDetailsPath } from '../coreConstants.js';
import { PackageDealDecorators } from '../utils/PackageDealDecorators.js';
import { useGetComputedIconFromKanban } from '../utils/useGetComputedIconFromKanban.js';
import type { StandAchievements, WorkflowDisplayState } from './typings/store.js';
import { StandAchievementsTable } from './StandAchievementsTable.js';

interface StandHandlingStatusIconProps {
  readonly status: StandHandlingStatus;
}

function StandHandlingStatusIcon({ status }: StandHandlingStatusIconProps): JSX.Element {
  switch (status) {
    case 'currentlyHandled':
      return <FaIcon id="user" size="small" additionalClass="spaced-icon" />;
    case 'waitingForHandle':
    case 'hasBeenHandled':
      return <FaIcon id="r/hourglass" size="small" additionalClass="spaced-icon" />;
    case 'anomaly':
      return <FaIcon id="exclamation-triangle" size="small" additionalClass="spaced-icon" />;
    default:
      return <></>;
  }
}

interface ComputedKanbanIconProps extends AppProps<Store> {
  readonly kanban?: Kanban;
}

export function ComputedKanbanIcon({ $gs, kanban }: ComputedKanbanIconProps): JSX.Element {
  const icon = useGetComputedIconFromKanban($gs, kanban);
  return isTruthyAndNotEmpty(icon) ? (
    <FaIcon id={icon} size="small" additionalClass="spaced-icon" />
  ) : (
    <></>
  );
}

interface KanbanItemDisplayProps extends AppProps<Store> {
  readonly standId: string;
  readonly kanban: WithProgress<Kanban>;
  readonly browseable: boolean;
  readonly kanbanColorationCharter: KanbanColorationCharter | undefined;
}

export function KanbanItemDisplay({
  standId,
  kanban,
  browseable,
  kanbanColorationCharter,
  $gs,
}: KanbanItemDisplayProps): JSX.Element {
  const [t] = useTranslation();

  const { id, infos, handlings } = kanban;
  // Booleans that will help to build the item color
  const standHandlingStatus = handlingHelpers.getHandleStatusForStand(handlings, standId);
  const gotoKanbanDetailsCallback = useActionCallback(
    ({ actionDispatch, navigate }: ActionContext<Store, StoreState>): void => {
      if (browseable) {
        navigate(computeKanbanDetailsPath(kanban.id));
      } else {
        actionDispatch.setProperty('message', {
          type: 'info',
          title: kanban.infos.license,
          content: `${i18nHelpers.displayStringOrPlaceholder(t, kanban.infos.brand)} ${
            kanban.infos.model
          }`,
        });
      }
    },
    [browseable, kanban.id, kanban.infos.brand, t, kanban.infos.license, kanban.infos.model],
    $gs
  );

  const colorClass = useMemo((): string => {
    return kanbanPriorityLevelHelpers.getCssIdForPriorityLevelFromKanban(
      kanban,
      kanbanColorationCharter
    );
  }, [kanban, kanbanColorationCharter]);

  return (
    <>
      <button
        key={id}
        type="button"
        onClick={gotoKanbanDetailsCallback}
        className={`button is-rounded is-small is-narrow ${colorClass} is-rounded is-family-monospace`}
      >
        <ComputedKanbanIcon kanban={kanban} $gs={$gs} />
        {infos.license}
        <PackageDealDecorators kanban={kanban} $gs={$gs} />
        <StandHandlingStatusIcon status={standHandlingStatus} />
      </button>
    </>
  );
}

interface KanbanBoardColumnItemProps extends AppProps<Store> {
  readonly standId: string;
  readonly kanbansAreBrowseable: boolean;
  readonly kanbans: readonly WithProgress<Kanban>[];
  readonly achievements: StandAchievements;
}

export function KanbanBoardColumnItem({
  $gs,
  standId,
  achievements,
  kanbans,
  kanbansAreBrowseable,
}: KanbanBoardColumnItemProps): JSX.Element {
  const kanbanColorationCharterFromState = useGetState(
    $gs.$siteConfiguration.$displayConfiguration.$kanbanColorationCharter
  );
  // kind of trick (because /dislay/dashboard is the landing page)
  // to prevent this page from crashing before the configuration edition can be done
  const kanbanColorationCharter = useMemo((): KanbanColorationCharter => {
    return kanbanColorationCharterFromState ?? DEFAULT_KANBAN_COLORATION_CHARTER;
  }, [kanbanColorationCharterFromState]);
  /* On display dashboard page kanbans need to be sorted in this order :
   * - firstly by handlings : is it currently handled ? then has it been handled ?
   * - secondly by dueDate
   * - thirdly by kanban's age
   */
  const sortedKanbans = useMemo(() => {
    return sortingHelpers.sortKanbansByStandHandlingThenByDueDateThenByAge(
      kanbans,
      [standId],
      kanbanColorationCharter?.dueDateThreshold,
      true
    );
  }, [kanbanColorationCharter, kanbans, standId]);

  return (
    <div className="column" key={standId}>
      <div className="box has-text-centered">
        <div className="is-size-4 has-text-black has-text-weight-bold p-t-none p-b-none">
          {`${standId} (${kanbans.length})`}
        </div>
        <div className="m-b-sm">
          <StandAchievementsTable
            standId={standId}
            size={0.3}
            kanbans={kanbans}
            achievements={achievements}
            showTotalEngagedRevenueAndRemainingWorkload
          />
        </div>
        {sortedKanbans.map(
          (kanban): JSX.Element => (
            <KanbanItemDisplay
              key={kanban.id}
              $gs={$gs}
              kanban={kanban}
              standId={standId}
              browseable={kanbansAreBrowseable}
              kanbanColorationCharter={kanbanColorationCharter}
            />
          )
        ) ?? <></>}
      </div>
    </div>
  );
}

interface KanbanBoardColumnProps extends WorkflowDisplayState, AppProps<Store> {
  readonly workflowId: string;
}

function KanbanBoardColumn({
  kanbansCount,
  stands,
  $gs,
  workflowId,
}: KanbanBoardColumnProps): JSX.Element {
  const user = useGetState($gs.$session.$user);
  const role = useGetState($gs.$session.$infos.optChaining().$role);
  const workflows = useGetState($gs.$siteConfiguration.$workflows);
  const workflow = useMemo(
    () => nonnull(workflows.find(({ id }) => id === workflowId)),
    [workflowId, workflows]
  );
  const kanbansAreBrowseable = isTruthy(user) && role !== Role.Display;
  const standsDefs = useGetState($gs.$siteConfiguration.$stands);
  const nonHiddenStandIds = useMemo(() => {
    return keysOf(stands).filter((standId) => {
      const { hideFromDashboard } = standsDefs.find((standDef) => standDef.id === standId)!;
      switch (hideFromDashboard) {
        case undefined:
          return true;
        case 'ifEmpty': {
          const {
            kanbanProgressesSum,
            kanbansDone,
            kanbansToRework,
            operationDoneRevenue,
            operationDoneWorkload,
          } = stands[standId].achievements;
          return (
            stands[standId].kanbans.length !== 0 ||
            kanbanProgressesSum !== 0 ||
            kanbansDone.length !== 0 ||
            operationDoneRevenue !== 0 ||
            operationDoneWorkload !== 0 ||
            kanbansToRework.length !== 0
          );
        }
        case 'always':
        default:
          return false;
      }
    });
  }, [stands, standsDefs]);
  return (
    <>
      <div className="box">
        <p className="title is-5">{`${workflow.description} (${kanbansCount})`}</p>
      </div>
      <div className="columns" style={{ overflowX: 'auto' }}>
        {nonHiddenStandIds.map(
          (standId): JSX.Element => (
            <KanbanBoardColumnItem
              key={standId}
              $gs={$gs}
              standId={standId}
              kanbansAreBrowseable={kanbansAreBrowseable}
              kanbans={stands[standId].kanbans}
              achievements={stands[standId].achievements}
            />
          )
        )}
        <div className="column">
          <GraphicWorkflow workflowNode={workflow.definition} />
        </div>
      </div>
    </>
  );
}

export function KanbanBoardDisplay({ $gs }: AppProps<Store>): JSX.Element {
  const workflowsStates = useGetState($gs.$displayView.$workflowsStates);

  /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
  return (
    <>
      {keysOf(workflowsStates).map((workflowId) => (
        <KanbanBoardColumn
          key={workflowId}
          $gs={$gs}
          workflowId={workflowId}
          {...workflowsStates[workflowId]}
        />
      ))}
    </>
  );
}
