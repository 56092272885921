/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  CarElement,
  CoreFields,
  SharedKanban,
  SharedPackageDeal,
  SharedSparePart,
  SharedUIContract,
  Site,
} from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ImageModalState } from '@stimcar/libs-uitoolkit';
import { filterReject, nonDeleted, packageDealHelpers, sortingHelpers } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, ToggleNestedButton } from '@stimcar/libs-uitoolkit';
import type { ComputeAttachmentUrlCallback } from '../../lib/components/attachments/typings/attachment.js';
import type { ShareStore } from '../state/typings/store.js';
import { MultilineValueTableCellContent } from '../../lib/bulma/elements/table/MultilineValueTableCellContent.js';
import { CustomerInformations } from '../../lib/components/displayInformations/CustomerInformations.js';
import {
  CarInfo,
  PrintableDocumentView,
} from '../../lib/components/documentGeneration/DocumentGenerationCommonComponents.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { ColumnedCarviewAndAttachmentThumbnailsDisplayer } from '../../lib/components/photosdisplayer/ColumnedCarviewAndAttachmentThumbnailsDisplayer.js';

type SharedSparePartWithPackageDealData = SharedSparePart & {
  readonly carElement: CoreFields<CarElement> | undefined;
  readonly packageDealLabel: string;
  readonly packageDealAttachments: readonly Attachment[];
};

interface Props extends AppProps<ShareStore> {
  readonly kanban: SharedKanban;
  readonly contract: SharedUIContract;
  readonly site: Site;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
}

export function KanbanShareSparePartsTab({
  $gs,
  kanban,
  contract,
  site,
  computeAttachmentUrlCallback,
}: Props): JSX.Element {
  const [t] = useTranslation('share');

  const { $imageModal, $kanbanShare } = $gs;
  const $ = $kanbanShare.$sparePartsTab;

  const activePackageDeals = kanban.packageDeals.filter(nonDeleted);

  const hasBeenValidated = useMemo(() => {
    return packageDealHelpers.hasBeenValidated(activePackageDeals);
  }, [activePackageDeals]);

  const selectedPackageDeals = useMemo(() => {
    const { filtered } = filterReject(activePackageDeals, (pd: SharedPackageDeal): boolean =>
      packageDealHelpers.isPackageDealAvailable(pd, !hasBeenValidated)
    );
    return filtered;
  }, [activePackageDeals, hasBeenValidated]);

  const sortedSelectedPackageDeals = useMemo(() => {
    const sortFunction: (o1: SharedPackageDeal, o2: SharedPackageDeal) => number =
      sortingHelpers.createSortPackageDealByCarElementIndexThenLabelFunction('DOWN');
    return selectedPackageDeals.slice().sort(sortFunction);
  }, [selectedPackageDeals]);

  const {
    sparePartsManagedByClient,
    sparePartsManagedByStimcar,
    sparePartsOrderedByStimcarFromCustomersCatalog,
  } = useMemo((): {
    sparePartsManagedByClient: readonly SharedSparePartWithPackageDealData[];
    sparePartsManagedByStimcar: readonly SharedSparePartWithPackageDealData[];
    sparePartsOrderedByStimcarFromCustomersCatalog: readonly SharedSparePartWithPackageDealData[];
  } => {
    const managedByCustomer: SharedSparePartWithPackageDealData[] = [];
    const managedByStimcar: SharedSparePartWithPackageDealData[] = [];
    const orderedByStimcarFromCustomersCatalog: SharedSparePartWithPackageDealData[] = [];
    sortedSelectedPackageDeals.forEach((pd) => {
      pd.spareParts.filter(nonDeleted).forEach((sp) => {
        const computedSparePartLabel = packageDealHelpers.getSparePartDisplayedLabel(sp, pd);

        const data: SharedSparePartWithPackageDealData = {
          ...sp,
          label: computedSparePartLabel,
          carElement: pd.carElement,
          packageDealLabel: pd.label,
          packageDealAttachments: pd.attachments?.filter((a) => !a.deleted) ?? [],
        };
        switch (sp.managementType) {
          case 'fullyManagedByCustomer': {
            managedByCustomer.push(data);
            break;
          }
          case 'fullyManagedByStimcar': {
            managedByStimcar.push(data);
            break;
          }
          case 'orderedByStimcarFromCustomersCatalog': {
            orderedByStimcarFromCustomersCatalog.push(data);
            break;
          }
          default:
            throw Error('Unexpected spare part management type');
        }
      });
    });
    return {
      sparePartsManagedByClient: managedByCustomer,
      sparePartsManagedByStimcar: managedByStimcar,
      sparePartsOrderedByStimcarFromCustomersCatalog: orderedByStimcarFromCustomersCatalog,
    };
  }, [sortedSelectedPackageDeals]);
  const allActiveSpareParts = [
    ...sparePartsManagedByClient,
    ...sparePartsManagedByStimcar,
    ...sparePartsOrderedByStimcarFromCustomersCatalog,
  ];

  return (
    <>
      <PrintableDocumentView
        printDocumentTitle={t(`sparePartsTab.printDocumentTitle`, {
          contract: kanban.contract.code,
          license: kanban.infos.license,
        })}
        kanban={kanban}
        address={site.configuration.infos.address}
        companyName={site.configuration.infos.companyName}
      >
        <div className="has-background-white is-fullwidth">
          <div className="columns has-text-centered">
            <div
              className="column is-fullwidth"
              style={{ border: '0.0625em solid', marginRight: '0.5em', marginLeft: '0.5em' }}
            >
              <p className="title">{t(`tabs.spareParts`)}</p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-8">
              <CarInfo attributeDescs={contract.attributeDescs} kanban={kanban} />
            </div>
            <div className="column">
              <CustomerInformations customer={kanban.customer} />
            </div>
          </div>

          {!hasBeenValidated && (
            <div className="has-text-centered m-b-lg">
              <FaIcon
                id="exclamation-triangle"
                label={t(`sparePartsTab.nonValidatedWarning`)}
                size="large-2x"
              />
            </div>
          )}
          <DisplayContentOrPlaceholder
            displayCondition={allActiveSpareParts.length > 0}
            placeholder={t('sparePartsTab.noSparePartsMsg')}
          >
            <>
              {sparePartsManagedByClient.length > 0 && (
                <div>
                  <p className="m-b-md">
                    <span className="is-size-5 has-text-weight-bold">
                      {t(`sparePartsTab.sparePartsManagedByCustomer`)}
                    </span>
                  </p>
                  <ShareSparePartTable
                    spareParts={sparePartsManagedByClient}
                    kanbanId={kanban.id}
                    computeAttachmentUrlCallback={computeAttachmentUrlCallback}
                    $imageModal={$imageModal}
                    $expandedSectionIds={$.$expandedSectionIdsDispatch}
                  />
                </div>
              )}
              {sparePartsOrderedByStimcarFromCustomersCatalog.length > 0 && (
                <div>
                  <p className="m-b-md m-t-md">
                    <span className="is-size-5 has-text-weight-bold">
                      {t(`sparePartsTab.sparePartsOrderedByStimcarFromCustomersCatalog`)}
                    </span>
                  </p>
                  <ShareSparePartTable
                    spareParts={sparePartsOrderedByStimcarFromCustomersCatalog}
                    kanbanId={kanban.id}
                    computeAttachmentUrlCallback={computeAttachmentUrlCallback}
                    $imageModal={$imageModal}
                    $expandedSectionIds={$.$expandedSectionIdsDispatch}
                  />
                </div>
              )}
              {sparePartsManagedByStimcar.length > 0 && (
                <div>
                  <p className="m-b-md m-t-md">
                    <span className="is-size-5 has-text-weight-bold">
                      {t(`sparePartsTab.sparePartsManagedByUs`)}
                    </span>
                  </p>
                  <ShareSparePartTable
                    spareParts={sparePartsManagedByStimcar}
                    kanbanId={kanban.id}
                    computeAttachmentUrlCallback={computeAttachmentUrlCallback}
                    $imageModal={$imageModal}
                    $expandedSectionIds={$.$expandedSectionIdsDispatch}
                  />
                </div>
              )}
            </>
          </DisplayContentOrPlaceholder>
        </div>
      </PrintableDocumentView>
    </>
  );
}

interface ShareSparePartTableProps {
  readonly spareParts: readonly SharedSparePartWithPackageDealData[];
  readonly kanbanId: string;
  readonly $imageModal: StoreStateSelector<ShareStore, ImageModalState>;
  readonly $expandedSectionIds: StoreStateSelector<ShareStore, readonly string[]>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
}

function ShareSparePartTable({
  spareParts,
  kanbanId,
  $imageModal,
  $expandedSectionIds,
  computeAttachmentUrlCallback,
}: ShareSparePartTableProps): JSX.Element {
  const [t] = useTranslation('share');

  return (
    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>{t('sparePartsTab.carElement')}</th>
          <th>{t('sparePartsTab.packageDealCode')}</th>
          <th>{t('sparePartsTab.sparePartLabel')}</th>
          <th>{t('sparePartsTab.sparePartCustomerComment')}</th>
          <th style={{ width: '6em' }}>{t('sparePartsTab.quantity')}</th>
          <th style={{ width: '4em' }}>{t('sparePartsTab.received')}</th>
        </tr>
      </thead>
      <tbody>
        {spareParts.map((sp) => (
          <ShareSparePartRow
            key={sp.id}
            sparePart={sp}
            kanbanId={kanbanId}
            computeAttachmentUrlCallback={computeAttachmentUrlCallback}
            $imageModal={$imageModal}
            $expandedSectionIds={$expandedSectionIds}
          />
        ))}
      </tbody>
    </table>
  );
}

interface ShareSparePartRowProps {
  readonly sparePart: SharedSparePartWithPackageDealData;
  readonly kanbanId: string;
  readonly $imageModal: StoreStateSelector<ShareStore, ImageModalState>;
  readonly $expandedSectionIds: StoreStateSelector<ShareStore, readonly string[]>;
  readonly computeAttachmentUrlCallback: ComputeAttachmentUrlCallback;
}

function ShareSparePartRow({
  sparePart,
  kanbanId,
  $imageModal,
  $expandedSectionIds,
  computeAttachmentUrlCallback,
}: ShareSparePartRowProps): JSX.Element {
  const [t] = useTranslation('share');
  const expandedSectionIds = useGetState($expandedSectionIds);
  return (
    <>
      <tr>
        <td>
          <ToggleNestedButton
            $expandedIds={$expandedSectionIds}
            id={sparePart.id}
            hasChildren={sparePart.packageDealAttachments.length > 0}
            label={sparePart.carElement?.label ?? ''}
          />
        </td>
        <td>{sparePart.packageDealLabel}</td>
        <td>{sparePart.label}</td>
        <td>
          <MultilineValueTableCellContent value={sparePart.commentForCustomer} />
        </td>
        <td className="has-text-right">{sparePart.quantity}</td>
        <td>
          {sparePart.dateOfReception ? (
            <FaIcon id="check" tooltip={t('sparePartsTab.received')} />
          ) : (
            <></>
          )}
        </td>
      </tr>
      {expandedSectionIds.includes(sparePart.id) && (
        <tr>
          <td colSpan={5}>
            <ColumnedCarviewAndAttachmentThumbnailsDisplayer
              category={sparePart.carElement?.category ?? 'MISC'}
              selectedShapes={sparePart.carElement?.shapes ?? []}
              attachments={sparePart.packageDealAttachments}
              computeAttachmentUrl={computeAttachmentUrlCallback}
              $imageModal={$imageModal}
              kanbanId={kanbanId}
              photoHeight={125}
            />
          </td>
        </tr>
      )}
    </>
  );
}
