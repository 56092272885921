import React from 'react';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { LabelledEntity, LabelledEntityListProps } from './LabelledEntityList.js';
import { LabelledEntityList } from './LabelledEntityList.js';

export type LabelledEntityListFormFieldProps<T extends LabelledEntity> = Omit<
  FormFieldProps,
  'type' | 'children' | 'warning'
> &
  LabelledEntityListProps<T>;

export function LabelledEntityListFormField<T extends LabelledEntity>({
  label,
  labelledEntities,

  editSelectedLabelledEntity,
  deleteSelectedLabelledEntity,
  createLabelledEntity,
  $selectedEntityId,
  horizontal,
  creationDisabled,
}: LabelledEntityListFormFieldProps<T>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($selectedEntityId);

  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      noExclamationTriangleIfWarning
      horizontal={horizontal}
    >
      <LabelledEntityList<T>
        labelledEntities={labelledEntities}
        editSelectedLabelledEntity={editSelectedLabelledEntity}
        deleteSelectedLabelledEntity={deleteSelectedLabelledEntity}
        createLabelledEntity={createLabelledEntity}
        $selectedEntityId={$selectedEntityId}
        creationDisabled={creationDisabled}
      />
    </FormField>
  );
}
