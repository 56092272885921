import React from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ActionCallbackFromFunction,
  AnyStoreDef,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Pagination } from '@stimcar/libs-uitoolkit';
import type { KanbanListProps } from './KanbanList.js';
import { KanbanList } from './KanbanList.js';

type Props<SD extends AnyStoreDef> = KanbanListProps & {
  readonly $searchText: StoreStateSelector<SD, string>;
  readonly runSearchActionCallback: ActionCallbackFromFunction<SD, (page: number) => Promise<void>>;
  readonly pageCount?: number;
  readonly activePage?: number;
  readonly totalFound?: number;
  readonly maxPageButtons?: number;
  readonly searchShouldBeRerun: boolean;
  readonly warning?: string;
};

export function KanbanListWithSearch<SD extends AnyStoreDef>({
  kanbans,
  selectKanban,
  runSearchActionCallback,
  searchShouldBeRerun,
  warning,
  kanbanColorationCharter,
  $searchText,
  pageCount = 1,
  activePage = 0,
  maxPageButtons = 5,
  totalFound,
  selectedKanbanId,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  const defaultSearchActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.execCallback(runSearchActionCallback, 1);
    },
    [runSearchActionCallback],
    $searchText
  );

  const onKeyPressedActionCallback = useActionCallback(
    async ({ actionDispatch }, e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await actionDispatch.execCallback(defaultSearchActionCallback);
      }
    },
    [defaultSearchActionCallback],
    $searchText
  );

  const onSearchTextChangedHandler = useActionCallback(
    ({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) => {
      actionDispatch.setValue(e.target.value);
    },
    [],
    $searchText
  );

  const searchText = useGetState($searchText);

  return (
    <div>
      <div className="field">
        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className="input"
              type="text"
              placeholder={t('search.placeholder')}
              defaultValue={searchText}
              onKeyPress={onKeyPressedActionCallback}
              onChange={onSearchTextChangedHandler}
            />
          </div>
          <div className="control">
            <button
              type="button"
              className="button is-primary"
              onClick={defaultSearchActionCallback}
            >
              {t('search.label')}
            </button>
          </div>
        </div>
        {isTruthyAndNotEmpty(warning) && (
          <p className="has-text-centered help is-primary">{warning}</p>
        )}
        {searchShouldBeRerun && (
          <p className="has-text-centered help is-primary">{t('kanbanList.rerunSearchMessage')}</p>
        )}
      </div>
      {pageCount > 1 ? (
        <Pagination
          activePage={activePage}
          gotoPage={runSearchActionCallback}
          maxPageButtons={maxPageButtons}
          pageCount={pageCount}
        />
      ) : (
        <></>
      )}
      <KanbanList
        kanbans={kanbans}
        selectKanban={selectKanban}
        kanbanColorationCharter={kanbanColorationCharter}
        selectedKanbanId={selectedKanbanId}
      />
      <div>
        {totalFound !== undefined && totalFound > 0
          ? t('search.totalFoundLabel', { totalFound })
          : ''}
      </div>
    </div>
  );
}
