import i18next from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type {
  MergedCarViewCategory,
  OperationRequestMessage,
  SubcontractorKanban,
  SubcontractorPackageDeal,
  SubcontractorUnassignedPackageDeal,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  filterReject,
  nonDeleted,
  packageDealHelpers,
  sortingHelpers,
  SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
  SubcontractorBackendRoutes,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useRecordItemSelector } from '@stimcar/libs-uikernel';
import { Button, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { RawActionButtonDesc } from '../../lib/components/misc/ActionButtonComponent.js';
import type { TabElementDef } from '../../lib/components/Tabs.js';
import type { SubcontractorUIOperation } from '../lib/subcontractorCardUtils.js';
import type {
  SubcontractorStore,
  SubcontractorWorkshopPostIdentification,
} from '../state/typings/store.js';
import { EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE } from '../../app/utils/operationCompletion/typings/store.js';
import { ShowHideButton } from '../../lib/bulma/elements/ShowHideButton.js';
import { RawSwitch } from '../../lib/bulma/form/Switch.js';
import { TextAreaFormField } from '../../lib/bulma/form/TextAreaFormField.js';
import { KanbanMessageChatStyleItem } from '../../lib/bulmalegacy/components/KanbanMessageQuickviewComponent.js';
import { KanbanListItem } from '../../lib/components/kanbanList/KanbanListItem.js';
import { useActionButtonDescs } from '../../lib/components/misc/ActionButtonComponent.js';
import { ColumnedCarviewAndAttachmentThumbnailsDisplayer } from '../../lib/components/photosdisplayer/ColumnedCarviewAndAttachmentThumbnailsDisplayer.js';
import { Tabs } from '../../lib/components/Tabs.js';
import { kanbanPriorityLevelHelpers } from '../../lib/utils/kanbanPriorityLevelHelpers.js';
import { useQuery } from '../../utils/generalUtils.js';
import {
  computeSubcontractorOperationDisplayedLabel,
  extractOperationsAndSpareParts,
  SubcontractorKanbanCardSpareParts,
  usePrepareDataForSubcontractorKanbanCard,
} from '../lib/subcontractorCardUtils.js';
import { SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH } from '../subcontractorConstants.js';
import { useComputeSubcontractorAttachmentUrl } from '../utils/useSubcontractorComputeAttachmentUrl.js';
import type {
  OperationToDoState,
  SubcontractorOperateState,
  SubcontractorOperationMessageModal,
} from './typings/store.js';
import { SubContractorOperateFolderTab } from './SubcontractorOperateFolderTab.js';
import { SubcontractorUploadDocumentForCompletionModalDialog } from './SubcontractorUploadDocumentForOperationCompletionModalDialog.js';
import {
  SUBCONTRACTOR_OPERATE_EMPTY_STATE,
  SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE,
} from './typings/store.js';

async function initializeOperateKanban(
  {
    actionDispatch,
    httpClient,
    getGlobalState,
  }: ActionContext<SubcontractorStore, SubcontractorOperateState>,
  kanbanId: string,
  standId: string,
  implantationId: string | null,
  implantationCategory: string | null
): Promise<void> {
  const { session } = getGlobalState();
  let kanban: SubcontractorKanban;
  if (implantationId && implantationCategory) {
    kanban = await httpClient.httpGetAsJson<SubcontractorKanban>(
      SubcontractorBackendRoutes.GET_SUBCONTRACTOR_KANBAN_FOR_POST(
        session.selectedSite,
        kanbanId,
        standId,
        implantationId,
        implantationCategory
      )
    );
  } else {
    kanban = await httpClient.httpGetAsJson<SubcontractorKanban>(
      SubcontractorBackendRoutes.GET_SUBCONTRACTOR_KANBAN(session.selectedSite, kanbanId, standId)
    );
  }

  const { operationsToDo } = extractOperationsAndSpareParts(kanban);
  const operationsState: Record<string, OperationToDoState> = {};
  operationsToDo.forEach((o) => {
    operationsState[o.operationId] = {
      isAttachmentsExpanded:
        isTruthy(o.attachments) && o.attachments.length > 0 && !isTruthy(o.completionDate),
    };
  });
  actionDispatch.setValue({
    ...SUBCONTRACTOR_OPERATE_EMPTY_STATE,
    kanban,
    operationsToDoTabState: {
      operationsState,
    },
  });
}

async function toggleOperationStatusAction(
  {
    getState,
    httpClient,
    getGlobalState,
    actionDispatch,
    globalActionDispatch,
  }: ActionContext<SubcontractorStore, SubcontractorOperateState>,
  operationId: string,
  standId: string | undefined,
  workshopPost: SubcontractorWorkshopPostIdentification | undefined,
  selectedFiles?: readonly string[]
) {
  const { kanban } = getState();
  const packageDeal = nonnull(kanban).packageDealsToHandle.find(({ operations }) =>
    operations.some(({ id }) => id === operationId)
  ) as SubcontractorPackageDeal;
  const operation = nonnull(packageDeal.operations.find(({ id }) => id === operationId));

  if (!isTruthy(selectedFiles) && operation.type === 'UploadDocument' && operation.documentsInfo) {
    actionDispatch.scopeProperty('subcontractorUploadDocumentForCompletionModal').setValue({
      ...EMPTY_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
      kanbanId: nonnull(kanban).id,
      operation,
      active: true,
      documentsInfo: operation.documentsInfo,
    });
  } else {
    try {
      let newKanban;
      const { selectedSite } = getGlobalState().session;

      if (workshopPost) {
        newKanban = await httpClient.httpGetAsJson<SubcontractorKanban>(
          SubcontractorBackendRoutes.TOGGLE_SUBCONTRACTOR_WORKSHOP_POST_OPERATION_STATUS(
            selectedSite,
            standId ?? '',
            workshopPost.implantationId,
            workshopPost.categoryId,
            workshopPost.postName,
            nonnull(kanban).id,
            packageDeal.id,
            operationId,
            selectedFiles?.join(URL_LIST_ELEMENTS_SEPARATOR) ?? ''
          )
        );
      } else {
        newKanban = await httpClient.httpGetAsJson<SubcontractorKanban>(
          SubcontractorBackendRoutes.TOGGLE_SUBCONTRACTOR_STAND_OPERATION_STATUS(
            selectedSite,
            standId ?? '',
            nonnull(kanban).id,
            packageDeal.id,
            operationId,
            selectedFiles?.join(URL_LIST_ELEMENTS_SEPARATOR) ?? ''
          )
        );
      }
      actionDispatch.setProperty('kanban', newKanban);
      // Collapse attachments
      if (!isTruthy(operation.completionDate)) {
        actionDispatch
          .scopeProperty('operationsToDoTabState')
          .scopeProperty('operationsState')
          .scopeRecordItem(operationId)
          .setProperty('isAttachmentsExpanded', false);
      }
    } catch {
      globalActionDispatch.setProperty('message', {
        type: 'error',
        title: i18next.t('subcontractor:operateKanban.toggleOperationErrorModalTitle'),
        content: i18next.t('subcontractor:operateKanban.toggleOperationErrorModalMessage'),
        redirectTo: SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH,
      });
    }
  }
}

async function terminateHandleKanbanAction(
  {
    httpClient,
    getGlobalState,
    navigate,
  }: ActionContext<SubcontractorStore, SubcontractorOperateState>,
  kanbanId: string,
  standId: string,
  workshopPost?: SubcontractorWorkshopPostIdentification
): Promise<void> {
  if (workshopPost) {
    const { session } = getGlobalState();
    try {
      await httpClient.httpGet(
        SubcontractorBackendRoutes.STOP_HANDLE_ON_WORKSHOP_SUBCONTRACTOR_KANBAN(
          session.selectedSite,
          kanbanId,
          standId,
          workshopPost.implantationId,
          workshopPost.categoryId,
          workshopPost.postName
        )
      );
    } catch {
      // Do nothing specific, redirect to kanban selection
    }
  }
  navigate(SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH);
}

export function SubcontractorOperate({ $gs }: AppProps<SubcontractorStore>): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const { id, standId } = useParams<{ id: string; standId: string }>();
  const query = useQuery();
  const implantationId = query.get('implantationId');
  const categoryId = query.get('categoryId');
  const postName = query.get('postName');

  const $ = $gs.$operate;

  const kanban = useGetState($.$kanban);

  const workshopPost = useMemo((): SubcontractorWorkshopPostIdentification | undefined => {
    if (implantationId && categoryId && postName) {
      return { implantationId, categoryId, postName };
    }
    return undefined;
  }, [categoryId, implantationId, postName]);

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      if (id && standId) {
        await actionDispatch.exec(initializeOperateKanban, id, standId, implantationId, categoryId);
      }
    },
    [categoryId, id, implantationId, standId],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const toggleCompletedOperationStatusActionCallback = useActionCallback(
    async ({ actionDispatch }, operationId: string, selectedFiles?: readonly string[]) => {
      await actionDispatch.exec(
        toggleOperationStatusAction,
        operationId,
        standId,
        workshopPost,
        selectedFiles
      );
    },
    [standId, workshopPost],
    $
  );

  return (
    <div>
      {isTruthy(kanban) ? (
        <>
          <InternalSubcontractorOperate
            $gs={$gs}
            kanban={kanban}
            standId={nonnull(standId)}
            workshopPost={workshopPost}
          />
          <SubcontractorUploadDocumentForCompletionModalDialog
            $gs={$gs}
            $={$gs.$operate.$subcontractorUploadDocumentForCompletionModal}
            toggleOperationActionCallback={toggleCompletedOperationStatusActionCallback}
          />
        </>
      ) : (
        t('operateKanban.noKanban')
      )}
    </div>
  );
}

interface Props extends AppProps<SubcontractorStore> {
  readonly kanban: SubcontractorKanban;
  readonly standId: string;
  readonly workshopPost?: SubcontractorWorkshopPostIdentification;
}

function InternalSubcontractorOperate({ $gs, kanban, standId, workshopPost }: Props): JSX.Element {
  const [t] = useTranslation('subcontractor');

  const $ = $gs.$operate;

  const attachmentUrl = useComputeSubcontractorAttachmentUrl($gs);

  const [hasAMessage, requestMessageClassName] = useMemo(() => {
    let message = kanban.messages.find((m) => m.type === 'request' && m.status === 'pending');
    if (!message) {
      message = kanban.messages.find((m) => m.type === 'request' && m.status === 'rejected');
    }
    return [
      isTruthy(message),
      isTruthy(message) ? kanbanPriorityLevelHelpers.getKanbanMessageColorClassName(message) : '',
    ];
  }, [kanban]);

  const colorationCssClass = kanbanPriorityLevelHelpers.getCssIdFromKanbanPriorityLevel(
    kanban.priority
  );

  const footerElements = useActionButtonDescs(
    () => {
      const elements: RawActionButtonDesc<SubcontractorStore, SubcontractorOperateState>[] = [];
      elements.push({
        id: 'select',
        label: t('operateKanban.terminateButton'),
        action: async ({ actionDispatch }) => {
          await actionDispatch.exec(terminateHandleKanbanAction, kanban.id, standId, workshopPost);
        },
        disabled: false,
      });
      return elements;
    },
    [kanban.id, standId, t, workshopPost],
    $
  );

  return (
    <KanbanListItem
      kanbanPositionIconId={kanban.iconId}
      $imageModal={$gs.$imageModal}
      attachmentUrl={attachmentUrl}
      hasAMessage={hasAMessage}
      requestMessageClassName={requestMessageClassName}
      kanbanId={kanban.id}
      contractCode={kanban.contractCode}
      kanbanColorationClassName={colorationCssClass}
      infos={kanban.infos}
      actionDescs={footerElements}
      unfoldable={
        <Content $gs={$gs} kanban={kanban} standId={standId} workshopPost={workshopPost} />
      }
      $expandedKanbanStatuses={$.$expandedKanbanStatuses}
    />
  );
}

interface ContentProps extends AppProps<SubcontractorStore> {
  readonly kanban: SubcontractorKanban;
  readonly standId: string;
  readonly workshopPost?: SubcontractorWorkshopPostIdentification;
}

function Content({ $gs, kanban, workshopPost, standId }: ContentProps): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const $ = $gs.$operate;

  const tab = useGetState($.$tab);

  const { operationsToDo, spareParts, allOperationsAreFinished } =
    usePrepareDataForSubcontractorKanbanCard(kanban);

  const unassignedPackageDealsPerCategory = useMemo(() => {
    const map: Map<MergedCarViewCategory, SubcontractorUnassignedPackageDeal[]> = new Map();
    (kanban.unassignedPackageDeals ?? []).forEach((pck) => {
      const category = packageDealHelpers.getMergedCategoryCode(pck);
      const values = map.get(category) ?? [];
      values.push(pck);
      map.set(category, values);
    });
    return map;
  }, [kanban.unassignedPackageDeals]);

  const allUnassignedCarViewCategories = [...unassignedPackageDealsPerCategory.keys()];

  const tabLabels = useMemo(() => {
    const labels: Record<string, string | TabElementDef> = {
      operationsToDo: {
        label: '',
        icon: {
          id: t('operateKanban.todo.icon'),
        },
      },
    };
    if (kanban.folders.length > 0) {
      labels.folders = {
        label: '',
        icon: {
          id: t('operateKanban.folders.icon'),
        },
      };
    }
    labels.messages = {
      label: t('operateKanban.messages.title', { count: kanban.messages.length }),
      icon: {
        id: t('operateKanban.messages.icon'),
      },
    };
    if (allUnassignedCarViewCategories.length > 0) {
      labels.otherOperations = t('operateKanban.additionalPackages.title');
    }
    return labels;
  }, [allUnassignedCarViewCategories.length, kanban.messages.length, kanban.folders.length, t]);

  return (
    <>
      {keysOf(tabLabels).length > 1 && <Tabs isCentered labels={tabLabels} $selectedTab={$.$tab} />}
      {tab === 'otherOperations' &&
        allUnassignedCarViewCategories.map((c) => (
          <UnassignedPackageDealsForCategoryComponent
            key={c}
            category={c}
            unassignedPackageDeals={nonnull(unassignedPackageDealsPerCategory.get(c))}
            $expandedCategories={$.$otherOperationsTabState.$expandedCategories}
          />
        ))}
      {tab === 'operationsToDo' && (
        <div className={allOperationsAreFinished ? 'is-green-light' : ''}>
          {operationsToDo &&
            operationsToDo.map((o) => (
              <OperatedKanbanOperationLine
                key={o.operationId}
                kanban={kanban}
                uiOperation={o}
                $={$}
                $gs={$gs}
                standId={standId}
                workshopPost={workshopPost}
              />
            ))}
          <SubcontractorKanbanCardSpareParts spareParts={spareParts} />
        </div>
      )}
      {tab === 'folders' && <SubContractorOperateFolderTab t={t} $gs={$gs} kanban={kanban} />}
      {tab === 'messages' && (
        <>
          {kanban.messages.length === 0 ? (
            <div className="has-text-centered">{t('operateKanban.noMessages')}</div>
          ) : (
            kanban.messages.map((m) => <KanbanMessageChatStyleItem key={m.id} message={m} />)
          )}
        </>
      )}
    </>
  );
}

interface UnassignedPackageDealsForCategoryComponentProps {
  readonly category: MergedCarViewCategory;
  readonly unassignedPackageDeals: readonly SubcontractorUnassignedPackageDeal[];
  readonly $expandedCategories: StoreStateSelector<
    SubcontractorStore,
    Record<MergedCarViewCategory, boolean>
  >;
}

function UnassignedPackageDealsForCategoryComponent({
  category,
  unassignedPackageDeals,
  $expandedCategories,
}: UnassignedPackageDealsForCategoryComponentProps): JSX.Element {
  const [t] = useTranslation('subcontractor');

  const $isExpanded = useRecordItemSelector($expandedCategories, category);
  const isExpanded = useGetState($isExpanded);

  const [toDoPcks, doingPcks, donePcks] = useMemo(() => {
    function convertToString(pck: SubcontractorUnassignedPackageDeal): string {
      return `${pck.label}${isTruthy(pck.carElement) ? ` (${pck.carElement.label})` : ''}`;
    }

    const { filtered: notStarted, rejected } = filterReject(
      unassignedPackageDeals,
      (pck) => pck.status === 'notStarted'
    );
    const { filtered: done, rejected: doing } = filterReject(
      rejected,
      (pck) => pck.status === 'done'
    );

    return [
      notStarted.map(convertToString).sort(),
      doing.map(convertToString).sort(),
      done.map(convertToString).sort(),
    ];
  }, [unassignedPackageDeals]);

  return (
    <div>
      <ShowHideButton<SubcontractorStore>
        $={$isExpanded}
        label={packageDealHelpers.getMergedCategoryLabelFromCategoryCode(category, t)}
        className="title is-5"
        iconPosition="right"
      />
      {isExpanded && (
        <div>
          {donePcks.length > 0 && (
            <DisplayOtherPackages
              title={t('operateKanban.additionalPackages.status.done')}
              packageDeals={donePcks}
            />
          )}
          {doingPcks.length > 0 && (
            <DisplayOtherPackages
              title={t('operateKanban.additionalPackages.status.doing')}
              packageDeals={doingPcks}
            />
          )}
          {toDoPcks.length > 0 && (
            <DisplayOtherPackages
              title={t('operateKanban.additionalPackages.status.notStarted')}
              packageDeals={toDoPcks}
            />
          )}
        </div>
      )}
    </div>
  );
}

interface DisplayOtherPackagesProps {
  readonly title: string;
  readonly packageDeals: readonly string[];
}

function DisplayOtherPackages({ title, packageDeals }: DisplayOtherPackagesProps): JSX.Element {
  return (
    <div className="m-b-md">
      <div>
        <b>{title}</b>
      </div>
      <ul>
        {packageDeals.map((pck) => (
          <li key={pck}>{pck}</li>
        ))}
      </ul>
    </div>
  );
}

interface OperatedKanbanOperationLineProps extends AppProps<SubcontractorStore> {
  readonly uiOperation: SubcontractorUIOperation;
  readonly $: StoreStateSelector<SubcontractorStore, SubcontractorOperateState>;
  readonly kanban: SubcontractorKanban;
  readonly standId: string;
  readonly workshopPost?: SubcontractorWorkshopPostIdentification;
}

function OperatedKanbanOperationLine({
  uiOperation,
  $,
  $gs,
  kanban,
  standId,
  workshopPost,
}: OperatedKanbanOperationLineProps): JSX.Element {
  const { $imageModal } = $gs;

  const attachmentUrl = useComputeSubcontractorAttachmentUrl($gs);

  const notDeletedAttachment = uiOperation.attachments?.filter(nonDeleted) ?? [];
  const hasNoAttachments = notDeletedAttachment.length === 0 && !isTruthy(uiOperation.carElement);

  const $operation = useRecordItemSelector(
    $.$operationsToDoTabState.$operationsState,
    uiOperation.operationId
  );
  const isAttachmentsExpanded = useGetState($operation.$isAttachmentsExpanded);

  const isOperationFinished = isTruthy(uiOperation.completionDate);

  const toggleOperationStatusActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        toggleOperationStatusAction,
        uiOperation.operationId,
        standId,
        workshopPost,
        undefined
      );
    },
    [workshopPost, uiOperation.operationId, standId],
    $
  );

  const label = computeSubcontractorOperationDisplayedLabel(uiOperation);

  const operationMessage = useMemo(() => {
    let pendingMessage;
    const notCanceledMessages: OperationRequestMessage[] = [];
    kanban.messages.forEach((m) => {
      if (m.type === 'request' && m.elementId === uiOperation.operationId) {
        if (m.status === 'pending') {
          pendingMessage = m;
        } else if (m.status !== 'canceled') {
          notCanceledMessages.push(m);
        }
      }
    });
    notCanceledMessages.sort((m1, m2) =>
      sortingHelpers.compareNumbers(m1.timestamp, m2.timestamp, 'UP')
    );
    return isTruthy(pendingMessage) ? pendingMessage : notCanceledMessages[0];
  }, [kanban.messages, uiOperation.operationId]);

  let errorButtonClassName = '';
  if (isTruthy(operationMessage)) {
    errorButtonClassName =
      kanbanPriorityLevelHelpers.getKanbanMessageColorClassName(operationMessage);
  }

  const openMessageModalActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.scopeProperty('messageModal').reduce(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_initial): SubcontractorOperationMessageModal => {
          const isMessageEdition =
            isTruthy(operationMessage) &&
            (operationMessage.status === 'pending' || operationMessage.status === 'rejected');
          return {
            ...SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE,
            uiOperation,
            active: true,
            messageContent: isMessageEdition ? operationMessage.content : '',
            initialMessage: isMessageEdition ? operationMessage : undefined,
          };
        }
      );
    },
    [operationMessage, uiOperation],
    $
  );

  return (
    <div className={`p-b-sm ${isOperationFinished ? 'is-green-light' : ''}`}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flexGrow: 1 }}>
          <ShowHideButton
            $={$operation.$isAttachmentsExpanded}
            label={label}
            disabled={hasNoAttachments}
            iconPosition="left"
          />
        </div>
        <Button
          onClick={openMessageModalActionCallback}
          additionalClass={`${errorButtonClassName} m-r-sm`}
          iconId={SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID}
          size="small"
        />
        <RawSwitch
          isChecked={isOperationFinished}
          switchId={uiOperation.operationId}
          toggleAction={toggleOperationStatusActionCallback}
        />
      </div>
      <OperationMessageModal $gs={$gs} kanbanId={kanban.id} />
      {isAttachmentsExpanded && (
        <ColumnedCarviewAndAttachmentThumbnailsDisplayer
          attachments={notDeletedAttachment}
          category={uiOperation.carElement?.category}
          computeAttachmentUrl={attachmentUrl}
          $imageModal={$imageModal}
          kanbanId={kanban.id}
          selectedShapes={uiOperation.carElement?.shapes ?? []}
          photoHeight={50}
        />
      )}
    </div>
  );
}

interface OperationMessageModalProps extends AppProps<SubcontractorStore> {
  readonly kanbanId: string;
}

function OperationMessageModal({ $gs, kanbanId }: OperationMessageModalProps): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const { $messageModal } = $gs.$operate;
  const uiOperation = useGetState($messageModal.$uiOperation);
  const initialMessage = useGetState($messageModal.$initialMessage);
  const isActive = useGetState($messageModal.$active);

  const cancelOperationMessageOrCloseModaleActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient, getGlobalState }) => {
      const { session } = getGlobalState();
      let newKanban: SubcontractorKanban;
      if (isTruthy(initialMessage)) {
        newKanban = await httpClient.httpGetAsJson<SubcontractorKanban>(
          SubcontractorBackendRoutes.SUBCONTRACTOR_DELETE_OPERATION_MESSAGE(
            session.selectedSite,
            kanbanId,
            nonnull(initialMessage).id
          )
        );
      }

      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          kanban: isTruthy(newKanban) ? newKanban : initial.kanban,
          messageModal: SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE,
        };
      });
    },
    [initialMessage, kanbanId],
    $gs.$operate
  );

  const sendOperationMessageActionCallback = useActionCallback(
    async ({ actionDispatch, httpClient, getState, getGlobalState }) => {
      const { session } = getGlobalState();
      const messageModalState = getState().messageModal;
      const operation = nonnull(messageModalState.uiOperation);
      const { messageContent } = messageModalState;
      let newKanban: SubcontractorKanban;
      if (isTruthy(initialMessage)) {
        newKanban = await httpClient.httpPostAsJSON<
          { messageContent: string },
          SubcontractorKanban
        >(
          SubcontractorBackendRoutes.SUBCONTRACTOR_CHANGE_OPERATION_MESSAGE(
            session.selectedSite,
            kanbanId,
            initialMessage.id
          ),
          { messageContent }
        );
      } else {
        newKanban = await httpClient.httpPostAsJSON<
          { messageContent: string },
          SubcontractorKanban
        >(
          SubcontractorBackendRoutes.SUBCONTRACTOR_ADD_OPERATION_MESSAGE(
            session.selectedSite,
            kanbanId,
            operation.packageDealId,
            operation.operationId
          ),
          { messageContent }
        );
      }
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          kanban: newKanban,
          messageModal: SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE,
        };
      });
    },
    [initialMessage, kanbanId],
    $gs.$operate
  );

  if (!isActive || !isTruthy(uiOperation)) {
    return <></>;
  }

  return (
    <ModalCardDialog
      onOkClicked={sendOperationMessageActionCallback}
      onCancelClicked={cancelOperationMessageOrCloseModaleActionCallback}
      okLabel={
        isTruthy(initialMessage)
          ? t('operateKanban.requestModal.updateButton')
          : t('bulma:buttons.submit')
      }
      cancelLabel={
        isTruthy(initialMessage)
          ? t('operateKanban.requestModal.deleteButton')
          : t('bulma:buttons.cancel')
      }
      $active={$messageModal.$active}
      title={uiOperation.operationLabel}
    >
      <TextAreaFormField
        label={t('operateKanban.requestModal.requestLabel')}
        $={$messageModal.$messageContent}
      />
    </ModalCardDialog>
  );
}
