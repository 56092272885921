import React, { useMemo, useRef } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { useDeactivateOnClickOutside } from '../hooks/useDeactivateOnClickOutside.js';

export interface DropDownButtonProps<SD extends AnyStoreDef> {
  readonly $active: StoreStateSelector<SD, boolean>;
  readonly label: string;
  readonly children: JSX.Element;
  readonly iconId?: string;
  readonly additionalClass?: string;
  readonly disabled?: boolean;
}

export function DropdownButton<SD extends AnyStoreDef>({
  $active,
  label,
  children,
  iconId,
  disabled = false,
  additionalClass = '',
}: DropDownButtonProps<SD>): JSX.Element {
  const isActive = useGetState($active);

  const dropDownButtonRef = useRef<HTMLButtonElement | null>(null);
  useDeactivateOnClickOutside({
    domElementRef: dropDownButtonRef,
    $active,
    deactivateOnInternalClick: false,
  });

  const toggleDropdownActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue(true);
    },
    [],
    $active
  );
  const buttonAdditionalClass = useMemo(
    () => (isTruthyAndNotEmpty(additionalClass) ? ` ${additionalClass}` : ''),
    [additionalClass]
  );

  return (
    <div className={`dropdown${isActive ? ' is-active' : ''}`}>
      <div className="dropdown-trigger">
        <button
          type="button"
          aria-haspopup="true"
          ref={dropDownButtonRef}
          aria-controls="dropdown-menu"
          onClick={toggleDropdownActionCallback}
          disabled={disabled}
          className={`button${buttonAdditionalClass}`}
        >
          {isTruthyAndNotEmpty(iconId) && (
            <span className="icon is-small">
              <i className={`fas fa-${iconId}`} aria-hidden="true" />
            </span>
          )}
          <span>{label}</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  );
}
