import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormConsistencyAction } from '@stimcar/libs-uitoolkit';
import { contractHelpers, CoreBackendRoutes, enumerate } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminContractsState,
  AdminUIContract,
  DeleteContractDialogState,
  OnContractsChangeActionCallback,
} from './typings/store.js';
import { EMPTY_DELETE_CONTRACT_DIALOG_STATE } from './typings/store.js';

export function openDeleteContractModalAction(
  { actionDispatch }: ActionContext<Store, AdminContractsState>,
  contract: AdminUIContract
): void {
  actionDispatch.reduce((initial: AdminContractsState) => {
    return {
      ...initial,
      deleteContractDialogState: {
        ...EMPTY_DELETE_CONTRACT_DIALOG_STATE,
        active: true,
        contractCode: contract.id,
      },
    };
  });
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  DeleteContractDialogState
> = async ({ formState, t, customerRepository }): Promise<string | undefined> => {
  const { contractCode } = formState;
  const customers = await customerRepository.getAllEntities();
  const dependingCustomerShortnames = contractHelpers.getDependingCustomerShortnames(
    customers,
    contractCode
  );
  if (dependingCustomerShortnames.length > 0) {
    return t('warning.dependingCustomerExists', {
      clients: enumerate(dependingCustomerShortnames),
    });
  }
  return undefined;
};

async function deleteContractAction(
  { getState, httpClient, actionDispatch }: ActionContext<Store, AdminContractsState>,
  onContractsChangeActionCallback: OnContractsChangeActionCallback
): Promise<void> {
  const { contractCode } = getState().deleteContractDialogState;
  await httpClient.httpGet(CoreBackendRoutes.DELETE_CONTRACT(contractCode));
  actionDispatch.setProperty('deleteContractDialogState', EMPTY_DELETE_CONTRACT_DIALOG_STATE);
  // Update the UI
  await actionDispatch.execCallback(onContractsChangeActionCallback, [], [contractCode]);
}

interface DeleteContractModalProps {
  readonly $: StoreStateSelector<Store, AdminContractsState>;
  readonly onContractsChangeActionCallback: OnContractsChangeActionCallback;
}

export function DeleteContractModal({
  $,
  onContractsChangeActionCallback,
}: DeleteContractModalProps): JSX.Element {
  const [t] = useTranslation('adminContracts');
  const state = useGetState($.$deleteContractDialogState);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(deleteContractAction, onContractsChangeActionCallback);
    },
    [onContractsChangeActionCallback],
    $
  );
  const [onFormSubmit] = useFormWithValidation<Store, DeleteContractDialogState>({
    $: $.$deleteContractDialogState,
    mandatoryFields: [],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });
  return (
    <ModalCardDialog
      $active={$.$deleteContractDialogState.$active}
      title={t('deleteModal.title')}
      onOkClicked={onFormSubmit}
      warning={state.formWarning}
    >
      <p>{t('deleteModal.message')}</p>
    </ModalCardDialog>
  );
}
