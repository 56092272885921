import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { EMPTY_SPARE_PART_MANAGED_BY_US } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  AddSparePartToPackageDealData,
  AddSparePartToPackageDealDialogState,
  SparePartReferenceFormData,
  SparePartsReferenceState,
} from '../../typings/store.js';
import { EMPTY_ADD_SPARE_PART_TO_PACKAGE_DEAL_DIALOG_STATE } from '../../typings/store.js';

function saveSparePartAction({
  getState,
  httpClient,
  actionDispatch,
}: ActionContext<Store, SparePartsReferenceState>): void {
  const { formData, packageDeals } = getState().addSparePartToPackageDealDialogState;
  const { packageDealId, label } = formData;
  const packageDeal = nonnull(packageDeals.find((p) => p.id === packageDealId));
  const newSparePart: SparePartReferenceFormData = {
    ...EMPTY_SPARE_PART_MANAGED_BY_US,
    id: httpClient.getBrowserSequence().next(),
    label,
    packageDealId: packageDeal.id,
    packageDealLabel: packageDeal.label,
    carElement: packageDeal.carElement,
    packageDealAttachments: packageDeal.attachments?.filter((a) => !a.deleted) ?? [],
    providerUnitPrice: '0',
    price: '0',
    quantity: '1',
    isReferenced: false,
    provider: '',
    warnings: {},
  };
  actionDispatch.applyPayload({
    sparePartsUnderModification: [newSparePart],
  });
  actionDispatch.setProperty(
    'addSparePartToPackageDealDialogState',
    EMPTY_ADD_SPARE_PART_TO_PACKAGE_DEAL_DIALOG_STATE
  );
}

interface AddSparePartToPackageDealModalProps {
  readonly $: StoreStateSelector<Store, SparePartsReferenceState>;
  readonly sortedPackageDealsSuggestions: readonly {
    value: string;
    label: string;
  }[];
}

const mandatoryFields: (keyof AddSparePartToPackageDealData)[] = ['packageDealId', 'label'];

export function AddSparePartToPackageDealModal({
  $,
  sortedPackageDealsSuggestions,
}: AddSparePartToPackageDealModalProps): JSX.Element {
  const [t] = useTranslation('operators');
  const { $addSparePartToPackageDealDialogState } = $;
  const formWarning = useGetState($addSparePartToPackageDealDialogState.$formWarning);
  const submitValidDataAction = useActionCallback(saveSparePartAction, [], $);
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    AddSparePartToPackageDealDialogState
  >({
    $: $addSparePartToPackageDealDialogState,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$addSparePartToPackageDealDialogState.$active}
      titleIconId="cart-plus"
      title={t('sparePartsReference.addSparePartToPackageDealModal.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <ReactSelectFormField
        label={t('sparePartsReference.addSparePartToPackageDealModal.packageDealCode')}
        $={$formDataWithChangeTrigger.$packageDealId}
        horizontal
        suggestions={sortedPackageDealsSuggestions}
        isClearable
      />
      <InputFormField
        label={t('sparePartsReference.addSparePartToPackageDealModal.label')}
        placeholder={t('sparePartsReference.addSparePartToPackageDealModal.label')}
        $={$formDataWithChangeTrigger.$label}
        horizontal
      />
    </ModalCardDialog>
  );
}
