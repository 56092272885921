import type { CSSProperties } from 'react';
import React from 'react';
import type { FormFieldProps } from './FormField.js';
import { FormField } from './FormField.js';

interface Props extends Omit<FormFieldProps, 'children'> {
  readonly value: string;
  readonly className?: string;
  readonly style?: CSSProperties;
}

export function ReadOnlyInputFormField({
  label,
  value,
  warning,
  horizontal,
  className,
  noExclamationTriangleIfWarning,
  style,
}: Props): JSX.Element {
  return (
    <FormField
      label={label}
      horizontal={horizontal}
      warning={warning}
      noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
    >
      <input
        className={`input${className ? ` ${className}` : ''}`}
        readOnly
        disabled
        value={value}
        style={style}
      />
    </FormField>
  );
}
