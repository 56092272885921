import { type TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  KanbanSignatureType,
  PackageDealExpressionComputationResult,
  SharedKanban,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import {
  CoreBackendRoutes,
  electronicSignatureHelpers,
  expertiseHelpers,
  globalHelpers,
  HttpErrorCodes,
  nonDeleted,
  packageDealHelpers,
  priceHelpers,
  purchaseOrderHelpers,
} from '@stimcar/libs-base';
import { ensureError, getHttpStatusCode, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, Input, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type {
  CustomerValidationCodeModalState,
  KanbanShareSecondFactorState,
  NotAllocatedPackageDealsErrorModalState,
  SharePreferencesType,
  ShareStore,
  ShareStoreState,
} from '../state/typings/store.js';
import {
  CUSTOMER_VALIDATION_CODE_MODAL_EMPTY_STATE,
  SHARE_PREFERENCE_EMPTY_VALUE,
} from '../state/typings/store.js';
import { updateSharePreferences } from './EstimateMailPreferencesModal.js';

type ValidationModalModeType = KanbanSignatureType;

interface GeneralSecondFactorMailInputAndModalComponentProps {
  readonly shareId: string;
  readonly kanban: SharedKanban;
  readonly $: StoreStateSelector<ShareStore, KanbanShareSecondFactorState>;
  readonly businessMessage: string | undefined;
}

interface ExpertiseValidationSecondFactorMailInputAndModalComponentProps
  extends GeneralSecondFactorMailInputAndModalComponentProps {
  readonly updatedPackageDealsInformations: readonly PackageDealExpressionComputationResult[];
}

export function ExpertiseValidationSecondFactorMailInputAndModalComponent({
  shareId,
  $,
  kanban,
  businessMessage,
  updatedPackageDealsInformations,
}: ExpertiseValidationSecondFactorMailInputAndModalComponentProps): JSX.Element {
  const [revenue, availablePackageDealIds, canceledPackageDealIds] = useMemo(() => {
    const updatedPackageDeals = expertiseHelpers.applyPackageDealsValidationUpdatedInformations(
      kanban.packageDeals.filter(nonDeleted),
      updatedPackageDealsInformations
    );

    const activeAndAvailablePackageDeals = updatedPackageDeals.filter(
      packageDealHelpers.buildPackageDealFilter('available', false)
    );
    const computedRevenue = packageDealHelpers.getPackageDealsAndSparePartsTotalPriceWithoutVAT(
      activeAndAvailablePackageDeals,
      'all'
    );
    const availableIds = updatedPackageDeals
      .filter((pck) => pck.status === 'available')
      .map((pck) => pck.id);
    const canceldIds = expertiseHelpers.deduceCanceledPackageDealIdListFromAvailableOnes(
      updatedPackageDeals,
      availableIds
    );
    return [computedRevenue, availableIds, canceldIds];
  }, [kanban.packageDeals, updatedPackageDealsInformations]);

  return (
    <InternalSecondFactorMailInputAndModalComponent
      businessMessage={businessMessage}
      kanban={kanban}
      $={$}
      shareId={shareId}
      mode="estimateSignature"
      displayedCostWithoutVAT={revenue}
      additionalDataForCustomerAction={{
        availablePackageDealIds,
        canceledPackageDealIds,
        totalRefurbishRevenueWithoutVAT: revenue,
      }}
      updatedPackageDealsInformations={updatedPackageDealsInformations}
    />
  );
}

export function DeliverySecondFactorMailInputAndModalComponent({
  shareId,
  $,
  kanban,
  businessMessage,
}: GeneralSecondFactorMailInputAndModalComponentProps): JSX.Element {
  const cost = useMemo(
    () =>
      packageDealHelpers.getInvoiceablePackageDealsAndSparePartsPriceWithoutVAT(
        kanban.packageDeals
      ),
    [kanban]
  );

  return (
    <InternalSecondFactorMailInputAndModalComponent
      businessMessage={businessMessage}
      kanban={kanban}
      $={$}
      shareId={shareId}
      displayedCostWithoutVAT={cost}
      mode="deliverySignature"
    />
  );
}

interface InternalSecondFactorMailInputAndModalComponentProps
  extends GeneralSecondFactorMailInputAndModalComponentProps {
  readonly mode: ValidationModalModeType;
  readonly displayedCostWithoutVAT: number;
  readonly additionalDataForCustomerAction?: unknown;
  readonly updatedPackageDealsInformations?: readonly PackageDealExpressionComputationResult[];
}

function InternalSecondFactorMailInputAndModalComponent({
  $,
  mode,
  kanban,
  shareId,
  businessMessage,
  displayedCostWithoutVAT,
  additionalDataForCustomerAction,
  updatedPackageDealsInformations = [],
}: InternalSecondFactorMailInputAndModalComponentProps): JSX.Element {
  const [t] = useTranslation('share');

  const { $customerValidationCodeModal } = $;

  const email = useGetState($.$validationEmail);

  const sendValidationCodeCallback = useActionCallback(
    async ({ httpClient, actionDispatch }) => {
      actionDispatch.reduce((initial) => ({
        ...initial,
        customerValidationCodeModal: {
          ...CUSTOMER_VALIDATION_CODE_MODAL_EMPTY_STATE,
          isActive: true,
        },
      }));

      const packageDealsSignature = packageDealHelpers.computePackageDealsSignature(
        kanban.packageDeals,
        mode === 'estimateSignature'
      );

      await httpClient.httpPostAsJSON(
        CoreBackendRoutes.SEND_KANBAN_CUSTOMER_SIDE_VALIDATION_CODE(shareId, mode),
        {
          email,
          kanbanSignature: { packageDealsSignature },
          sequenceId: kanban.sequenceId,
          costWithoutVAT: displayedCostWithoutVAT,
        }
      );
    },
    [mode, email, shareId, kanban.sequenceId, kanban.packageDeals, displayedCostWithoutVAT],
    $
  );

  const submitValidationCodeCallback = useActionCallback(
    async ({ actionDispatch, keyValueStorage }) => {
      updateSharePreferences(keyValueStorage, { email });
      if (purchaseOrderHelpers.hasMultiplePurchaseOrders(kanban.purchaseOrders)) {
        const notAllocatedPackageDealsWithPricesDifferentFromZeroIds = purchaseOrderHelpers
          .getNotAllocatedPackageDealsWithPricesDifferentFromZero(updatedPackageDealsInformations)
          .map(({ id }) => id);
        if (notAllocatedPackageDealsWithPricesDifferentFromZeroIds.length > 0) {
          actionDispatch.reduce((initial) => ({
            ...initial,
            notAllocatedPackageDealsErrorModal: {
              isActive: true,
              notAllocatedPackageDealsWithPricesDifferentFromZero: kanban.packageDeals.filter(
                ({ id }) => notAllocatedPackageDealsWithPricesDifferentFromZeroIds.includes(id)
              ),
            },
          }));
        } else {
          await actionDispatch.execCallback(sendValidationCodeCallback);
        }
      } else {
        await actionDispatch.execCallback(sendValidationCodeCallback);
      }
    },
    [
      email,
      kanban.packageDeals,
      sendValidationCodeCallback,
      updatedPackageDealsInformations,
      kanban.purchaseOrders,
    ],
    $
  );

  const mailValidationMessage = useMemo(() => {
    if (isTruthyAndNotEmpty(businessMessage)) {
      return businessMessage;
    }
    if (email === '' || globalHelpers.isValidEmailAddressStructure(email)) {
      return undefined;
    }
    return t('incorrectEmail');
  }, [email, t, businessMessage]);

  return (
    <>
      <div className="columns is-vcentered">
        <div className="column" />
        <div className="column is-narrow">
          {mailValidationMessage && <p className="help is-primary">{mailValidationMessage}</p>}
        </div>
        <div className="column is-4">
          <Input
            $={$.$validationEmail}
            placeholder={t('validationEmail')}
            disabled={isTruthyAndNotEmpty(businessMessage)}
          />
        </div>
        <div className="column is-narrow">
          <Button
            additionalClass="is-primary"
            onClick={submitValidationCodeCallback}
            label={t(`validationCodeModal.${mode}.openButton`)}
            disabled={!isTruthyAndNotEmpty(email) || mailValidationMessage !== undefined}
          />
        </div>
      </div>
      <ValidationCodeAndProceedCustomerActionModal
        modalMode={mode}
        email={email}
        $={$customerValidationCodeModal}
        shareId={shareId}
        displayedCostWithoutVAT={displayedCostWithoutVAT}
        kanban={kanban}
        additionalDataForCustomerAction={additionalDataForCustomerAction}
      />
      <NotAllocatedPackageDealsErrorModal $={$.$notAllocatedPackageDealsErrorModal} />
    </>
  );
}

interface ValidationCodeAndProceedCustomerActionModalProps {
  readonly $: StoreStateSelector<ShareStore, CustomerValidationCodeModalState>;
  readonly shareId: string;
  readonly kanban: SharedKanban;
  readonly email: string;
  readonly modalMode: ValidationModalModeType;
  readonly additionalDataForCustomerAction: unknown;
  readonly displayedCostWithoutVAT: number;
}

function ValidationCodeAndProceedCustomerActionModal({
  $,
  kanban,
  shareId,
  email,
  modalMode,
  displayedCostWithoutVAT,
  additionalDataForCustomerAction,
}: ValidationCodeAndProceedCustomerActionModalProps): JSX.Element {
  const isActive = useGetState($.$isActive);
  return (
    <>
      {isActive && (
        <InternalValidationCodeAndAngageKanbanModal
          $={$}
          kanban={kanban}
          shareId={shareId}
          email={email}
          modalMode={modalMode}
          displayedCostWithoutVAT={displayedCostWithoutVAT}
          additionalDataForCustomerAction={additionalDataForCustomerAction}
        />
      )}
    </>
  );
}

async function applyCustomerActionAndCloseModalAction(
  {
    httpClient,
    getState,
    keyValueStorage,
    actionDispatch,
    globalActionDispatch,
  }: ActionContext<ShareStore, CustomerValidationCodeModalState>,
  t: TFunction,
  shareId: string,
  kanban: SharedKanban,
  email: string,
  modalMode: ValidationModalModeType,
  additionalDataForCustomerAction: unknown
): Promise<void> {
  const { formData } = getState();

  const ccEmails: string[] = [];
  if (modalMode === 'estimateSignature') {
    const prefs =
      keyValueStorage.getObjectItem<SharePreferencesType>(LocalStorageKeys.SHARE_PREFERENCES) ??
      SHARE_PREFERENCE_EMPTY_VALUE;
    ccEmails.push(...prefs.estimate.ccEmails);
  }

  let reloadKanban = true;
  let message = '';

  if (
    !electronicSignatureHelpers.isAdditionalDataForElectronicSignatureCorrect(
      modalMode,
      additionalDataForCustomerAction
    )
  ) {
    message = t('status.unknownError');
  } else {
    try {
      switch (modalMode) {
        case 'deliverySignature':
          await httpClient.httpPostAsJSON(CoreBackendRoutes.CUSTOMER_SIDE_DELIVER_KANBAN(shareId), {
            email,
            token: formData.validationCode,
          });
          break;
        case 'estimateSignature':
          await httpClient.httpPostAsJSON(CoreBackendRoutes.CUSTOMER_SIDE_ENGAGE_KANBAN(shareId), {
            email,
            ccEmails,
            token: formData.validationCode,
            additionalDataForCustomerAction,
          });
          break;
        default:
          throw Error(`Unavailable modale mode : ${modalMode}`);
      }
    } catch (e) {
      const error = ensureError(e);
      const statusCode = getHttpStatusCode(error) ?? -1;
      switch (statusCode) {
        case HttpErrorCodes.CUSTOMER_VALIDATION_KANBAN_TOKEN_INCORRECT:
          message = t(`status.${modalMode}IncorrectToken`, {
            secondFactorToken: formData.validationCode,
          });
          reloadKanban = false;
          break;
        case HttpErrorCodes.CUSTOMER_VALIDATION_KANBAN_SIGNATURE_INCORRECT:
          message = t('status.incorrectSignature', { license: kanban.infos.license });
          break;
        case HttpErrorCodes.CANNOT_ENGAGE_KANBAN_CURRENTLY_EXPERTIZED:
          message = t('status.kanbanCurrentlyHandled');
          break;
        case HttpErrorCodes.CANNOT_DELIVER_REFURBISH_NOT_FINISHED:
          message = t('status.kanbanRefurbishNotFinished');
          break;
        case HttpErrorCodes.CANNOT_ENGAGE_KANBAN_FORBIDDEN_PACKAGE_DEALS_CANCELED:
          message = t('status.forbiddenPackageDealsCanceled');
          reloadKanban = false;
          break;
        case HttpErrorCodes.CANNOT_ENGAGE_KANBAN_NEEDS_SPARE_PART_REFERENCING:
          message = t('status.unreferencedSparePartsManagedByStimcar');
          break;
        case HttpErrorCodes.SIGNATURE_ALREADY_DONE:
          if (modalMode === 'deliverySignature') {
            message = t('status.kanbanAlreadyDelivered');
          } else {
            message = t('status.kanbanAlreadyEngaged');
          }
          break;
        default:
          message = t('status.unknownSignatoryError');
          break;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return CUSTOMER_VALIDATION_CODE_MODAL_EMPTY_STATE;
  });
  if (isTruthyAndNotEmpty(message)) {
    globalActionDispatch.reduce((initial) => {
      return {
        ...initial,
        message,
      };
    });
  }
  if (reloadKanban) {
    const object = await httpClient.httpGetAsJson<SharedKanban>(
      CoreBackendRoutes.RELOAD_SHARED_OBJECT(shareId)
    );
    globalActionDispatch.reduce((initial): ShareStoreState => {
      return {
        ...initial,
        context: {
          ...initial.context,
          object,
        },
      };
    });
  }
}

function InternalValidationCodeAndAngageKanbanModal({
  $,
  kanban,
  shareId,
  email,
  modalMode,
  displayedCostWithoutVAT,
  additionalDataForCustomerAction,
}: ValidationCodeAndProceedCustomerActionModalProps): JSX.Element {
  const [t] = useTranslation('share');

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        applyCustomerActionAndCloseModalAction,
        t,
        shareId,
        kanban,
        email,
        modalMode,
        additionalDataForCustomerAction
      );
    },
    [additionalDataForCustomerAction, email, kanban, modalMode, shareId, t],
    $
  );

  const [onFormSubmit, , $formWithValidationTrigger] = useFormWithValidation<
    ShareStore,
    CustomerValidationCodeModalState
  >({
    $,
    mandatoryFields: ['validationCode'],
    submitValidDataAction,
    t,
  });

  const costWithVAT = useMemo(
    () => priceHelpers.getPriceWithVAT(displayedCostWithoutVAT),
    [displayedCostWithoutVAT]
  );

  const cancelModalCallback = useActionCallback(
    ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return CUSTOMER_VALIDATION_CODE_MODAL_EMPTY_STATE;
      });
    },
    [],
    $
  );

  const formWarning = useGetState($.$formWarning);
  const showVAT = !kanban.customer.ignoreVAT;

  return (
    <ModalCardDialog
      title={t('validationCodeModal.common.title')}
      zIndex={50}
      $active={$.$isActive}
      onOkClicked={onFormSubmit}
      onCancelClicked={cancelModalCallback}
      warning={formWarning}
    >
      <div>
        <p>{t('validationCodeModal.common.informationsText', { email })}</p>
        {modalMode === 'estimateSignature' && showVAT && (
          <p>
            {t(`validationCodeModal.${modalMode}.validationTextWithVAT`, {
              cost: displayedCostWithoutVAT.toFixed(2),
              costWithVAT: costWithVAT.toFixed(2),
            })}
          </p>
        )}
        {modalMode === 'estimateSignature' && !showVAT && (
          <p>
            {t(`validationCodeModal.${modalMode}.validationTextWithoutVAT`, {
              cost: displayedCostWithoutVAT.toFixed(2),
            })}
          </p>
        )}
        {modalMode === 'deliverySignature' && (
          <>
            <p>{t(`validationCodeModal.${modalMode}.validationText`)}</p>
            <p>{t(`validationCodeModal.${modalMode}.reservationText`)}</p>
          </>
        )}
        <Input
          placeholder={t('validationCodeModal.common.tokenPlaceholder')}
          $={$formWithValidationTrigger.$validationCode}
        />
      </div>
    </ModalCardDialog>
  );
}

interface NotAllocatedPackageDealsErrorModalProps {
  readonly $: StoreStateSelector<ShareStore, NotAllocatedPackageDealsErrorModalState>;
}

function NotAllocatedPackageDealsErrorModal({
  $,
}: NotAllocatedPackageDealsErrorModalProps): JSX.Element {
  const [t] = useTranslation('share');

  const notAllocatedPackageDealsWithPricesDifferentFromZero = useGetState(
    $.$notAllocatedPackageDealsWithPricesDifferentFromZero
  );

  return (
    <ModalCardDialog
      $active={$.$isActive}
      dontShowCancelButton
      title={t('notAllocatedPackageDealsErrorModal.title', {
        count: notAllocatedPackageDealsWithPricesDifferentFromZero.length,
      })}
      okLabel={t('notAllocatedPackageDealsErrorModal.understood')}
    >
      <p>
        {t('notAllocatedPackageDealsErrorModal.packageDealsToAllocate', {
          count: notAllocatedPackageDealsWithPricesDifferentFromZero.length,
        })}
      </p>
      <ul>
        {notAllocatedPackageDealsWithPricesDifferentFromZero.map((packageDeal) => (
          <li key={packageDeal.id}>
            {packageDealHelpers.getPackageDealDisplayedLabel(packageDeal)}
          </li>
        ))}
      </ul>
    </ModalCardDialog>
  );
}
