import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  AttachmentFolder,
  AttachmentMetadata,
  StorageCategories,
} from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  StoreStateSelector,
  WindowState,
} from '@stimcar/libs-uikernel';
import type { BaseStoreDefWithHttpClient, ImageModalState } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useStateIsDefined } from '@stimcar/libs-uikernel';
import { Button, convertFaIconSizeToFontSize, FaIcon, ImportButton } from '@stimcar/libs-uitoolkit';
import { downloadAndSave } from '../../utils/download.js';
import { VTabs } from '../Tabs.js';
import type { OnRemoveActionCallback } from './AttachmentThumbnail.js';
import type {
  ConvertToPdfHttpRequestAction,
  RemovePdfPageHttpRequestAction,
} from './PdfCreationAndUploadModal.js';
import type {
  ComputeAttachmentUrlCallback,
  ImportAttachmentsActionCallback,
} from './typings/attachment.js';
import type { AttachmentGalleryState } from './typings/store.js';
import { AttachmentThumbnail } from './AttachmentThumbnail.js';
import {
  openPdfCreationAndUploadModal,
  PdfCreationAndUploadModal,
} from './PdfCreationAndUploadModal.js';
import { EMPTY_ATTACHMENT_GALLERY_STATE } from './typings/store.js';

export type LoadAttachmentsActionCallback<SD extends BaseStoreDefWithHttpClient> =
  ActionCallbackFromFunction<
    SD,
    (
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => Promise<void> | void
  >;

export type RemoveToolkitProps<SD extends BaseStoreDefWithHttpClient> = {
  readonly onRemoveCallback: OnRemoveActionCallback<SD>;
  readonly showRemoveAction?: (
    attachment: Attachment,
    metadata: AttachmentMetadata | undefined
  ) => boolean;
};

interface UploadToolkitProps<SD extends BaseStoreDefWithHttpClient> {
  readonly importAttachmentsActionCallback: ImportAttachmentsActionCallback<SD>;
  // Additional action to perform in addition to the import action
  readonly onImportButtonClickedCallback?: () => void | Promise<void>;
  readonly convertToPdfToolkit?: {
    readonly loadPdfAttachmentContentActionCallback: LoadAttachmentsActionCallback<SD>;
    readonly convertToPdfHttpRequestAction: ConvertToPdfHttpRequestAction<SD>;
    readonly removePdfPageHttpRequestAction: RemovePdfPageHttpRequestAction<SD>;
    readonly clientSpecificFolderId: string;
    readonly onPdfUploadSuccessCallback?: ActionCallbackFromFunction<
      SD,
      (fileName: string) => Promise<void>
    >;
  };
}

export interface AttachmentsGalleryProps<SD extends BaseStoreDefWithHttpClient> {
  readonly category: StorageCategories;
  readonly objectId: string;
  readonly folders: readonly AttachmentFolder[] | AttachmentFolder;
  readonly $: StoreStateSelector<SD, AttachmentGalleryState>;
  readonly $window: StoreStateSelector<SD, WindowState>;
  readonly removeToolkit?: RemoveToolkitProps<SD>;
  readonly loadAttachmentsActionCallback: LoadAttachmentsActionCallback<SD>;
  readonly uploadToolkit?: UploadToolkitProps<SD>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly isOnline: boolean;
  readonly thumbnailSize?: number;
  readonly displaySelectedFolderLabelAsTitle?: boolean;
  readonly isBoxTabs?: boolean;
  // If no selectedPath array is proviced in the state (see AttachmentGalleryState)
  // the following property has no effect.
  readonly selectMode?: 'multiple' | 'one';
  // If no selectedPath array is proviced in the state, the "select" option has
  // no effect
  readonly onClickOnThumbnailBehavior?: 'openImageModal' | 'select';
  readonly attachmentFilter?: (attachment: Attachment) => boolean;
}

export function AttachmentsGallery<SD extends BaseStoreDefWithHttpClient>({
  category,
  objectId,
  folders: folderOrFoldersArray,
  $,
  $window,
  removeToolkit,
  loadAttachmentsActionCallback,
  uploadToolkit,
  computeAttachmentUrl,
  $imageModal,
  isOnline,
  thumbnailSize,
  displaySelectedFolderLabelAsTitle,
  isBoxTabs,
  selectMode,
  onClickOnThumbnailBehavior,
  attachmentFilter,
}: AttachmentsGalleryProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  const attachments = useGetState($.$attachments);
  const selectedAttachmentTab = useGetState($.$selectedAttachmentTab);

  const folders = useMemo(
    () => (Array.isArray(folderOrFoldersArray) ? folderOrFoldersArray : [folderOrFoldersArray]),
    [folderOrFoldersArray]
  );

  const initializeAttachmentGalleryCallback = useActionCallback(
    async function initializeAttachmentGalleryAction({ actionDispatch }): Promise<void> {
      if (isOnline) {
        actionDispatch.applyPayload({
          loadingStatus: t('attachments.gallery.loading'),
          selectedAttachmentTab: folders[0].id,
        });
        await actionDispatch.execCallback(
          loadAttachmentsActionCallback,
          category,
          objectId,
          folders.map((f): string => f.id)
        );
      }
    },
    [category, folders, isOnline, loadAttachmentsActionCallback, objectId, t],
    $
  );

  const clearAttachmentGalleryStateActionCallback = useActionCallback(
    function clearAttachmentGalleryStateAction({ actionDispatch, getState }) {
      actionDispatch.setValue({ ...getState(), ...EMPTY_ATTACHMENT_GALLERY_STATE });
    },
    [],
    $
  );

  useEffect((): (() => void) => {
    if (isTruthyAndNotEmpty(objectId)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      initializeAttachmentGalleryCallback();
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return clearAttachmentGalleryStateActionCallback;
  }, [clearAttachmentGalleryStateActionCallback, initializeAttachmentGalleryCallback, objectId]);

  const selectedFolder = useMemo(() => {
    return folders.find((f) => f.id === selectedAttachmentTab);
  }, [folders, selectedAttachmentTab]);

  const attachmentsPerFoldersRecord = useMemo(() => {
    const map: Record<string, Attachment[]> = {};
    let galleryAttachments = [...attachments];
    if (attachmentFilter) {
      galleryAttachments = galleryAttachments.filter(attachmentFilter);
    }
    galleryAttachments.forEach((a) => {
      let list = map[a.folder];
      if (list === undefined) {
        list = [];
        map[a.folder] = list;
      }
      list.push(a);
    });

    return map;
  }, [attachments, attachmentFilter]);

  const pdfUploadAdditionalActionCallback = useActionCallback(
    async ({ actionDispatch }, foldersToRefresh: readonly string[], fileName: string) => {
      if (uploadToolkit?.convertToPdfToolkit?.onPdfUploadSuccessCallback) {
        await actionDispatch.execCallback(
          uploadToolkit?.convertToPdfToolkit?.onPdfUploadSuccessCallback,
          fileName
        );
      }
      await actionDispatch.execCallback(
        loadAttachmentsActionCallback,
        category,
        objectId,
        foldersToRefresh,
        true
      );
    },
    [
      category,
      loadAttachmentsActionCallback,
      objectId,
      uploadToolkit?.convertToPdfToolkit?.onPdfUploadSuccessCallback,
    ],
    $
  );

  const tabLabels: Record<string, string> = useMemo(() => {
    const labels: Record<string, string> = {};
    folders.forEach((f) => {
      const tabAttachments = attachmentsPerFoldersRecord[f.id];
      labels[f.id] = isBoxTabs
        ? (f.shortLabel ?? f.id)
        : `${f.label ?? f.id}${tabAttachments && tabAttachments.length > 0 ? ` (${tabAttachments.length})` : ''}`;
    });
    return labels;
  }, [attachmentsPerFoldersRecord, folders, isBoxTabs]);

  if (selectedFolder === undefined) {
    return <div>{t('attachments.gallery.loading')}</div>;
  }

  return (
    <>
      {folders.length > 1 ? (
        <VTabs
          $window={$window}
          isBoxTabs={isBoxTabs}
          labels={tabLabels}
          $selectedTab={$.$selectedAttachmentTab}
        >
          <AttachmentGalleryTabContent
            $={$}
            folder={selectedFolder}
            objectId={objectId}
            isOnline={isOnline}
            category={category}
            $imageModal={$imageModal}
            removeToolkit={removeToolkit}
            uploadToolkit={uploadToolkit}
            thumbnailSize={thumbnailSize}
            computeAttachmentUrl={computeAttachmentUrl}
            tabAttachments={attachmentsPerFoldersRecord[selectedFolder.id]}
            loadAttachmentsActionCallback={loadAttachmentsActionCallback}
            displaySelectedFolderLabelAsTitle={displaySelectedFolderLabelAsTitle}
            selectMode={selectMode}
            onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
          />
        </VTabs>
      ) : (
        <AttachmentGalleryTabContent
          category={category}
          computeAttachmentUrl={computeAttachmentUrl}
          folder={selectedFolder}
          tabAttachments={attachmentsPerFoldersRecord[selectedFolder.id]}
          $imageModal={$imageModal}
          isOnline={isOnline}
          loadAttachmentsActionCallback={loadAttachmentsActionCallback}
          objectId={objectId}
          $={$}
          removeToolkit={removeToolkit}
          uploadToolkit={uploadToolkit}
          thumbnailSize={thumbnailSize}
          displaySelectedFolderLabelAsTitle={displaySelectedFolderLabelAsTitle}
          selectMode={selectMode}
          onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
        />
      )}
      {uploadToolkit?.convertToPdfToolkit && (
        <PdfCreationAndUploadModal
          $={$.$pdfCreationAndUploadModal}
          $imageModal={$imageModal}
          isOnline={isOnline}
          loadAttachmentsActionCallback={
            uploadToolkit.convertToPdfToolkit.loadPdfAttachmentContentActionCallback
          }
          computeAttachmentUrl={computeAttachmentUrl}
          clientSpecificFolderId={uploadToolkit.convertToPdfToolkit.clientSpecificFolderId}
          removePdfPageHttpRequestAction={
            uploadToolkit.convertToPdfToolkit.removePdfPageHttpRequestAction
          }
          importAttachmentsActionCallback={uploadToolkit.importAttachmentsActionCallback}
          convertToPdfHttpRequestAction={
            uploadToolkit.convertToPdfToolkit.convertToPdfHttpRequestAction
          }
          postUploadAdditionalGlobalActionCallback={pdfUploadAdditionalActionCallback}
          selectMode={selectMode}
          onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
        />
      )}
    </>
  );
}

export interface OneFolderAttachmentsGalleryProps<SD extends BaseStoreDefWithHttpClient>
  extends Omit<
    AttachmentsGalleryProps<SD>,
    'folders' | 'displaySelectedFolderLabelAsTitle' | '$window' | 'isBoxTabs'
  > {
  readonly theFolder: AttachmentFolder;
  readonly selectMode?: 'multiple' | 'one';
  readonly onClickOnThumbnailBehavior?: 'openImageModal' | 'select';
}

export function OneFolderAttachmentsGallery<SD extends BaseStoreDefWithHttpClient>({
  category,
  objectId,
  $,
  removeToolkit,
  loadAttachmentsActionCallback,
  uploadToolkit,
  computeAttachmentUrl,
  $imageModal,
  isOnline,
  theFolder,
  thumbnailSize,
  selectMode,
  onClickOnThumbnailBehavior,
}: OneFolderAttachmentsGalleryProps<SD>): JSX.Element {
  const attachments = useGetState($.$attachments);

  const theAttachments = useMemo((): Attachment[] => {
    return attachments.filter((a) => a.folder === theFolder.id);
  }, [attachments, theFolder.id]);

  const pdfUploadAdditionalActionCallback = useActionCallback(
    async ({ actionDispatch }, foldersToRefresh: readonly string[], fileName: string) => {
      if (uploadToolkit?.convertToPdfToolkit?.onPdfUploadSuccessCallback) {
        await actionDispatch.execCallback(
          uploadToolkit?.convertToPdfToolkit?.onPdfUploadSuccessCallback,
          fileName
        );
      }
      await actionDispatch.execCallback(
        loadAttachmentsActionCallback,
        category,
        objectId,
        foldersToRefresh
      );
    },
    [
      category,
      loadAttachmentsActionCallback,
      objectId,
      uploadToolkit?.convertToPdfToolkit?.onPdfUploadSuccessCallback,
    ],
    $
  );

  return (
    <>
      <AttachmentGalleryTabContent
        category={category}
        computeAttachmentUrl={computeAttachmentUrl}
        folder={theFolder}
        tabAttachments={theAttachments}
        $imageModal={$imageModal}
        isOnline={isOnline}
        loadAttachmentsActionCallback={loadAttachmentsActionCallback}
        objectId={objectId}
        $={$}
        removeToolkit={removeToolkit}
        uploadToolkit={uploadToolkit}
        thumbnailSize={thumbnailSize}
        selectMode={selectMode}
        onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
      />
      {uploadToolkit?.convertToPdfToolkit && (
        <PdfCreationAndUploadModal
          $={$.$pdfCreationAndUploadModal}
          $imageModal={$imageModal}
          isOnline={isOnline}
          loadAttachmentsActionCallback={loadAttachmentsActionCallback}
          computeAttachmentUrl={computeAttachmentUrl}
          clientSpecificFolderId={uploadToolkit.convertToPdfToolkit.clientSpecificFolderId}
          removePdfPageHttpRequestAction={
            uploadToolkit.convertToPdfToolkit.removePdfPageHttpRequestAction
          }
          importAttachmentsActionCallback={uploadToolkit.importAttachmentsActionCallback}
          convertToPdfHttpRequestAction={
            uploadToolkit.convertToPdfToolkit.convertToPdfHttpRequestAction
          }
          postUploadAdditionalGlobalActionCallback={pdfUploadAdditionalActionCallback}
          selectMode={selectMode}
          onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
        />
      )}
    </>
  );
}

interface AttachmentsGalleryTabProps<SD extends BaseStoreDefWithHttpClient> {
  readonly category: StorageCategories;
  readonly objectId: string;
  readonly folder: AttachmentFolder;
  readonly tabAttachments: readonly Attachment[] | undefined;
  readonly $: StoreStateSelector<SD, AttachmentGalleryState>;
  readonly removeToolkit?: RemoveToolkitProps<SD>;
  readonly loadAttachmentsActionCallback: LoadAttachmentsActionCallback<SD>;
  readonly uploadToolkit?: UploadToolkitProps<SD>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly isOnline: boolean;
  readonly thumbnailSize?: number;
  readonly displaySelectedFolderLabelAsTitle?: boolean;
  readonly selectMode?: 'multiple' | 'one';
  readonly onClickOnThumbnailBehavior?: 'openImageModal' | 'select';
}

function AttachmentGalleryTabContent<SD extends BaseStoreDefWithHttpClient>({
  category,
  objectId,
  folder,
  tabAttachments,
  $,
  removeToolkit,
  loadAttachmentsActionCallback,
  uploadToolkit,
  computeAttachmentUrl,
  $imageModal,
  isOnline,
  thumbnailSize,
  displaySelectedFolderLabelAsTitle,
  selectMode,
  onClickOnThumbnailBehavior,
}: AttachmentsGalleryTabProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  const loadingStatus = useGetState($.$loadingStatus);
  const attachmentsMetadata = useGetState($.$attachmentsMetadata);

  const openPdfCreationAndUploadModalCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openPdfCreationAndUploadModal, category, folder, objectId);
    },
    [category, folder, objectId],
    $.$pdfCreationAndUploadModal
  );

  const selectedPathsIsDefined = useStateIsDefined($.$selectedPaths);

  return (
    <>
      {loadingStatus ? (
        <p>{loadingStatus}</p>
      ) : (
        <>
          {!isOnline && (
            <div className="has-text-centered">
              <p className="has-text-primary">{t('attachments.offline')}</p>
            </div>
          )}
          <div className="columns is-mobile is-multiline">
            {uploadToolkit && (
              <>
                <AttachmentUploadButton
                  importAttachmentsActionCallback={uploadToolkit.importAttachmentsActionCallback}
                  reloadAttachmentsActionCallback={loadAttachmentsActionCallback}
                  folder={folder}
                  category={category}
                  isOnline={isOnline}
                  objectId={objectId}
                  $={$}
                  buttonLabel={t('attachments.importButton')}
                  onButtonClickedCallback={uploadToolkit.onImportButtonClickedCallback}
                />
                <AttachmentsDownloadButton
                  $={$}
                  category={category}
                  objectId={objectId}
                  folder={folder}
                  isOnline={isOnline}
                />
                {uploadToolkit.convertToPdfToolkit && (
                  <div className="column is-narrow">
                    <Button
                      onClick={openPdfCreationAndUploadModalCallback}
                      additionalClass="is-primary is-small"
                      disabled={!isOnline}
                      size="small"
                      iconId="file-pdf"
                      label={t('attachments.pdfCreationAndUploadModal.openModalButton')}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div>
            {displaySelectedFolderLabelAsTitle && <p className="title is-5 mb-1">{folder.label}</p>}
            {tabAttachments && tabAttachments.length > 0 ? (
              <div className="columns is-mobile is-multiline">
                {tabAttachments.map((a, index): JSX.Element => {
                  const metadata = attachmentsMetadata[a.id];
                  return (
                    <div className="column is-narrow" key={`${a.folder}/${a.name}/${a.id}`}>
                      <GalleryItem
                        attachmentIndex={index}
                        tabAttachments={tabAttachments}
                        computeAttachmentUrl={computeAttachmentUrl}
                        category={category}
                        $imageModal={$imageModal}
                        objectId={objectId}
                        attachmentMetadatum={metadata}
                        removeToolkit={removeToolkit}
                        $selectedPaths={
                          selectedPathsIsDefined ? $.$selectedPaths.asDefined() : undefined
                        }
                        isOnline={isOnline}
                        thumbnailSize={thumbnailSize}
                        selectMode={selectMode}
                        onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="columns is-mobile is-multiline is-vcentered">
                <div className="column is-narrow pl-3">{t('attachments.gallery.empty')}</div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

interface GalleryItemProps<SD extends BaseStoreDefWithHttpClient> {
  readonly objectId: string;
  readonly tabAttachments: readonly Attachment[];
  readonly attachmentMetadatum: AttachmentMetadata | undefined;
  readonly attachmentIndex: number;
  readonly category: StorageCategories;
  readonly isOnline: boolean;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly removeToolkit?: RemoveToolkitProps<SD>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly $selectedPaths?: StoreStateSelector<SD, readonly string[]>;
  readonly thumbnailSize?: number;
  readonly selectMode?: 'multiple' | 'one';
  readonly onClickOnThumbnailBehavior?: 'openImageModal' | 'select';
}

function GalleryItem<SD extends BaseStoreDefWithHttpClient>({
  $selectedPaths,
  attachmentIndex,
  computeAttachmentUrl,
  attachmentMetadatum,
  tabAttachments,
  category,
  objectId,
  isOnline,
  $imageModal,
  removeToolkit,
  thumbnailSize,
  selectMode,
  onClickOnThumbnailBehavior,
}: GalleryItemProps<SD>): JSX.Element {
  const attachmentDesc = useMemo(() => {
    return tabAttachments[attachmentIndex];
  }, [attachmentIndex, tabAttachments]);

  const isDeletable = useMemo(() => {
    let isActive = isOnline;
    if (isTruthy(removeToolkit) && isTruthy(removeToolkit.showRemoveAction)) {
      isActive = isActive && removeToolkit.showRemoveAction(attachmentDesc, attachmentMetadatum);
    }
    return isActive;
  }, [isOnline, removeToolkit, attachmentDesc, attachmentMetadatum]);

  return (
    <div className="column is-narrow">
      <AttachmentThumbnail
        category={category}
        objectId={objectId}
        size={thumbnailSize ?? 128}
        $selectedPaths={$selectedPaths}
        onRemoveCallback={isDeletable ? removeToolkit?.onRemoveCallback : undefined}
        computeAttachmentUrl={computeAttachmentUrl}
        caption={attachmentDesc.name}
        attachments={tabAttachments}
        $imageModal={$imageModal}
        attachmentIndex={attachmentIndex}
        selectMode={selectMode}
        onClickBehavior={onClickOnThumbnailBehavior}
      />
    </div>
  );
}

interface AttachmentUploadButtonProps<
  SD extends BaseStoreDefWithHttpClient,
  S extends AttachmentGalleryState,
> {
  readonly objectId: string;
  readonly category: StorageCategories;
  readonly folder: AttachmentFolder;
  readonly isOnline: boolean;
  readonly importAttachmentsActionCallback?: ImportAttachmentsActionCallback<SD>;
  readonly reloadAttachmentsActionCallback: LoadAttachmentsActionCallback<SD>;
  readonly $: StoreStateSelector<SD, S>;
  readonly onButtonClickedCallback?: () => void | Promise<void>;
  readonly buttonLabel?: string;
}

function AttachmentUploadButton<
  SD extends BaseStoreDefWithHttpClient,
  S extends AttachmentGalleryState,
>({
  folder,
  $,
  importAttachmentsActionCallback,
  reloadAttachmentsActionCallback,
  category,
  objectId,
  isOnline,
  onButtonClickedCallback,
  buttonLabel,
}: AttachmentUploadButtonProps<SD, S>): JSX.Element {
  const importAttachmentsCallback = useActionCallback(
    async ({ actionDispatch }, files: readonly File[]): Promise<void> => {
      await actionDispatch.execCallback(
        nonnull(importAttachmentsActionCallback),
        category,
        objectId,
        folder.id,
        files,
        undefined
      );
      if (isTruthy(reloadAttachmentsActionCallback)) {
        await actionDispatch.execCallback(
          reloadAttachmentsActionCallback,
          category,
          objectId,
          [folder.id],
          true
        );
      }
    },
    [
      category,
      folder.id,
      importAttachmentsActionCallback,
      objectId,
      reloadAttachmentsActionCallback,
    ],
    $
  );

  const label = useMemo(() => {
    if (isTruthyAndNotEmpty(buttonLabel)) {
      return buttonLabel;
    }
    return folder.label ?? folder.id;
  }, [buttonLabel, folder.id, folder.label]);

  return (
    <div className="column is-narrow">
      <ImportButton
        onFilesReadyCallback={importAttachmentsCallback}
        label={label}
        additionalClass="is-primary"
        size="small"
        disabled={!isOnline}
        allowMultipleImport
        onButtonClickedCallback={onButtonClickedCallback}
      />
    </div>
  );
}

interface AttachmentsDownloadButtonProps<
  SD extends BaseStoreDefWithHttpClient,
  S extends AttachmentGalleryState,
> {
  readonly $: StoreStateSelector<SD, S>;
  readonly category: StorageCategories;
  readonly objectId: string;
  readonly folder: AttachmentFolder;
  readonly isOnline: boolean;
}

function AttachmentsDownloadButton<
  SD extends BaseStoreDefWithHttpClient,
  S extends AttachmentGalleryState,
>({ $, category, objectId, folder, isOnline }: AttachmentsDownloadButtonProps<SD, S>): JSX.Element {
  const [t] = useTranslation('libComponents');
  const downloadStandardPicturesActionCallback = useActionCallback(
    async ({ httpClient }): Promise<void> => {
      await downloadAndSave(
        httpClient,
        CoreBackendRoutes.ZIPPED_ATTACHMENT_FOLDER(category, objectId, folder.id),
        'attachments.zip'
      );
    },
    [category, objectId, folder],
    $
  );

  return (
    <div className="column is-narrow">
      <div className="file is-small">
        <button
          type="button"
          className="button is-primary is-small"
          onClick={downloadStandardPicturesActionCallback}
          disabled={!isOnline}
        >
          <span className="file-icon">
            <FaIcon id="download" tooltip={t('attachments.downloadButton')} size="small" />
          </span>
          <span className={`file-label is-size-${convertFaIconSizeToFontSize('small')}`}>
            {t('attachments.downloadButton')}
          </span>
        </button>
      </div>
    </div>
  );
}
