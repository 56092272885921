import React from 'react';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { iconHelpers } from '../../../../utils/index.js';

export type FaIconSize =
  | 'small'
  | 'standard'
  | 'standard-lg'
  | 'medium'
  | 'medium-lg'
  | 'medium-2x'
  | 'large'
  | 'large-lg'
  | 'large-2x'
  | 'large-3x'
  | 'small-xs';

export interface FaIconProps {
  /**
   *  id can be prefixed by the style (eg : "r/check-square")
   *  s = solid: r = regular; b = brands; etc.
   */
  readonly id: string;
  readonly tooltip?: string;
  readonly label?: string;
  readonly size?: FaIconSize | number;
  readonly additionalClass?: string;
  readonly hasBoldLabel?: boolean;
  readonly iconColor?: string;
  readonly backgroundColor?: string;
}

const FA_ICON_TO_FONT_SIZES_MAP: Record<string, number> = {
  small: 7,
  standard: 6,
  'standard-lg': 5,
  medium: 5,
  'medium-lg': 4,
  'medium-2x': 3,
  large: 5,
  'large-lg': 4,
  'large-2x': 3,
  'large-3x': 1,
};

export const convertFaIconSizeToFontSize = (size: FaIconSize | undefined): number => {
  return FA_ICON_TO_FONT_SIZES_MAP[!size ? 'standard' : size];
};

export function FaIcon({
  id,
  tooltip,
  label,
  size = 'standard',
  hasBoldLabel = false,
  additionalClass,
  iconColor,
  backgroundColor,
}: FaIconProps): JSX.Element {
  const containerSize = typeof size === 'string' ? size.replace(/-.*$/, '') : 'standard';

  const computeIconClassNameValues = (): string => {
    let className = 'icon';
    if (containerSize !== 'standard') {
      className += ` is-${containerSize}`;
    }
    if (additionalClass) {
      className += ` ${additionalClass}`;
    }
    return className;
  };

  const { style, icon } = iconHelpers.getFontAwesomeStyleAndIconFromIconId(id);

  const faSize =
    typeof size === 'string' && size.includes('-') ? size.replace(/^.*-/, '') : undefined;
  const fontSize = typeof size === 'string' ? convertFaIconSizeToFontSize(size) : undefined;

  const customStyle =
    typeof size === 'number'
      ? {
          width: size,
          fontSize: size,
          lineHeight: '1em',
        }
      : {};
  const customColorsStyle = {
    color: iconColor,
    background: backgroundColor,
  };
  return isTruthyAndNotEmpty(icon) ? (
    <>
      <span
        className={computeIconClassNameValues()}
        title={tooltip}
        style={{ ...customStyle, ...customColorsStyle }}
      >
        <i
          className={`fa${style === 'brands' ? `-` : ''}${style} fa-${icon}${faSize ? ` fa-${faSize}` : ''}`}
        />
      </span>
      {label && (
        <span
          className={`${hasBoldLabel ? ' has-text-weight-bold' : ''}${
            fontSize ? ` is-size-${fontSize}` : ''
          } `}
          title={tooltip}
          style={{ ...customStyle, ...customColorsStyle }}
        >
          {label}
        </span>
      )}
    </>
  ) : (
    <></>
  );
}

type FaIconCallerProps = Omit<FaIconProps, 'id'>;

export function FasUsersIcon({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="users" tooltip={tooltip} label={label} {...props} />;
}

export function FasHomeIcon({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="home" tooltip={tooltip} label={label} {...props} />;
}

export function FasAsteriskIcon({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="asterisk" tooltip={tooltip} label={label} {...props} />;
}

export function FasPlusIcon({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="plus" tooltip={tooltip} label={label} {...props} />;
}

export function FasEditIcon({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="edit" tooltip={tooltip} label={label} {...props} />;
}

export function FasPackageDealIcon({
  tooltip = '',
  label,
  ...props
}: FaIconCallerProps): JSX.Element {
  return <FaIcon id="archive" tooltip={tooltip} label={label} {...props} />;
}

export function FasCloseIcon({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="times" tooltip={tooltip} label={label} {...props} />;
}

export function FasPulseSpinner({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="pulse fa-spinner" tooltip={tooltip} label={label} {...props} />;
}

export function FarCheckSquare({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="r/check-square" tooltip={tooltip} label={label} {...props} />;
}

export function FarCheckCircle({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="r/check-circle" tooltip={tooltip} label={label} {...props} />;
}

export function FasUnlink({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="unlink" tooltip={tooltip} label={label} {...props} />;
}

export function FasShareSquare({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="share-square" tooltip={tooltip} label={label} {...props} />;
}

export function FasFold({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="chevron-up" tooltip={tooltip} label={label} {...props} />;
}

export function FasUnfold({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FaIcon id="chevron-down" tooltip={tooltip} label={label} {...props} />;
}

export function FasUp({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FasFold tooltip={tooltip} label={label} {...props} />;
}

export function FasDown({ tooltip = '', label, ...props }: FaIconCallerProps): JSX.Element {
  return <FasUnfold tooltip={tooltip} label={label} {...props} />;
}

type FasFoldUnfoldProps = Omit<FaIconCallerProps, 'id' | 'additionalClass'>;

export function FasFoldGrey({ tooltip = '', ...props }: FasFoldUnfoldProps): JSX.Element {
  return (
    <FaIcon id="chevron-up" tooltip={tooltip} additionalClass="has-text-grey-light" {...props} />
  );
}

export function FasUnfoldGrey({ tooltip = '', ...props }: FasFoldUnfoldProps): JSX.Element {
  return (
    <FaIcon id="chevron-down" tooltip={tooltip} additionalClass="has-text-grey-light" {...props} />
  );
}
