import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  MKTP_EQUIPMENT_CATEGORY_COMFORT,
  MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA,
  MKTP_EQUIPMENT_CATEGORY_OTHERS,
  MKTP_EQUIPMENT_CATEGORY_SECURITY,
} from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';

interface MarketplaceEquipmentByCategoryProps {
  readonly category: string;
  readonly equipments: readonly string[];
}

export function MarketplaceEquipmentByCategory({
  category,
  equipments,
}: MarketplaceEquipmentByCategoryProps): JSX.Element {
  const [t] = useTranslation('details');
  return (
    <>
      <div className="title is-6 m-2">{t(`tabs.marketplace.equipments.${category}`)}</div>
      <div className="fixed-grid has-2-cols m-2">
        <div className="grid is-gap-0">
          {equipments.map((value) => (
            <div key={`${category}-${value}`}>{value}</div>
          ))}
        </div>
      </div>
    </>
  );
}

interface MarketplaceEquipmentsPreviewProps {
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceEquipmentsPreview({
  $selectedKanban,
}: MarketplaceEquipmentsPreviewProps): JSX.Element {
  const equipmentsByCategory = useGetState($selectedKanban.$marketplaceInfos.$equipments);
  return (
    <>
      <MarketplaceEquipmentByCategory
        category={MKTP_EQUIPMENT_CATEGORY_SECURITY}
        equipments={equipmentsByCategory[MKTP_EQUIPMENT_CATEGORY_SECURITY] ?? []}
      />
      <MarketplaceEquipmentByCategory
        category={MKTP_EQUIPMENT_CATEGORY_COMFORT}
        equipments={equipmentsByCategory[MKTP_EQUIPMENT_CATEGORY_COMFORT] ?? []}
      />
      <MarketplaceEquipmentByCategory
        category={MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA}
        equipments={equipmentsByCategory[MKTP_EQUIPMENT_CATEGORY_MULTIMEDIA] ?? []}
      />
      <MarketplaceEquipmentByCategory
        category={MKTP_EQUIPMENT_CATEGORY_OTHERS}
        equipments={equipmentsByCategory[MKTP_EQUIPMENT_CATEGORY_OTHERS] ?? []}
      />
    </>
  );
}
