import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { ModalCardDialogProps } from '@stimcar/libs-uitoolkit';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { CalendarInputProps } from '../bulma/form/CalendarInput.js';
import { CalendarInput } from '../bulma/form/CalendarInput.js';

export interface CalendarModalDialogProps<SD extends AnyStoreDef>
  extends Omit<ModalCardDialogProps<SD>, 'onClose' | 'children'>,
    CalendarInputProps<SD, number> {}

export function CalendarModalDialog<SD extends AnyStoreDef>({
  $active,
  title,
  titleIconId,
  onOkClicked,
  onCancelClicked,
  okLabel,
  cancelLabel,
  warning,
  isReadonly = false,
  zIndex,
  preventModalBodyScrolling = false,
  size,
  $,
  datePickerType,
  showFooter,
  weekStart,
  color,
  lang,
  dateFormat,
  minDate,
  maxDate,
}: CalendarModalDialogProps<SD>): JSX.Element {
  return (
    <ModalCardDialog
      title={title}
      $active={$active}
      okLabel={okLabel}
      onOkClicked={onOkClicked}
      warning={warning}
      titleIconId={titleIconId}
      cancelLabel={cancelLabel}
      isReadonly={isReadonly}
      zIndex={zIndex}
      preventModalBodyScrolling={preventModalBodyScrolling}
      size={size}
      onCancelClicked={onCancelClicked}
    >
      <div className="columns">
        <div className="column" />
        <div className="column is-narrow">
          <CalendarInput
            $={$}
            datePickerType={datePickerType}
            showFooter={showFooter}
            weekStart={weekStart}
            color={color}
            lang={lang}
            dateFormat={dateFormat}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <div className="column" />
      </div>
    </ModalCardDialog>
  );
}
