import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDealVariableBaseType, SparePartDesc } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { CheckFormConsistencyAction } from '@stimcar/libs-uitoolkit';
import {
  EMPTY_SPARE_PART_DESC,
  enumerate,
  forEachRecordValues,
  nonDeleted,
  PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FormField, Input, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { EditSparePartDescModalState, SparePartDescFormData } from '../typings/store.js';
import { EDIT_SPARE_PART_DESC_MODAL_EMPTY_STATE } from '../typings/store.js';

function convertSparePartDescFormDataToSparePartDesc(
  formData: SparePartDescFormData
): SparePartDesc {
  return {
    ...EMPTY_SPARE_PART_DESC,
    id: formData.id,
    label: isTruthyAndNotEmpty(formData.label) ? formData.label : '',
  };
}

function saveSparePartDescAction({
  getState,
  actionDispatch,
}: ActionContext<Store, EditSparePartDescModalState>): void {
  const { initialSparePartDesc, formData, sparePartDescs } = getState();
  let newSparePartsDescs = sparePartDescs.slice(0);
  if (initialSparePartDesc) {
    // Update
    newSparePartsDescs = newSparePartsDescs.map((sp) => {
      if (sp.id === formData.id) {
        return convertSparePartDescFormDataToSparePartDesc(formData);
      }
      return sp;
    });
  } else {
    // Create
    // If a deleted part with the same label exists, we reuse it
    const newSPD = convertSparePartDescFormDataToSparePartDesc(formData);
    const existingDeletedSPD = newSparePartsDescs.find(
      (spd) => !nonDeleted(spd) && spd.label === newSPD.label
    );
    if (existingDeletedSPD !== undefined) {
      // We replace the deleted existing part
      newSparePartsDescs = newSparePartsDescs.map((spd) => {
        if (spd.id === existingDeletedSPD.id) {
          return newSPD;
        }
        return spd;
      });
    } else {
      // else we just push it to the list
      newSparePartsDescs.push(newSPD);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return {
      ...EDIT_SPARE_PART_DESC_MODAL_EMPTY_STATE,
      sparePartDescs: newSparePartsDescs,
    };
  });
}

const mandatoryFields: (keyof SparePartDescFormData)[] = ['label'];

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditSparePartDescModalState
> = ({ formState, t }): string | undefined => {
  const { initialSparePartDesc, sparePartDescs, formData } = formState;
  // First we check if the label is not already used
  const labelAlreadyUsed = sparePartDescs
    .filter((spd) => nonDeleted(spd))
    .some(
      (spd) =>
        spd.label === formData.label &&
        (initialSparePartDesc === undefined || initialSparePartDesc.id !== spd.id)
    );
  if (labelAlreadyUsed) {
    return t('sparePartDescModal.form.warnings.labelAlreadyUsed');
  }

  return undefined;
};

interface EditSparePartDescModalProps<T extends PackageDealVariableBaseType> {
  readonly $: StoreStateSelector<Store, EditSparePartDescModalState>;
  readonly localVariables: Record<string, T | null>;
}

export function EditSparePartDescModal<T extends PackageDealVariableBaseType>({
  $,
  localVariables,
}: EditSparePartDescModalProps<T>): JSX.Element {
  const [t] = useTranslation('packageDealDescs');
  const formWarning = useGetState($.$formWarning);
  const initialSparePartDesc = useGetState($.$initialSparePartDesc);
  const isCreateMode = initialSparePartDesc === undefined;
  const submitValidDataAction = useActionCallback(saveSparePartDescAction, [], $);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditSparePartDescModalState
  >({
    $,
    mandatoryFields,
    submitValidDataAction,
    checkFormConsistencyAction,
    t,
  });

  const availableInternalVariableNames = useMemo(() => {
    const variableNames: string[] = [];
    forEachRecordValues(localVariables, (value, key) => {
      if (value !== null) {
        variableNames.push(key);
      }
    });
    return variableNames;
  }, [localVariables]);

  return (
    <ModalCardDialog
      title={isCreateMode ? t('sparePartDescModal.titleNew') : t('sparePartDescModal.titleUpdate')}
      $active={$.$active}
      okLabel={
        isCreateMode
          ? t('sparePartDescModal.form.newButton')
          : t('sparePartDescModal.form.updateButton')
      }
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <FormField label={t('sparePartDescModal.form.label')} horizontal>
        <Input
          $={$formDataWithChangeTrigger.$label}
          placeholder={t('sparePartDescModal.form.label')}
        />
        {availableInternalVariableNames.length > 0 && (
          <p className="help">
            {t('operationDescModal.form.availableVariables', {
              value: enumerate(
                availableInternalVariableNames,
                ', ',
                PACKAGE_DEAL_DESC_TEMPLATE_LABEL_SUFFIX_AND_PREFIX
              ),
            })}
          </p>
        )}
      </FormField>
    </ModalCardDialog>
  );
}
