import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { CheckboxProps } from './Checkbox.js';
import { Checkbox } from './Checkbox.js';

export type CheckboxFormFieldProps<SD extends AnyStoreDef> = CheckboxProps<SD> &
  Omit<FormFieldProps, 'children' | 'warning' | 'noExclamationTriangleIfWarning'>;

export function CheckboxFormField<SD extends AnyStoreDef>({
  label,
  $,
  horizontal,
  ...props
}: CheckboxFormFieldProps<SD>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      noExclamationTriangleIfWarning
      horizontal={horizontal}
    >
      <Checkbox $={$} {...props} />
    </FormField>
  );
}
