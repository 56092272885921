import React from 'react';
import type { AnyStoreDef } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { CalendarInputProps } from './CalendarInput.js';
import { CalendarInput } from './CalendarInput.js';

export type CalendarFormFieldProps<
  SD extends AnyStoreDef,
  T extends number | string,
> = CalendarInputProps<SD, T> & Omit<FormFieldProps, 'children' | 'warning'>;

export function CalendarFormField<SD extends AnyStoreDef, T extends number | string>({
  label,
  $,
  noExclamationTriangleIfWarning,
  className,
  horizontal,
  ...props
}: CalendarFormFieldProps<SD, T>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  // It's not possible to add the is-info class if there is a dispatch
  // warning, the style is not compatible with bulma-calendar
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
    >
      <CalendarInput<SD, T>
        className={`input${className ? ` ${className}` : ''}`}
        $={$}
        {...props}
      />
    </FormField>
  );
}
