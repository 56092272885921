import type { ReactElement } from 'react';
import React from 'react';

interface TableProps {
  readonly children: ReactElement;
  readonly className?: string;
}

export function TruncableTable({ className, children }: TableProps): JSX.Element {
  return (
    <table
      className={className}
      style={{ width: '100%', tableLayout: 'fixed', whiteSpace: 'nowrap' }}
    >
      {children}
    </table>
  );
}

export interface ThTdProps {
  readonly children: string | number | JSX.Element;
  readonly tooltip?: string;
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly colspan?: number;
}

function getTooltipValue({ children, tooltip }: ThTdProps): string {
  if (tooltip) {
    return tooltip;
  }
  if (typeof children === 'number') {
    return String(children);
  }
  if (typeof children === 'string') {
    return children;
  }
  return '';
}

function extendedStyle(style: React.CSSProperties | undefined): React.CSSProperties {
  return {
    ...style,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
}

export function TruncableTableTd(props: ThTdProps): JSX.Element {
  const { className, colspan = 1, children, style } = props;

  return (
    <td
      title={getTooltipValue(props)}
      className={className}
      colSpan={colspan}
      style={extendedStyle(style)}
    >
      {children}
    </td>
  );
}

export function TruncableTableTh(props: ThTdProps): JSX.Element {
  const { className, colspan = 1, children, style } = props;
  return (
    <th
      title={getTooltipValue(props)}
      className={className}
      colSpan={colspan}
      style={extendedStyle(style)}
    >
      {children}
    </th>
  );
}
