import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { SparePart } from '@stimcar/libs-base';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type {
  EditSparePartCommentModalState,
  OperatorSparePartState,
} from '../../../typings/store.js';
import { TextAreaFormField } from '../../../../../lib/bulma/form/TextAreaFormField.js';
import { EMPTY_EDIT_SPARE_PART_COMMENT_MODAL_STATE } from '../../../typings/store.js';

export function openEditSparePartCommentModalAction(
  { actionDispatch }: ActionContext<Store, OperatorSparePartState>,
  sparePart: SparePart
): void {
  actionDispatch.applyPayload({
    editSparePartsCommentModal: {
      ...EMPTY_EDIT_SPARE_PART_COMMENT_MODAL_STATE,
      active: true,
      sparePartId: sparePart.id,
      sparePartLabel: sparePart.label,
      formData: {
        ...EMPTY_EDIT_SPARE_PART_COMMENT_MODAL_STATE.formData,
        comment: sparePart.comment,
        commentForWorkshop: sparePart.commentForWorkshop,
      },
    },
  });
}

interface EditCommentModalProps {
  readonly $: StoreStateSelector<Store, EditSparePartCommentModalState>;
  readonly submitValidDataAction: NoArgActionCallback<Store>;
}

export function EditSparePartCommentModal({
  $,
  submitValidDataAction,
}: EditCommentModalProps): JSX.Element {
  const [t] = useTranslation('operators');

  const sparePartLabel = useGetState($.$sparePartLabel);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditSparePartCommentModalState
  >({
    $,
    mandatoryFields: [],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  const formWarning = useGetState($.$formWarning);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.keyCode === 13) {
        e.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onFormSubmit();
      }
    },
    [onFormSubmit]
  );

  return (
    <ModalCardDialog
      title={t('generalView.spareParts.editCommentModal.title', { label: sparePartLabel })}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <TextAreaFormField
        label={t('generalView.spareParts.editCommentModal.comment')}
        $={$formDataWithChangeTrigger.$comment}
        rows={3}
        onKeyDown={onKeyDown}
      />
      <TextAreaFormField
        label={t('generalView.spareParts.editCommentModal.commentForWorkshop')}
        $={$formDataWithChangeTrigger.$commentForWorkshop}
        rows={3}
        onKeyDown={onKeyDown}
      />
    </ModalCardDialog>
  );
}
