import React from 'react';

interface Props {
  readonly value: string | undefined;
}

export function MultilineValueTableCellContent({ value }: Props): JSX.Element {
  return (
    <>
      {(value ?? '').split('\n').map(
        (line, j): JSX.Element => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={j}>
            {line}
            <br />
          </React.Fragment>
        )
      )}
    </>
  );
}
