import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type {
  EditAnomalyMessageModalState,
  SendKanbanToAnomalyValidationData,
} from '../typings/store.js';
import { useIsWorkshopLogin } from '../../../../app/utils/useIsWorkshopLogin.js';
import { VirtualKeyboardInputFormField } from '../../input/VirtualKeyboard.js';
import { EDIT_ANOMALY_MESSAGE_MODAL_STATE } from '../typings/store.js';

async function editAnomalyMessageValidationAction({
  getState,
  httpClient,
  actionDispatch,
  kanbanRepository,
}: ActionContext<Store, EditAnomalyMessageModalState>): Promise<void> {
  const { kanban, user, formData, standId, qualifiedPostId } = getState();
  const { reason } = formData;

  if (!isTruthyAndNotEmpty(standId) || !isTruthyAndNotEmpty(qualifiedPostId)) {
    throw Error(`The stand (${standId}) and origin post (${qualifiedPostId}) must be provided`);
  }

  if (isTruthy(kanban)) {
    const kanbanInAnomaly = kanbanHelpers.openAnomalyInterval(
      httpClient.getBrowserSequence(),
      kanban,
      [user],
      reason,
      qualifiedPostId,
      standId
    );
    await kanbanRepository.updateEntity(kanbanInAnomaly);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial) => {
    return EDIT_ANOMALY_MESSAGE_MODAL_STATE;
  });
}

const mandatoryFields: (keyof SendKanbanToAnomalyValidationData)[] = ['reason'];

interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, EditAnomalyMessageModalState>;
}

export function EditAnomalyMessageModal({ $, $gs }: Props): JSX.Element {
  const [t] = useTranslation('workshop');
  const isWorkshopLogin = useIsWorkshopLogin($gs);

  const closeModalCallback = useActionCallback(
    ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return EDIT_ANOMALY_MESSAGE_MODAL_STATE;
      });
    },
    [],
    $
  );

  const submitValidDataAction = useActionCallback(editAnomalyMessageValidationAction, [], $);
  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditAnomalyMessageModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      title={t('implantation.editAnomalyMessage.title')}
      $active={$.$active}
      onOkClicked={onFormSubmit}
      onCancelClicked={closeModalCallback}
      okLabel={t('implantation.editAnomalyMessage.okLabel')}
    >
      {isWorkshopLogin ? (
        <VirtualKeyboardInputFormField
          $={$formDataWithChangeTrigger.$reason}
          label={t('implantation.editAnomalyMessage.reasonInput')}
          preSelectContent
          horizontal
        />
      ) : (
        <InputFormField
          label={t('implantation.editAnomalyMessage.reasonInput')}
          $={$formDataWithChangeTrigger.$reason}
          horizontal
          preSelectContent
        />
      )}
    </ModalCardDialog>
  );
}
