import React from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  readonly giveWay: boolean;
  readonly redirectTo: string;
  readonly children: JSX.Element;
}

export function ProtectedRoute({ giveWay, children, redirectTo }: Props): JSX.Element {
  return giveWay ? children : <Navigate to={redirectTo} />;
}
