import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { userHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import type { LoginModalDialogState } from '../lib/components/login/typings/store.js';
import { DropDownMenu } from '../lib/components/DropDownMenu.js';
import { LoginModalDialog } from '../lib/components/login/LoginModalDialog.js';
import type { SubcontractorSessionState, SubcontractorStore } from './state/typings/store.js';
import { loginAction } from './authentication/utils.js';
import { SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH } from './subcontractorConstants.js';

async function logoutAction({
  httpClient,
  actionDispatch,
}: ActionContext<SubcontractorStore, SubcontractorSessionState>) {
  await httpClient.logout();
  actionDispatch.setProperty('user', undefined);
}

const cancelLoginAndCloseLoginDialogAction = async ({
  globalActionDispatch,
  getGlobalState,
}: ActionContext<SubcontractorStore, LoginModalDialogState>): Promise<void> => {
  globalActionDispatch.scopeProperty('loginView').setProperty('loginModalActive', false);
  if (getGlobalState().session.user !== undefined) {
    await globalActionDispatch.scopeProperty('session').exec(logoutAction);
  }
};

export function SubcontractorNavBar({ $gs }: AppProps<SubcontractorStore>): JSX.Element {
  const [t] = useTranslation(['refitit', 'globals']);
  const { $navbar: $, $session, $loginView } = $gs;

  const burgerIsActive = useGetState($.$burgerIsActive);

  const toggleBurger = useSetCallback($.$burgerIsActive, !burgerIsActive);

  const logoutCallback = useActionCallback(logoutAction, [], $gs.$session);

  const user = useGetState($session.$user);

  const loginActionCallback = useActionCallback(loginAction, [], $gs.$loginView);
  const cancelLoginAndCloseLoginDialogActionCallback = useActionCallback(
    cancelLoginAndCloseLoginDialogAction,
    [],
    $gs.$loginView
  );

  /* eslint-disable jsx-a11y/interactive-supports-focus */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <>
      <nav className="navbar is-primary is-fixed-top">
        <div className="navbar-brand">
          <NavLink className="navbar-item" to={SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH}>
            <img src="img/logo.png" alt="stimcar" />
          </NavLink>
          <a
            role="button"
            className={`navbar-burger burger${burgerIsActive ? ' is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={toggleBurger}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div id="mainNavbar" className={`navbar-menu ${burgerIsActive ? ' is-active' : ''}`}>
          <div className="navbar-end">
            <DropDownMenu
              $active={$.$userMenuIsActive}
              title={userHelpers.getDisplayName(nonnull(user))}
              iconId="user"
              iconTooltip={t('tooltips.user')}
            >
              <a role="button" className="navbar-item" onClick={logoutCallback}>
                {t('user.logout')}
              </a>
            </DropDownMenu>
          </div>
        </div>
      </nav>
      {useGetState($loginView.$loginModalActive) && (
        <LoginModalDialog
          isWorkshopLogin={false}
          $={$gs.$loginView}
          loginActionCallback={loginActionCallback}
          cancelLoginActionCallback={cancelLoginAndCloseLoginDialogActionCallback}
        />
      )}
    </>
  );
}
