import React, { useEffect } from 'react';
import type { StoreDef, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { useArgs } from '@storybook/preview-api';
import type { StoreConnector } from './storybookStoreStates.js';
import { createStorybookStore } from './storybookStoreStates.js';

type FormFieldValueType = string | number | boolean | undefined | null | readonly string[];

type BaseStoreState<T extends FormFieldValueType> = {
  readonly value: T;
};

export type FieldStoreDef<T extends FormFieldValueType> = StoreDef<BaseStoreState<T>, object>;

type WarningContainer = {
  readonly warning: string;
};

export type FormFieldStoreDef<T extends FormFieldValueType> = StoreDef<
  WithFormValidationWarnings<BaseStoreState<T>>,
  object
>;

/**
 * Creates a store adapted to simple field storybooks (not form fields).
 * @param initialValue the initial state value.
 */
export function createFieldStorybookStore<TCmpOrArgs, T extends FormFieldValueType>(
  initialValue: T
): StoreConnector<TCmpOrArgs, FieldStoreDef<T>> {
  return createStorybookStore<TCmpOrArgs, BaseStoreState<T>>({
    value: initialValue,
  });
}

/**
 * Adds a warning argument in storybook arguments and links it the the store warning.
 * @param initialValue the initial state value.
 */
export function createFormFieldStorybookStore<TCmpOrArgs, T extends FormFieldValueType>(
  initialValue: T
): StoreConnector<TCmpOrArgs, FormFieldStoreDef<T>> {
  const store = createStorybookStore<TCmpOrArgs, WithFormValidationWarnings<BaseStoreState<T>>>({
    value: initialValue,
    warnings: {},
  });

  const CopyWarningArgToStoreDecorator = (Story: React.ComponentType): JSX.Element => {
    const [args] = useArgs<WarningContainer>();
    const updateWarningActionCallback = useActionCallback(
      ({ actionDispatch }, warning: string) => {
        actionDispatch.scopeProperty('warnings').setProperty('value', warning);
      },
      [],
      store.$
    );
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateWarningActionCallback(args.warning);
    }, [args.warning, updateWarningActionCallback]);

    // Drop selector and state fields
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return <Story />;
  };

  return {
    $: store.$,
    connectArgs: (input) => ({ ...store.connectArgs(input), warning: '' }),
    connectArgTypes: (input) => ({ ...store.connectArgTypes(input), warning: { control: 'text' } }),
    connectStory: (input) => {
      const storyObj = store.connectStory(input);
      return {
        ...input,
        decorators: Array.isArray(storyObj.decorators)
          ? [...storyObj.decorators, CopyWarningArgToStoreDecorator]
          : [storyObj.decorators, CopyWarningArgToStoreDecorator],
      };
    },
  };
}
