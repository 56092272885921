import React from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  EMPTY_FORM_WITH_VALIDATION_STATE,
  InputFormField,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { PurchaseOrderAddEditModalState, PurchaseOrderFormData } from './typings/store.js';
import { getExistingPurchaseOrder } from './invoicingUtils.js';

export function openPurchaseOrderAddEditModalAction({
  actionDispatch,
}: ActionContext<Store, PurchaseOrderAddEditModalState>): void {
  actionDispatch.setValue({
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    isActive: true,
    purchaseOrderId: '',
    formData: {
      label: '',
      purchaseNumber: '',
      warnings: {},
    },
  });
}

export async function openPurchaseOrderEditModalAction(
  { actionDispatch, kanbanRepository }: ActionContext<Store, PurchaseOrderAddEditModalState>,
  kanbanId: string,
  purchaseOrderId: string
): Promise<void> {
  const currentPurchaseOrder = await getExistingPurchaseOrder(
    kanbanRepository,
    kanbanId,
    purchaseOrderId
  );

  actionDispatch.setValue({
    ...EMPTY_FORM_WITH_VALIDATION_STATE,
    isActive: true,
    purchaseOrderId: currentPurchaseOrder.id,
    formData: {
      label: currentPurchaseOrder.label ?? '',
      purchaseNumber: currentPurchaseOrder.purchaseNumber,
      warnings: {},
    },
  });
}

type PurchaseOrderAddEditModalProps = {
  readonly $: StoreStateSelector<Store, PurchaseOrderAddEditModalState>;
  readonly title: string;
  readonly onFormSubmitCallback: NoArgActionCallback<Store>;
  readonly kanbanId: string;
};

export function PurchaseOrderAddEditModal({
  $,
  title,
  onFormSubmitCallback,
}: PurchaseOrderAddEditModalProps): JSX.Element {
  const [t] = useTranslation('details');
  const formWarning = useGetState($.$formWarning);

  const submitPurchaseOrderActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.execCallback(onFormSubmitCallback);
    },
    [onFormSubmitCallback],
    $
  );

  const MANDATORY_FIELDS: readonly (keyof PurchaseOrderFormData)[] = ['purchaseNumber'];
  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation({
    $,
    mandatoryFields: MANDATORY_FIELDS,
    submitValidDataAction: submitPurchaseOrderActionCallback,
    t,
  });

  return (
    <ModalCardDialog
      title={title}
      $active={$.$isActive}
      okLabel={t('tabs.invoice.purchaseOrderAddEditModal.okButtonLabel')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t('tabs.invoice.purchaseOrderAddEditModal.purchaseNumber')}
        $={$formWithChangeTrigger.$purchaseNumber}
      />
      <InputFormField
        label={t('tabs.invoice.purchaseOrderAddEditModal.label')}
        $={$formWithChangeTrigger.$label}
      />
    </ModalCardDialog>
  );
}
