import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { ButtonProps } from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, urlHelpers } from '@stimcar/libs-base';
import { Logger, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import type { ShareKanbanModalState } from './typings/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

type ShareKanbanProps = Pick<
  ButtonProps,
  'isFullWidth' | 'size' | 'additionalClass' | 'disabled'
> & {
  readonly kanbanId: string;
  readonly $: StoreStateSelector<Store, ShareKanbanModalState>;
};

// eslint-disable-next-line @typescript-eslint/require-await
async function shareKanbanAction(
  { httpClient, actionDispatch }: ActionContext<Store, ShareKanbanModalState>,
  kanbanId: string
): Promise<void> {
  const shareId = await httpClient.httpGetAsText(
    CoreBackendRoutes.NEW_SHARE_ID('kanban', kanbanId)
  );
  actionDispatch.setProperty('shareId', shareId);
  actionDispatch.setProperty('active', true);
}

export function ShareKanbanButton({ kanbanId, $, ...props }: ShareKanbanProps): JSX.Element {
  const [t] = useTranslation('refitit');
  const onClickHandler = useActionCallback(
    async ({ actionDispatch }): Promise<void> => actionDispatch.exec(shareKanbanAction, kanbanId),
    [kanbanId],
    $
  );
  const shareId = useGetState($.$shareId);
  const { protocol, host } = document.location;
  const url = urlHelpers.computeShareUrl(`${protocol}//${host}`, shareId);

  const urlInputRef = useRef<HTMLInputElement>(null);

  const copyUrlToClipboard = useCallback((): void => {
    // Copy through a trick using a text input
    const input = nonnull(urlInputRef.current);
    try {
      input.disabled = false;
      input.focus();
      input.select();
      const result = document.execCommand('copy');
      if (!result) {
        log.error('Failed to copy text.');
      }
    } finally {
      input.selectionEnd = 0;
      input.disabled = true;
    }
  }, []);

  const hiddenLinkRef = useRef<HTMLAnchorElement>(null);

  const openUrlInNewTabCallback = useCallback((): void => {
    nonnull(hiddenLinkRef.current).click();
  }, []);

  return (
    <>
      <Button iconId="share-alt" label={t('share.button')} onClick={onClickHandler} {...props} />
      <ModalCardDialog titleIconId="share-alt" title={t('share.button')} $active={$.$active}>
        <p>{`${t('share.dialogText')} :`}</p>
        <div className="columns">
          <div className="column">
            <input ref={urlInputRef} className="input" type="text" value={url} readOnly disabled />
          </div>
          <div className="column is-narrow">
            <Button
              iconId="copy"
              onClick={copyUrlToClipboard}
              tooltip={t('share.copyUrlToClipboard')}
            />
          </div>
          <div className="column is-narrow">
            <Button
              iconId="external-link-alt"
              onClick={openUrlInNewTabCallback}
              tooltip={t('share.openUrlInNewTab')}
            />
          </div>
        </div>
        <a
          ref={hiddenLinkRef}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'none' }}
        >
          Hidden
        </a>
      </ModalCardDialog>
    </>
  );
}
