import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Label } from './Label.js';

export interface CheckboxProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, boolean>;
  readonly text?: string;
  readonly disabled?: boolean;
  readonly tooltip?: string;
  readonly className?: string;
  readonly style?: React.CSSProperties;
}

export function Checkbox<SD extends AnyStoreDef>({
  text,
  disabled = false,
  $,
  tooltip,
  className,
  style,
}: CheckboxProps<SD>): JSX.Element {
  const isChecked = useGetState($);
  const onClickActionCallback = useActionCallback(
    function onClickAction({ actionDispatch }, e: React.ChangeEvent<HTMLInputElement>) {
      actionDispatch.setValue(e.target.checked);
    },
    [],
    $
  );

  return (
    <Label disabled={disabled} tooltip={tooltip} className="checkbox">
      <input
        type="checkbox"
        disabled={disabled !== undefined ? disabled : false}
        className={`m-r-sm${className ? ` ${className}` : ''}`}
        onChange={onClickActionCallback}
        checked={isChecked}
        title={tooltip}
        style={style}
      />
      {text}
    </Label>
  );
}
