import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ButtonProps } from '@stimcar/libs-uitoolkit';
import { Button } from '@stimcar/libs-uitoolkit';
import type { PrintableDocumentViewProps } from '../DocumentGenerationCommonComponents.js';
import { PrintableDocumentView } from '../DocumentGenerationCommonComponents.js';
import type { DeliveryComponentProps } from './DeliveryComponent.js';
import { DeliveryComponent } from './DeliveryComponent.js';

type Props = DeliveryComponentProps & Omit<PrintableDocumentViewProps, 'children'>;

export function DeliveryView({
  returnAction,
  kanban,
  attributeDescs,
  address,
  companyName,
  hidePrintAction,
  bottomPlaceholderSize,
  toolbarContributions,
  logoUrl,
  user,
}: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  return (
    <PrintableDocumentView
      printDocumentTitle={t('documentGeneration.delivery.documentTitle', {
        contract: kanban.contract.code,
        license: kanban.infos.license,
      })}
      kanban={kanban}
      returnAction={returnAction}
      companyName={companyName}
      address={address}
      bottomPlaceholderSize={bottomPlaceholderSize}
      toolbarContributions={toolbarContributions}
      logoUrl={logoUrl}
      hidePrintAction={hidePrintAction}
    >
      <DeliveryComponent kanban={kanban} attributeDescs={attributeDescs} user={user} />
    </PrintableDocumentView>
  );
}

type DeliveryButtonProps = Pick<
  ButtonProps,
  'isFullWidth' | 'onClick' | 'size' | 'additionalClass' | 'disabled'
>;

export function ShowDeliveryButton({ disabled, ...props }: DeliveryButtonProps): JSX.Element {
  const [t] = useTranslation('libComponents');
  return (
    <Button
      label={t('documentGeneration.delivery.actions.show')}
      tooltip={
        disabled
          ? t('documentGeneration.delivery.notAllPackageDealsValidatedMessage')
          : t('documentGeneration.delivery.actions.showTooltip')
      }
      iconId="file-signature"
      disabled={disabled}
      {...props}
    />
  );
}
