import { type TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  CarViewCategory,
  Kanban,
  Memo,
  MemoDesc,
  PackageDeal,
  PartialRecord,
  StorageCategories,
  UIContract,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  CAR_VIEW_CATEGORIES,
  carElementHelpers,
  CoreBackendRoutes,
  EMPTY_CONTRACT_CONFIGURATION,
  filterReject,
  forEachRecordValues,
  mapRecordValues,
  nonDeleted,
  packageDealDescHelpers,
  packageDealHelpers,
  sortingHelpers,
  TC_PACKAGE_DEAL_CODE,
  transverseHelpers,
  UPSTREAM_TC_STAND_ID,
  URL_LIST_ELEMENTS_SEPARATOR,
  WORK_SHEET_ATTRIBUTE_NAME,
} from '@stimcar/libs-base';
import {
  applyPayload,
  computePayload,
  isTruthy,
  isTruthyAndNotEmpty,
  keysOf,
  nonnull,
} from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useScreenIsBulmaMobile,
  useScreenWidthLessThan,
  useSelectorWithChangeTrigger,
  useSetCallback,
} from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { EditMileageDialogState } from '../../../../lib/components/identityPictureAndGeneralInfoDisplay/typings/store.js';
import type { Store, StoreState } from '../../../state/typings/store.js';
import type {
  MemosState,
  OperatorExpertViewState,
  OperatorExpertViewValidationErrors,
  OperatorViewState,
  UpperExpertiseHintsSubTab,
  UpperExpertiseSubTab,
  UserDefinedNullMemo,
} from '../../typings/store.js';
import { ShowHideButton } from '../../../../lib/bulma/elements/ShowHideButton.js';
import { ListSelectFormField } from '../../../../lib/bulma/form/custom/ListSelectFormField.js';
import { GridCellBox } from '../../../../lib/bulma/grid/GridCellBox.js';
import { AttachmentsGallery } from '../../../../lib/components/attachments/AttachmentsGallery.js';
import { CarViewsSwitch } from '../../../../lib/components/carviews/CarViewsSwitch.js';
import {
  convertToAttributeExpectedType,
  DisplayAttributesComponent,
} from '../../../../lib/components/DisplayAttributesComponent.js';
import { KanbanOneLineGeneralInformations } from '../../../../lib/components/displayInformations/KanbanOneLineGeneralInformations.js';
import { DisplayPredictionsComponent } from '../../../../lib/components/DisplayPredictionsComponent.js';
import { DisplayWorkSheetHintsComponent } from '../../../../lib/components/DisplayWorkSheetHintsComponent.js';
import {
  EstimateView,
  initializeSelectedPurchaseOrderTabAction,
} from '../../../../lib/components/documentGeneration/estimate/EstimateView.js';
import {
  EditMileageModal,
  KanbanGeneralInformations,
} from '../../../../lib/components/identityPictureAndGeneralInfoDisplay/KanbanGeneralInformations.js';
import {
  EMPTY_EDIT_MILEAGE_DIALOG_STATE,
  EMPTY_EDIT_MILEAGE_FORM,
} from '../../../../lib/components/identityPictureAndGeneralInfoDisplay/typings/store.js';
import { Tabs } from '../../../../lib/components/Tabs.js';
import { KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE } from '../../../../lib/components/typings/store.js';
import { downloadAndSave } from '../../../../lib/utils/download.js';
import {
  importAttachmentsAction,
  importAttachmentsAndOverrideAction,
  loadAttachmentsGalleryAction,
  loadEstimateAttachmentsAction,
  showRemoveAttachmentConfirmDialogAction,
  useGetExpertiseAttachmentFolder,
} from '../../../utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../../../utils/useComputeAttachmentUrl.js';
import { useGetContractByCode } from '../../../utils/useGetContract.js';
import {
  addOrUpdatePackageDealAction,
  addValidationOperation,
  applyToggleOperationsPayloadForExpertise,
  getExpertiseValidationErrors,
  replaceUpdatedOperationsInPackageDeals,
  updateStandRelatedPackageDealsAndOperationsAction,
} from '../../expertiseUtils.js';
import {
  getDefaultSelectedUpperExpertiseTab,
  getUpperExpertiseTabsStateFromMemoDescs,
  selectUpperExpertiseTabAction,
  useLowerExpertiseTabs,
  useUpperExpertiseSubTabs,
  useUpperExpertiseTabs,
} from '../../hooks/useExpertiseTabs.js';
import { EMPTY_DEFECTS_TAB_STATE } from '../../typings/store.js';
import { DefectsTabComponent } from '../defects/DefectsTabComponent.js';
import { OperatorWorkingArea } from '../OperatorWorkingArea.js';
import { DeletePackageDealModalComponent } from './DeletePackageDealModalComponent.js';
import {
  EditPackageDealModalComponent,
  openDialogForAddition,
} from './EditPackageDealModalComponent.js';
import { ExpertComponentButtons } from './ExpertComponentButtons.js';
import { ExpertPackageDealDescDetailsAndMessagesPanelComponent } from './ExpertPackageDealDescDetailsAndMessagesPanelComponent.js';
import { ExpertPackageDealListComponent } from './ExpertPackageDealListComponent.js';
import {
  convertStringToMemoTypeValue,
  IncorrectPckDealAutoCreationExpressionModal,
  TabledMemoInput,
} from './TabledMemoInput.js';

type MemoForComparison = {
  id: string;
  value: string | boolean | number | null | undefined;
  category?: string;
  type?: string;
};

function convertKanbanMemosForComparison(
  existingMemos: Record<string, Memo>
): readonly MemoForComparison[] {
  const memos: MemoForComparison[] = [];

  forEachRecordValues(existingMemos, (memo, key) => {
    if (isTruthy(memo)) {
      if (isTruthy(memo.value)) {
        memos.push({
          id: key,
          value: String(memo.value),
        });
      } else {
        memos.push({
          id: key,
          value: null,
          category: memo.category,
          type: memo.type,
        });
      }
    }
  });

  return memos;
}

function convertStateMemosForComparison(memosState: MemosState): readonly MemoForComparison[] {
  // We use a record because a same memo can be in memosState.memos and
  // in memosStare.userDefinedNullMemos at the same time
  // but we want only one value for one memo
  const memos: Record<string, MemoForComparison> = {};

  // Transform memos which have a value
  forEachRecordValues(memosState.memos, (value, key) => {
    memos[key] = {
      id: key,
      value,
    };
  });

  // Transform the memos specified as non applicable
  memosState.userDefinedNullMemos.forEach(({ category, type, id }) => {
    memos[id] = {
      id,
      category,
      type,
      value: null,
    };
  });
  // Convert the record into an array
  return mapRecordValues(memos, (item) => item);
}

function convertMemosStatePayloadToMemosState(
  memosStatePayload: readonly MemoForComparison[]
): Pick<MemosState, 'memos' | 'userDefinedNullMemos'> {
  const { filtered: payloadMemos, rejected: payloadUserDefinedNullMemos } = filterReject(
    memosStatePayload,
    (item) => item.value !== null
  );
  // convert memos
  const memos: Record<string, string> = {};
  payloadMemos.forEach((item) => {
    memos[item.id] = String(item.value);
  });

  // convert user defined null memos
  const userDefinedNullMemos = payloadUserDefinedNullMemos.map((item) => {
    return {
      id: item.id,
      category: item.category,
      type: item.type,
    } as UserDefinedNullMemo;
  });

  const result: Pick<MemosState, 'memos' | 'userDefinedNullMemos'> = {
    memos,
    userDefinedNullMemos,
  };

  return result;
}

function getUpdatedMemos(
  initialKanban: Kanban,
  updatedKanban: Kanban,
  memosState: MemosState
): Pick<MemosState, 'memos' | 'userDefinedNullMemos'> {
  const initialMemos = convertKanbanMemosForComparison(initialKanban.memos);
  const updatedMemos = convertKanbanMemosForComparison(updatedKanban.memos);
  const stateMemos = convertStateMemosForComparison(memosState);

  // Compute changes between state and initial kanban
  const statePayload = computePayload(initialMemos, stateMemos);

  // Apply payload to updated memos
  const mergedMemosChanges = applyPayload(updatedMemos, statePayload);

  // Remove Ids that were used only to compute changes
  return convertMemosStatePayloadToMemosState(mergedMemosChanges);
}

function getUpdatedPackageDeals(
  initialKanban: Kanban,
  updatedKanban: Kanban,
  statePkgDeals: readonly PackageDeal[]
): readonly PackageDeal[] {
  // Compute changes on packages deals
  const actualPayloadPkgDeals = computePayload(initialKanban.packageDeals, statePkgDeals);

  let newPackageDeals = applyPayload(updatedKanban.packageDeals, actualPayloadPkgDeals);
  newPackageDeals = packageDealHelpers.updateAllPackageDealsExpressionComputations(
    {
      ...updatedKanban,
      packageDeals: newPackageDeals,
    },
    true
  );
  return newPackageDeals;
}

export const updateExpertiseViewAction = (
  { actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>,
  updatedKanban: Kanban
): void => {
  const { initialKanban, packageDealListComponentState, memosState } = getState();

  if (initialKanban && initialKanban.id === updatedKanban.id) {
    // Compute changes on package deals
    const updatedPkgDeals = getUpdatedPackageDeals(
      initialKanban,
      updatedKanban,
      packageDealListComponentState.packageDeals
    );
    // Compute changes on memos
    const updatedMemos = getUpdatedMemos(initialKanban, updatedKanban, memosState);

    // Chenge the initial kanban that will be used for further references
    actionDispatch.setProperty('initialKanban', updatedKanban);
    // Update the package deals in UI state
    actionDispatch
      .scopeProperty('packageDealListComponentState')
      .setProperty('packageDeals', updatedPkgDeals);
    // Update the memos in UI state
    const memosStateDispatch = actionDispatch.scopeProperty('memosState');
    memosStateDispatch.setProperty('memos', updatedMemos.memos);
    memosStateDispatch.setProperty('userDefinedNullMemos', updatedMemos.userDefinedNullMemos);
  }
};

function computeMemoNewValue(
  memoId: string,
  currentMemo: Memo,
  inputValue: string,
  userDefinedNullMemos: readonly UserDefinedNullMemo[]
): string | boolean | number | null | undefined {
  const convertedInput = convertStringToMemoTypeValue(inputValue, currentMemo.type);

  if (
    convertedInput === undefined &&
    userDefinedNullMemos.find((m) => m.id === memoId && m.category === currentMemo.category) !==
      undefined
  ) {
    return null;
  }

  return currentMemo.value !== convertedInput ? convertedInput : currentMemo.value;
}

async function refreshPackageDealCarElementsList({
  actionDispatch,
  carElementRepository,
  getState,
}: ActionContext<Store, OperatorExpertViewState>): Promise<void> {
  const { packageDeals } = getState().packageDealListComponentState;
  // Load operation car elements (using the last state, so we have to retrieve operations
  // through getState())
  // eslint-disable-next-line
  // @ts-ignore TS doesn't understand that we are sure there is no undefined  in the returned array
  const carElementIds: string[] = packageDeals
    .map((o): string | undefined => o.carElement?.id)
    .filter((id) => isTruthyAndNotEmpty(id));
  const uniqueCarElementIds = new Set(carElementIds);
  const packageDealCarElements = await carElementRepository.getEntities(...uniqueCarElementIds);

  actionDispatch.reduce((initial: OperatorExpertViewState): OperatorExpertViewState => {
    return {
      ...initial,
      packageDealCarElements,
    };
  });
}

async function initOperatorExpertViewFromKanban(
  {
    actionDispatch,
    packageDealDescRepository,
    carElementRepository,
    getState,
    getGlobalState,
    httpClient,
    kanbanRepository,
  }: ActionContext<Store, StoreState>,
  isOnline: boolean
): Promise<void> {
  const { operatedKanban } = getState().operatorView;
  const { contracts, siteConfiguration } = getGlobalState();
  if (operatedKanban === undefined) {
    return;
  }
  const { packageDeals } = operatedKanban;
  const pkgsWithoutDeletedAndUnachievable = packageDeals.map((p): PackageDeal => {
    if (!p.deleted && isTruthyAndNotEmpty(p.unachievableReason)) {
      return {
        ...p,
        status: 'canceled',
        unachievableReason: null,
      };
    }
    return p;
  });
  const { attributeDescs, packageDealDatabase, memoDescs } = nonnull(
    contracts.find((c) => c.code === operatedKanban.contract.code)
  );
  const allPackageDealDescs = (
    await packageDealDescRepository.getEntitiesFromIndex(
      PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
      packageDealDatabase
    )
  ).filter((pdd) => !packageDealHelpers.isPackageDealUnremovable(pdd.code));

  const typedMemosKeys: { [k: string]: string } = {};
  const userNullMemos: UserDefinedNullMemo[] = [];
  if (operatedKanban.memos) {
    keysOf(operatedKanban.memos).forEach((key) => {
      if (operatedKanban.memos[key] && isTruthy(operatedKanban.memos[key].value)) {
        typedMemosKeys[key] = String(nonnull(operatedKanban.memos[key].value));
      } else {
        typedMemosKeys[key] = '';
      }
    });
  }

  keysOf(memoDescs).forEach((key) => {
    const descs = memoDescs[key];
    if (isTruthy(descs)) {
      descs.forEach((memoDesc) => {
        if (
          memoDesc?.additionalBehavior === 'naAllowed' &&
          operatedKanban.memos[memoDesc.id]?.value === null
        ) {
          userNullMemos.push({ id: memoDesc.id, category: key, type: memoDesc.type });
        }
      });
    }
  });

  const allCarElements = (await carElementRepository.getAllEntities()).filter(
    (c) => c.status !== 'closed'
  );

  const expertViewDisptach = actionDispatch
    .scopeProperty('operatorView')
    .scopeProperty('expertOperatorState');

  expertViewDisptach.reduce((initial: OperatorExpertViewState): OperatorExpertViewState => {
    return {
      ...initial,
      editMileageDialogState: {
        ...EMPTY_EDIT_MILEAGE_DIALOG_STATE,
        initialMileage: operatedKanban.infos.mileage,
        formData: {
          ...EMPTY_EDIT_MILEAGE_FORM,
        },
      },
      packageDealListComponentState: {
        ...initial.packageDealListComponentState,
        packageDeals: [...pkgsWithoutDeletedAndUnachievable],
      },
      defectsTabState: {
        ...EMPTY_DEFECTS_TAB_STATE,
        defects: operatedKanban.defects ?? [],
      },
      allPackageDealDescs,
      allCarElements,
      selectedUpperExpertiseTab: getDefaultSelectedUpperExpertiseTab(operatedKanban.attributes),
      upperExpertiseTabsState: getUpperExpertiseTabsStateFromMemoDescs(
        initial.upperExpertiseTabsState,
        memoDescs,
        operatedKanban.attributes,
        isOnline
      ),
      initialKanban: operatedKanban,
      attributesState: {
        ...initial.attributesState,
        attributes: { ...operatedKanban.attributes },
        contractAttributeDescs: attributeDescs,
      },
      memosState: {
        ...initial.memosState,
        memos: typedMemosKeys,
        userDefinedNullMemos: userNullMemos,
      },
      packageDealDatabase,
    };
  });

  await actionDispatch
    .scopeProperty('operatorView')
    .exec(updateStandRelatedPackageDealsAndOperationsAction);
  await expertViewDisptach.exec(selectUpperExpertiseTabAction);
  await expertViewDisptach.exec(refreshPackageDealCarElementsList);
  await actionDispatch
    .scopeProperty('detailsView')
    .scopeProperty('desktopState')
    .scopeProperty('estimateView')
    .exec(initializeSelectedPurchaseOrderTabAction, operatedKanban.purchaseOrders);

  const kanbanWithValidationOperation = addValidationOperation(
    httpClient.getBrowserSequence(),
    operatedKanban,
    siteConfiguration
  );
  const kanbanPayload = computePayload(operatedKanban, kanbanWithValidationOperation);
  if (isTruthy(kanbanPayload)) {
    await kanbanRepository.updateEntityFromPayload({
      entityId: operatedKanban.id,
      payload: kanbanPayload,
    });
  }
}

async function selectedCarElementChangedAction({
  actionDispatch,
  getState,
  packageDealDescRepository,
}: ActionContext<Store, OperatorExpertViewState>): Promise<void> {
  const { selectedUpperExpertiseTab, selectedCarElementId, allCarElements, packageDealDatabase } =
    getState();
  const carElementId = selectedCarElementId.trim();

  if (!carElementId) {
    actionDispatch.setProperty('highlightedCarViewShapes', []);
    actionDispatch.setProperty('availablePackageDealDescs', []);
  } else {
    const carElement = nonnull(allCarElements.find((ce): boolean => ce.id === carElementId));
    // Update element shapes
    actionDispatch.setProperty('highlightedCarViewShapes', carElement.shapes);

    const packageDeals = await packageDealDescRepository.getEntitiesFromIndex(
      PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
      packageDealDatabase
    );
    const availablePackageDealDescs = packageDeals.filter(
      (o): boolean =>
        o.carElementIds.includes(nonnull(carElementId)) &&
        carElementHelpers.getCarElementCategory(carElement) === selectedUpperExpertiseTab
    );
    actionDispatch.setProperty('availablePackageDealDescs', availablePackageDealDescs);
    actionDispatch.setProperty('selectedPackageDealDescId', '');
  }
}

async function shapeClickedHandler(
  { actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>,
  id: string
): Promise<void> {
  if (!getState().selectedCarViewShapes.includes(id)) {
    // Update element shapes
    actionDispatch.setProperty('selectedCarViewShapes', [id]);

    // Load corresponding car elements
    const category = getState().selectedUpperExpertiseTab;
    const carElementIds = getState()
      .allCarElements.filter((e): boolean => e.category === category && e.shapes.includes(id))
      .map((ce) => ce.id);
    actionDispatch.setProperty('availableCarElementIds', carElementIds);
    actionDispatch.setProperty(
      'selectedCarElementId',
      carElementIds.length === 1 ? carElementIds[0] : ''
    );
    actionDispatch.setProperty('selectedElementType', 'packageDealDesc');
    await actionDispatch.exec(selectedCarElementChangedAction);
  }
}

function submitKanbanAttributeModification({
  getState,
  actionDispatch,
}: ActionContext<Store, OperatorViewState>): void {
  const { operatedKanban, expertOperatorState } = getState();
  const { attributeKey, attributeValue } =
    expertOperatorState.attributesState.kanbanAttributeUpdateModalState;

  if (!isTruthy(operatedKanban)) {
    return;
  }

  actionDispatch.scopeProperty('expertOperatorState').reduce((initial) => {
    return {
      ...initial,
      kanbanAttributeUpdateModalState: {
        ...KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE,
      },
      attributesState: {
        ...initial.attributesState,
        attributes: {
          ...initial.attributesState.attributes,
          [attributeKey]: convertToAttributeExpectedType(
            operatedKanban.attributes,
            attributeKey,
            attributeValue
          ),
        },
      },
    };
  });
}

function computeUpdatedMemos(
  initialMemos: Record<string, Memo>,
  inputsFromFormData: Record<string, string>,
  userDefinedNullMemos: readonly UserDefinedNullMemo[],
  memoDescs: PartialRecord<CarViewCategory, MemoDesc[]>
): Record<string, Memo> {
  const newMemos: Record<string, Memo> = {};
  if (keysOf(initialMemos).length > 0) {
    keysOf(initialMemos).forEach((key) => {
      if (initialMemos[key]) {
        const newMemoValue = computeMemoNewValue(
          key,
          initialMemos[key],
          inputsFromFormData[key],
          userDefinedNullMemos
        );
        if (newMemoValue !== undefined) {
          const newMemo: Memo = {
            ...initialMemos[key],
            value: newMemoValue,
          } as Memo;
          newMemos[key] = newMemo;
        }
      }
    });
  }
  keysOf(inputsFromFormData).forEach((fdKey) => {
    if (fdKey !== 'warnings') {
      const memoDesc = transverseHelpers.getMemoDescWithCategory(memoDescs, fdKey);
      const initialMemo = initialMemos[fdKey];
      if (isTruthy(memoDesc)) {
        const type = isTruthy(initialMemo) ? initialMemo.type : memoDesc.type;
        let value: string | number | boolean | undefined | null = convertStringToMemoTypeValue(
          inputsFromFormData[fdKey],
          type
        );
        if (
          value === undefined &&
          userDefinedNullMemos.find((m) => m.id === fdKey && m.category === memoDesc.category) !==
            undefined
        ) {
          value = null;
        }
        if (value !== undefined) {
          const newMemo: Memo = {
            type,
            value,
            category: memoDesc.category,
          } as Memo;
          newMemos[fdKey] = newMemo;
        }
      }
    }
  });

  return newMemos;
}

interface ExpertComponentViewProps extends AppProps<Store> {
  readonly standId: string;
}

export function ExpertComponentView({ $gs, standId }: ExpertComponentViewProps): JSX.Element {
  const [t] = useTranslation('operators');
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  const isNarrowScreen = useScreenWidthLessThan($gs.$window, 1_350);

  const { $operatorView, $detailsView } = $gs;
  const { $desktopState } = $detailsView;
  const { $expertOperatorState } = $operatorView;
  const {
    $memosState,
    $attachmentsTab,
    $editMileageDialogState,
    $addOrUpdatePackageDealModalState,
  } = $expertOperatorState;
  const { $incorrectPckDealAutoCreationExpressionModal } = $memosState;

  const initialKanban = useGetState($expertOperatorState.$initialKanban);
  const operatedKanban = useGetState($operatorView.$operatedKanban);
  const packageDealListComponentState = useGetState(
    $expertOperatorState.$packageDealListComponentState
  );
  const defectsTabState = useGetState($expertOperatorState.$defectsTabState);
  const selectedElementType = useGetState($expertOperatorState.$selectedElementType);
  const attributes = useGetState($expertOperatorState.$attributesState.$attributes);
  const memosState = useGetState($expertOperatorState.$memosState);
  const showEstimate = useGetState($expertOperatorState.$showEstimate);
  const selectedPackageDealId = useGetState(
    $expertOperatorState.$detailsAndMessagesComponentState.$selectedPackageDealId
  );
  const availablePackageDealDescs = useGetState($expertOperatorState.$availablePackageDealDescs);
  const selectedPackageDealDescId = useGetState($expertOperatorState.$selectedPackageDealDescId);
  const allCarElements = useGetState($expertOperatorState.$allCarElements);
  const selectedCarElementId = useGetState($expertOperatorState.$selectedCarElementId);

  const isOnline = useGetState($gs.$session.$isOnline);
  const contract = useGetContractByCode($gs, initialKanban.contract.code);
  const { memoDescs, attributeDescs } = contract || EMPTY_CONTRACT_CONFIGURATION;

  // Spread the operated kanban information in the UI state
  const initOperatorExpertViewFromKanbanCallback = useActionCallback(
    initOperatorExpertViewFromKanban,
    [],
    $gs
  );

  useEffect(() => {
    const asyncEffect = initOperatorExpertViewFromKanbanCallback;
    if (!isMobile) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncEffect(isOnline);
    }
  }, [initOperatorExpertViewFromKanbanCallback, isMobile, isOnline]);

  const stands = useGetState($gs.$siteConfiguration.$stands);

  const {
    lowerExpertiseTabsLabels,
    disabledLowerExpertiseTabs,
    $selectedLowerExpertiseTabWithChangeTrigger,
    selectedLowerExpertiseTab,
  } = useLowerExpertiseTabs($operatorView);

  const { upperExpertiseTabLabels, $selectedUpperExpertiseTabWithChangeTrigger } =
    useUpperExpertiseTabs($expertOperatorState, stands, standId);

  const { standRelatedOperations } = useGetState($expertOperatorState);

  const updatedKanban = useMemo(() => {
    const { packageDeals } = packageDealListComponentState;
    const { defects } = defectsTabState;

    const newKanban = {
      ...initialKanban,
      packageDeals: replaceUpdatedOperationsInPackageDeals(packageDeals, standRelatedOperations),
      defects,
    };
    const newMemos = computeUpdatedMemos(
      initialKanban.memos,
      memosState.memos,
      memosState.userDefinedNullMemos,
      memoDescs
    );
    if (keysOf(newMemos).length > 0) {
      newKanban.memos = newMemos;
    }
    const newAttributes = { ...initialKanban.attributes, ...(!attributes ? {} : attributes) };
    if (keysOf(newAttributes).length > 0) {
      newKanban.attributes = newAttributes;
    }
    return newKanban;
  }, [
    attributes,
    initialKanban,
    memoDescs,
    memosState.memos,
    memosState.userDefinedNullMemos,
    packageDealListComponentState,
    defectsTabState,
    standRelatedOperations,
  ]);

  const hasLocalChanges = useMemo(() => {
    return computePayload(initialKanban, updatedKanban) !== undefined;
  }, [initialKanban, updatedKanban]);

  const asyncEffect = useActionCallback(
    ({ globalActionDispatch }): void => {
      globalActionDispatch.setProperty('preventNavigation', hasLocalChanges);
    },
    [hasLocalChanges],
    $gs
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const workStatus = useGetState($operatorView.$workStatus);

  const isActionDisabled = useMemo(() => {
    return workStatus !== 'started';
  }, [workStatus]);

  const onMileageChanged = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
    }: ActionContext<Store, EditMileageDialogState>): Promise<void> => {
      const { formData } = getState();
      const newKanban: Kanban = {
        ...nonnull(operatedKanban),
        infos: {
          ...nonnull(operatedKanban).infos,
          mileage: Number.parseInt(formData.mileage, 10),
        },
      };
      await kanbanRepository.updateEntity(newKanban);
      actionDispatch.setProperty('active', false);
    },
    [operatedKanban],
    $editMileageDialogState
  );

  const isFocusedOnPackageDeal = useMemo((): boolean => {
    return selectedElementType === 'packageDeal';
  }, [selectedElementType]);

  const showMileageUpdateModal = useActionCallback(
    ({ actionDispatch, getState }) => {
      actionDispatch
        .scopeProperty('editMileageDialogState')
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .reduce((initial) => {
          return {
            ...EMPTY_EDIT_MILEAGE_DIALOG_STATE,
            active: true,
            initialMileage: getState().initialKanban.infos.mileage,
            formData: {
              ...EMPTY_EDIT_MILEAGE_FORM,
              mileage: getState().initialKanban.infos.mileage.toString(),
            },
          };
        });
    },
    [],
    $expertOperatorState
  );

  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $attachmentsTab
  );

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const updateKanbanIdentityPictureAction = useActionCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { actionDispatch }: ActionContext<Store, any>,
      category: StorageCategories,
      objectId: string,
      folder: string,
      file: File
    ) => {
      await actionDispatch.exec(importAttachmentsAndOverrideAction, category, objectId, folder, [
        file,
      ]);
    },
    [],
    $expertOperatorState
  );

  const updateStandRelatedPackageDealsAndOperationsActionCallback = useActionCallback(
    updateStandRelatedPackageDealsAndOperationsAction,
    [],
    $operatorView
  );

  const forceAddPackageDealAction = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      actionDispatch.scopeProperty('addOrUpdatePackageDealModalState').applyPayload({
        forceAddDuplicatePD: true,
        isForceAddPDDModalActive: false,
      });
      await actionDispatch.exec(
        addOrUpdatePackageDealAction,
        updateStandRelatedPackageDealsAndOperationsActionCallback
      );
    },
    [updateStandRelatedPackageDealsAndOperationsActionCallback],
    $expertOperatorState
  );

  const [selectedEntity, selectedCarElement] = useMemo(() => {
    if (selectedElementType === 'packageDeal') {
      const selectedPackageDeal = packageDealListComponentState.packageDeals.find(
        (pd) => pd.id === selectedPackageDealId
      );
      return [selectedPackageDeal, selectedPackageDeal?.carElement];
    }
    const selectedPackageDealDesc = availablePackageDealDescs.find(
      (pd) => pd.id === selectedPackageDealDescId
    );
    return [selectedPackageDealDesc, allCarElements.find((ce) => ce.id === selectedCarElementId)];
  }, [
    allCarElements,
    availablePackageDealDescs,
    packageDealListComponentState.packageDeals,
    selectedCarElementId,
    selectedElementType,
    selectedPackageDealDescId,
    selectedPackageDealId,
  ]);

  const computeValidationErrors = useMemo(
    () =>
      (kanban: Kanban): OperatorExpertViewValidationErrors =>
        getExpertiseValidationErrors(
          kanban,
          memoDescs,
          standRelatedOperations,
          upperExpertiseTabLabels
        ),
    [memoDescs, upperExpertiseTabLabels, standRelatedOperations]
  );

  const hideEstimateCallback = useSetCallback($expertOperatorState.$showEstimate, false);
  const showEstimateCallback = useSetCallback($expertOperatorState.$showEstimate, true);

  const loadEstimateAttachmentsActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(loadEstimateAttachmentsAction, 'kanban', updatedKanban.id);
    },
    [updatedKanban],
    $desktopState.$estimateView
  );

  const downloadAttachmentsZipCallback = useActionCallback(
    async ({ httpClient }) =>
      await downloadAndSave(
        httpClient,
        CoreBackendRoutes.ZIPPED_ATTACHMENT_FOLDER('kanban', updatedKanban.id, 'expertise'),
        'download.zip'
      ),
    [updatedKanban],
    $expertOperatorState
  );

  const postId = useGetState($gs.$session.$infos.optChaining().$id);
  const siteAddress = useGetState($gs.$siteConfiguration.$infos.$address);
  const siteCompanyName = useGetState($gs.$siteConfiguration.$infos.$companyName);
  const siteLogoUrl = useGetState($gs.$siteConfiguration.$infos.$logoUrl);

  const memoPackageDealContentIsExpanded = useGetState(
    $expertOperatorState.$memosPackageDealsSectionIsExpanded
  );

  // Fix bug: when cancelling the dialog "Package deal already exists", the containing form
  // could not be submitted anymore
  const onCancelForceAddDupplicateCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('formSubmitted', false);
      actionDispatch.setProperty('formSubmitClickedOnce', false);
    },
    [],
    $addOrUpdatePackageDealModalState
  );

  if (!isTruthy(contract)) {
    return <div>{t('expertiseView.contractNotFound')}</div>;
  }

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <>
      {showEstimate && isTruthy(updatedKanban) && isTruthy(updatedKanban.contract) && (
        <EstimateView
          returnAction={hideEstimateCallback}
          kanban={updatedKanban}
          attributeDescs={contract.attributeDescs}
          estimateMention={contract.estimateMention}
          memoDescs={contract.memoDescs}
          address={siteAddress}
          companyName={siteCompanyName}
          logoUrl={siteLogoUrl}
          computeAttachmentUrl={computeAttachmentUrl}
          $imageModal={$gs.$imageModal}
          $={$desktopState.$estimateView}
          loadAvailableAttachmentsEffect={loadEstimateAttachmentsActionCallback}
          downloadAttachmentsZipCallback={downloadAttachmentsZipCallback}
        />
      )}
      {!showEstimate && (
        <div className="fixed-grid has-12-cols">
          <div className="grid">
            <GridCellBox colspan={12}>
              <nav className="level">
                <KanbanOneLineGeneralInformations
                  kanban={initialKanban}
                  $imageModal={$gs.$imageModal}
                  computeUrlCallback={computeAttachmentUrl}
                  showMileageUpdateModal={showMileageUpdateModal}
                  pictureEditionToolkit={{
                    updateKanbanIdentityPictureAction,
                    $: $expertOperatorState.$pictureEditionToolkitState,
                    onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
                  }}
                  isOnline={isOnline}
                />
              </nav>
            </GridCellBox>
            <GridCellBox colspan={8}>
              <div className="columns disable-bulma-child-margin-bottom">
                <div className="column is-narrow">
                  <ShowHideButton $={$expertOperatorState.$memosPackageDealsSectionIsExpanded} />
                </div>
                <div className="column is-auto-fill">
                  <Tabs
                    isSmall={isNarrowScreen}
                    labels={upperExpertiseTabLabels}
                    $selectedTab={$selectedUpperExpertiseTabWithChangeTrigger}
                    className="narrow-bottom-margin"
                  />
                </div>
              </div>
              {memoPackageDealContentIsExpanded && (
                <TabContent $gs={$gs} contract={contract} isActionDisabled={isActionDisabled} />
              )}
            </GridCellBox>
            <GridCellBox colspan={4} rowspan={2}>
              <ExpertComponentButtons
                $gs={$gs}
                kanban={updatedKanban}
                $={$operatorView}
                hasLocalChanges={hasLocalChanges}
                // Should not happen, only here because TypeScript can't understand that using  nonnull() method
                // on browserInfosState give us the confidence it will not be null
                postId={postId ?? ''}
                getValidationErrors={computeValidationErrors}
                standId={standId}
                showEstimateCallback={showEstimateCallback}
              />
              <ExpertPackageDealDescDetailsAndMessagesPanelComponent
                $gs={$gs}
                entity={selectedEntity}
                carElement={selectedCarElement}
              />
            </GridCellBox>
            <GridCellBox colspan={8}>
              <Tabs
                isVerticalText
                disabledTabs={disabledLowerExpertiseTabs}
                labels={lowerExpertiseTabsLabels}
                $selectedTab={$selectedLowerExpertiseTabWithChangeTrigger}
                className="narrow-bottom-margin is-full-height"
              />
              <div style={{ display: 'flex' }}>
                {selectedLowerExpertiseTab === 'ESTIMATE' && (
                  <ExpertPackageDealListComponent
                    $={$operatorView}
                    isFocused={isFocusedOnPackageDeal}
                    isEditionDisabled={isActionDisabled}
                    recomputeSizeHint={memoPackageDealContentIsExpanded ? 1 : 0}
                    packageDeals={updatedKanban.packageDeals}
                  />
                )}
                {selectedLowerExpertiseTab === 'OPERATIONS' && (
                  <div
                    className="mt-2 mr-1"
                    style={{ marginLeft: '1.75rem', maxHeight: '11.25rem' }}
                  >
                    <OperatorWorkingArea
                      $gs={$gs}
                      standId={standId}
                      memoDescs={memoDescs}
                      attributeDescs={attributeDescs}
                      applyToggleOperationsPayloadAction={applyToggleOperationsPayloadForExpertise}
                    />
                  </div>
                )}
              </div>
            </GridCellBox>
          </div>
        </div>
      )}
      <EditMileageModal $={$editMileageDialogState} submitValidDataAction={onMileageChanged} />
      <EditPackageDealModalComponent
        $gs={$gs}
        $={$expertOperatorState}
        kanbanId={initialKanban.id}
        updateStandRelatedPackageDealsAndOperationsActionCallback={
          updateStandRelatedPackageDealsAndOperationsActionCallback
        }
      />
      <DeletePackageDealModalComponent
        $={$expertOperatorState}
        updateStandRelatedPackageDealsAndOperationsActionCallback={
          updateStandRelatedPackageDealsAndOperationsActionCallback
        }
      />
      <ModalCardDialog
        title={t('expertiseView.forceAddPDDModal.title')}
        $active={$addOrUpdatePackageDealModalState.$isForceAddPDDModalActive}
        okLabel={t('expertiseView.forceAddPDDModal.okLabel')}
        onOkClicked={forceAddPackageDealAction}
        onCancelClicked={onCancelForceAddDupplicateCallback}
      >
        {t('expertiseView.forceAddPDDModal.content')}
      </ModalCardDialog>
      <IncorrectPckDealAutoCreationExpressionModal
        $={$incorrectPckDealAutoCreationExpressionModal}
      />
    </>
  );
}

/**
 * Get all memo descriptions, i.e. those coming from the contract augmented with one for TC countervisit if required
 */
function getAllMemoDescs(
  memoDescsPerCategory: Readonly<Record<CarViewCategory, readonly MemoDesc[]>>,
  packageDeals: readonly PackageDeal[],
  t: TFunction
): Readonly<Record<CarViewCategory, readonly MemoDesc[]>> {
  // Check if an upstream TC operation has been done
  const tcPkgOrUndefined = packageDeals
    .filter(nonDeleted)
    .filter((pkg) => packageDealHelpers.isPackageDealAvailableAndAchievable(pkg))
    .find((pkg) => pkg.code === TC_PACKAGE_DEAL_CODE);

  if (tcPkgOrUndefined !== undefined) {
    const upstreamTCDone = tcPkgOrUndefined.operations
      .filter(nonDeleted)
      .some((o) => o.completionDate !== null && o.standId === UPSTREAM_TC_STAND_ID);
    if (upstreamTCDone) {
      const counterVisitMemoId = t('expertiseView.memoDescs.requiredCounterVisitQuestion');

      // Insert the new memo right after the existing one on TC
      const miscMemoDescs = [...memoDescsPerCategory.MISC];

      const hasCounterVisitMemoDesc = miscMemoDescs.some(
        (memoDesc) => memoDesc.id === counterVisitMemoId
      );
      if (!hasCounterVisitMemoDesc) {
        // Add a memo for the counter inspection
        const memo: MemoDesc = {
          id: counterVisitMemoId,
          type: 'boolean',
          packageDeal: {
            code: 'CTA',
            isToCreateExpression: 'return value;',
          },
          additionalBehavior: 'naAllowed',
        };

        // Find position of the existing TC memo
        const index = miscMemoDescs.findIndex((existingMemo) => {
          if (existingMemo.packageDeal !== undefined && existingMemo.packageDeal) {
            const { packageDeal } = existingMemo;
            if (typeof packageDeal === 'object') {
              return packageDeal.code === TC_PACKAGE_DEAL_CODE;
            }
          }
          return false;
        });

        // If no TC memo was found, our new one would be inserted at first position
        // (but this case should never happen)
        miscMemoDescs.splice(index + 1, 0, memo);

        return {
          ...memoDescsPerCategory,
          MISC: miscMemoDescs as readonly MemoDesc[],
        };
      }
    }
  }
  return memoDescsPerCategory;
}

const FAKE_ENTRIES: readonly FormFieldEntry<string>[] = [
  { id: '0', label: '-' },
  { id: '1', label: '-' },
  { id: '2', label: '-' },
  { id: '3', label: '-' },
  { id: '4', label: '-' },
];

interface Props extends AppProps<Store> {
  readonly contract: UIContract;
  readonly isActionDisabled: boolean;
}

function TabContent({ $gs, isActionDisabled, contract }: Props): JSX.Element {
  const [t] = useTranslation('operators');

  const { $imageModal, $operatorView } = $gs;
  const { $expertOperatorState } = $operatorView;
  const { $attachmentsTab } = $expertOperatorState;

  const isOnline = useGetState($gs.$session.$isOnline);
  const initialKanban = useGetState($expertOperatorState.$initialKanban);
  const selectedCarViewShapes = useGetState($expertOperatorState.$selectedCarViewShapes);
  const availableCarElementIds = useGetState($expertOperatorState.$availableCarElementIds);
  const allCarElements = useGetState($expertOperatorState.$allCarElements);
  const carViewCanvasHeight = useGetState($expertOperatorState.$carViewCanvasSize.$height);
  const selectedElementType = useGetState($expertOperatorState.$selectedElementType);
  const selectedCarElementId = useGetState($expertOperatorState.$selectedCarElementId);
  const highlightedCarViewShapes = useGetState($expertOperatorState.$highlightedCarViewShapes);
  const availablePackageDealDescs = useGetState($expertOperatorState.$availablePackageDealDescs);
  const packageDealListComponentState = useGetState(
    $expertOperatorState.$packageDealListComponentState
  );

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const shapeClickedHandlerCallback = useActionCallback(
    shapeClickedHandler,
    [],
    $expertOperatorState
  );

  const submitKanbanAttributeModificationCallback = useActionCallback(
    submitKanbanAttributeModification,
    [],
    $operatorView
  );

  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $attachmentsTab
  );

  const selectedCarElementChangedActionCallback = useActionCallback(
    selectedCarElementChangedAction,
    [],
    $expertOperatorState
  );

  const $selectedCarElementIdWithChangeTrigger = useSelectorWithChangeTrigger(
    $expertOperatorState.$selectedCarElementId,
    selectedCarElementChangedActionCallback
  );

  const memoDescs = useMemo(
    () => getAllMemoDescs(contract.memoDescs, initialKanban.packageDeals, t),
    [contract.memoDescs, initialKanban.packageDeals, t]
  );

  const {
    selectedUpperExpertiseTab,
    upperExpertiseSubTabLabels,
    selectedUpperExpertiseSubTab,
    disabledUpperExpertiseSubTabs,
    $selectedUpperExpertiseSubTab,
  } = useUpperExpertiseSubTabs($expertOperatorState, memoDescs, initialKanban.attributes, isOnline);

  const openDialogForAdditionActionCallback = useActionCallback(
    async ({ actionDispatch }, pddId: string): Promise<void> => {
      const carElement =
        selectedUpperExpertiseTab === 'MISC'
          ? undefined
          : nonnull(allCarElements.find(({ id }) => id === selectedCarElementId));
      await actionDispatch.exec(
        openDialogForAddition,
        pddId,
        nonnull(contract).sparePartManagementType,
        carElement
      );
    },
    [allCarElements, contract, selectedUpperExpertiseTab, selectedCarElementId],
    $expertOperatorState
  );

  const setFocusOnPackageDealDescs = useSetCallback(
    $expertOperatorState.$selectedElementType,
    'packageDealDesc'
  );

  const isFocusedOnPackageDealDescs = useMemo((): boolean => {
    return selectedElementType === 'packageDealDesc';
  }, [selectedElementType]);

  const listHeight = useMemo((): number => {
    return (carViewCanvasHeight < 200 ? 200 : carViewCanvasHeight) - 30;
  }, [carViewCanvasHeight]);

  const orderedCarElements = useMemo((): readonly FormFieldEntry<string>[] => {
    const sortFunction = sortingHelpers.createSortByNumericField('DOWN', 'index');
    const intersection = allCarElements.filter((ce) => availableCarElementIds.includes(ce.id));
    return intersection.length === 0 ? FAKE_ENTRIES : intersection.sort(sortFunction);
  }, [availableCarElementIds, allCarElements]);

  const sortedAvailablePackageDealDescEntries = useMemo((): readonly FormFieldEntry<string>[] => {
    const result = availablePackageDealDescs
      .map((pdd): FormFieldEntry<string> => {
        return {
          id: pdd.id,
          label: packageDealDescHelpers.getPackageDealDescLabelWithVariableValuePlaceholder(pdd),
        };
      })
      .sort((pd1, pd2): number => pd1.label.localeCompare(pd2.label));
    return result.length === 0 ? FAKE_ENTRIES : result;
  }, [availablePackageDealDescs]);

  const lineActionCallback = useActionCallback(
    async ({ actionDispatch }, index: number) => {
      const pdd = sortedAvailablePackageDealDescEntries[index];
      actionDispatch.applyPayload({ selectedPackageDealDescId: pdd.id });
      await actionDispatch.execCallback(openDialogForAdditionActionCallback, pdd.id);
    },
    [openDialogForAdditionActionCallback, sortedAvailablePackageDealDescEntries],
    $expertOperatorState
  );

  const expertiseAttachmentFolder = useGetExpertiseAttachmentFolder();

  const saveStateInLocalStorageCallback = useActionCallback(
    ({ keyValueStorage, getState }) => {
      keyValueStorage.setObjectItem(LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP, getState());
    },
    [],
    $expertOperatorState
  );

  const globalVariables = useMemo(() => {
    return packageDealHelpers.computeAllGlobalVariableValues({
      ...initialKanban,
      packageDeals: packageDealListComponentState.packageDeals,
    });
  }, [initialKanban, packageDealListComponentState.packageDeals]);

  const importGalleryAttachmentsAndClearLocalStorageAction = async (
    ctx: ActionContext<Store, StoreState>,
    category: StorageCategories,
    objectId: string,
    folder: string,
    files: readonly File[]
  ): Promise<void> => {
    await importAttachmentsAction(ctx, category, objectId, folder, files);
    const { keyValueStorage } = ctx;
    keyValueStorage.removeItem(LocalStorageKeys.DESKTOP_EXPERTISE_STATE_DUMP);
  };

  const loadGalleryAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $attachmentsTab
  );
  const importGalleryAttachmentsAndClearLocalStorageActionCallback = useActionCallback(
    importGalleryAttachmentsAndClearLocalStorageAction,
    [],
    $gs
  );

  return (
    <>
      {CAR_VIEW_CATEGORIES.includes(selectedUpperExpertiseTab as CarViewCategory) ? (
        <>
          <Tabs
            isCentered
            isVerticalText
            className="narrow-bottom-margin"
            disabledTabs={disabledUpperExpertiseSubTabs as readonly UpperExpertiseSubTab[]}
            labels={upperExpertiseSubTabLabels as Record<UpperExpertiseSubTab, string>}
            $selectedTab={
              $selectedUpperExpertiseSubTab as StoreStateSelector<Store, UpperExpertiseSubTab>
            }
          />
          <div style={{ display: 'flex' }}>
            {selectedUpperExpertiseSubTab === 'PACKAGE_DEALS' && (
              <>
                <CarViewsSwitch
                  category={selectedUpperExpertiseTab}
                  selectedShapes={selectedCarViewShapes}
                  highlightedShapes={highlightedCarViewShapes}
                  shapeClicked={shapeClickedHandlerCallback}
                  $size={$expertOperatorState.$carViewCanvasSize}
                  maxHeight={250}
                  maxWidth={245}
                  isFocused
                />
                {selectedUpperExpertiseTab !== 'MISC' && (
                  <div className="p-r-sm" style={{ flex: 1 }}>
                    <ListSelectFormField
                      label={t('expertiseView.carElements')}
                      entries={orderedCarElements}
                      height={listHeight}
                      isComponentFocused={isFocusedOnPackageDealDescs}
                      setComponentFocus={setFocusOnPackageDealDescs}
                      $={$selectedCarElementIdWithChangeTrigger}
                      readOnly={orderedCarElements === FAKE_ENTRIES}
                    />
                  </div>
                )}
                <div style={{ flex: 1 }}>
                  <ListSelectFormField
                    label={t('expertiseView.packageDealDescs')}
                    entries={sortedAvailablePackageDealDescEntries}
                    height={listHeight}
                    isComponentFocused={isFocusedOnPackageDealDescs}
                    setComponentFocus={setFocusOnPackageDealDescs}
                    $={$expertOperatorState.$selectedPackageDealDescId}
                    lineActionToolkit={
                      isActionDisabled
                        ? undefined
                        : {
                            actionHandler: lineActionCallback,
                            tooltip: t('expertiseView.addButton'),
                          }
                    }
                    readOnly={sortedAvailablePackageDealDescEntries === FAKE_ENTRIES}
                  />
                </div>
              </>
            )}
            {selectedUpperExpertiseSubTab === 'MEMOS' && (
              <div className="p-r-sm" style={{ flex: 1 }}>
                <TabledMemoInput
                  memos={initialKanban.memos}
                  memoDescs={memoDescs}
                  selectedExpertiseTab={selectedUpperExpertiseTab}
                  isEditionDisabled={isActionDisabled}
                  height={240}
                  globalVariables={globalVariables}
                  $gs={$gs}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div style={{ display: 'flex' }}>
          {selectedUpperExpertiseTab === 'HINTS' && (
            <>
              <Tabs
                isCentered
                isVerticalText
                className="narrow-bottom-margin"
                labels={upperExpertiseSubTabLabels as Record<UpperExpertiseHintsSubTab, string>}
                $selectedTab={
                  $selectedUpperExpertiseSubTab as StoreStateSelector<
                    Store,
                    UpperExpertiseHintsSubTab
                  >
                }
                disabledTabs={disabledUpperExpertiseSubTabs as readonly UpperExpertiseHintsSubTab[]}
              />
              {selectedUpperExpertiseSubTab === 'WORK_SHEET_HINTS' && (
                <DisplayWorkSheetHintsComponent
                  $imageModal={$gs.$imageModal}
                  isOnline={isOnline}
                  objectId={initialKanban.id}
                  computeAttachmentUrl={computeAttachmentUrl}
                  $={$expertOperatorState.$workSheetHintsTabState}
                  workSheetFileName={initialKanban.attributes[WORK_SHEET_ATTRIBUTE_NAME] as string}
                />
              )}
              {selectedUpperExpertiseSubTab === 'PREDICTIONS' && (
                <DisplayPredictionsComponent
                  isOnline={isOnline}
                  $={$expertOperatorState}
                  kanbanId={initialKanban.id}
                  kanbanInfos={initialKanban.infos}
                  sparePartManagementType={contract.sparePartManagementType}
                />
              )}
            </>
          )}
          {selectedUpperExpertiseTab === 'ATTACHMENTS' && (
            <div className="p-md">
              <AttachmentsGallery
                category="kanban"
                objectId={initialKanban.id}
                folders={expertiseAttachmentFolder}
                $={$attachmentsTab}
                $window={$gs.$window}
                removeToolkit={{
                  onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
                  showRemoveAction: (): boolean => !isActionDisabled,
                }}
                $imageModal={$imageModal}
                computeAttachmentUrl={computeAttachmentUrl}
                loadAttachmentsActionCallback={loadGalleryAttachmentsActionCallback}
                uploadToolkit={{
                  importAttachmentsActionCallback:
                    importGalleryAttachmentsAndClearLocalStorageActionCallback,
                  onImportButtonClickedCallback: saveStateInLocalStorageCallback,
                }}
                isOnline={isOnline}
              />
            </div>
          )}
          {selectedUpperExpertiseTab === 'ATTRIBUTES' && (
            <div style={{ flex: 1 }}>
              <DisplayAttributesComponent
                submitKanbanAttributeModificationCallback={
                  submitKanbanAttributeModificationCallback
                }
                $={$expertOperatorState.$attributesState}
                isEditable={!isActionDisabled}
                scrollableProps={{ sizeInPx: 255 }}
              />
            </div>
          )}
          {selectedUpperExpertiseTab === 'GENERAL_INFOS' && (
            <KanbanGeneralInformations kanban={initialKanban} contract={contract} />
          )}
          {selectedUpperExpertiseTab === 'DEFECTS' && (
            <DefectsTabComponent
              $={$expertOperatorState.$defectsTabState}
              $kanban={$expertOperatorState.$initialKanban}
              $packageDeals={$expertOperatorState.$packageDealListComponentState.$packageDeals}
              $gs={$gs}
              isEditable={!isActionDisabled}
            />
          )}
        </div>
      )}
    </>
  );
}
