import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RepositoryEntity } from '@stimcar/libs-base';
import { FarCheckCircle, FasPulseSpinner, FasShareSquare } from '@stimcar/libs-uitoolkit';

interface RepositoryEntityDirtyIconProps {
  readonly entity: RepositoryEntity;
  readonly isOnline: boolean;
  readonly isSmall?: boolean;
}

export function RepositoryEntityDirtyIcon({
  entity,
  isOnline,
  isSmall,
}: RepositoryEntityDirtyIconProps): JSX.Element {
  const [t] = useTranslation('refititCommonComponents');

  const size = isSmall ? 'small' : undefined;
  return (
    <>
      {!entity.dirty && (
        <FarCheckCircle tooltip={t('repositoryEntityDirtyTooltip.synchronized')} size={size} />
      )}
      {entity.dirty && isOnline && (
        <FasPulseSpinner tooltip={t('repositoryEntityDirtyTooltip.synchronizing')} size={size} />
      )}
      {entity.dirty && !isOnline && (
        <FasShareSquare tooltip={t('repositoryEntityDirtyTooltip.unreachable')} size={size} />
      )}
    </>
  );
}
