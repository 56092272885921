import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, PackageDeal } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { packageDealHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import type {
  MarkAsUnachievablePackageDealFormData,
  MarkAsUnachievablePackageDealModalState,
  PkgUnachievableStatus,
} from './typings/store.js';
import { EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_MODAL_STATE } from './typings/store.js';

export function openMarkAsUnachievablePackageDealModal(
  { actionDispatch }: ActionContext<Store, MarkAsUnachievablePackageDealModalState>,
  packageDealId: string,
  packageDealName: string,
  pkgUnachievableStatus: PkgUnachievableStatus,
  kanban: Kanban
): void {
  const newKanban: Kanban = {
    ...kanban,
    packageDeals: kanban.packageDeals.map((p): PackageDeal => {
      if (p.id === packageDealId) {
        return {
          ...p,
          status: 'canceled',
        };
      }
      return p;
    }),
  };
  const updatedPkgs = packageDealHelpers.updateAllPackageDealsExpressionComputations(newKanban);
  let priceMidified = false;
  kanban.packageDeals.forEach((p) => {
    const found = updatedPkgs.find((pkg) => pkg.id === p.id);
    if (isTruthy(found) && found.price !== p.price) {
      priceMidified = true;
    }
  });
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial): MarkAsUnachievablePackageDealModalState => {
      return {
        ...EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_MODAL_STATE,
        active: true,
        packageDealId,
        packageDealName,
        kanbanId: kanban.id,
        pkgUnachievableStatus: !priceMidified
          ? pkgUnachievableStatus
          : 'unmarkableAsUnachievableBecauseGroupedByTag',
        formData: {
          reason: '',
          warnings: {},
        },
      };
    }
  );
}

async function markPackageDealAsUnachievableAction({
  getState,
  actionDispatch,
  kanbanRepository,
}: ActionContext<Store, MarkAsUnachievablePackageDealModalState>): Promise<void> {
  const { formData, packageDealId, kanbanId } = getState();
  const { reason } = formData;
  const kanban = await kanbanRepository.getEntity(kanbanId);
  const pkgToMarkAsUnachievable = kanban.packageDeals.find((p) => p.id === packageDealId);
  if (isTruthy(pkgToMarkAsUnachievable)) {
    const newPkgs = kanban.packageDeals.map((p): PackageDeal => {
      if (p.id === pkgToMarkAsUnachievable.id) {
        return {
          ...pkgToMarkAsUnachievable,
          unachievableReason: reason,
        };
      }
      return p;
    });
    const newKanban: Kanban = {
      ...kanban,
      packageDeals: newPkgs,
    };
    await kanbanRepository.updateEntity(newKanban);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial: MarkAsUnachievablePackageDealModalState) => {
    return {
      ...EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_MODAL_STATE,
    };
  });
}

const mandatoryFields: (keyof MarkAsUnachievablePackageDealFormData)[] = ['reason'];

interface Props {
  readonly $: StoreStateSelector<Store, MarkAsUnachievablePackageDealModalState>;
}

export function MarkAsUnachievablePackageDealModal({ $ }: Props): JSX.Element {
  const [t] = useTranslation('details');
  const formWarning = useGetState($.$formWarning);
  const packageDealName = useGetState($.$packageDealName);
  const pkgUnachievableStatus = useGetState($.$pkgUnachievableStatus);
  const submitValidDataAction = useActionCallback(markPackageDealAsUnachievableAction, [], $);

  const [onFormSubmit, , $formDataWithChangeRecorder] = useFormWithValidation<
    Store,
    MarkAsUnachievablePackageDealModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <>
      {pkgUnachievableStatus === 'markableAsUnachievable' && (
        <ModalCardDialog
          $active={$.$active}
          title={t('tabs.packageDeals.markAsUnachievablePackageDealModal.title')}
          onOkClicked={onFormSubmit}
          warning={formWarning}
        >
          {t('tabs.packageDeals.markAsUnachievablePackageDealModal.msg', { packageDealName })}
          <div className="m-t-md">
            <InputFormField
              type="text"
              label={t('tabs.packageDeals.markAsUnachievablePackageDealModal.reason')}
              placeholder={t('tabs.packageDeals.markAsUnachievablePackageDealModal.reason')}
              $={$formDataWithChangeRecorder.$reason}
              horizontal
            />
          </div>
        </ModalCardDialog>
      )}
      {(pkgUnachievableStatus === 'unmarkableAsUnachievableBecauseMandatory' ||
        pkgUnachievableStatus === 'unmarkableAsUnachievableBecauseOfFinishedOperations' ||
        pkgUnachievableStatus === 'unmarkableAsUnachievableBecauseOfStatus' ||
        pkgUnachievableStatus === 'unmarkableAsUnachievableBecauseGroupedByTag' ||
        pkgUnachievableStatus === 'unmarkableAsUnachievableBecauseOrderedOrReceivedSpareParts') && (
        <ModalCardDialog
          $active={$.$active}
          titleIconId="exclamation-triangle"
          title={t('tabs.packageDeals.markAsUnachievablePackageDealModal.title')}
        >
          <p>
            {t(`tabs.packageDeals.markAsUnachievablePackageDealModal.${pkgUnachievableStatus}Msg`, {
              packageDealName,
            })}
          </p>
        </ModalCardDialog>
      )}
    </>
  );
}

export function openRepickPackageDealModal(
  { actionDispatch }: ActionContext<Store, MarkAsUnachievablePackageDealModalState>,
  packageDealId: string,
  packageDealName: string,
  kanbanId: string
): void {
  actionDispatch.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial): MarkAsUnachievablePackageDealModalState => {
      return {
        ...EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_MODAL_STATE,
        repickActive: true,
        packageDealId,
        packageDealName,
        kanbanId,
      };
    }
  );
}

async function repickPackageDealAction({
  getState,
  actionDispatch,
  kanbanRepository,
}: ActionContext<Store, MarkAsUnachievablePackageDealModalState>): Promise<void> {
  const { packageDealId, kanbanId } = getState();
  const kanban = await kanbanRepository.getEntity(kanbanId);
  const pkgToPick = kanban.packageDeals.find((p) => p.id === packageDealId);
  if (isTruthy(pkgToPick)) {
    const newPkgs = kanban.packageDeals.map((p): PackageDeal => {
      if (p.id === pkgToPick.id) {
        return {
          ...pkgToPick,
          unachievableReason: null,
        };
      }
      return p;
    });
    const newKanban: Kanban = {
      ...kanban,
      packageDeals: newPkgs,
    };
    await kanbanRepository.updateEntity(newKanban);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.reduce((initial: MarkAsUnachievablePackageDealModalState) => {
    return {
      ...EMPTY_MARK_AS_UNACHIEVABLE_PACKAGE_DEAL_MODAL_STATE,
    };
  });
}

export function RepickPackageDealModal({ $ }: Props): JSX.Element {
  const [t] = useTranslation('details');
  const packageDealName = useGetState($.$packageDealName);
  const submitValidDataAction = useActionCallback(repickPackageDealAction, [], $);

  const [onFormSubmit] = useFormWithValidation<Store, MarkAsUnachievablePackageDealModalState>({
    $,
    mandatoryFields: [],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$.$repickActive}
      title={t('tabs.packageDeals.repickPackageDealModal.title')}
      onOkClicked={onFormSubmit}
    >
      {t('tabs.packageDeals.repickPackageDealModal.msg', { packageDealName })}
    </ModalCardDialog>
  );
}
