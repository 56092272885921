import React from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { InputFormFieldProps } from '@stimcar/libs-uitoolkit';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useFormFieldWarning } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import type { InputWithNumericKeyboardSpecificProps } from './InputWithNumericKeyboard.js';
import { InputWithNumericKeyboard } from './InputWithNumericKeyboard.js';

export type InputWithNumericKeyboardFormFieldProps<SD extends AnyStoreDef> = Omit<
  InputFormFieldProps<SD>,
  'noExclamationTriangleIfWarning' | 'dispatch'
> &
  InputWithNumericKeyboardSpecificProps<SD> & {
    readonly $: StoreStateSelector<SD, string | undefined>;
  };

export function InputWithNumericKeyboardFormField<SD extends AnyStoreDef>({
  label,
  $,
  horizontal,
  ...rest
}: InputWithNumericKeyboardFormFieldProps<SD>): JSX.Element {
  const dispatchWarning = useFormFieldWarning($);
  const hasWarning = isTruthyAndNotEmpty(dispatchWarning);
  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning
    >
      <InputWithNumericKeyboard $={$} warning={hasWarning} {...rest} />
    </FormField>
  );
}
