/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Kanban,
  PackageDeal,
  PurchaseOrder,
  RepositoryEntityStatus,
  SparePart,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, CheckFormConsistencyAction, FormFieldEntry } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  DELEGATION_SOURCE,
  enumerate,
  globalHelpers,
  KANBAN_ATTRIBUTES,
  nonDeleted,
  PACKAGE_DEAL_ATTRIBUTES,
  PACKAGE_DEAL_CODES_THAT_CANNOT_BE_REMOVED,
  packageDealHelpers,
  shortDayMonthYearHourFormatOptions,
  sortingHelpers,
  sparePartHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useFormFieldWarning, useGetState } from '@stimcar/libs-uikernel';
import {
  FaIcon,
  ModalCardDialog,
  ScrollableTableComponent,
  TableActionCell,
  useFormWithValidation,
  WithWarning,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../state/typings/store.js';
import { TableActionHeaderCell } from '../../lib/bulma/elements/table/TableHeaderActionCell.js';
import { Checkbox } from '../../lib/bulma/form/Checkbox.js';
import { TextArea } from '../../lib/bulma/form/TextArea.js';
import { PackageDealRecommendationLogo } from '../../lib/components/images/StimcarLogo.js';
import { DisplayContentOrPlaceholder } from '../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { TruncableLi } from '../../lib/components/misc/TruncableLi.js';
import { ShowHideBoxContainer } from '../../lib/components/ShowHideContainer.js';
import { TableSortableHeaderComponent } from '../../lib/components/TableSortableHeaderComponent.js';
import { Tabs } from '../../lib/components/Tabs.js';
import { useHasModifyPermission } from '../../registeredapp/permissionHooks.js';
import { useGetAllCarViewCategoryLabels } from '../../utils/carViewCategoriesTranslationHooks.js';
import { useCategoryEntries } from '../utils/useCategoryEntries.js';
import { useIsWorkshopLogin } from '../utils/useIsWorkshopLogin.js';
import type {
  EditPackageDealWorkshopCommentModalState,
  KanbanDetailsState,
  MobileDetailsSubPartState,
  PackageDealDetailsInternalDataStructure,
  PackageDealsTabState,
  SparePartDetailStatus,
} from './typings/store.js';
import { DelegatePackageDealModal, toggleDelegatePackageDeal } from './DelegatePackageDealModal.js';
import {
  DURATION_DECIMAL_LENGTH,
  getSparePartDetailStatus,
  kanbanToDetailsInternalPackageDealStructure,
  PRICE_DECIMAL_LENGTH,
} from './kanbanDetailsUtils.js';
import {
  MarkAsUnachievablePackageDealModal,
  openMarkAsUnachievablePackageDealModal,
  openRepickPackageDealModal,
  RepickPackageDealModal,
} from './MarkAsUnachievablePackageDealModal.js';
import { MobileDetailsModalMessageDialog } from './MobileDetailsModalMessageDialog.js';
import { SparePartStatusIcon } from './SparePartStatusIcon.js';
import {
  EDIT_PACKAGE_DEAL_WORKSHOP_COMMENT_MODAL_EMPTY_STATE,
  PackageDealsDisplayTabs,
} from './typings/store.js';

const computeCompletionStatusMsgAndTooltip = (
  t: TFunction,
  data: PackageDealDetailsInternalDataStructure
): { msg: string; tooltip: string } => {
  if (data.operationsCount === 0) {
    return {
      msg: t('tabs.packageDeals.noOperationStatusMsg'),
      tooltip: '',
    };
  }
  if (isTruthy(data.completionDate)) {
    return {
      msg: t('tabs.packageDeals.completedStatusMsg', {
        formattedCompletionDate: globalHelpers.convertTimestampToDateString(
          data.completionDate,
          shortDayMonthYearHourFormatOptions
        ),
      }),
      tooltip: t('tabs.packageDeals.startedStatusTooltip', {
        formattedStartDate: globalHelpers.convertTimestampToDateString(
          data.startDate,
          shortDayMonthYearHourFormatOptions
        ),
      }),
    };
  }
  if (isTruthy(data.startDate)) {
    return {
      msg: t('tabs.packageDeals.startedStatusMsg'),
      tooltip: t('tabs.packageDeals.startedStatusTooltip', {
        formattedStartDate: globalHelpers.convertTimestampToDateString(
          data.startDate,
          shortDayMonthYearHourFormatOptions
        ),
      }),
    };
  }
  return {
    msg: t('tabs.packageDeals.unstartedStatusMsg'),
    tooltip: '',
  };
};

const computeSparePartCompletionStatusMsgAndTooltip = (
  t: TFunction,
  sparePart: SparePart
): { msg: string; tooltip: string } => {
  if (isTruthy(sparePart.dateOfReception)) {
    return {
      msg: t('tabs.packageDeals.completedStatusMsg', {
        formattedCompletionDate: globalHelpers.convertTimestampToDateString(
          sparePart.dateOfReception,
          shortDayMonthYearHourFormatOptions
        ),
      }),
      tooltip: t('tabs.packageDeals.orderedStatusTooltip', {
        formattedDateOfOrder: globalHelpers.convertTimestampToDateString(
          sparePart.dateOfOrder,
          shortDayMonthYearHourFormatOptions
        ),
      }),
    };
  }
  if (isTruthy(sparePart.dateOfOrder)) {
    return {
      msg: t('tabs.packageDeals.startedStatusMsg'),
      tooltip: t('tabs.packageDeals.orderedStatusTooltip', {
        formattedDateOfOrder: globalHelpers.convertTimestampToDateString(
          sparePart.dateOfOrder,
          shortDayMonthYearHourFormatOptions
        ),
      }),
    };
  }
  return {
    msg: t('tabs.packageDeals.unstartedStatusMsg'),
    tooltip: '',
  };
};

function sortByNullCompletionDate(
  p1: PackageDealDetailsInternalDataStructure,
  p2: PackageDealDetailsInternalDataStructure
): number {
  if (!isTruthy(p1.completionDate)) {
    return !isTruthy(p2.completionDate) ? 0 : -1;
  }
  return !isTruthy(p2.completionDate) ? 1 : 0;
}

function sortByNoOperation(
  p1: PackageDealDetailsInternalDataStructure,
  p2: PackageDealDetailsInternalDataStructure
): number {
  if (p1.operationsCount === 0) {
    return p2.operationsCount === 0 ? 0 : 1;
  }
  return p2.operationsCount === 0 ? -1 : 0;
}

function getDataPriceIncludingSparePart(data: PackageDealDetailsInternalDataStructure): number {
  return data.price + sparePartHelpers.sumPrice(data.spareParts.filter(nonDeleted));
}

interface SingleSparePartRowComponentProps {
  readonly sparePart: SparePart;
  readonly kanban: Kanban;
  readonly showTechnicalId: boolean;
  readonly showOptionalColumns: ShowOptionalColumns;
}

function SingleSparePartRowComponent({
  sparePart,
  kanban,
  showTechnicalId,
  showOptionalColumns,
}: SingleSparePartRowComponentProps): JSX.Element {
  const [t] = useTranslation('details');
  const completionStatusMsgAndTooltip = computeSparePartCompletionStatusMsgAndTooltip(t, sparePart);

  const computedLabel = useMemo(() => {
    const containingPkgDeal = sparePartHelpers.getPackageDealThatHasGivenSparePart(
      kanban.packageDeals,
      sparePart.id
    );
    if (containingPkgDeal !== undefined) {
      return packageDealHelpers.getSparePartDisplayedLabel(sparePart, containingPkgDeal);
    }
    return sparePart.label;
  }, [sparePart, kanban]);

  return (
    <React.Fragment key={sparePart.id}>
      <tr>
        <td />
        {showTechnicalId && <td>{sparePart.id}</td>}
        <td colSpan={3} />
        <td>{`- ${computedLabel}`}</td>
        {showOptionalColumns.delegationSite && <td>&nbsp;</td>}
        {showOptionalColumns.delegateAction && <td>&nbsp;</td>}
        <td colSpan={2} />
        <td className="has-text-right">
          {t('tabs.price', {
            price: sparePart.price.toFixed(PRICE_DECIMAL_LENGTH),
          })}
        </td>
        <td>&nbsp;</td>
        {showOptionalColumns.hint && <td>&nbsp;</td>}
        {showOptionalColumns.comment && <td>{sparePart.comment}</td>}
        {showOptionalColumns.updateCommentAction && <td>&nbsp;</td>}
        {showOptionalColumns.completionStatus && (
          <td title={completionStatusMsgAndTooltip.tooltip}>{completionStatusMsgAndTooltip.msg}</td>
        )}
        {showOptionalColumns.unachievableReason && <td>&nbsp;</td>}
        {showOptionalColumns.markAsUnachievableAction && <td>&nbsp;</td>}
        {showOptionalColumns.repickAction && <td>&nbsp;</td>}
      </tr>
    </React.Fragment>
  );
}

interface ShowOptionalColumns {
  readonly delegationSite: boolean;
  readonly hint: boolean;
  readonly comment: boolean;
  readonly completionStatus: boolean;
  readonly unachievableReason: boolean;
  readonly markAsUnachievableAction: boolean;
  readonly repickAction: boolean;
  readonly delegateAction: boolean;
  readonly updateCommentAction: boolean;
}

interface PackageDealItemProps extends AppProps<Store> {
  readonly packageDeal: PackageDealDetailsInternalDataStructure;
  readonly $: StoreStateSelector<Store, PackageDealsTabState>;
  readonly showTechnicalId: boolean;
  readonly showPurchaseOrderId: boolean;
  readonly kanbanId: string;
  readonly kanban: Kanban;
  readonly showOptionalColumns: ShowOptionalColumns;
}

function PackageDealItem({
  packageDeal,
  $,
  $gs,
  showTechnicalId,
  showPurchaseOrderId,
  kanbanId,
  kanban,
  showOptionalColumns,
}: PackageDealItemProps): JSX.Element {
  const [t] = useTranslation('details');
  const isWorkshopLogin = useIsWorkshopLogin($gs);

  const hasMarkPackAsUnachievablePermission = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.MARK_PACKAGE_DEALS_AS_UNACHIEVABLE_ACTION
  );

  const openUpdatePackageDealCommentModalCallback = useActionCallback(
    ({ actionDispatch }: ActionContext<Store, PackageDealsTabState>): void => {
      actionDispatch.scopeProperty('updateWorkshopCommentModal').applyPayload({
        active: true,
        formData: {
          comment: packageDeal.comment,
        },
        initialComment: packageDeal.comment,
        packageDealId: packageDeal.id,
        packageDealCode: packageDeal.code,
        kanbanId: kanban.id,
      });
    },
    [packageDeal.comment, packageDeal.id, packageDeal.code, kanban.id],
    $
  );

  const onToggleDelegatePackageDealCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, PackageDealsTabState>): Promise<void> => {
      await actionDispatch
        .scopeProperty('delegatePackageDealModalState')
        .exec(toggleDelegatePackageDeal, kanbanId, packageDeal.id);
    },
    [kanbanId, packageDeal.id],
    $
  );

  const openRepickPackageDealModalCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, PackageDealsTabState>): Promise<void> => {
      await actionDispatch
        .scopeProperty('markAsUnachievablePackageDealModalState')
        .exec(openRepickPackageDealModal, packageDeal.id, packageDeal.label, kanbanId);
    },
    [kanbanId, packageDeal.id, packageDeal.label],
    $
  );

  const completionStatusMsgAndTooltip = computeCompletionStatusMsgAndTooltip(t, packageDeal);

  const isMarkableAsUnachievable =
    !isWorkshopLogin &&
    hasMarkPackAsUnachievablePermission &&
    packageDeal.unachievableStatus === 'markableAsUnachievable';

  const isRepickable =
    !isWorkshopLogin &&
    hasMarkPackAsUnachievablePermission &&
    packageDeal.unachievableStatus === 'repickable';

  let notMarkableAsUnachievableTooltip = '';
  switch (packageDeal.unachievableStatus) {
    case 'unmarkableAsUnachievableBecauseMandatory':
    case 'unmarkableAsUnachievableBecauseOfFinishedOperations':
    case 'unmarkableAsUnachievableBecauseOrderedOrReceivedSpareParts':
    case 'unmarkableAsUnachievableBecauseOfStatus':
    case 'unmarkableAsUnachievableBecauseGroupedByTag':
      notMarkableAsUnachievableTooltip = t(
        `tabs.packageDeals.markAsUnachievablePackageDealModal.${packageDeal.unachievableStatus}Msg`,
        {
          packageDealName: packageDeal.label,
          finishedOperationsLabel: enumerate(
            packageDeal.operations
              .filter((o) => !o.deleted && isTruthy(o.completionDate))
              .map((o) => o.label)
          ),
          orderedOrReceivedSpareParts: enumerate(
            packageDeal.spareParts
              .filter(
                (sp) => nonDeleted(sp) && (isTruthy(sp.dateOfOrder) || isTruthy(sp.dateOfReception))
              )
              .map((sp) => sp.label)
          ),
          status: packageDeal.status,
        }
      );
      break;
    default:
      break;
  }
  const isDelegable = useMemo(() => {
    return (
      !packageDeal.startDate /*  is started */ &&
      !PACKAGE_DEAL_CODES_THAT_CANNOT_BE_REMOVED.includes(packageDeal.code)
    );
  }, [packageDeal.code, packageDeal.startDate]);

  const openMarkAsUnachievablePackageDealModalCallback = useActionCallback(
    async ({
      actionDispatch,
      globalActionDispatch,
    }: ActionContext<Store, PackageDealsTabState>): Promise<void> => {
      if (!isMarkableAsUnachievable) {
        globalActionDispatch.setProperty('message', notMarkableAsUnachievableTooltip);
      } else {
        await actionDispatch
          .scopeProperty('markAsUnachievablePackageDealModalState')
          .exec(
            openMarkAsUnachievablePackageDealModal,
            packageDeal.id,
            packageDeal.label,
            packageDeal.unachievableStatus,
            kanban
          );
      }
    },
    [
      isMarkableAsUnachievable,
      notMarkableAsUnachievableTooltip,
      packageDeal.id,
      packageDeal.label,
      packageDeal.unachievableStatus,
      kanban,
    ],
    $
  );

  return (
    <React.Fragment key={packageDeal.id}>
      <tr key={packageDeal.id}>
        <td>
          {packageDeal.attachments.length > 0 ? (
            <FaIcon
              id="image"
              tooltip={enumerate(packageDeal.attachments.map(({ name }) => name))}
            />
          ) : (
            ''
          )}
        </td>
        {showTechnicalId && <td>{packageDeal.id}</td>}
        {showPurchaseOrderId && <td>{packageDeal.purchaseOrder ?? ''}</td>}
        <td>{packageDeal.translatedCategoryLabel}</td>
        <td>{packageDeal.code}</td>
        <td>
          <PackageDealRecommendationLogo recommendedByExpert={packageDeal.recommendedByExpert} />
        </td>
        <td>{packageDeal.label}</td>
        {showOptionalColumns.delegationSite && (
          <td>
            {packageDeal.attributes[PACKAGE_DEAL_ATTRIBUTES.DELEGATED]
              ? String(kanban.attributes[KANBAN_ATTRIBUTES.DELEGATION_SITE]).replace(/^.*\//, '')
              : ''}
          </td>
        )}
        {showOptionalColumns.delegateAction &&
          (isDelegable ? (
            <TableActionCell
              onClick={onToggleDelegatePackageDealCallback}
              iconId={
                packageDeal.attributes[PACKAGE_DEAL_ATTRIBUTES.DELEGATED] ? 'times' : 'handshake'
              }
              tooltip={t('tabs.packageDeals.delegateModal.title')}
            />
          ) : (
            <td>&nbsp;</td>
          ))}
        <td>{packageDeal.tag}</td>
        <td>{packageDeal.carElement}</td>
        <td className="has-text-right">
          {t('tabs.price', {
            price: packageDeal.price.toFixed(PRICE_DECIMAL_LENGTH),
          })}
        </td>
        <td className="has-text-right">
          {t('tabs.hoursDuration', {
            hours: packageDeal.duration.toFixed(DURATION_DECIMAL_LENGTH),
          })}
        </td>
        {showOptionalColumns.hint && <td style={{ whiteSpace: 'pre-wrap' }}>{packageDeal.hint}</td>}
        {showOptionalColumns.comment && (
          <td style={{ whiteSpace: 'pre-wrap' }}>{packageDeal.comment}</td>
        )}
        {showOptionalColumns.updateCommentAction && (
          <TableActionCell
            onClick={openUpdatePackageDealCommentModalCallback}
            iconId="edit"
            tooltip={t('tabs.packageDeals.updateCommentModal.title')}
          />
        )}
        {showOptionalColumns.completionStatus && (
          <td title={completionStatusMsgAndTooltip.tooltip}>{completionStatusMsgAndTooltip.msg}</td>
        )}
        {showOptionalColumns.unachievableReason && (
          <td className="has-text-left">{packageDeal.unachievableReason}</td>
        )}
        {showOptionalColumns.markAsUnachievableAction &&
          (packageDeal.completionDate === null &&
          packageDeal.unachievableStatus !== 'unmarkableAsUnachievableBecauseMandatory' ? (
            <TableActionCell
              onClick={openMarkAsUnachievablePackageDealModalCallback}
              iconId="s/cut"
              tooltip={
                isMarkableAsUnachievable
                  ? t('tabs.packageDeals.markAsUnachievablePackageDealModal.title')
                  : notMarkableAsUnachievableTooltip
              }
            />
          ) : (
            <td />
          ))}
        {showOptionalColumns.repickAction &&
          (isRepickable ? (
            <TableActionCell
              onClick={openRepickPackageDealModalCallback}
              iconId="undo"
              tooltip={t('tabs.packageDeals.repickPackageDealModal.title')}
              disabled={!isRepickable}
            />
          ) : (
            <td>&nbsp;</td>
          ))}
      </tr>
      {packageDeal.spareParts.filter(nonDeleted).map((sp): JSX.Element => {
        return (
          <SingleSparePartRowComponent
            key={sp.id}
            sparePart={sp}
            kanban={kanban}
            showTechnicalId={showTechnicalId}
            showOptionalColumns={showOptionalColumns}
          />
        );
      })}
    </React.Fragment>
  );
}

interface Props extends AppProps<Store> {
  readonly packageDeals: readonly PackageDeal[];
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
  readonly delegationSite: string | undefined;
  readonly delegatedStatus: RepositoryEntityStatus | undefined;
  readonly isEditable: boolean;
}

export function DisplayAllPackageDealsComponent({
  packageDeals,
  $,
  $gs,
  isEditable,
  delegationSite,
  delegatedStatus,
}: Props): JSX.Element {
  const [t] = useTranslation('details');
  const selectedKanban = nonnull(useGetState($.$selectedKanban));
  const hasDelegation = isTruthy(selectedKanban.attributes[KANBAN_ATTRIBUTES.DELEGATION_SITE]);
  const isADelegatedKanban = selectedKanban.origin.source === DELEGATION_SOURCE;
  const { $packageDealsTab } = $.$desktopState;

  const availablePackageDeals = useMemo((): readonly PackageDeal[] => {
    return packageDeals.filter((pkg) =>
      packageDealHelpers.isPackageDealAvailableAndAchievable(pkg, true)
    );
  }, [packageDeals]);
  const canceledPackageDeals = useMemo((): readonly PackageDeal[] => {
    return packageDeals.filter((pkg) => packageDealHelpers.isPackageDealCanceled(pkg, true));
  }, [packageDeals]);
  const unachievablePackageDeals = useMemo((): readonly PackageDeal[] => {
    return packageDeals.filter((pkg) => !packageDealHelpers.isPackageDealAchievable(pkg));
  }, [packageDeals]);

  const tabLabels: Record<string, string> = useMemo(() => {
    const labels: Record<string, string> =
      unachievablePackageDeals.length > 0
        ? {
            availablePackageDeals: t('tabs.packageDeals.allAvailableTitle'),
            unachievablePackageDeals: t('tabs.packageDeals.allUnachievableTitle'),
            canceledPackageDeals: t('tabs.packageDeals.allCanceledTitle'),
          }
        : {
            availablePackageDeals: t('tabs.packageDeals.allAvailableTitle'),
            canceledPackageDeals: t('tabs.packageDeals.allCanceledTitle'),
          };
    return labels;
  }, [t, unachievablePackageDeals.length]);

  const packageDealsDisplayTabs = useGetState($packageDealsTab.$packageDealsDisplayTabs);

  const displayContent = (): JSX.Element => {
    switch (packageDealsDisplayTabs) {
      case PackageDealsDisplayTabs.availablePackageDeals:
        return (
          <DisplayPackageDealsComponent
            packageDeals={availablePackageDeals}
            $={$}
            $gs={$gs}
            showOptionalColumns={{
              delegationSite: hasDelegation,
              hint: true,
              comment: true,
              completionStatus: true,
              unachievableReason: false,
              markAsUnachievableAction: isEditable,
              repickAction: false,
              // Allowed except if it is a delegated kanban (nested delegation is not supported)
              // and if the delegated kanban has not been archived or closed
              delegateAction:
                isEditable &&
                !isADelegatedKanban &&
                (delegatedStatus === undefined || delegatedStatus === 'open'),
              updateCommentAction: isEditable,
            }}
            delegationSite={delegationSite}
          />
        );
      case PackageDealsDisplayTabs.canceledPackageDeals:
        return (
          <DisplayPackageDealsComponent
            packageDeals={canceledPackageDeals}
            $={$}
            $gs={$gs}
            showOptionalColumns={{
              delegationSite: false,
              hint: true,
              comment: true,
              completionStatus: false,
              unachievableReason: false,
              markAsUnachievableAction: false,
              repickAction: false,
              delegateAction: false,
              updateCommentAction: false,
            }}
            delegationSite={delegationSite}
          />
        );
      case PackageDealsDisplayTabs.unachievablePackageDeals:
        return (
          <DisplayPackageDealsComponent
            packageDeals={unachievablePackageDeals}
            $={$}
            $gs={$gs}
            showOptionalColumns={{
              delegationSite: false,
              hint: false,
              comment: false,
              completionStatus: false,
              unachievableReason: true,
              markAsUnachievableAction: false,
              repickAction: isEditable,
              delegateAction: false,
              updateCommentAction: false,
            }}
            delegationSite={delegationSite}
          />
        );
      default:
        return <div>{t('tabs.incorrectTab')}</div>;
    }
  };

  return (
    <>
      <div className="columns">
        <div className="column">
          <div className="box">
            <Tabs
              isCentered
              labels={tabLabels}
              $selectedTab={$packageDealsTab.$packageDealsDisplayTabs}
              className="narrow-bottom-margin"
            />
            {displayContent()}
          </div>
        </div>
      </div>
    </>
  );
}

interface DisplayPackageDealsComponentProps extends AppProps<Store> {
  readonly packageDeals: readonly PackageDeal[];
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
  readonly showOptionalColumns: ShowOptionalColumns;
  readonly delegationSite: string | undefined;
}

export function DisplayPackageDealsComponent({
  packageDeals,
  $,
  $gs,
  showOptionalColumns,
  delegationSite,
}: DisplayPackageDealsComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const { $packageDealsTab } = $.$desktopState;
  const {
    $markAsUnachievablePackageDealModalState,
    $updateWorkshopCommentModal,
    $delegatePackageDealModalState,
  } = $packageDealsTab;

  const allCategoryEntries = useCategoryEntries();
  const expertiseCategoryLabels = useGetAllCarViewCategoryLabels();
  const purchaseOrders = useGetState($.$selectedKanban.$purchaseOrders);

  const internalDatas = useMemo(() => {
    return kanbanToDetailsInternalPackageDealStructure(
      packageDeals,
      expertiseCategoryLabels,
      purchaseOrders,
      delegationSite
    );
  }, [delegationSite, expertiseCategoryLabels, packageDeals, purchaseOrders]);

  const selectedCategories = useGetState($packageDealsTab.$selectedCategories);
  const sortBy = useGetState($packageDealsTab.$sort.$by);
  const sortDirection = useGetState($packageDealsTab.$sort.$direction);

  const filteredAndSortedPackageDeals = useMemo((): PackageDealDetailsInternalDataStructure[] => {
    const sortedDatas = internalDatas.filter((d) => {
      return selectedCategories.includes(d.category);
    });
    let sortFunction: (
      pd1: PackageDealDetailsInternalDataStructure,
      pd2: PackageDealDetailsInternalDataStructure
    ) => number;
    switch (sortBy) {
      case 'purchaseOrder':
      case 'code':
      case 'label':
      case 'translatedCategoryLabel':
      case 'carElement':
      case 'tag':
      case 'delegationSite':
        sortFunction = sortingHelpers.createSortByStringField(sortDirection, sortBy);
        break;
      case 'price':
      case 'duration':
      case 'completionDate':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      default:
        return sortedDatas
          .sort(sortingHelpers.createSortByNumericField('DOWN', 'startDate'))
          .sort(sortingHelpers.createSortByNumericField('UP', 'completionDate'))
          .sort(sortByNullCompletionDate)
          .sort(sortByNoOperation); // sortingHelpers.createSortByNumericField('UP', 'operationsCount') would re-sort pds already sorted by completionDate, we don't want that
    }
    return sortedDatas.sort(sortFunction);
  }, [internalDatas, selectedCategories, sortBy, sortDirection]);

  const computeTotalPriceForStatus = useCallback((): string => {
    return internalDatas
      .reduce((acc, d) => acc + getDataPriceIncludingSparePart(d), 0)
      .toFixed(PRICE_DECIMAL_LENGTH);
  }, [internalDatas]);

  const computeTotalDurationForStatus = useCallback((): string => {
    return internalDatas.reduce((acc, d) => acc + d.duration, 0).toFixed(DURATION_DECIMAL_LENGTH);
  }, [internalDatas]);

  const showTechnicalId = useGetState($packageDealsTab.$showTechnicalId);
  const selectedKanban = useGetState($.$selectedKanban);
  const multiplePurchaseOrders =
    isTruthy(selectedKanban) && selectedKanban.purchaseOrders.length > 1;

  return (
    <DisplayContentOrPlaceholder
      displayCondition={packageDeals.length > 0}
      placeholder={t('tabs.packageDeals.emptyPlaceholder')}
      isScrollable
    >
      <>
        <Checkbox $={$packageDealsTab.$showTechnicalId} text="ID " />
        <CheckboxForAllItems
          allCategories={allCategoryEntries.map((c) => c.id)}
          selectedCategories={selectedCategories}
          $={$packageDealsTab}
        />
        {allCategoryEntries.map((e): JSX.Element => {
          return (
            <CheckboxItem
              key={e.id}
              entry={e}
              selectedCategories={selectedCategories}
              $={$packageDealsTab}
            />
          );
        })}
        <ScrollableTableComponent tableClassName="table is-narrow is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>
                <FaIcon id="image" />
              </th>
              {showTechnicalId && <th>{t('tabs.idTitle')}</th>}
              {multiplePurchaseOrders && (
                <TableSortableHeaderComponent
                  content={t('tabs.packageDeals.purchaseOrder')}
                  centerLabel={false}
                  isTruncable
                  sortedField="purchaseOrder"
                  $sort={$packageDealsTab.$sort}
                />
              )}
              <TableSortableHeaderComponent
                content={t('tabs.packageDeals.category')}
                centerLabel={false}
                isTruncable
                sortedField="translatedCategoryLabel"
                $sort={$packageDealsTab.$sort}
              />
              <TableSortableHeaderComponent
                content={t('tabs.packageDeals.code')}
                centerLabel={false}
                isTruncable
                sortedField="code"
                $sort={$packageDealsTab.$sort}
              />
              <th>
                <PackageDealRecommendationLogo recommendedByExpert />
              </th>
              <TableSortableHeaderComponent
                content={t('tabs.packageDeals.label')}
                centerLabel={false}
                isTruncable
                sortedField="label"
                $sort={$packageDealsTab.$sort}
              />
              {showOptionalColumns.delegationSite && (
                <TableSortableHeaderComponent
                  content={t('tabs.packageDeals.delegationSite')}
                  centerLabel={false}
                  isTruncable
                  sortedField="delegationSite"
                  $sort={$packageDealsTab.$sort}
                />
              )}
              {showOptionalColumns.delegateAction && <TableActionHeaderCell />}
              <TableSortableHeaderComponent
                content={t('tabs.packageDeals.tag')}
                centerLabel={false}
                isTruncable
                sortedField="tag"
                $sort={$packageDealsTab.$sort}
              />
              <TableSortableHeaderComponent
                content={t('tabs.packageDeals.carElement')}
                centerLabel={false}
                isTruncable
                sortedField="carElement"
                $sort={$packageDealsTab.$sort}
              />
              <TableSortableHeaderComponent
                content={t('tabs.packageDeals.price')}
                centerLabel={false}
                isTruncable
                className="has-text-right"
                sortedField="price"
                $sort={$packageDealsTab.$sort}
              />
              <TableSortableHeaderComponent
                content={t('tabs.packageDeals.duration')}
                centerLabel={false}
                isTruncable
                className="has-text-right"
                sortedField="duration"
                $sort={$packageDealsTab.$sort}
              />
              {showOptionalColumns.hint && <th>{t('tabs.packageDeals.hint')}</th>}
              {showOptionalColumns.comment && <th>{t('tabs.packageDeals.comment')}</th>}
              {showOptionalColumns.updateCommentAction && <TableActionHeaderCell />}
              {showOptionalColumns.completionStatus && (
                <TableSortableHeaderComponent
                  content={t('tabs.packageDeals.completionStatus')}
                  centerLabel={false}
                  isTruncable
                  sortedField="completionDate"
                  $sort={$packageDealsTab.$sort}
                />
              )}
              {showOptionalColumns.unachievableReason && (
                <th>{t('tabs.packageDeals.markAsUnachievablePackageDealModal.reason')}</th>
              )}
              {showOptionalColumns.markAsUnachievableAction && <TableActionHeaderCell />}
              {showOptionalColumns.repickAction && <TableActionHeaderCell />}
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedPackageDeals.map((pd): JSX.Element => {
              return (
                <PackageDealItem
                  key={pd.id}
                  $gs={$gs}
                  packageDeal={pd}
                  $={$packageDealsTab}
                  showTechnicalId={showTechnicalId}
                  showPurchaseOrderId={multiplePurchaseOrders}
                  kanbanId={selectedKanban?.id ?? ''}
                  kanban={nonnull(selectedKanban)}
                  showOptionalColumns={showOptionalColumns}
                />
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={showTechnicalId ? 8 : 7} className="has-text-right">
                <strong>{t('tabs.packageDeals.total')}</strong>
              </td>
              <td className="has-text-right">
                {t('tabs.price', {
                  price: computeTotalPriceForStatus(),
                })}
              </td>
              <td className="has-text-right">
                {t('tabs.hoursDuration', {
                  hours: computeTotalDurationForStatus(),
                })}
              </td>
              <td colSpan={6} />
            </tr>
          </tfoot>
        </ScrollableTableComponent>
        <MarkAsUnachievablePackageDealModal $={$markAsUnachievablePackageDealModalState} />
        <RepickPackageDealModal $={$markAsUnachievablePackageDealModalState} />
        <UpdatePackageDealCommentModal $={$updateWorkshopCommentModal} />
        <DelegatePackageDealModal $={$delegatePackageDealModalState} />
      </>
    </DisplayContentOrPlaceholder>
  );
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  Store,
  EditPackageDealWorkshopCommentModalState
> = ({ formState, t }): string | undefined => {
  const { initialComment, formData } = formState;
  if (initialComment === formData.comment) {
    return t('globals:warnings.noChange');
  }
  return undefined;
};

interface UpdatePackageDealCommentModalProps {
  $: StoreStateSelector<Store, EditPackageDealWorkshopCommentModalState>;
}

function UpdatePackageDealCommentModal({ $ }: UpdatePackageDealCommentModalProps): JSX.Element {
  const [t] = useTranslation('details');

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch, getState, kanbanRepository }): Promise<void> => {
      const { kanbanId, packageDealId, formData, initialComment } = getState();
      if (formData.comment !== initialComment) {
        await kanbanRepository.updateEntityFromPayload({
          entityId: kanbanId,
          payload: { packageDeals: [{ id: packageDealId, comment: formData.comment }] },
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => EDIT_PACKAGE_DEAL_WORKSHOP_COMMENT_MODAL_EMPTY_STATE);
    },
    [],
    $
  );

  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation<
    Store,
    EditPackageDealWorkshopCommentModalState
  >({
    $,
    mandatoryFields: [],
    checkFieldContentActions: undefined,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const formWarning = useGetState($.$formWarning);

  const packageDealCode = useGetState($.$packageDealCode);
  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('tabs.packageDeals.updateCommentModal.title')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <p>{t('tabs.packageDeals.updateCommentModal.msg', { packageDealCode })}</p>
      <WithWarning warning={useFormFieldWarning($formWithChangeTrigger.$comment)}>
        <TextArea
          $={$formWithChangeTrigger.$comment}
          placeholder={t('tabs.packageDeals.updateCommentModal.placeholder')}
        />
      </WithWarning>
    </ModalCardDialog>
  );
}

interface CheckboxItemProps {
  readonly entry: FormFieldEntry<string>;
  readonly selectedCategories: readonly string[];
  readonly $: StoreStateSelector<Store, PackageDealsTabState>;
}

function CheckboxItem({ entry, selectedCategories, $ }: CheckboxItemProps): JSX.Element {
  const isChecked = selectedCategories.find((s) => s === entry.id) !== undefined;

  const toggleSelectCategoryCallback = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<
      Store,
      PackageDealsTabState
    >): // eslint-disable-next-line @typescript-eslint/require-await
    Promise<void> => {
      if (isChecked) {
        const newCategories = selectedCategories.filter((c) => c !== entry.id);
        actionDispatch.setProperty('selectedCategories', newCategories);
      } else {
        actionDispatch.setProperty('selectedCategories', [...selectedCategories, entry.id]);
      }
    },
    [entry.id, isChecked, selectedCategories],
    $
  );

  return (
    <label className="checkbox m-r-md">
      <input
        type="checkbox"
        className="m-r-xs"
        checked={isChecked}
        onChange={toggleSelectCategoryCallback}
      />
      {entry.label}
    </label>
  );
}

interface CheckboxForAllItemsProps {
  readonly selectedCategories: readonly string[];
  readonly allCategories: readonly string[];
  readonly $: StoreStateSelector<Store, PackageDealsTabState>;
}

function CheckboxForAllItems({
  selectedCategories,
  allCategories,
  $,
}: CheckboxForAllItemsProps): JSX.Element {
  const [t] = useTranslation('details');

  const isChecked =
    selectedCategories
      .filter((c) => !allCategories.includes(c))
      .concat(allCategories.filter((c) => !selectedCategories.includes(c))).length === 0;

  const toggleSelectCategoryCallback = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<
      Store,
      PackageDealsTabState
    >): // eslint-disable-next-line @typescript-eslint/require-await
    Promise<void> => {
      if (isChecked) {
        actionDispatch.setProperty('selectedCategories', []);
      } else {
        actionDispatch.setProperty('selectedCategories', allCategories);
      }
    },
    [isChecked, allCategories],
    $
  );

  return (
    <label className="checkbox m-r-md">
      <input
        type="checkbox"
        className="m-r-xs"
        checked={isChecked}
        onChange={toggleSelectCategoryCallback}
      />
      {t('tabs.packageDeals.checkAll')}
    </label>
  );
}

interface MobilePackageDealsComponentProps {
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<PackageDealDetailsInternalDataStructure>
  >;
  readonly displayedPackageDeals: readonly PackageDeal[];
  readonly purchaseOrders: readonly PurchaseOrder[];
  readonly tabHeaderLabelTitleKey: 'all' | 'available' | 'canceled' | 'unachievable';
  readonly delegationSite: string | undefined;
}

export function MobilePackageDealsComponent({
  $,
  displayedPackageDeals,
  purchaseOrders,
  tabHeaderLabelTitleKey,
  delegationSite,
}: MobilePackageDealsComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const expertiseCategoryLabels = useGetAllCarViewCategoryLabels();

  const packageDealsInternalDatas = useMemo(() => {
    const datas = kanbanToDetailsInternalPackageDealStructure(
      displayedPackageDeals,
      expertiseCategoryLabels,
      purchaseOrders,
      delegationSite
    );
    return datas
      .sort(sortingHelpers.createSortByNumericField('DOWN', 'startDate'))
      .sort(sortingHelpers.createSortByNumericField('UP', 'completionDate'))
      .sort(sortByNullCompletionDate)
      .sort(sortingHelpers.createSortByNumericField('UP', 'operationsCount'));
  }, [delegationSite, displayedPackageDeals, expertiseCategoryLabels, purchaseOrders]);

  const details = useGetState($.$showDetailsFor);

  const tabHeaderLabel = useMemo(() => {
    const price = packageDealsInternalDatas
      .reduce((acc, d) => acc + getDataPriceIncludingSparePart(d), 0)
      .toFixed(PRICE_DECIMAL_LENGTH);
    switch (tabHeaderLabelTitleKey) {
      case 'available':
      case 'canceled':
      case 'unachievable':
        return t(`tabs.packageDeals.${tabHeaderLabelTitleKey}Title`, {
          price,
        });
      default:
        return t(`tabs.packageDeals.${tabHeaderLabelTitleKey}Title`);
    }
  }, [packageDealsInternalDatas, tabHeaderLabelTitleKey, t]);

  const completionStatus = useMemo((): string => {
    if (!isTruthy(details)) {
      return t('tabs.noStatus');
    }
    return computeCompletionStatusMsgAndTooltip(t, details).msg;
  }, [details, t]);

  return (
    <>
      <ShowHideBoxContainer title={tabHeaderLabel} $={$.$isUnfolded} isMobile>
        <DisplayContentOrPlaceholder
          displayCondition={packageDealsInternalDatas.length > 0}
          placeholder={t('tabs.packageDeals.emptyPlaceholder')}
        >
          <>
            <ul className="hide-bullets">
              {packageDealsInternalDatas.map(
                (pd): JSX.Element => (
                  <React.Fragment key={pd.id}>
                    <MobilePackageDealLine packageDeal={pd} $={$} />
                    {pd.spareParts.map(
                      (sp): JSX.Element => (
                        <MobileSparePartLine key={sp.id} sparePart={sp} />
                      )
                    )}
                  </React.Fragment>
                )
              )}
            </ul>
          </>
        </DisplayContentOrPlaceholder>
      </ShowHideBoxContainer>
      <MobileDetailsModalMessageDialog $={$}>
        <>
          <p>
            <strong>{`${t('tabs.packageDeals.category')}: `}</strong>
            {details?.category}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.code')}: `}</strong>
            {details?.code}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.label')}: `}</strong>
            {details?.label}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.tag')}: `}</strong>
            {details?.tag}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.carElement')}: `}</strong>
            {isTruthyAndNotEmpty(details?.carElement)
              ? details?.carElement
              : t('tabs.packageDeals.noCarElement')}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.price')}: `}</strong>
            {t('tabs.price', {
              price: details
                ? getDataPriceIncludingSparePart(details).toFixed(PRICE_DECIMAL_LENGTH)
                : '',
            })}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.duration')}: `}</strong>
            {t('tabs.hoursDuration', {
              hours: details?.duration.toFixed(DURATION_DECIMAL_LENGTH),
            })}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.spareParts')}: `}</strong>
            {details ? enumerate(details.spareParts.map((sp) => sp.label)) : ''}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.hint')}: `}</strong>
            {details?.hint}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.comment')}: `}</strong>
            {details?.comment}
          </p>
          <p>
            <strong>{`${t('tabs.packageDeals.completionStatus')}: `}</strong>
            {completionStatus}
          </p>
        </>
      </MobileDetailsModalMessageDialog>
    </>
  );
}

const computeStatus = (
  t: TFunction,
  data: PackageDealDetailsInternalDataStructure
): JSX.Element => {
  if (isTruthy(data.completionDate)) {
    return <FaIcon id="check-double" tooltip={t('tabs.finishedTooltip')} />;
  }
  if (data.status === 'canceled') {
    return <FaIcon id="ban" tooltip={t('tabs.canceledTooltip')} />;
  }
  if (data.status === 'unachievable') {
    return <FaIcon id="cut" tooltip={t('tabs.unachievableTooltip')} />;
  }
  return <></>;
};
interface MobilePackageDealLineProps {
  readonly packageDeal: PackageDealDetailsInternalDataStructure;
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<PackageDealDetailsInternalDataStructure>
  >;
}

function MobilePackageDealLine({ packageDeal, $ }: MobilePackageDealLineProps): JSX.Element {
  const [t] = useTranslation('details');

  const actionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('showDetailsFor', packageDeal);
    },
    [packageDeal],
    $
  );

  const status = computeStatus(t, packageDeal);

  return (
    <TruncableLi
      key={packageDeal.id}
      actionToolbox={{ action: actionCallback, className: 'is-rounded is-small is-text' }}
    >
      <>
        {status}
        <span>{`${packageDeal.code}: ${packageDeal.label}`}</span>
      </>
    </TruncableLi>
  );
}

interface MobileSparePartLineProps {
  readonly sparePart: SparePart;
}

function MobileSparePartLine({ sparePart }: MobileSparePartLineProps): JSX.Element {
  const [t] = useTranslation('details');

  const status: SparePartDetailStatus = getSparePartDetailStatus(sparePart);

  return (
    <TruncableLi key={sparePart.id}>
      <>
        <SparePartStatusIcon
          status={status}
          dateOfOrder={sparePart.dateOfOrder}
          dateOfReception={sparePart.dateOfReception}
          t={t}
        />
        <span>{`- ${sparePart.label}`}</span>
      </>
    </TruncableLi>
  );
}
