import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { InputFormField, ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type {
  ColumnsToDestinationsDialogState,
  ColumnsToDestinationsState,
  ColumnToDestinationData,
} from '../typings/store.js';
import { EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE } from '../typings/store.js';

function saveColumnToDestinationAction({
  getState,
  actionDispatch,
}: ActionContext<Store, ColumnsToDestinationsState>): void {
  const { columnsToDestinationsDialogState } = getState();
  const { formData, mode } = columnsToDestinationsDialogState;
  const { column, destination } = formData;

  if (isTruthy(mode)) {
    actionDispatch.applyPayload({
      columnsToDestinations: [
        {
          id: column,
          destination,
        },
      ],
      columnsToDestinationsDialogState: EMPTY_COLUMN_TO_DESTINATION_DIALOG_STATE,
    });
  }
}

interface ColumnToDestinationModalProps {
  readonly $: StoreStateSelector<Store, ColumnsToDestinationsState>;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
  readonly translationKey: string;
}

export function ColumnToDestinationModal({
  $,
  horizontalFormFields,
  translationKey,
}: ColumnToDestinationModalProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const { $columnsToDestinationsDialogState } = $;

  const formWarning = useGetState($columnsToDestinationsDialogState.$formWarning);

  const mandatoryFields: (keyof ColumnToDestinationData)[] = ['column', 'destination'];
  const submitValidDataAction = useActionCallback(saveColumnToDestinationAction, [], $);
  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation<
    Store,
    ColumnsToDestinationsDialogState
  >({
    $: $columnsToDestinationsDialogState,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction,
    t,
  });

  return (
    <ModalCardDialog
      $active={$columnsToDestinationsDialogState.$active}
      title={t(`argumentsInputs.${translationKey}.dialogTitle`)}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <InputFormField
        label={t(`argumentsInputs.${translationKey}.column`)}
        placeholder={t(`argumentsInputs.${translationKey}.column`)}
        $={$formWithChangeTrigger.$column}
        horizontal={horizontalFormFields}
      />
      <InputFormField
        label={t(`argumentsInputs.${translationKey}.destination`)}
        placeholder={t(`argumentsInputs.${translationKey}.destination`)}
        $={$formWithChangeTrigger.$destination}
        horizontal={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}
