import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';
import { getEcoStickerLabel, getEnergyLabel, getGearboxLabel } from '../i18nUtils.js';

interface MarketplaceMainSpecsPreviewProps {
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceMainSpecsPreview({
  $selectedKanban,
}: MarketplaceMainSpecsPreviewProps): JSX.Element {
  const [t] = useTranslation('details');
  const marketplaceInfos = useGetState($selectedKanban.$marketplaceInfos);
  return (
    <>
      <div className="m-2">
        <span className="m-2">
          <b>{`${t('tabs.marketplace.mainSpecs.energy')}: `}</b>
          {getEnergyLabel(marketplaceInfos?.mainSpecs.energy)}
        </span>
        <span className="m-2">
          <b>{`${t('tabs.marketplace.mainSpecs.gearbox')}: `}</b>
          {getGearboxLabel(marketplaceInfos?.mainSpecs.gearbox)}
        </span>
        <span className="m-2">
          <b>{`${t('tabs.marketplace.mainSpecs.horsepower')}: `}</b>
          {marketplaceInfos?.mainSpecs.horsepower ?? ''}
        </span>
        <span className="m-2">
          <b>{`${t('tabs.marketplace.mainSpecs.taxHorsepower')}: `}</b>
          {marketplaceInfos?.mainSpecs.taxHorsepower ?? ''}
        </span>
      </div>
      <div className="m-2">
        <span className="m-2">
          <b>{`${t('tabs.marketplace.mainSpecs.doors')}: `}</b>
          {marketplaceInfos?.mainSpecs.doors ?? ''}
        </span>
        <span className="m-2">
          <b>{`${t('tabs.marketplace.mainSpecs.seats')}: `}</b>
          {marketplaceInfos?.mainSpecs.seats ?? ''}
        </span>
        <span className="m-2">
          <b>{`${t('tabs.marketplace.mainSpecs.ecoSticker')}: `}</b>
          {getEcoStickerLabel(marketplaceInfos?.mainSpecs.ecoSticker)}
        </span>
      </div>
    </>
  );
}
