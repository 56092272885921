import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, RepositoryEntityPayload } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { EMPTY_FORM_WITH_VALIDATION_STATE, FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { convertBusinessCustomerToFormData } from '../../../lib/components/customer/EditCustomerDialog.js';
import { EMPTY_EDIT_CUSTOMER_DIALOG_STATE } from '../../../lib/components/customer/typings/store.js';
import { ConvertUrlsToLinks } from '../../../lib/components/DisplayAttributesComponent.js';
import { PRICE_DECIMAL_LENGTH } from '../kanbanDetailsUtils.js';
import { openDeleteInvoiceInfosDeletionModalAction } from './DeleteInvoiceInfosModal.js';
import { getExistingPurchaseOrder } from './invoicingUtils.js';
import { openPurchaseOrderEditModalAction } from './PurchaseOrderAddEditModal.js';
import { openPurchaseOrderDeletionModalAction } from './PurchaseOrderDeleteModal.js';
import { openRefundInvoiceModalAction } from './RefundInvoiceModal.js';
import { type InvoiceDetail, type KanbanInvoiceTabState } from './typings/store.js';

type InvoiceDetailItemProps = {
  readonly invoiceDetail: InvoiceDetail;
  readonly kanban: Kanban;
  readonly canModifyInvoicing: boolean;
  readonly canEditPurchaseOrders: boolean;
  readonly canEditPurchaseOrderCustomers: boolean;
  readonly showTechnicalId: boolean;
  readonly $: StoreStateSelector<Store, KanbanInvoiceTabState>;
};

export function InvoiceDetailItem({
  invoiceDetail,
  kanban,
  canModifyInvoicing,
  canEditPurchaseOrders,
  canEditPurchaseOrderCustomers,
  showTechnicalId,
  $,
}: InvoiceDetailItemProps): JSX.Element {
  const [t] = useTranslation('details');

  const { $invoiceRefundModalState, $invoiceInfoDeletionModalState } = $;

  const {
    invoiceDetailId,
    purchaseOrderId,
    purchaseOrderLabel,
    purchaseOrderCustomerLabel,
    invoiceInfoId,
    invoiceId,
    reference,
    amount,
    isRefund,
    refundedInvoiceReference,
    refundingInvoiceId,
    link,
  } = invoiceDetail;

  const hasAllocatedPackageDeals = useMemo(
    () =>
      kanbanHelpers.getPackageDealsAllocatedToPurchaseOrderId(kanban, invoiceDetail.purchaseOrderId)
        .length > 0,
    [kanban, invoiceDetail]
  );

  const openRefundInvoiceDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      if (isTruthy(invoiceId)) {
        await actionDispatch.exec(
          openRefundInvoiceModalAction,
          invoiceId,
          purchaseOrderId,
          reference ?? ''
        );
      }
    },
    [invoiceId, purchaseOrderId, reference],
    $invoiceRefundModalState
  );

  const openDeleteInvoiceInfosDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        openDeleteInvoiceInfosDeletionModalAction,
        purchaseOrderId,
        invoiceInfoId ?? ''
      );
    },
    [purchaseOrderId, invoiceInfoId],
    $invoiceInfoDeletionModalState
  );

  const openPurchaseOrderEditDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openPurchaseOrderEditModalAction, kanban.id, purchaseOrderId);
    },
    [kanban, purchaseOrderId],
    $.$purchaseOrderEditModalState
  );

  const openPurchaseOrderDeletionDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openPurchaseOrderDeletionModalAction, kanban.id, purchaseOrderId);
    },
    [kanban, purchaseOrderId],
    $.$purchaseOrderDeletionModalState
  );

  const openAddCustomerDialogCallback = useActionCallback(
    ({ actionDispatch }): void => {
      actionDispatch.setValue({
        purchaseOrderId,
        editCustomerDialogState: {
          ...EMPTY_FORM_WITH_VALIDATION_STATE,
          active: true,
          initialCustomer: undefined,
          mode: 'create',
          formData: EMPTY_EDIT_CUSTOMER_DIALOG_STATE.formData,
        },
      });
    },
    [purchaseOrderId],
    $.$purchaseOrderCustomerAddModalState
  );

  const openEditCustomerDialogCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository }): Promise<void> => {
      const currentPurchaseOrder = await getExistingPurchaseOrder(
        kanbanRepository,
        kanban.id,
        purchaseOrderId
      );

      actionDispatch.setValue({
        purchaseOrderId,
        editCustomerDialogState: {
          ...EMPTY_FORM_WITH_VALIDATION_STATE,
          active: true,
          initialCustomer: currentPurchaseOrder.customer ?? undefined,
          mode: 'update',
          formData: currentPurchaseOrder.customer
            ? convertBusinessCustomerToFormData(currentPurchaseOrder.customer)
            : EMPTY_EDIT_CUSTOMER_DIALOG_STATE.formData,
        },
      });
    },
    [kanban, purchaseOrderId],
    $.$purchaseOrderCustomerEditModalState
  );

  const deleteCustomerActionCallback = useActionCallback(
    async ({ kanbanRepository }: ActionContext<Store, KanbanInvoiceTabState>): Promise<void> => {
      const currentPurchaseOrder = await getExistingPurchaseOrder(
        kanbanRepository,
        kanban.id,
        purchaseOrderId
      );

      const payload: RepositoryEntityPayload<Kanban> = {
        entityId: kanban.id,
        payload: {
          purchaseOrders: [
            {
              id: purchaseOrderId,
              customer: currentPurchaseOrder.customer ? null : undefined,
            },
          ],
        },
      };
      await kanbanRepository.updateEntityFromPayload(payload);
    },
    [kanban, purchaseOrderId],
    $
  );

  const typeLabel = isRefund ? t('tabs.invoice.refund') : t('tabs.invoice.invoice');

  // A refund is possible on InvoiceInfos instances :
  // - which do not correspond to a refund
  // - which correspond to an invoice which has not yet been refunded
  const hasNoInvoiceInfos = !isTruthy(invoiceId);
  const isARefundedInvoice = isTruthy(refundingInvoiceId);
  const isRefundPossible = !hasNoInvoiceInfos && !isRefund && !isARefundedInvoice;

  let refundTooltip = t('tabs.invoice.actions.refund');
  if (hasNoInvoiceInfos) {
    refundTooltip = t('tabs.invoice.actions.noRefundWhenNoInvoiceInfos');
  } else if (isRefund) {
    refundTooltip = t('tabs.invoice.actions.noRefundOnRefund');
  } else if (isARefundedInvoice) {
    refundTooltip = t('tabs.invoice.actions.noRefundOnRefundedInvoice');
  }

  const hasCustomer = isTruthyAndNotEmpty(purchaseOrderCustomerLabel);
  const isDeletePossible = isTruthy(invoiceInfoId);

  return (
    <tr key={invoiceDetailId}>
      {showTechnicalId && <td>{invoiceDetailId}</td>}
      <td>
        <div className="is-flex is-justify-content-space-between">
          {purchaseOrderLabel}
          <div>
            <button
              key="edit"
              aria-label="edit"
              type="button"
              className="button is-small is-transparent"
              title={t('tabs.invoice.actions.edit')}
              onClick={openPurchaseOrderEditDialogCallback}
              disabled={!canEditPurchaseOrders}
            >
              <FaIcon id="edit" />
            </button>
            <button
              key="delete"
              aria-label="delete"
              type="button"
              className="button is-small is-transparent"
              title={t('tabs.invoice.actions.delete')}
              onClick={openPurchaseOrderDeletionDialogCallback}
              disabled={hasAllocatedPackageDeals && !canEditPurchaseOrders}
            >
              <FaIcon id="trash" />
            </button>
          </div>
        </div>
      </td>
      <td>
        {hasCustomer ? (
          <div className="is-flex is-justify-content-space-between">
            {purchaseOrderCustomerLabel}
            <div>
              <button
                key="edit"
                aria-label="edit"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.edit')}
                onClick={openEditCustomerDialogCallback}
                disabled={!canEditPurchaseOrderCustomers}
              >
                <FaIcon id="edit" />
              </button>
              <button
                key="delete"
                aria-label="delete"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.delete')}
                onClick={deleteCustomerActionCallback}
                disabled={!canEditPurchaseOrderCustomers}
              >
                <FaIcon id="trash" />
              </button>
            </div>
          </div>
        ) : (
          <div className="is-flex is-justify-content-space-between">
            <div />
            <button
              key="add"
              aria-label="add"
              type="button"
              className="button has-text-right is-small is-transparent"
              title={t('tabs.invoice.actions.delete')}
              onClick={openAddCustomerDialogCallback}
              disabled={!canEditPurchaseOrderCustomers}
            >
              <FaIcon id="plus" />
            </button>
          </div>
        )}
      </td>
      <td>{isTruthyAndNotEmpty(reference) ? typeLabel : ''}</td>
      <td>
        <div className="is-flex is-justify-content-space-between">
          <div>
            <span className={isTruthy(refundingInvoiceId) ? 'strike-through' : ''}>
              {reference}
            </span>
            {isTruthy(refundedInvoiceReference) && (
              <span
                className={`is-size-7 p-l-sm${isTruthy(refundedInvoiceReference) ? ' strike-through' : ''}`}
              >
                {`(${refundedInvoiceReference})`}
              </span>
            )}
          </div>
          {canModifyInvoicing && (
            <div>
              <button
                key="refund"
                aria-label="refund"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.refund')}
                onClick={openRefundInvoiceDialogCallback}
                disabled={!isRefundPossible}
              >
                <FaIcon id="rotate-left" tooltip={refundTooltip} />
              </button>
              <button
                key="delete"
                aria-label="delete"
                type="button"
                className="button is-small is-transparent"
                title={t('tabs.invoice.actions.delete')}
                onClick={openDeleteInvoiceInfosDialogCallback}
                disabled={!isDeletePossible}
              >
                <FaIcon
                  id="trash"
                  tooltip={
                    isDeletePossible
                      ? t('tabs.invoice.actions.delete')
                      : t('tabs.invoice.actions.noActionWhenNoInvoiceInfos')
                  }
                />
              </button>
            </div>
          )}
        </div>
      </td>
      <td className="has-text-right">
        {isTruthy(amount)
          ? t('tabs.price', {
              price: amount.toFixed(PRICE_DECIMAL_LENGTH),
            })
          : ''}
      </td>
      <td aria-label="link">
        <ConvertUrlsToLinks jsxElementKeyPrefix="link" text={link ?? ''} />
      </td>
    </tr>
  );
}
