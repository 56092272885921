import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  AttachmentDesc,
  AttachmentFolder,
  SharedKanban,
  Site,
  StorageCategories,
} from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, FormFieldEntry } from '@stimcar/libs-uitoolkit';
import {
  appendBuildVersion,
  contractHelpers,
  CoreBackendRoutes,
  DELIVERY_STAND_ID,
  electronicSignatureHelpers,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { ThumbnailOptions } from '../lib/components/attachments/typings/attachment.js';
import type { MenuItem } from '../lib/components/Menu.js';
import { PageLoader } from '../lib/bulma/extensions/PageLoader.js';
import { GraphicWorkflowWithProgress } from '../lib/components/GraphicWorkflowWithProgress.js';
import { Menu } from '../lib/components/Menu.js';
import { getFoldersWithKanbanSpecificAttachmentFolders } from '../utils/folderUtils.js';
import type { KanbanShareState, ShareStore } from './state/typings/store.js';
import { KanbanShareAttachmentsTab } from './components/KanbanShareAttachmentsTab.js';
import {
  initializeKanbanShareDeliveryTab,
  KanbanShareDeliveryTab,
} from './components/KanbanShareDeliveryTab.js';
import {
  initializeKanbanShareEstimateTab,
  KanbanShareEstimateTab,
} from './components/KanbanShareEstimateTab.js';
import { KanbanShareSparePartsTab } from './components/KanbanShareSparePartsTab.js';

export const loadShareableAttachmentsAction = async (
  { actionDispatch, httpClient }: ActionContext<ShareStore, KanbanShareState>,
  shareId: string,
  folders: readonly string[]
): Promise<void> => {
  const attachments = await httpClient.httpGetAsJson<readonly AttachmentDesc[]>(
    CoreBackendRoutes.SHARED_ATTACHMENT_FOLDER(
      shareId,
      'kanban',
      folders.join(URL_LIST_ELEMENTS_SEPARATOR)
    )
  );
  actionDispatch.setProperty(
    'availableAttachments',
    attachments.map(({ name, folder }): Attachment => {
      return { id: name, folder, name };
    })
  );
};

function getTabToDisplay(defaultTab: string | undefined, kanban: SharedKanban, site: Site) {
  if (defaultTab === undefined) {
    // If the default tab is not specified we check if we can sign the delivery, then delivery tab will be displayed
    const { canBeSigned } = electronicSignatureHelpers.hasADocumentToSignByCustomer(
      kanban,
      site.configuration,
      DELIVERY_STAND_ID
    );
    if (canBeSigned) {
      return 'delivery';
    }
  }
  return defaultTab;
}

export async function initializeKanbanShareViewState(
  { actionDispatch, getGlobalState }: ActionContext<ShareStore, KanbanShareState>,
  shareId: string,
  sharableAttachmentsFolders: readonly string[],
  defaultTab?: string
): Promise<void> {
  const { context } = getGlobalState();
  const { object: kanban } = context;

  if (defaultTab) {
    actionDispatch.setProperty('selectedMenuItem', defaultTab);
  }
  await actionDispatch.exec(loadShareableAttachmentsAction, shareId, sharableAttachmentsFolders);
  await actionDispatch
    .scopeProperty('estimateTab')
    .exec(initializeKanbanShareEstimateTab, shareId, kanban);
  await actionDispatch.scopeProperty('deliveryTab').exec(initializeKanbanShareDeliveryTab);
  actionDispatch.setProperty('isInitialized', true);
}

interface KanbanShareProps extends AppProps<ShareStore> {
  readonly shareId: string;
  readonly defaultTab?: string;
}

export function KanbanShare({ $gs, shareId, defaultTab }: KanbanShareProps): JSX.Element {
  const [t] = useTranslation('share');
  const { $imageModal, $kanbanShare } = $gs;
  const { $estimateTab, $deliveryTab } = $kanbanShare;

  const kanban = useGetState($gs.$context.$object);
  const site = useGetState($gs.$context.$site);
  const sharedUIContract = useGetState($gs.$context.$sharedUIContract);

  const attachmentsFolders: readonly AttachmentFolder[] = useMemo(() => {
    const contractAttachmentFolders = contractHelpers.getAttachmentFolders(
      sharedUIContract.documents,
      true
    );
    const attachmentFolders = getFoldersWithKanbanSpecificAttachmentFolders(
      kanban,
      contractAttachmentFolders
    );
    return attachmentFolders.map((folder) => ({
      ...folder,
      label: folder.label || t(`globals:${folder.id}FolderUploadLabel`),
    }));
  }, [kanban, sharedUIContract.documents, t]);

  const tabToDisplay = useMemo(() => {
    return getTabToDisplay(defaultTab, kanban, site);
  }, [defaultTab, kanban, site]);

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(
        initializeKanbanShareViewState,
        shareId,
        attachmentsFolders.map((f) => f.id),
        tabToDisplay
      );
      document.title = kanban.infos.license;
    },
    [attachmentsFolders, kanban.infos.license, shareId, tabToDisplay],
    $kanbanShare
  );

  // Set page title and initialize views
  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect, attachmentsFolders, kanban.infos.license, shareId]);

  const availableAttachments = useGetState($kanbanShare.$availableAttachments);

  const attachmentsPerFoldersRecord = useMemo(() => {
    const map: Record<string, Attachment[]> = {};

    attachmentsFolders.forEach((f) => {
      map[f.id] = [];
    });
    if (!isTruthy(availableAttachments)) {
      return map;
    }
    [...availableAttachments].forEach((a) => {
      const list = map[a.folder];
      list.push(a);
    });

    return map;
  }, [attachmentsFolders, availableAttachments]);

  const menuItems: readonly MenuItem[] = useMemo(() => {
    const items: MenuItem[] = [
      {
        categoryLabel: t('tabs.interactiveMenuCategoryLabel'),
        entries: [
          {
            id: 'estimate',
            label: t('tabs.estimate'),
          },
          {
            id: 'delivery',
            label: t('tabs.delivery'),
          },
          {
            id: 'spareParts',
            label: t('tabs.spareParts'),
          },
        ],
      },
    ];
    if (attachmentsFolders.length > 0) {
      const attachmentMenuItem: MenuItem = {
        categoryLabel: t('tabs.sharableAttachmentsMenuCategoryLabel'),
        entries: attachmentsFolders.map((f): FormFieldEntry<string> => {
          const folderAttachment = attachmentsPerFoldersRecord[f.id];
          return {
            id: f.id,
            label: `${isTruthyAndNotEmpty(f.label) ? f.label : t(`tabs.${f.id}`, f.id)}${
              folderAttachment.length > 0 ? ` (${folderAttachment.length})` : ''
            }`,
          };
        }),
      };
      items.push(attachmentMenuItem);
    }
    return items;
  }, [attachmentsFolders, attachmentsPerFoldersRecord, t]);

  const computeAttachmentUrlCallback = useCallback(
    (
      category: StorageCategories,
      folder: string,
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      objectId: string,
      thumbnailOptions?: ThumbnailOptions
    ): string => {
      const url = thumbnailOptions
        ? CoreBackendRoutes.SHARED_ATTACHMENT_THUMBNAIL(
            shareId,
            category,
            folder,
            thumbnailOptions.mode,
            thumbnailOptions.size,
            name
          )
        : CoreBackendRoutes.SHARED_ATTACHMENT(shareId, category, folder, name);
      return appendBuildVersion(url);
    },
    [shareId]
  );

  const workflows = useGetState($gs.$context.$site.$configuration.$workflows);

  const workflow = useMemo(() => {
    return nonnull(workflows.find((w) => w.id === kanban.workflowId));
  }, [workflows, kanban.workflowId]);

  const selectedMenuItem = useGetState($kanbanShare.$selectedMenuItem);

  const displayContent = (): JSX.Element => {
    switch (selectedMenuItem) {
      case 'delivery':
        return (
          <KanbanShareDeliveryTab
            kanban={kanban}
            contract={sharedUIContract}
            shareId={shareId}
            site={site}
            $={$deliveryTab}
          />
        );
      case 'estimate':
        return (
          <KanbanShareEstimateTab
            $gs={$gs}
            kanban={kanban}
            contract={sharedUIContract}
            shareId={shareId}
            site={site}
            $={$estimateTab}
            computeAttachmentUrlCallback={computeAttachmentUrlCallback}
          />
        );
      case 'spareParts':
        return (
          <KanbanShareSparePartsTab
            $gs={$gs}
            kanban={kanban}
            contract={sharedUIContract}
            computeAttachmentUrlCallback={computeAttachmentUrlCallback}
            site={site}
          />
        );
      default: {
        const foundFolder = attachmentsFolders.find((f) => f.id === selectedMenuItem);
        if (isTruthy(foundFolder)) {
          return (
            <KanbanShareAttachmentsTab
              attachments={attachmentsPerFoldersRecord[foundFolder.id]}
              $imageModal={$imageModal}
              computeAttachmentUrlCallback={computeAttachmentUrlCallback}
              kanbanId={kanban.id}
              folderLabel={foundFolder.label ?? t(`tabs.${foundFolder.id}`)}
            />
          );
        }
        return <div>{t('tabs.incorrectTab')}</div>;
      }
    }
  };
  const isInitialized = useGetState($kanbanShare.$isInitialized);
  return (
    <>
      <div className="columns">
        <div className="column is-2 no-printing">
          <Menu menuItems={menuItems} $={$kanbanShare.$selectedMenuItem} />
          <div className="m-t-md" style={{ display: 'flex', justifyContent: 'center' }}>
            <GraphicWorkflowWithProgress kanban={kanban} workflow={workflow} />
          </div>
        </div>
        <div className="column">
          <PageLoader loadingStatus={!isInitialized}>{displayContent()}</PageLoader>
        </div>
      </div>
    </>
  );
}
