import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface TimerProps {
  readonly consumedTime: number;
  readonly totalTime: number;
  readonly started: boolean;
  readonly isCountdown: boolean;
}

function Timer({ consumedTime, totalTime, started, isCountdown }: TimerProps): JSX.Element {
  const [t] = useTranslation('refititCommonComponents');
  const [internalConsumedTime, setInternalConsumedTime] = React.useState(0);

  useEffect(() => {
    setInternalConsumedTime(consumedTime);
  }, [consumedTime]);

  const tick = useCallback((): void => {
    setInternalConsumedTime((int: number) => int + 1);
  }, [setInternalConsumedTime]);

  useEffect((): (() => void) => {
    let timerId: NodeJS.Timeout | undefined;
    if (started) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      timerId = setInterval(() => tick(), 1000);
    }

    return (): void => {
      if (timerId !== undefined) {
        clearInterval(timerId);
      }
    };
  }, [started, tick]);

  const isExceeded = useCallback((): boolean => {
    return isCountdown && internalConsumedTime > totalTime;
  }, [isCountdown, internalConsumedTime, totalTime]);

  const time = useMemo((): number => {
    let timeInMinutes: number;
    if (isCountdown) {
      timeInMinutes = Math.abs(totalTime - internalConsumedTime) / 60;
    } else {
      timeInMinutes = Math.abs(internalConsumedTime) / 60;
    }
    return Math.ceil(timeInMinutes);
  }, [totalTime, internalConsumedTime, isCountdown]);

  const displayedTime = useMemo((): string => {
    const hours = Math.trunc(time / 60);
    const remainingMinutes = time % 60;

    let hoursString: string;
    if (hours < 10) {
      hoursString = `0${hours}`;
    } else {
      hoursString = hours.toString();
    }

    let minutesString: string;
    if (remainingMinutes < 10) {
      minutesString = `0${remainingMinutes}`;
    } else {
      minutesString = remainingMinutes.toString();
    }

    return t('timer.time', { hours: hoursString, minutes: minutesString });
  }, [time, t]);

  const timerLabel = useMemo(() => {
    if (!isCountdown) {
      return t('timer.stopwatch');
    }
    if (isExceeded()) {
      return t('timer.exceeded');
    }
    return t('timer.remaining');
  }, [isCountdown, isExceeded, t]);

  return (
    <div
      className={`has-text-centered has-text-weight-semibold is-size-4${
        isExceeded() ? ' has-text-warning' : ''
      }`}
    >
      <span className="is-size-3 has-text-weight-bold">{displayedTime}</span>
      {` ${timerLabel}`}
    </div>
  );
}

interface CountdownProps {
  readonly consumedTime: number;
  readonly totalTime: number;
  readonly started: boolean;
}

export function Countdown({ consumedTime, totalTime, started }: CountdownProps): JSX.Element {
  return <Timer consumedTime={consumedTime} started={started} totalTime={totalTime} isCountdown />;
}

interface StopwatchProps {
  readonly consumedTime: number;
  readonly started: boolean;
}

export function Stopwatch({ consumedTime, started }: StopwatchProps): JSX.Element {
  return <Timer consumedTime={consumedTime} totalTime={0} started={started} isCountdown={false} />;
}
