import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AttachmentFolder, Kanban, StorageCategories } from '@stimcar/libs-base';
import type { GlobalStoreStateSelector, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  CoreBackendRoutes,
  kanbanHelpers,
  MARKETPLACE_PHOTO_ATTACHMENT_FOLDER,
  MARKETPLACE_TEXT_ATTACHMENT_FOLDER,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import { AttachmentsGallery } from '../../../lib/components/attachments/AttachmentsGallery.js';
import { DisplayContentOrPlaceholder } from '../../../lib/components/misc/DisplayContentOrPlaceholder.js';
import { Tabs } from '../../../lib/components/Tabs.js';
import { loadAttachmentsGalleryAction } from '../../utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import type { MarketplaceDetailsState } from './typings/store.js';
import { computeMarketplaceTabs } from './i18nUtils.js';
import { MarketplaceAdPreview } from './MarketplaceAdPreview.js';
import { MarketplaceStimer } from './MarketplaceStimer.js';
import { MarketplaceOverview } from './overview/MarketplaceOverview.js';
import { MarketplaceSpecs } from './specs/MarketplaceSpecs.js';
import {
  MKTP_TABS_AD_PREVIEW,
  MKTP_TABS_OVERVIEW,
  MKTP_TABS_PHOTO_GALLERY,
  MKTP_TABS_SPECS,
  MKTP_TABS_STIMER,
  MKTP_TABS_TEXT_ATTACHMENT_GALLERY,
} from './typings/store.js';

const MARKETPLACE_PHOTO_FOLDERS: readonly AttachmentFolder[] = [
  MARKETPLACE_PHOTO_ATTACHMENT_FOLDER,
];
const MARKETPLACE_TEXT_FOLDERS: readonly AttachmentFolder[] = [MARKETPLACE_TEXT_ATTACHMENT_FOLDER];

export interface MarketplaceDetailsProps {
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly isEditable: boolean;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
}

export function MarketplaceDetails({
  $,
  $gs,
  isEditable,
  $selectedKanban,
}: MarketplaceDetailsProps): JSX.Element {
  const [t] = useTranslation('details');
  const isOnline = useGetState($gs.$session.$isOnline);
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const loadAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ): Promise<void> => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $.$attachmentGalleryDialogModal
  );

  const kanbanId = useGetState($selectedKanban.$id);

  const selectedTab = useGetState($.$selectedTab);

  const selectedKanban = useGetState($selectedKanban);
  const isMarketplaceKanban = useMemo(
    () => isTruthy(selectedKanban) && kanbanHelpers.isMarketplaceKanban(selectedKanban),
    [selectedKanban]
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={isMarketplaceKanban}
      placeholder={t('kanbanAttributes.emptyPlaceholder')}
      isScrollable
    >
      <div className="container is-fluid mt-3">
        <div className="container is-max-widescreen" style={{ minHeight: 400 }}>
          <Tabs labels={computeMarketplaceTabs()} $selectedTab={$.$selectedTab} isCentered />
          {selectedTab === MKTP_TABS_OVERVIEW && (
            <MarketplaceOverview
              $={$.$marketplaceOverview}
              $gs={$gs}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === MKTP_TABS_SPECS && (
            <MarketplaceSpecs
              $={$}
              $gs={$gs}
              isEditable={isEditable}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === MKTP_TABS_AD_PREVIEW && (
            <MarketplaceAdPreview
              $={$.$marketplacePreview}
              $gs={$gs}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === MKTP_TABS_STIMER && (
            <MarketplaceStimer
              $={$.$marketplaceStimer}
              $gs={$gs}
              $selectedKanban={$selectedKanban}
            />
          )}
          {selectedTab === MKTP_TABS_PHOTO_GALLERY && (
            <AttachmentsGallery
              category="kanban"
              objectId={kanbanId}
              folders={MARKETPLACE_PHOTO_FOLDERS}
              $={$.$attachmentGalleryDialogModal}
              $window={$gs.$window}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$gs.$imageModal}
              isOnline={isOnline}
            />
          )}
          {selectedTab === MKTP_TABS_TEXT_ATTACHMENT_GALLERY && (
            <AttachmentsGallery
              category="kanban"
              objectId={kanbanId}
              folders={MARKETPLACE_TEXT_FOLDERS}
              $={$.$attachmentGalleryDialogModal}
              $window={$gs.$window}
              loadAttachmentsActionCallback={loadAttachmentsActionCallback}
              computeAttachmentUrl={computeAttachmentUrl}
              $imageModal={$gs.$imageModal}
              isOnline={isOnline}
            />
          )}
        </div>
      </div>
    </DisplayContentOrPlaceholder>
  );
}
