import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CarViewCategory, Memo, MemoDesc, PartialBy } from '@stimcar/libs-base';
import {
  globalHelpers,
  shortDayMonthYearDateFormatOptions,
  sortingHelpers,
  splitElementsIntoEqualParts,
} from '@stimcar/libs-base';
import { isTruthy, keysOf } from '@stimcar/libs-kernel';
import { DisplayContentOrPlaceholder } from '../misc/DisplayContentOrPlaceholder.js';
import { DisplayMultilineString } from '../misc/DisplayMultilineString.js';

type MemoWithOptionalValue = PartialBy<Memo, 'value'>;

interface Props {
  readonly memoDescs: Record<CarViewCategory, MemoDesc[]>;
  readonly memos: Record<string, Memo>;
  readonly showOnlyEstimateMemos?: boolean;
  readonly numberOfColumns: number;
}

export const computeMemoStringValue = (
  t: TFunction,
  memo: MemoWithOptionalValue | undefined
): string => {
  if (!isTruthy(memo) || memo.value === undefined) {
    return t('libComponents:memo.emptyMemo');
  }
  if (memo.value === null) {
    return t('libComponents:memo.naMemoValue');
  }

  switch (memo?.type) {
    case 'boolean':
      return memo.value
        ? t('libComponents:memo.booleanTrue')
        : t('libComponents:memo.booleanFalse');
    case 'numeric':
      return Number.isNaN(memo.value as number) ? '' : String(memo.value);
    case 'date':
      return Number.isNaN(memo.value as number)
        ? t('libComponents:memo.noDate')
        : globalHelpers.convertTimestampToDateString(
            memo.value as number,
            shortDayMonthYearDateFormatOptions
          );
    default:
      return String(memo?.value);
  }
};

export const computeMemosToDisplay = (
  memoDescs: Record<CarViewCategory, MemoDesc[]>,
  memos: Record<string, Memo>,
  showOnlyEstimateMemos: boolean
): Record<string, MemoWithOptionalValue> => {
  const filteredMemos: Record<string, MemoWithOptionalValue> = {};
  keysOf(memoDescs).forEach((category) => {
    const categoryMemoDescs = memoDescs[category] ?? [];
    categoryMemoDescs.forEach((memoDesc) => {
      if (!showOnlyEstimateMemos || memoDesc.showInEstimate) {
        const theMemo = memos[memoDesc.id];
        if (!isTruthy(theMemo)) {
          filteredMemos[memoDesc.id] = { category, type: memoDesc.type };
        } else {
          filteredMemos[memoDesc.id] = theMemo;
        }
      }
    });
  });
  return filteredMemos;
};

function compareMemos(a: MemoWithLabelAndOptionalValue, b: MemoWithLabelAndOptionalValue): number {
  // First compare with categories
  const compareByCategory = sortingHelpers.categoryComparatorForMemo(a, b);
  if (compareByCategory === 0) {
    // When categories are the same, compare the memos labels
    return a.label.localeCompare(b.label);
  }
  return compareByCategory;
}

type MemoWithLabelAndOptionalValue = MemoWithOptionalValue & { label: string };

function getMemosToBeDisplayed(
  memoDescs: Record<CarViewCategory, MemoDesc[]>,
  memos: Record<string, Memo>,
  showOnlyEstimateMemos: boolean
): MemoWithLabelAndOptionalValue[] {
  const memosToDisplay = computeMemosToDisplay(memoDescs, memos, showOnlyEstimateMemos);

  const memosWithLabel = Object.entries(memosToDisplay).map(([label, memo]) => {
    return { ...memo, label };
  });

  return memosWithLabel.sort(compareMemos);
}

export function MemosBloc({
  memoDescs,
  memos,
  numberOfColumns,
  showOnlyEstimateMemos = false,
}: Props): JSX.Element {
  const [t] = useTranslation('libComponents');

  const sortedMemos = useMemo(() => {
    return getMemosToBeDisplayed(memoDescs, memos, showOnlyEstimateMemos);
  }, [memoDescs, memos, showOnlyEstimateMemos]);

  return (
    <DisplayContentOrPlaceholder
      displayCondition={keysOf(sortedMemos).length > 0}
      placeholder={t('memo.emptyPlaceholder')}
    >
      <table className="table is-narrow is-fullwidth">
        <tbody>
          <tr>
            <td>
              <div
                className="columns is-multiline"
                style={{ padding: '0.5em', paddingTop: '1em', paddingBottom: '1em' }}
              >
                {splitElementsIntoEqualParts(sortedMemos, numberOfColumns).map(
                  (groupOfMemos, index): JSX.Element => {
                    return (
                      <div
                        className="column"
                        style={{ padding: '0' }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      >
                        {groupOfMemos.map((memo): JSX.Element => {
                          return (
                            <React.Fragment key={memo.label}>
                              <span>
                                <strong>{memo.label}</strong>
                                {': '}
                              </span>
                              {memo.type === 'textarea' ? (
                                <DisplayMultilineString
                                  // We check that the type of the Memo is textarea so we are sure that the value is
                                  // string or undefined
                                  value={memo?.value as unknown as string}
                                  placeholder={t('libComponents:memo.emptyMemo')}
                                />
                              ) : (
                                computeMemoStringValue(t, memo)
                              )}
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    );
                  }
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </DisplayContentOrPlaceholder>
  );
}
