import React, { useMemo } from 'react';
import type {
  AnyStoreDef,
  StoreStateSelector,
  WindowState,
  WindowStateStoreDef,
} from '@stimcar/libs-uikernel';
import { isTruthy, isTruthyAndNotEmpty, keysOf } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';

type TabStatus = 'ACTIVE' | 'DISABLED' | 'STANDARD';

function getTabStatus<T extends string>(
  key: T,
  selectedTab: T,
  disabledTabs: readonly T[] = []
): TabStatus {
  if (disabledTabs.includes(key)) {
    return 'DISABLED';
  }
  if (selectedTab === key) {
    return 'ACTIVE';
  }
  return 'STANDARD';
}

interface TabProps<SD extends AnyStoreDef, T extends string> {
  readonly status: TabStatus;
  readonly id: T;
  readonly tabElement: string | TabElementDef;
  readonly $selectedTab: StoreStateSelector<SD, string>;
  readonly verticalText?: boolean;
}

function Tab<SD extends AnyStoreDef, T extends string>({
  status,
  tabElement,
  $selectedTab,
  id,
  verticalText = false,
}: TabProps<SD, T>): JSX.Element {
  const selectTabActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      if (status !== 'DISABLED') {
        actionDispatch.setValue(id);
      }
    },
    [id, status],
    $selectedTab
  );

  const [label, icon] = useMemo(() => {
    if (typeof tabElement === 'string') {
      return [tabElement, undefined];
    }
    return [tabElement.label, tabElement.icon];
  }, [tabElement]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <li
        onClick={selectTabActionCallback}
        className={`${verticalText ? 'vertical-text' : ''} ${status === 'ACTIVE' ? 'is-active' : ''} ${status === 'DISABLED' ? 'is-disabled' : ''}`}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>
          {isTruthy(icon) && (
            <FaIcon id={icon.id} size="small" additionalClass={icon.colorClass ?? ''} />
          )}
          {isTruthyAndNotEmpty(label) && (
            <span className={`tab-label ${!verticalText && 'horizontal-tab-label'}`}>{label}</span>
          )}
        </a>
      </li>
    </>
  );
}

export interface TabElementDef {
  readonly label: string;
  readonly icon?: {
    readonly id: string;
    readonly colorClass?: string;
  };
}

interface BaseTabsProps<SD extends AnyStoreDef, T extends string> {
  readonly labels: Record<T, string | TabElementDef>;
  readonly $selectedTab: StoreStateSelector<SD, T>;
}

interface TabsProps<SD extends AnyStoreDef, T extends string> extends BaseTabsProps<SD, T> {
  readonly isCentered?: boolean;
  readonly isSmall?: boolean;
  readonly className?: string;
  readonly isVerticalText?: boolean;
  readonly disabledTabs?: readonly T[];
  readonly sortingFunction?: (a: T, b: T) => number;
}

export function Tabs<SD extends AnyStoreDef, T extends string = string>({
  labels,
  $selectedTab,
  isCentered = false,
  className,
  isVerticalText = false,
  isSmall = false,
  disabledTabs = [],
  sortingFunction,
}: TabsProps<SD, T>): JSX.Element {
  const selectedTab = useGetState($selectedTab);

  const tabsLabels = useMemo(() => {
    if (sortingFunction) {
      return [...keysOf(labels)].sort(sortingFunction);
    }
    return keysOf(labels);
  }, [labels, sortingFunction]);

  return (
    <div
      className={`${isVerticalText && 'vertical-tabs'} tabs${isCentered ? ' is-centered' : ''}${isSmall ? ' is-small' : ''} ${
        className && `${className}`
      }`}
    >
      <ul>
        {tabsLabels.map(
          (key): JSX.Element => (
            <Tab
              key={key}
              status={getTabStatus(key, selectedTab, disabledTabs)}
              id={key}
              tabElement={labels[key]}
              $selectedTab={$selectedTab}
              verticalText={isVerticalText}
            />
          )
        )}
      </ul>
    </div>
  );
}

interface BoxTabsProps<SD extends AnyStoreDef> extends BaseTabsProps<SD, string> {
  readonly isMobile: boolean;
}

function BoxTabs<SD extends AnyStoreDef>({
  isMobile,
  labels,
  $selectedTab,
}: BoxTabsProps<SD>): JSX.Element {
  const selectedTab = useGetState($selectedTab);

  return (
    <div
      style={{
        width: '1.75rem',
        height: '100%',
        display: 'grid',
        marginRight: isMobile ? '3.125rem' : '5rem',
        marginLeft: isMobile ? '0rem' : '1rem',
      }}
      className="is-fullheight is-grid"
    >
      {keysOf(labels).map(
        (key): JSX.Element => (
          <BoxTab
            key={key}
            isActive={selectedTab === key}
            id={key}
            tabElement={labels[key]}
            $selectedTab={$selectedTab}
          />
        )
      )}
    </div>
  );
}

interface BoxTabProps<SD extends AnyStoreDef> {
  readonly isActive: boolean;
  readonly id: string;
  readonly tabElement: string | TabElementDef;
  readonly $selectedTab: StoreStateSelector<SD, string>;
}

function BoxTab<SD extends AnyStoreDef>({
  isActive,
  tabElement,
  $selectedTab,
  id,
}: BoxTabProps<SD>): JSX.Element {
  const selectTabActionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setValue(id);
    },
    [id],
    $selectedTab
  );

  const label = useMemo(() => {
    if (typeof tabElement === 'string') {
      return tabElement;
    }
    return tabElement.label;
  }, [tabElement]);

  return (
    <button
      type="button"
      onClick={selectTabActionCallback}
      className={`button is-block p-t-xs p-r-xs p-l-xs p-b-xxs mb-1 ${isActive && 'is-focused'}`}
    >
      <div className="is-size-7">{label}</div>
    </button>
  );
}

interface VTabsProps<SD extends WindowStateStoreDef> {
  readonly $window: StoreStateSelector<SD, WindowState>;
  readonly labels: Record<string, string | TabElementDef>;
  readonly $selectedTab: StoreStateSelector<SD, string>;
  readonly isBoxTabs?: boolean;
  readonly children: JSX.Element;
}

export function VTabs<SD extends WindowStateStoreDef>({
  $window,
  labels,
  $selectedTab,
  children,
  isBoxTabs = false,
}: VTabsProps<SD>): JSX.Element {
  const isMobile = useScreenIsBulmaMobile($window);
  const selectedTab = useGetState($selectedTab);

  if (isBoxTabs) {
    return (
      <>
        <BoxTabs labels={labels} isMobile={isMobile} $selectedTab={$selectedTab} />
        {children}
      </>
    );
  }

  if (isMobile) {
    return (
      <>
        <Tabs labels={labels} $selectedTab={$selectedTab} />
        {children}
      </>
    );
  }

  return (
    <div className="vtabs-wrapper">
      <div className="vtabs">
        <ul>
          {keysOf(labels).map((key): JSX.Element => {
            return (
              <Tab
                key={key}
                status={getTabStatus(selectedTab, key)}
                id={key}
                tabElement={labels[key]}
                $selectedTab={$selectedTab}
              />
            );
          })}
        </ul>
      </div>
      <div className="content-wrap">
        <div className="vtab-content">{children}</div>
      </div>
    </div>
  );
}
