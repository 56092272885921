/* eslint-disable react/no-danger */
import { marked } from 'marked';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '../../bulma/index.js';
import type { MessageDialogState, MessageModalContainerState } from './typings/store.js';

type Props<SD extends AnyStoreDef> = {
  readonly $: StoreStateSelector<SD, MessageModalContainerState>;
};

export function MessageDialog<SD extends AnyStoreDef>({ $ }: Props<SD>): JSX.Element {
  const [t] = useTranslation('custom');
  const rawMessage = useGetState($.$message);
  const dialogState = useMemo((): MessageDialogState | false => {
    if (rawMessage === false) {
      return false;
    }
    if (typeof rawMessage === 'string') {
      marked.use({ async: false });
      return {
        type: 'info',
        title: t('messageDialog.title'),
        content: marked.parse(rawMessage) as string,
        redirectTo: undefined,
      };
    }
    return {
      ...rawMessage,
      content: marked.parse(rawMessage.content) as string,
    };
  }, [rawMessage, t]);

  const iconId = useMemo(() => {
    if (dialogState === false) {
      return '';
    }
    switch (dialogState.type) {
      case 'error':
        return 'bug';
      case 'warning':
        return 'exclamation-triangle';
      case 'info':
      default:
        return 'info-circle';
    }
  }, [dialogState]);

  const redirectCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('message', false);
      if (typeof dialogState === 'object' && isTruthyAndNotEmpty(dialogState.redirectTo)) {
        document.location.href = dialogState.redirectTo;
      }
    },
    [dialogState],
    $
  );

  return dialogState ? (
    <ModalCardDialog
      $active={$.$message as StoreStateSelector<SD, boolean>}
      onOkClicked={redirectCallback}
      titleIconId={iconId}
      title={dialogState.title}
      zIndex={Number.MAX_SAFE_INTEGER}
      dontShowCancelButton
    >
      <div dangerouslySetInnerHTML={{ __html: dialogState.content }} />
    </ModalCardDialog>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}
