import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { applyPayload, computePayload } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  InputFormField,
  ModalCardDialog,
  ReactSelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { MarketplaceDetailsProps } from '../MarketplaceDetails.js';
import type {
  MarketplaceEditMainSpecsDialogState,
  MarketplaceEditMainSpecsForm,
} from '../typings/store.js';
import {
  computeEcoStickerValues,
  computeEnergyValues,
  computeGearboxValues,
} from '../i18nUtils.js';

const mandatoryFields: readonly (keyof MarketplaceEditMainSpecsForm)[] = [
  'energy',
  'gearbox',
  'horsepower',
  'taxHorsepower',
  'doors',
  'seats',
];

interface MarketplaceEditMainSpecsModalDialogProps extends Pick<MarketplaceDetailsProps, '$gs'> {
  readonly $: StoreStateSelector<Store, MarketplaceEditMainSpecsDialogState>;
  readonly kanbanId: string;
}

export function MarketplaceEditMainSpecsModalDialog({
  $,
  $gs,
  kanbanId,
}: MarketplaceEditMainSpecsModalDialogProps): JSX.Element {
  const [t] = useTranslation('details');

  const submitValidDataActionCallback = useActionCallback(
    async ({
      getState,
      actionDispatch,
      kanbanRepository,
    }: ActionContext<Store, MarketplaceEditMainSpecsDialogState>): Promise<void> => {
      const { energy, gearbox, horsepower, taxHorsepower, doors, seats, ecoSticker } =
        getState().formData;
      const kanban = await kanbanRepository.getEntity(kanbanId);
      const newKanban = applyPayload(kanban, {
        marketplaceInfos: {
          mainSpecs: {
            energy,
            gearbox,
            horsepower: Number.parseFloat(horsepower),
            taxHorsepower: Number.parseFloat(taxHorsepower),
            doors: Number.parseInt(doors, 10),
            seats: Number.parseInt(seats, 10),
            ecoSticker,
          },
        },
      });

      // Update the kanban in the repository only if needed
      const payload = computePayload(kanban, newKanban);
      if (payload !== undefined) {
        await kanbanRepository.updateEntityFromPayload({ entityId: kanbanId, payload });
      }
      actionDispatch.setProperty('active', false);
    },
    [kanbanId],
    $
  );

  const [onFormSubmit, , $fomDataWithChangeTrigger] = useFormWithValidation<
    Store,
    MarketplaceEditMainSpecsDialogState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions: undefined,
    checkFormConsistencyAction: undefined,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  const formWarning = useGetState($.$formWarning);
  const isOnline = useGetState($gs.$session.$isOnline);

  const modalWarning = useMemo(() => {
    if (formWarning !== undefined) {
      return formWarning;
    }
    if (!isOnline) {
      return t('tabs.marketplace.editAdModalDialog.warnings.offline');
    }
    return undefined;
  }, [formWarning, isOnline, t]);

  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('tabs.marketplace.mainSpecs.subtitle')}
      onOkClicked={onFormSubmit}
      warning={modalWarning}
      size="max-desktop"
    >
      <>
        <div className="title is-size-5 m-2">{t('tabs.marketplace.mainSpecs.engineInfos')}</div>
        <div className="columns m-2">
          <div className="column is-3">
            <ReactSelectFormField
              label={t('tabs.marketplace.mainSpecs.energy')}
              $={$fomDataWithChangeTrigger.$energy}
              suggestions={computeEnergyValues()}
            />
          </div>
          <div className="column is-3">
            <ReactSelectFormField
              label={t('tabs.marketplace.mainSpecs.gearbox')}
              $={$fomDataWithChangeTrigger.$gearbox}
              suggestions={computeGearboxValues()}
            />
          </div>
          <div className="column is-3">
            <InputFormField
              $={$fomDataWithChangeTrigger.$horsepower}
              type="number"
              label={t('tabs.marketplace.mainSpecs.horsepower')}
            />
          </div>
          <div className="column is-3">
            <InputFormField
              $={$fomDataWithChangeTrigger.$taxHorsepower}
              type="number"
              label={t('tabs.marketplace.mainSpecs.taxHorsepower')}
            />
          </div>
        </div>
        <div className="title is-5 m-2">{t('tabs.marketplace.mainSpecs.otherInfos')}</div>
        <div className="columns m-2">
          <div className="column is-3">
            <InputFormField
              $={$fomDataWithChangeTrigger.$doors}
              type="number"
              label={t('tabs.marketplace.mainSpecs.doors')}
            />
          </div>
          <div className="column is-3">
            <InputFormField
              $={$fomDataWithChangeTrigger.$seats}
              type="number"
              label={t('tabs.marketplace.mainSpecs.seats')}
            />
          </div>
          <div className="column is-3">
            <ReactSelectFormField
              label={t('tabs.marketplace.mainSpecs.ecoSticker')}
              $={$fomDataWithChangeTrigger.$ecoSticker}
              suggestions={computeEcoStickerValues()}
            />
          </div>
        </div>
      </>
    </ModalCardDialog>
  );
}
