import React from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  useActionCallback,
  useArrayItemSelector,
  useGetArrayItemIds,
  useGetState,
} from '@stimcar/libs-uikernel';
import { Notification } from '../lib/bulma/elements/Notification.js';
import type { NotificationEntry, Store } from './state/typings/store.js';

interface NotificationMessageProps {
  readonly id: string;
  readonly $notifications: StoreStateSelector<Store, readonly NotificationEntry[]>;
}

function NotificationMessage({ $notifications, id }: NotificationMessageProps): JSX.Element {
  const $notification = useArrayItemSelector($notifications, id);
  const level = useGetState($notification.$level);
  const message = useGetState($notification.$message);
  const closeNotificationActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      actionDispatch.setValue(getState().filter(({ id: itemId }) => itemId !== id));
    },
    [id],
    $notifications
  );
  return (
    <Notification
      type={level}
      value={message}
      closeHandler={closeNotificationActionCallback}
      blink
    />
  );
}

export function NotificationMessages({ $gs }: AppProps<Store>): JSX.Element {
  const notifications = useGetArrayItemIds($gs.$notifications);
  return (
    <div style={{ position: 'absolute', zIndex: 10, right: 10, top: 10, minWidth: 300 }}>
      {notifications
        .slice()
        .reverse()
        .map((id) => (
          <NotificationMessage key={id} id={id} $notifications={$gs.$notifications} />
        ))}
    </div>
  );
}
