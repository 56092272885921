import React from 'react';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import type { AnyStoreDef, NoArgActionCallback } from '../../zustand/index.js';

interface IconButtonProps {
  readonly icon: string;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly ariaLabel: string;
  readonly iconClassname?: string;
  readonly buttonClassname?: string;
  readonly labelClassname?: string;
  readonly onClickCallback?:
    | NoArgActionCallback<AnyStoreDef>
    | React.MouseEventHandler<HTMLButtonElement>;
}

export function IconButton({
  icon,
  label = '',
  disabled = false,
  ariaLabel,
  iconClassname = '',
  buttonClassname = '',
  labelClassname = '',
  onClickCallback,
}: IconButtonProps): JSX.Element {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={!disabled ? onClickCallback : undefined}
      aria-label={ariaLabel}
      className={`button ${buttonClassname}`}
    >
      <span className="icon">
        <i className={`fa fa-${icon} ${iconClassname}`} />
      </span>
      {isTruthyAndNotEmpty(label) && <span className={`ml-2 ${labelClassname}`}>{label}</span>}
    </button>
  );
}
