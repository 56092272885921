/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { KeyValueStorage } from '@stimcar/libs-kernel';
import type {
  ActionContext,
  AnyStoreDef,
  ArrayItemStateType,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { AnyTableStoreDef, CheckFormConsistencyAction } from '@stimcar/libs-uitoolkit';
import { Sequence } from '@stimcar/libs-base';
import { computePayload, isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useGetState,
  useRecordItemSelector,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  FaIcon,
  Input,
  ModalCardDialog,
  ScrollableContainer,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import { MenuButton } from '../../../components/MenuButton.js';
import type {
  DisplayColumnDesc,
  Filter,
  FilterAssociationOperators,
  FilterCondition,
  FiltersMenuState,
  Operator,
  PropertyTypes,
  SavedFilter,
  SavedFilterIdDialogState,
} from './typings/store.js';
import { filterEmptyStringConditions } from './filterAndSortUtils.js';
import { FiltersMenuLink } from './impl/FiltersMenuLink.js';
import {
  BOOLEAN_FILTER_OPERATORS,
  EMPTY_FILTER,
  EMPTY_FILTER_CONDITION,
  FILTER_ASSOCIATION_OPERATORS,
  NUMBER_FILTER_OPERATORS,
  SAVED_FILTER_ID_DIALOG_EMPTY_STATE,
  TEXT_FILTER_OPERATORS,
} from './typings/store.js';

function removeAllConditionsAction<SD extends AnyTableStoreDef>({
  actionDispatch,
}: ActionContext<SD, FiltersMenuState>): void {
  actionDispatch.reduce((initial: FiltersMenuState) => {
    return {
      ...initial,
      isActive: false,
      filter: EMPTY_FILTER,
    };
  });
}

function getDefaultOperatorForColumn<O extends ArrayItemStateType>(
  column: DisplayColumnDesc<O>
): Operator {
  let operator: Operator = TEXT_FILTER_OPERATORS[0];
  switch (column.propertyType) {
    case 'date':
    case 'number':
      [operator] = NUMBER_FILTER_OPERATORS;
      break;
    case 'string':
      break;
    case 'boolean':
      [operator] = BOOLEAN_FILTER_OPERATORS;
      break;
    default:
      break;
  }
  return operator;
}

const FILTER_CONDITION_SEQUENCE = new Sequence('c');

function addConditionAction<SD extends AnyTableStoreDef, O extends ArrayItemStateType>(
  { actionDispatch }: ActionContext<SD, FiltersMenuState>,
  defaultColumn: DisplayColumnDesc<O>
): void {
  actionDispatch.reduce((initial: FiltersMenuState) => {
    const newCondition: FilterCondition = {
      ...EMPTY_FILTER_CONDITION,
      id: FILTER_CONDITION_SEQUENCE.next(),
      columnId: defaultColumn.id,
      propertyType: defaultColumn.propertyType,
      operator: getDefaultOperatorForColumn(defaultColumn),
    };
    return {
      ...initial,
      filter: {
        ...initial.filter,
        filterConditions: [...initial.filter.filterConditions, newCondition],
      },
    };
  });
}

function selectSavedFilterAction<SD extends AnyTableStoreDef>(
  { actionDispatch }: ActionContext<SD, FiltersMenuState>,
  savedFilter: SavedFilter | undefined
): void {
  actionDispatch.reduce((initial: FiltersMenuState) => {
    return {
      ...initial,
      filter: savedFilter?.filter ?? EMPTY_FILTER,
      selectedSavedFilterId: savedFilter?.id,
    };
  });
}

function saveInLocalStorage(
  keyValueStorage: KeyValueStorage,
  localStorageKey: string,
  state: FiltersMenuState
): void {
  if (localStorageKey) {
    const storedPrefs = keyValueStorage.getObjectItem(localStorageKey);
    keyValueStorage.setObjectItem(localStorageKey, {
      ...storedPrefs,
      filters: state.savedFilters,
    });
  }
}

export interface FiltersMenuProps<SD extends AnyTableStoreDef, O extends ArrayItemStateType> {
  readonly $: StoreStateSelector<SD, FiltersMenuState>;
  readonly columnDescs: readonly DisplayColumnDesc<O>[];
  readonly loadAndFilterItemsActionCallback: NoArgActionCallback<SD>;
  readonly buttonLabel?: string;
  readonly localStorageKey?: string;
}

export function FiltersMenu<SD extends AnyTableStoreDef, O extends ArrayItemStateType>({
  $,
  columnDescs,
  loadAndFilterItemsActionCallback,
  buttonLabel,
  localStorageKey,
}: FiltersMenuProps<SD, O>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const filter = useGetState($.$filter);

  const sortedColumnDescs = useMemo(() => {
    return columnDescs
      .filter((c) => !c.isNotFilterable)
      .sort((a, b) => a.columnLabel.localeCompare(b.columnLabel));
  }, [columnDescs]);

  const removeAllConditionsActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(removeAllConditionsAction);
      await actionDispatch.execCallback(loadAndFilterItemsActionCallback);
    },
    [loadAndFilterItemsActionCallback],
    $
  );

  const addConditionActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        addConditionAction,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortedColumnDescs[0] as any
      );
      await actionDispatch.execCallback(loadAndFilterItemsActionCallback);
    },
    [loadAndFilterItemsActionCallback, sortedColumnDescs],
    $
  );

  return (
    <MenuButton
      $active={$.$active}
      isDeactivateOnClickOutsideEnabled={useIsDeactivateOnClickOutsideEnabled<SD>($)}
      label={
        buttonLabel ||
        t('filtersMenu.buttonLabel', {
          count: filterEmptyStringConditions(filter.filterConditions).length,
        })
      }
    >
      <div className="dropdown-content">
        <FilterHeader
          $={$}
          localStorageKey={localStorageKey}
          loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
        />
        <ScrollableContainer isNarrowUntilMaxHeight>
          <>
            {filter.filterConditions.length === 0 && (
              <div className="dropdown-item">{t('filtersMenu.noConditions')}</div>
            )}
            {filter.filterConditions.map(
              (c, i): JSX.Element => (
                <FilterEntryComponent
                  key={c.id}
                  conditionId={c.id}
                  associationOperator={filter.associationOperator}
                  index={i}
                  loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
                  columnDescs={sortedColumnDescs}
                  $={$}
                />
              )
            )}
          </>
        </ScrollableContainer>
        <hr className="dropdown-divider" />
        <div className="columns">
          <div className="column is-narrow">
            <button
              type="button"
              className="button has-text-centered is-text is-small"
              onClick={addConditionActionCallback}
            >
              {t('filtersMenu.add')}
            </button>
          </div>
          <div className="column" />
          <div className="column is-narrow">
            <button
              type="button"
              className="button has-text-centered is-text is-small"
              onClick={removeAllConditionsActionCallback}
            >
              {t('filtersMenu.clearAll')}
            </button>
          </div>
        </div>
      </div>
    </MenuButton>
  );
}

const checkFormConsistencyAction: CheckFormConsistencyAction<
  AnyStoreDef,
  SavedFilterIdDialogState
> = ({ t, formState }): string | undefined => {
  const isUnique =
    formState.savedFilterIds.find((n: string) => n === formState.formData.idInput.trim()) ===
    undefined;
  return !isUnique ? t(`filtersMenu.chooseIdModal.idAlreadyUsed`) : undefined;
};

// Allows to deactivate the auto-close behavior if one of the modal dialog
// is opened
function useIsDeactivateOnClickOutsideEnabled<SD extends AnyTableStoreDef>(
  $: StoreStateSelector<SD, FiltersMenuState>
) {
  const savedFilterIdDialogIsActive = useGetState($.$savedFilterIdDialog.$isActive);
  const filterDeletionDialogIsActive = useGetState($.$filterDeletionDialog.$isActive);
  const isDeactivateOnClickOutsideEnabled =
    !savedFilterIdDialogIsActive && !filterDeletionDialogIsActive;
  return isDeactivateOnClickOutsideEnabled;
}

interface FilterHeaderProps<SD extends AnyTableStoreDef> {
  readonly $: StoreStateSelector<SD, FiltersMenuState>;
  readonly loadAndFilterItemsActionCallback: NoArgActionCallback<SD>;
  readonly localStorageKey?: string;
}

function FilterHeader<SD extends AnyTableStoreDef>({
  $,
  loadAndFilterItemsActionCallback,
  localStorageKey,
}: FilterHeaderProps<SD>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const savedFilters = useGetState($.$savedFilters);
  const selectedSavedFilterId = useGetState($.$selectedSavedFilterId);
  const filter = useGetState($.$filter);

  const deleteSavedFilterCallback = useActionCallback(
    async ({
      actionDispatch,
      keyValueStorage,
      getState,
    }: ActionContext<SD, FiltersMenuState>): Promise<void> => {
      actionDispatch.reduce((initial: FiltersMenuState) => {
        return {
          ...initial,
          filterDeletionDialog: {
            ...initial.filterDeletionDialog,
            isActive: false,
          },
          savedFilters: initial.savedFilters.filter((f) => f.id !== initial.selectedSavedFilterId),
        };
      });
      await actionDispatch.exec(selectSavedFilterAction, undefined);

      if (localStorageKey) {
        saveInLocalStorage(keyValueStorage, localStorageKey, getState());
      }
    },
    [localStorageKey],
    $
  );

  const registerSavedFilterCallback = useActionCallback(
    ({ actionDispatch, keyValueStorage, getState }: ActionContext<SD, FiltersMenuState>) => {
      const { selectedSavedFilterId: selectedId } = getState();
      if (isTruthyAndNotEmpty(selectedId)) {
        actionDispatch.reduce((initial: FiltersMenuState) => {
          const newSavedFilters = initial.savedFilters.map((f): SavedFilter => {
            if (f.id === selectedId) {
              return {
                ...f,
                filter: initial.filter,
              };
            }
            return f;
          });

          return {
            ...initial,
            savedFilters: newSavedFilters,
            selectedSavedFilterId: selectedId,
            savedFilterIdDialog: SAVED_FILTER_ID_DIALOG_EMPTY_STATE,
          };
        });
        if (localStorageKey) {
          saveInLocalStorage(keyValueStorage, localStorageKey, getState());
        }
      }
    },
    [localStorageKey],
    $
  );

  const registerAsSavedFilterCallback = useActionCallback(
    ({ actionDispatch, keyValueStorage, getState }: ActionContext<SD, FiltersMenuState>) => {
      const { savedFilterIdDialog } = getState();
      if (isTruthyAndNotEmpty(savedFilterIdDialog.formData.idInput)) {
        actionDispatch.reduce((initial: FiltersMenuState) => {
          const id = savedFilterIdDialog.formData.idInput;
          const newSavedFilters = [...initial.savedFilters, { id, filter: initial.filter }];
          return {
            ...initial,
            savedFilters: newSavedFilters,
            selectedSavedFilterId: id,
            savedFilterIdDialog: SAVED_FILTER_ID_DIALOG_EMPTY_STATE,
          };
        });
        if (localStorageKey) {
          saveInLocalStorage(keyValueStorage, localStorageKey, getState());
        }
      } else {
        actionDispatch.reduce((initial: FiltersMenuState) => {
          return {
            ...initial,
            savedFilterIdDialog: {
              ...SAVED_FILTER_ID_DIALOG_EMPTY_STATE,
              isActive: true,
              savedFilterIds: initial.savedFilters.map((n): string => n.id),
            },
          };
        });
      }
    },
    [localStorageKey],
    $
  );

  const openDeleteModalCallback = useActionCallback(
    ({ actionDispatch }: ActionContext<SD, FiltersMenuState>) => {
      actionDispatch.reduce((initial: FiltersMenuState) => {
        return {
          ...initial,
          filterDeletionDialog: { ...initial.filterDeletionDialog, isActive: true },
        };
      });
    },
    [],
    $
  );

  const selectedFilter = useMemo(() => {
    return savedFilters.find((f) => f.id === selectedSavedFilterId);
  }, [savedFilters, selectedSavedFilterId]);

  const hasChanges = useMemo(() => {
    if (selectedFilter) {
      if (
        filter.associationOperator === selectedFilter.filter.associationOperator &&
        filter.filterConditions.length === selectedFilter.filter.filterConditions.length
      ) {
        const payload = computePayload(
          filter.filterConditions,
          selectedFilter.filter.filterConditions
        );
        return isTruthy(payload) && payload.length > 0;
      }
      return true;
    }

    return false;
  }, [selectedFilter, filter.associationOperator, filter.filterConditions]);

  const computeSelectedFilterLabel = useMemo(() => {
    if (isTruthyAndNotEmpty(selectedSavedFilterId)) {
      return `${selectedSavedFilterId}${hasChanges ? '*' : ''}`;
    }
    return t('filtersMenu.chooseSavedFilter');
  }, [selectedSavedFilterId, t, hasChanges]);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    SD,
    SavedFilterIdDialogState
  >({
    $: $.$savedFilterIdDialog,
    mandatoryFields: ['idInput'],
    checkFormConsistencyAction,
    submitValidDataAction: registerAsSavedFilterCallback,
    t,
  });

  const isSaveButtonDisabled = (): boolean => {
    if (isTruthyAndNotEmpty(selectedSavedFilterId)) {
      if (isTruthy(selectedFilter?.isCreatedByDefault) || !hasChanges) {
        return true;
      }
    }
    if (filter.filterConditions.length === 0) {
      return true;
    }
    return false;
  };

  const isSaveAsButtonDisabled = (): boolean => {
    if (filter.filterConditions.length === 0) {
      return true;
    }
    return false;
  };

  const isDeactivateOnClickOutsideEnabled = useIsDeactivateOnClickOutsideEnabled<SD>($);
  return (
    <>
      {(localStorageKey || (!localStorageKey && savedFilters.length > 0)) && (
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
              <FiltersMenuLink
                $active={$.$savedFilterMenuActive}
                additionalClasses="dropdown-item"
                label={computeSelectedFilterLabel}
                activateOnLabelClick
                deactivateOnInternalClick
                isDeactivateOnClickOutsideEnabled={isDeactivateOnClickOutsideEnabled}
              >
                <ScrollableContainer className="dropdown-content" isNarrowUntilMaxHeight>
                  <>
                    {isTruthyAndNotEmpty(selectedSavedFilterId) && (
                      <>
                        <SavedFilterMenuEntry
                          $={$}
                          savedFilter={undefined}
                          loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
                        />
                        <hr className="dropdown-divider" />
                      </>
                    )}
                    {savedFilters.length === 0 ? (
                      <>
                        <a
                          className="dropdown-item"
                          style={{ cursor: 'not-allowed', pointerEvents: 'none' }}
                        >
                          {t('filtersMenu.noSavedFilter')}
                        </a>
                      </>
                    ) : (
                      savedFilters.map((f): JSX.Element => {
                        return (
                          <SavedFilterMenuEntry
                            key={f.id}
                            $={$}
                            savedFilter={f}
                            loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
                          />
                        );
                      })
                    )}
                  </>
                </ScrollableContainer>
              </FiltersMenuLink>
            </div>
            {localStorageKey && (
              <div className="buttons">
                <button
                  type="button"
                  className="button has-text-centered is-text is-small"
                  onClick={registerSavedFilterCallback}
                  disabled={isSaveButtonDisabled()}
                >
                  {t('filtersMenu.save')}
                </button>
                <button
                  type="button"
                  className="button has-text-centered is-text is-small"
                  onClick={registerAsSavedFilterCallback}
                  disabled={isSaveAsButtonDisabled()}
                >
                  {t('filtersMenu.saveAs')}
                </button>
                <button
                  type="button"
                  className="button has-text-centered is-text is-small"
                  onClick={openDeleteModalCallback}
                  disabled={
                    !isTruthyAndNotEmpty(selectedSavedFilterId) ||
                    isTruthy(selectedFilter?.isCreatedByDefault)
                  }
                >
                  {t('filtersMenu.delete')}
                </button>
              </div>
            )}
          </div>
          <hr className="dropdown-divider" />
        </>
      )}
      <ModalCardDialog
        $active={$.$savedFilterIdDialog.$isActive}
        onOkClicked={onFormSubmit}
        okLabel={t('filtersMenu.chooseIdModal.okButton')}
        title={t('filtersMenu.chooseIdModal.title')}
        warning={useGetState($.$savedFilterIdDialog.$formWarning)}
      >
        <Input
          $={$formDataWithChangeTrigger.$idInput}
          placeholder={t('filtersMenu.chooseIdModal.placeholder')}
        />
      </ModalCardDialog>
      <ModalCardDialog
        $active={$.$filterDeletionDialog.$isActive}
        onOkClicked={deleteSavedFilterCallback}
        okLabel={t('filtersMenu.deleteFilterModal.okButton')}
        title={t('filtersMenu.deleteFilterModal.title')}
        warning={useGetState($.$savedFilterIdDialog.$formWarning)}
      >
        {t('filtersMenu.deleteFilterModal.content')}
      </ModalCardDialog>
    </>
  );
}

interface SavedFilterMenuEntryProps<SD extends AnyTableStoreDef> {
  readonly savedFilter: SavedFilter | undefined;
  readonly loadAndFilterItemsActionCallback: NoArgActionCallback<SD>;
  readonly $: StoreStateSelector<SD, FiltersMenuState>;
}

function SavedFilterMenuEntry<SD extends AnyTableStoreDef>({
  savedFilter,
  loadAndFilterItemsActionCallback,
  $,
}: SavedFilterMenuEntryProps<SD>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const selectSavedFilterActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(selectSavedFilterAction, savedFilter);
      await actionDispatch.execCallback(loadAndFilterItemsActionCallback);
    },
    [loadAndFilterItemsActionCallback, savedFilter],
    $
  );

  return (
    <a role="button" className="dropdown-item" onClick={selectSavedFilterActionCallback}>
      {savedFilter?.id ?? t('filtersMenu.removeSavedFilter')}
    </a>
  );
}

function removeConditionAction<SD extends AnyTableStoreDef>(
  { actionDispatch }: ActionContext<SD, FiltersMenuState>,
  conditionId: string
): void {
  actionDispatch.reduce((initial: FiltersMenuState) => {
    return {
      ...initial,
      filter: {
        ...initial.filter,
        filterConditions: initial.filter.filterConditions.filter((f) => f.id !== conditionId),
      },
    };
  });
}

const getAssociationOperatorName = (t: TFunction, operator: FilterAssociationOperators) => {
  return t(`filtersMenu.associationOperators.${operator}`);
};

const getConditionOperatorName = (
  t: TFunction,
  operator: Operator,
  selectedColumnType: PropertyTypes | undefined
): string => {
  if (selectedColumnType === 'date') {
    return t(`filtersMenu.operators.${operator}-date`);
  }
  if (selectedColumnType === 'boolean') {
    return t(`filtersMenu.operators.${operator}-boolean`);
  }
  return t(`filtersMenu.operators.${operator}`);
};

interface FilterEntryComponentProps<SD extends AnyTableStoreDef, O extends ArrayItemStateType> {
  readonly conditionId: string;
  readonly loadAndFilterItemsActionCallback: NoArgActionCallback<SD>;
  readonly columnDescs: readonly DisplayColumnDesc<O>[];
  readonly index: number;
  readonly associationOperator: FilterAssociationOperators;
  readonly $: StoreStateSelector<SD, FiltersMenuState>;
}

function FilterEntryComponent<SD extends AnyTableStoreDef, O extends ArrayItemStateType>({
  conditionId,
  associationOperator,
  loadAndFilterItemsActionCallback,
  index,
  columnDescs,
  $,
}: FilterEntryComponentProps<SD, O>): JSX.Element {
  const [t] = useTranslation('libBulma');
  const $conditionSelector = useArrayItemSelector($.$filter.$filterConditions, conditionId);

  const $valueWithChangeTrigger = useSelectorWithChangeTrigger(
    $conditionSelector.$value,
    loadAndFilterItemsActionCallback
  );
  const condition = useGetState($conditionSelector);

  const removeConditionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(removeConditionAction, condition.id);
      await actionDispatch.execCallback(loadAndFilterItemsActionCallback);
    },
    [condition.id, loadAndFilterItemsActionCallback],
    $
  );

  const getSelectedColumnName = (): string => {
    return columnDescs.find((c) => c.id === condition.columnId)?.columnLabel ?? '';
  };

  const selectedColumnType = useMemo(() => {
    return columnDescs.find((c) => c.id === condition.columnId)?.propertyType;
  }, [columnDescs, condition.columnId]);

  const getCorrectOperators = (): readonly Operator[] => {
    if (selectedColumnType === 'number' || selectedColumnType === 'date') {
      return NUMBER_FILTER_OPERATORS;
    }
    if (selectedColumnType === 'boolean') {
      return BOOLEAN_FILTER_OPERATORS;
    }
    return TEXT_FILTER_OPERATORS;
  };

  const displayInputArea = (): boolean => {
    return (
      condition.operator !== 'empty' &&
      condition.operator !== 'notEmpty' &&
      condition.propertyType !== 'boolean'
    );
  };

  return (
    <div className="dropdown-item">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'minmax(1px, auto) 50px repeat(2, 150px) 250px',
          gridColumnGap: '10px',
          alignItems: 'center',
        }}
      >
        <a role="button" onClick={removeConditionCallback}>
          <FaIcon id="times" />
        </a>

        {index === 0 && 'Filter'}
        {index === 1 && (
          <FiltersMenuLink
            label={getAssociationOperatorName(t, associationOperator)}
            activateOnLabelClick
            deactivateOnInternalClick
            $active={$.$associationOperatorMenuActive}
          >
            <ScrollableContainer className="dropdown-content" isNarrowUntilMaxHeight>
              {FILTER_ASSOCIATION_OPERATORS.map((o): JSX.Element => {
                return (
                  <AssociationOperatorMenuEntry
                    key={o}
                    $={$.$filter}
                    loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
                    operator={o}
                  />
                );
              })}
            </ScrollableContainer>
          </FiltersMenuLink>
        )}
        {index > 1 && t(`filtersMenu.associationOperators.${associationOperator}`)}

        <FiltersMenuLink
          $active={useRecordItemSelector($.$filterFragmentMenuActive, `${conditionId}-columnName`)}
          label={getSelectedColumnName()}
          activateOnLabelClick
          deactivateOnInternalClick
        >
          <ScrollableContainer className="dropdown-content" isNarrowUntilMaxHeight>
            {columnDescs.map((c): JSX.Element => {
              return (
                <ColumnSelectionMenuEntry
                  key={c.id}
                  columnDesc={c}
                  loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
                  condition={condition}
                  $={$}
                />
              );
            })}
          </ScrollableContainer>
        </FiltersMenuLink>

        <FiltersMenuLink
          $active={useRecordItemSelector(
            $.$filterFragmentMenuActive,
            `${conditionId}-operatorName`
          )}
          label={getConditionOperatorName(t, condition.operator, selectedColumnType)}
          activateOnLabelClick
          deactivateOnInternalClick
        >
          <ScrollableContainer className="dropdown-content" isNarrowUntilMaxHeight>
            {getCorrectOperators().map((o): JSX.Element => {
              return (
                <ConditionOperatorMenuEntry
                  key={o}
                  conditionId={condition.id}
                  $={$.$filter}
                  loadAndFilterItemsActionCallback={loadAndFilterItemsActionCallback}
                  operator={o}
                  selectedColumnType={selectedColumnType}
                />
              );
            })}
          </ScrollableContainer>
        </FiltersMenuLink>

        {displayInputArea() && (
          <Input
            type={condition.propertyType === 'date' ? 'date' : 'text'}
            className="is-small"
            $={$valueWithChangeTrigger}
            placeholder={t('filtersMenu.inputPlaceholder')}
          />
        )}
      </div>
    </div>
  );
}

function updateSelectedColumnAction<SD extends AnyTableStoreDef, O extends ArrayItemStateType>(
  { actionDispatch }: ActionContext<SD, FiltersMenuState>,
  conditionId: string,
  columnDesc: DisplayColumnDesc<O>
): void {
  actionDispatch.reduce((initial: FiltersMenuState) => {
    return {
      ...initial,
      filter: {
        ...initial.filter,
        filterConditions: initial.filter.filterConditions.map((c): FilterCondition => {
          if (c.id === conditionId) {
            return {
              ...c,
              columnId: columnDesc.id,
              operator: getDefaultOperatorForColumn(columnDesc),
              propertyType: columnDesc.propertyType,
            };
          }
          return c;
        }),
      },
    };
  });
}

interface ColumnSelectionMenuEntryProps<SD extends AnyTableStoreDef, O extends ArrayItemStateType> {
  readonly columnDesc: DisplayColumnDesc<O>;
  readonly condition: FilterCondition;
  readonly $: StoreStateSelector<SD, FiltersMenuState>;
  readonly loadAndFilterItemsActionCallback: NoArgActionCallback<SD>;
}

function ColumnSelectionMenuEntry<SD extends AnyTableStoreDef, O extends ArrayItemStateType>({
  columnDesc,
  loadAndFilterItemsActionCallback,
  condition,
  $,
}: ColumnSelectionMenuEntryProps<SD, O>): JSX.Element {
  const updateColumnCallback = useActionCallback(
    async ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await actionDispatch.exec(updateSelectedColumnAction, condition.id, columnDesc as any);
      await actionDispatch.execCallback(loadAndFilterItemsActionCallback);
    },
    [columnDesc, condition.id, loadAndFilterItemsActionCallback],
    $
  );

  return (
    <a role="button" className="dropdown-item" onClick={updateColumnCallback}>
      {columnDesc.columnLabel}
    </a>
  );
}

interface ConditionOperatorMenuEntryProps<SD extends AnyTableStoreDef> {
  readonly operator: Operator;
  readonly conditionId: string;
  readonly $: StoreStateSelector<SD, Filter>;
  readonly selectedColumnType: PropertyTypes | undefined;
  readonly loadAndFilterItemsActionCallback: NoArgActionCallback<SD>;
}

function ConditionOperatorMenuEntry<SD extends AnyTableStoreDef>({
  operator,
  conditionId,
  $,
  selectedColumnType,
  loadAndFilterItemsActionCallback,
}: ConditionOperatorMenuEntryProps<SD>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const updateOperatorCallback = useActionCallback(
    async ({ actionDispatch }) => {
      actionDispatch.applyPayload({
        filterConditions: [{ id: conditionId, operator }],
      });
      await actionDispatch.execCallback(loadAndFilterItemsActionCallback);
    },
    [conditionId, loadAndFilterItemsActionCallback, operator],
    $
  );

  return (
    <a key={operator} role="button" className="dropdown-item" onClick={updateOperatorCallback}>
      {getConditionOperatorName(t, operator, selectedColumnType)}
    </a>
  );
}

interface AssociationOperatorMenuEntryProps<SD extends AnyTableStoreDef> {
  readonly operator: FilterAssociationOperators;
  readonly loadAndFilterItemsActionCallback: NoArgActionCallback<SD>;
  readonly $: StoreStateSelector<SD, Filter>;
}

function AssociationOperatorMenuEntry<SD extends AnyTableStoreDef>({
  operator,
  loadAndFilterItemsActionCallback,
  $,
}: AssociationOperatorMenuEntryProps<SD>): JSX.Element {
  const [t] = useTranslation('libBulma');

  const updateOperatorCallback = useActionCallback(
    async ({ actionDispatch }) => {
      actionDispatch.setProperty('associationOperator', operator);
      await actionDispatch.execCallback(loadAndFilterItemsActionCallback);
    },
    [loadAndFilterItemsActionCallback, operator],
    $
  );

  return (
    <a key={operator} role="button" className="dropdown-item" onClick={updateOperatorCallback}>
      {getAssociationOperatorName(t, operator)}
    </a>
  );
}
