import React, { useMemo } from 'react';
import type { Civility, Customer, SpecificFields } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, CheckFormFieldContentActions } from '@stimcar/libs-uitoolkit';
import { EMPTY_REPOSITORY_ENTITY } from '@stimcar/libs-base';
import { applyPayload } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { EditCustomerDialogState } from '../../../lib/components/customer/typings/store.js';
import type { Store } from '../../state/typings/store.js';
import {
  computeCustomerFormDataPayload,
  convertBusinessCustomerToFormData,
  EditCustomerDialog,
  openEditCustomerDialogAction,
} from '../../../lib/components/customer/EditCustomerDialog.js';
import { useGetContractCodes } from '../../utils/useGetContract.js';
import type { AdminEditCustomerDialogState } from './typings/store.js';

export async function openAdminEditCustomerDialogAction(
  {
    customerRepository,
    httpClient,
    actionDispatch,
  }: ActionContext<Store, AdminEditCustomerDialogState>,
  selectedCustomerId?: string,
  mode?: EditCustomerDialogState['mode']
): Promise<void> {
  // Set customer identifier
  actionDispatch.setProperty(
    'customerId',
    !selectedCustomerId || mode === 'create'
      ? httpClient.getBrowserSequence().next()
      : selectedCustomerId
  );
  let initial: SpecificFields<Customer> | undefined;
  if (selectedCustomerId) {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { id, timestamp, sequenceId, dirty, status, ...rest } =
      await customerRepository.getEntity(selectedCustomerId);
    /* eslint-enable @typescript-eslint/no-unused-vars */
    initial = rest;
  }
  await actionDispatch.exec(openEditCustomerDialogAction, initial, mode);
}

export async function openAdminCreateCustomerDialogAction(
  ctx: ActionContext<Store, AdminEditCustomerDialogState>
): Promise<void> {
  return await openAdminEditCustomerDialogAction(ctx);
}

// eslint-disable-next-line @typescript-eslint/require-await
async function closeEditDialogAction({
  actionDispatch,
}: ActionContext<Store, AdminEditCustomerDialogState>): Promise<void> {
  actionDispatch.setProperty('active', false);
}

// eslint-disable-next-line @typescript-eslint/require-await
export async function updateAdminCustomerEditDialogStateFromSSEAction(
  { getState, actionDispatch }: ActionContext<Store, AdminEditCustomerDialogState>,
  customers: readonly Customer[]
): Promise<void> {
  const { active, customerId, initialCustomer, formData } = getState();
  if (active) {
    customers.forEach((c) => {
      if (customerId === c.id) {
        actionDispatch.setProperty('initialCustomer', c);
        const updatedToUI = convertBusinessCustomerToFormData(c);
        const payload = computeCustomerFormDataPayload(initialCustomer, formData);
        const newCustomer = payload ? applyPayload(updatedToUI, payload) : updatedToUI;
        actionDispatch.setProperty('formData', newCustomer);
      }
    });
  }
}

async function saveCustomerAction({
  customerRepository,
  getState,
  actionDispatch,
}: ActionContext<Store, AdminEditCustomerDialogState>): Promise<void> {
  const { customerId, formData, mode } = getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { warnings, type, ...formDataWithoutWarnings } = formData;
  const businessCustomer: Customer = {
    ...EMPTY_REPOSITORY_ENTITY,
    ...formDataWithoutWarnings,
    contract: formDataWithoutWarnings.contractCode,
    invoiceId: Number.parseInt(formDataWithoutWarnings.invoiceId, 10),
    civility: formDataWithoutWarnings.civility as Civility,
    individual: type === 'individual',
    id: customerId,
  };
  if (mode === 'create') {
    await customerRepository.createEntity(businessCustomer);
  } else {
    await customerRepository.updateEntity(businessCustomer);
  }
  // Close the diagram
  await actionDispatch.exec(closeEditDialogAction);
}

export function AdminEditCustomerDialog({ $gs }: AppProps<Store>): JSX.Element {
  const { $editCustomerDialog } = $gs.$adminView.$adminCustomers;
  const customerId = useGetState($editCustomerDialog.$customerId);

  const contractCodes = useGetContractCodes($gs);

  const submitValidDataAction = useActionCallback(saveCustomerAction, [], $editCustomerDialog);

  const checkFieldContentActions = useMemo((): CheckFormFieldContentActions<
    Store,
    EditCustomerDialogState
  > => {
    return {
      shortName: async ({
        value,
        t,
        customerRepository,
        formState,
      }): Promise<string | undefined> => {
        // For professionals, we must ensure the company is unique
        if (formState.formData.type === 'professional') {
          const allEntitiesOfTheSameCompany = (await customerRepository.getAllEntities()).filter(
            (e) =>
              !e.individual && e.contract === formState.formData.contractCode && e.id !== customerId
          );
          const sameShortName = allEntitiesOfTheSameCompany.find((c) => c.shortName === value);
          if (sameShortName !== undefined) {
            return t(`editCustomerDialog.warnings.nameAlreadyUsed`, {
              contractCode: formState.formData.contractCode,
            });
          }
        }
        return undefined;
      },
    };
  }, [customerId]);

  return (
    <EditCustomerDialog
      $={$editCustomerDialog}
      checkFieldContentActions={checkFieldContentActions}
      submitValidDataAction={submitValidDataAction}
      contractCodes={contractCodes}
    />
  );
}
