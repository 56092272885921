import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ScheduledTaskType } from '@stimcar/libs-base';
import type { StoreStateSelector, WithFormValidationWarnings } from '@stimcar/libs-uikernel';
import type { AppProps, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type { CreateScheduledTaskData } from './typings/store.js';
import { ImportVOLeanInputs } from './inputs/ImportVOLeanInputs.js';
import {
  CarsExpectedAtStandMailInputs,
  CustomersBasedMailInputs,
  DailyProdMailInputs,
  InvoiceMailInputs,
  SparePartsMailInputs,
} from './inputs/MailTypedScheduledTaskInputs.js';

export interface ArgumentsInputsProps extends AppProps<Store> {
  readonly $formData: StoreStateSelector<
    Store,
    WithFormValidationWarnings<CreateScheduledTaskData>
  >;
  readonly type: ScheduledTaskType;
  readonly standIds: readonly string[];
  readonly allCustomerShortnames: readonly string[];
  readonly allWorkflowIds: readonly string[];
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
}
export function ArgumentsInputsForScheduledTaskCreation({
  $formData,
  $gs,
  type,
  standIds,
  allCustomerShortnames,
  allWorkflowIds,
  horizontalFormFields,
}: ArgumentsInputsProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  switch (type) {
    case 'voLeanImport': {
      return (
        <ImportVOLeanInputs
          $formData={$formData}
          $gs={$gs}
          allWorkflowIds={allWorkflowIds}
          horizontalFormFields={horizontalFormFields}
        />
      );
    }
    case 'invoiceMail': {
      return (
        <InvoiceMailInputs
          $formData={$formData}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
        />
      );
    }
    case 'dailyprodmail': {
      return (
        <DailyProdMailInputs
          $formData={$formData}
          allStandIds={standIds}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
        />
      );
    }
    case 'ctmail': {
      return (
        <CustomersBasedMailInputs
          $formData={$formData}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
        />
      );
    }
    case 'sparepartsmail': {
      return (
        <SparePartsMailInputs
          $formData={$formData}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
        />
      );
    }
    case 'carsexpectedatstandmail': {
      return (
        <CarsExpectedAtStandMailInputs
          $formData={$formData}
          allStandIds={standIds}
          allCustomerShortnames={allCustomerShortnames}
          horizontalFormFields={horizontalFormFields}
        />
      );
    }
    default:
      throw new Error(t('errors.creationOrEditionNotAllowedForType'));
  }
}
