import React from 'react';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { WorkshopImplantationViewFromProvidedState } from '../../../lib/components/workshop/implantation/WorkshopImplantationViewFromProvidedState.js';

export function AdminWorkshopImplantation({ $gs }: AppProps<Store>): JSX.Element {
  return (
    <WorkshopImplantationViewFromProvidedState
      $={$gs.$adminView.$adminWorkshopImplantation}
      $gs={$gs}
      isInteractive
    />
  );
}
