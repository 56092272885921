import type { ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { BaseReactSelectProps, ReactSelectOption } from './ReactSelect.js';
import {
  ensureNoPrimitivesLeftInOptions,
  flattenOptionsAndGroupedOptions,
  getCustomStyles,
  inputCreationValidationFunction,
  noOptionMessageFunction,
} from './ReactSelect.js';

interface CustomMultiValueLabelProps {
  readonly data: {
    readonly label: string;
    readonly value: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly selectProps: any;
  readonly children: ReactNode;
  readonly innerProps: { readonly className?: string | undefined };
}

function CustomMultiValueLabel({
  data,
  children,
  innerProps,
  selectProps,
}: CustomMultiValueLabelProps): JSX.Element {
  const { label } = data;
  const { onValueLabelClick } = selectProps;

  const onClick = useCallback(() => {
    if (onValueLabelClick) {
      onValueLabelClick(label);
    }
  }, [label, onValueLabelClick]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLSpanElement>) => {
      if (e.key === 'Enter') {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={onValueLabelClick && 'is-clickable'}
    >
      <components.MultiValueLabel data={data} selectProps={selectProps} innerProps={innerProps}>
        {children}
      </components.MultiValueLabel>
    </div>
  );
}

export type ReactSelectMultiProps<
  SD extends AnyStoreDef,
  T extends string | number,
> = BaseReactSelectProps<T> & {
  readonly $: StoreStateSelector<SD, readonly T[]>;
  readonly onValueLabelClick?: (label: string) => void;
};

export function ReactSelectMulti<SD extends AnyStoreDef, T extends string | number>({
  $,
  suggestions,
  isClearable = false,
  placeholder,
  formatOptionLabel,
  creation = false,
  hideDropdownIndicator = false,
  isDisabled = false,
  customStylesOptions = {},
  onValueLabelClick,
}: ReactSelectMultiProps<SD, T>): JSX.Element {
  const [t] = useTranslation('bulma');
  const value = useGetState($);

  const creationToolkit = useMemo(() => {
    if (typeof creation === 'boolean') {
      if (creation) {
        return {};
      }
      return undefined;
    }
    return creation;
  }, [creation]);

  const structuredSuggestions = useMemo(
    () => ensureNoPrimitivesLeftInOptions(suggestions ?? []),
    [suggestions]
  );

  const dispatchedOptions: readonly ReactSelectOption<T>[] = useMemo(() => {
    const flattenOptions = flattenOptionsAndGroupedOptions(structuredSuggestions);
    return value.map((value): ReactSelectOption<T> => {
      return flattenOptions.find((s) => s.value === value) ?? { value, label: String(value) };
    });
  }, [value, structuredSuggestions]);

  const handleChangeActionCallback = useActionCallback(
    ({ actionDispatch }, newValuesList: readonly ReactSelectOption<T>[]) => {
      if (isTruthy(newValuesList) && Array.isArray(newValuesList)) {
        const newDispatchedValue = newValuesList.map((newValue) => newValue.value);
        actionDispatch.setValue(newDispatchedValue);
      }
    },
    [],
    $
  );

  const formatCreateLabelFunction = useCallback(
    (inputValue: string): string => {
      const toolkit = nonnull(creationToolkit);
      return toolkit.formatValidLabel
        ? toolkit.formatValidLabel(inputValue)
        : t('reactSelect.createLabel', { value: inputValue });
    },
    [creationToolkit, t]
  );

  const customStyles = getCustomStyles(customStylesOptions);
  const placeHolder = isTruthyAndNotEmpty(placeholder) ? placeholder : t('reactSelect.placeholder');

  const inputCreationValidationFunctionCallback = (inputValue: string): boolean => {
    return inputCreationValidationFunction(creationToolkit, inputValue);
  };

  const noOptionMessageFunctionCallback = ({ inputValue }: { inputValue: string }): string => {
    return noOptionMessageFunction(t, creationToolkit, inputValue);
  };
  return (
    <div className="control">
      {creationToolkit ? (
        <CreatableSelect
          value={dispatchedOptions}
          isMulti
          options={structuredSuggestions}
          isClearable={isClearable}
          onChange={handleChangeActionCallback}
          formatCreateLabel={formatCreateLabelFunction}
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={noOptionMessageFunctionCallback}
          isValidNewOption={inputCreationValidationFunctionCallback}
          placeholder={placeHolder}
          className="refitit-select"
          classNamePrefix="refitit-select"
          // these two last props set the options list's displaying on foreground with fixed position
          menuPortalTarget={document.body}
          styles={customStyles}
          // @ts-ignore
          onValueLabelClick={onValueLabelClick}
          components={{
            MultiValueLabel: CustomMultiValueLabel,
            ...(hideDropdownIndicator
              ? {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
              : {}),
          }}
          isDisabled={isDisabled}
        />
      ) : (
        <Select
          value={dispatchedOptions}
          isMulti
          options={structuredSuggestions}
          isClearable={isClearable}
          onChange={handleChangeActionCallback}
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={noOptionMessageFunctionCallback}
          placeholder={placeHolder}
          className="refitit-select"
          classNamePrefix="refitit-select"
          // these two last props set the options list's displaying on foreground with fixed position
          menuPortalTarget={document.body}
          styles={customStyles}
          // @ts-ignore
          onValueLabelClick={onValueLabelClick}
          components={{
            MultiValueLabel: CustomMultiValueLabel,
            ...(hideDropdownIndicator
              ? {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
              : {}),
          }}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
}
