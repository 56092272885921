import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminScheduledTasksState,
  OnScheduledTaskChangeActionCallback,
} from './typings/store.js';
import { EMPTY_DELETE_SCHEDULED_TASK_DIALOG_STATE } from './typings/store.js';

export async function deleteScheduledTaskAction(
  { getState, httpClient, actionDispatch }: ActionContext<Store, AdminScheduledTasksState>,
  onScheduledTaskChangeActionCallback: OnScheduledTaskChangeActionCallback
): Promise<void> {
  const { scheduledTaskId } = getState().deleteScheduledTaskDialogState;
  await httpClient.httpGet(CoreBackendRoutes.DELETE_SCHEDULED_TASK(scheduledTaskId));
  await actionDispatch.execCallback(onScheduledTaskChangeActionCallback, [], [scheduledTaskId]);
  actionDispatch.setProperty(
    'deleteScheduledTaskDialogState',
    EMPTY_DELETE_SCHEDULED_TASK_DIALOG_STATE
  );
}

export interface DeleteScheduledTaskProps {
  readonly $: StoreStateSelector<Store, AdminScheduledTasksState>;
  readonly onScheduledTaskChangeActionCallback: OnScheduledTaskChangeActionCallback;
}
export function DeleteScheduledTaskModal({
  $,
  onScheduledTaskChangeActionCallback,
}: DeleteScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const scheduledTaskTaskLabel = useGetState(
    $.$deleteScheduledTaskDialogState.$scheduledTaskTaskLabel
  );
  const deleteActionCallback = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(deleteScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );
  return (
    <ModalCardDialog
      $active={$.$deleteScheduledTaskDialogState.$active}
      title={t('deleteTitle')}
      onOkClicked={deleteActionCallback}
    >
      <p>{t('deleteMessage', { taskLabel: scheduledTaskTaskLabel })}</p>
    </ModalCardDialog>
  );
}
