import type { RefObject } from 'react';
import * as React from 'react';

interface IProps {
  readonly style?: IScrollbarStyle;
  readonly containerRef: RefObject<HTMLDivElement>;
  readonly tableRef: RefObject<HTMLTableElement>;
  readonly scrollTo: number;
  readonly onScroll: (scrollTo: number) => void;
}

interface IState {
  readonly focused: boolean;
  readonly containerHeight: number;
  readonly tableHeight: number;
  readonly theadHeight: number;
  readonly scrollbarHeight: number;
}

export interface IScrollbarStyle {
  readonly background: React.CSSProperties;
  readonly backgroundFocus: React.CSSProperties;
  readonly foreground: React.CSSProperties;
  readonly foregroundFocus: React.CSSProperties;
}

export class TableVerticalScrollbar extends React.Component<IProps, IState> {
  private readonly minHeight = 15;

  private scrollbarRef = React.createRef<HTMLDivElement>();

  private isMoving = false;

  private previousMoveClientY = 0;

  constructor(props: IProps) {
    super(props);

    this.state = {
      focused: false,
      containerHeight: 0,
      tableHeight: 0,
      theadHeight: 0,
      scrollbarHeight: 0,
    };
  }

  public componentDidMount(): void {
    this.calculateDimensions();
    const scrollbar = this.scrollbarRef.current;

    if (scrollbar) {
      scrollbar.addEventListener('mousedown', this.onMouseDown);
    }

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  }

  public componentDidUpdate(): void {
    const { containerRef, tableRef } = this.props;
    const { containerHeight, tableHeight } = this.state;

    const newContainerHeight = containerRef.current?.getBoundingClientRect().height;
    const newTableHeight = tableRef.current?.getBoundingClientRect().height;

    if (containerHeight !== newContainerHeight || tableHeight !== newTableHeight) {
      this.calculateDimensions();
    }
  }

  public componentWillUnmount(): void {
    const scrollbar = this.scrollbarRef.current;

    if (scrollbar) {
      scrollbar.removeEventListener('mousedown', this.onMouseDown);
    }
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  private onMouseDown = (event: MouseEvent): void => {
    event.preventDefault();

    this.isMoving = true;
    this.previousMoveClientY = event.clientY;
  };

  private onMouseMove = (event: MouseEvent): void => {
    let { scrollTo } = this.props;
    const { containerHeight, theadHeight, scrollbarHeight } = this.state;

    if (!this.isMoving) {
      return;
    }

    event.preventDefault();

    const currentMoveClientY = event.clientY;
    const deltaY = currentMoveClientY - this.previousMoveClientY;

    const scrollbarMoveableDistance = containerHeight - theadHeight - scrollbarHeight;

    scrollTo = scrollbarMoveableDistance
      ? (scrollbarMoveableDistance * scrollTo + deltaY) / scrollbarMoveableDistance
      : 0;

    scrollTo = Math.max(0, Math.min(scrollTo, 1));

    this.previousMoveClientY = currentMoveClientY;

    const { onScroll } = this.props;
    onScroll(scrollTo);
  };

  private onMouseUp = (event: MouseEvent): void => {
    if (!this.isMoving) {
      return;
    }

    event.preventDefault();

    this.isMoving = false;
    this.previousMoveClientY = 0;
  };

  private onMouseOver = (): void => {
    this.setState({ focused: true });
  };

  private onMouseOut = (): void => {
    this.setState({ focused: false });
  };

  private calculateDimensions(): void {
    const { containerRef, tableRef } = this.props;

    if (!containerRef.current || !tableRef.current) {
      return;
    }

    const containerHeight = containerRef.current.getBoundingClientRect().height;
    const tableHeight = tableRef.current.getBoundingClientRect().height;
    const theadHeight =
      tableRef.current.querySelector('thead')?.getBoundingClientRect().height || 0;
    const noHeaderTableHeight = tableHeight - theadHeight;

    const visibleContainerHeight = containerHeight - theadHeight;

    let scrollbarHeight = noHeaderTableHeight
      ? visibleContainerHeight ** 2 / noHeaderTableHeight
      : 0;

    scrollbarHeight = Math.max(this.minHeight, Math.min(scrollbarHeight, visibleContainerHeight));

    this.setState({
      containerHeight,
      tableHeight,
      theadHeight,
      scrollbarHeight,
    });
  }

  public render(): JSX.Element {
    const { style, tableRef, scrollTo } = this.props;
    const { focused, containerHeight, tableHeight, theadHeight, scrollbarHeight } = this.state;

    const isScrollable = tableRef.current
      ? containerHeight - theadHeight < tableHeight - theadHeight
      : false;

    let scrollbarContainerStyle: React.CSSProperties = {
      display: isScrollable ? 'block' : 'none',
      boxSizing: 'border-box',
      position: 'absolute',
      top: theadHeight,
      right: 0,
      bottom: 0,
      backgroundColor: '#E3E5EB',
      width: 8,
    };

    if (style) {
      if (focused) {
        scrollbarContainerStyle = { ...scrollbarContainerStyle, ...style.backgroundFocus };
      } else {
        scrollbarContainerStyle = { ...scrollbarContainerStyle, ...style.background };
      }
    }

    const scrollbarPositionTop = (containerHeight - theadHeight - scrollbarHeight) * scrollTo;

    let scrollbarStyle: React.CSSProperties = {
      boxSizing: 'border-box',
      position: 'absolute',
      top: scrollbarPositionTop,
      right: 0,
      backgroundColor: '#888C97',
      borderRadius: 4,
      width: 8,
      height: scrollbarHeight,
    };

    if (style) {
      if (focused) {
        scrollbarStyle = { ...scrollbarStyle, ...style.foregroundFocus };
      } else {
        scrollbarStyle = { ...scrollbarStyle, ...style.foreground };
      }
    }

    return (
      <div
        style={scrollbarContainerStyle}
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOver={this.onMouseOver}
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOut={this.onMouseOut}
      >
        <div ref={this.scrollbarRef} style={scrollbarStyle} />
      </div>
    );
  }
}
