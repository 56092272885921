import type { TFunction } from 'i18next';
import i18next from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  AllWorkshopSubcontractorKanbans,
  AllWorkshopSubcontractorKanbansOnImplantation,
  GetAllSubcontractorKanbansResult,
  HTTPClientWithAuth,
  SubcontractorKanban,
  SubcontractorKanbansPerStand,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  forEachRecordValues,
  globalHelpers,
  mapRecordValues,
  SubcontractorBackendRoutes,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useScreenIsBulmaMobile } from '@stimcar/libs-uikernel';
import { Input } from '@stimcar/libs-uitoolkit';
import type { ChoiceButtonDef } from '../../lib/bulma/form/custom/ChoiceAddOnButtons.js';
import type { RawActionButtonDesc } from '../../lib/components/misc/ActionButtonComponent.js';
import type { SubcontractorUIOperation } from '../lib/subcontractorCardUtils.js';
import type {
  SubcontractorStore,
  SubcontractorWorkshopPost,
  SubcontractorWorkshopPostIdentification,
} from '../state/typings/store.js';
import { ChoiceAddOnButtons } from '../../lib/bulma/form/custom/ChoiceAddOnButtons.js';
import { Select } from '../../lib/bulma/form/Select.js';
import { KanbanListItem } from '../../lib/components/kanbanList/KanbanListItem.js';
import { useActionButtonDescs } from '../../lib/components/misc/ActionButtonComponent.js';
import { Tabs } from '../../lib/components/Tabs.js';
import { kanbanPriorityLevelHelpers } from '../../lib/utils/kanbanPriorityLevelHelpers.js';
import {
  computeSubcontractorOperationDisplayedLabel,
  SubcontractorKanbanCardSpareParts,
  usePrepareDataForSubcontractorKanbanCard,
} from '../lib/subcontractorCardUtils.js';
import {
  computeSubcontractorOperateKanbanPath,
  SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH,
} from '../subcontractorConstants.js';
import { useComputeSubcontractorAttachmentUrl } from '../utils/useSubcontractorComputeAttachmentUrl.js';
import type { SubcontractorSelectKanbanState } from './typings/store.js';

/**
 * Having two implantation per stands is not supported for now
 */
function assertOnlyOneImplantationPerStand(kanbansPerStandMap: SubcontractorKanbansPerStand): void {
  forEachRecordValues(kanbansPerStandMap, (value) => {
    if (!Array.isArray(value) && keysOf(value).length > 1) {
      throw Error('Only one implantation per stand is supported for now');
    }
  });
}

const INTERNAL_STAND_OR_POST_TAB_SEPARATOR = '#';

async function loadAvailableKanbans(
  httpClient: HTTPClientWithAuth,
  siteId: string
): Promise<GetAllSubcontractorKanbansResult> {
  const result = await httpClient.httpGetAsJson<GetAllSubcontractorKanbansResult>(
    SubcontractorBackendRoutes.GET_AVAILABLE_SUBCONTRACTOR_KANBANS(siteId)
  );

  assertOnlyOneImplantationPerStand(result.kanbansPerStandMap);

  return result;
}

async function initializeSelectKanban({
  actionDispatch,
  getGlobalState,
  httpClient,
}: ActionContext<SubcontractorStore, SubcontractorSelectKanbanState>): Promise<void> {
  const { session } = getGlobalState();

  const { kanbansPerStandMap, standOrder } = await loadAvailableKanbans(
    httpClient,
    session.selectedSite
  );

  actionDispatch.reduce((initial): SubcontractorSelectKanbanState => {
    return {
      ...initial,
      kanbansPerStands: kanbansPerStandMap,
      standOrder,
      selectedTab: isTruthyAndNotEmpty(initial.selectedTab)
        ? initial.selectedTab
        : keysOf(kanbansPerStandMap)[0],
    };
  });
}

async function ejectKanbanAction(
  {
    httpClient,
    getGlobalState,
    actionDispatch,
  }: ActionContext<SubcontractorStore, SubcontractorSelectKanbanState>,
  kanbanId: string,
  standId: string,
  workshopPost: SubcontractorWorkshopPostIdentification
): Promise<void> {
  const { session } = getGlobalState();
  await httpClient.httpGet(
    SubcontractorBackendRoutes.SUBCONTRACTOR_EJECT_KANBAN_FROM_POST(
      session.selectedSite,
      kanbanId,
      standId,
      workshopPost.implantationId,
      workshopPost.categoryId,
      workshopPost.postName
    )
  );

  const { kanbansPerStandMap } = await loadAvailableKanbans(httpClient, session.selectedSite);

  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      kanbansPerStands: kanbansPerStandMap,
    };
  });
}

function getKanbanCountPerSortedStandOrPost(
  kanbansPerStands: SubcontractorKanbansPerStand,
  standOrder: readonly string[]
): { standOrPostId: string; count: number }[] {
  const returnValue: { standOrPostId: string; count: number }[] = [];
  standOrder.forEach((standId) => {
    const kanbansOnStand = kanbansPerStands[standId];
    if (Array.isArray(kanbansOnStand)) {
      returnValue.push({ standOrPostId: standId, count: kanbansOnStand.length });
    } else {
      forEachRecordValues(
        kanbansOnStand as Record<string, AllWorkshopSubcontractorKanbansOnImplantation>,
        (subcontractorCategory, implantationId) => {
          const { sortedAvailableCategoryIds, ...kanbansPerCategoryId } = subcontractorCategory;
          sortedAvailableCategoryIds.forEach((categoryId) => {
            const kanbansOnCategory = kanbansPerCategoryId[categoryId];
            let count = 0;
            keysOf(kanbansOnCategory).forEach((post) => {
              const k = kanbansOnCategory[post];
              if (Array.isArray(k)) {
                count += k.length;
              } else if (k !== null) {
                count += 1;
              }
            });
            // The key allowing to retrieve the specific post
            const key =
              standId +
              INTERNAL_STAND_OR_POST_TAB_SEPARATOR +
              implantationId +
              INTERNAL_STAND_OR_POST_TAB_SEPARATOR +
              categoryId;

            returnValue.push({ standOrPostId: key, count });
          });
        }
      );
    }
  });

  return returnValue;
}

function getAllKanbans(
  dataOnStand: readonly SubcontractorKanban[] | AllWorkshopSubcontractorKanbans,
  selectedImplantationId?: string,
  selectedCategoryId?: string
): readonly SubcontractorKanban[] {
  if (!isTruthy(dataOnStand)) {
    return [];
  }

  if (selectedImplantationId && selectedCategoryId) {
    // On an implantation and a category, data is defined in a more complicated structure
    const { waitingLine, ...rest } = (dataOnStand as AllWorkshopSubcontractorKanbans)[
      selectedImplantationId
    ][selectedCategoryId];
    const kanbansOnImplantation = mapRecordValues(rest, (value) => value).filter((value) =>
      isTruthy(value)
    );
    return [...waitingLine, ...kanbansOnImplantation] as readonly SubcontractorKanban[];
  }
  // Not on an implantation
  return dataOnStand as readonly SubcontractorKanban[];
}

function getAllAvailableContracts(
  dataOnStand: readonly SubcontractorKanban[] | AllWorkshopSubcontractorKanbans,
  selectedImplantationId?: string,
  selectedCategoryId?: string
): readonly string[] {
  const allKanbansOnStand = getAllKanbans(dataOnStand, selectedImplantationId, selectedCategoryId);
  return [...new Set(allKanbansOnStand.map((k) => k.contractCode))];
}

export function SubcontractorSelectKanban({ $gs }: AppProps<SubcontractorStore>): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const $ = $gs.$selectKanban;

  const asyncEffect = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(initializeSelectKanban);
    },
    [],
    $
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    asyncEffect();
  }, [asyncEffect]);

  const selectedTab = useGetState($.$selectedTab);

  const kanbansPerStands = useGetState($.$kanbansPerStands);
  const standOrder = useGetState($.$standOrder);

  const sortedSuggestions = useMemo(() => {
    const theSuggestions: Record<string, string> = {};
    const countPerStand = getKanbanCountPerSortedStandOrPost(kanbansPerStands, standOrder);
    countPerStand.forEach(({ standOrPostId, count }) => {
      // Don't display the implantation since only one per stand is supported for now
      const tab = standOrPostId.split(INTERNAL_STAND_OR_POST_TAB_SEPARATOR);
      let stand = '';
      if (tab.length === 1) {
        [stand] = tab;
      } else {
        stand = `${tab[0]}/${tab[2]}`;
      }
      theSuggestions[standOrPostId] = t('selectKanban.title', {
        stand,
        count,
      });
    });
    return theSuggestions;
  }, [kanbansPerStands, standOrder, t]);

  const [selectedStandId, selectedImplantationId, selectedCategoryId] = useMemo(() => {
    return selectedTab.split(INTERNAL_STAND_OR_POST_TAB_SEPARATOR);
  }, [selectedTab]);

  const kanbans = useMemo(
    () => kanbansPerStands[selectedStandId] ?? [],
    [kanbansPerStands, selectedStandId]
  );

  const availableContracts = useMemo(
    () => getAllAvailableContracts(kanbans, selectedImplantationId, selectedCategoryId),
    [kanbans, selectedImplantationId, selectedCategoryId]
  );

  const searchByLicense = useGetState($.$searchByLicense);
  const searchBySite = useGetState($.$searchBySite);
  const searchByContract = useGetState($.$searchByContract);

  const availableSites = useGetState($gs.$session.$availableSites);

  return (
    <div>
      {availableSites.length > 1 && (
        <Select $={$gs.$session.$selectedSite} entries={availableSites} isFullWidth sortEntries />
      )}
      <div
        className="has-background-beige-lighter p-b-md"
        style={{
          zIndex: 20,
          top: '52px',
          position: 'sticky',
        }}
      >
        {keysOf(sortedSuggestions).length > 1 && (
          <div className="m-b-md">
            <Tabs
              labels={sortedSuggestions}
              $selectedTab={$.$selectedTab}
              className="is-toggle is-fullwidth has-background-white"
            />
          </div>
        )}
        <KanbanListFilterComponent
          $gs={$gs}
          $searchByLicense={$.$searchByLicense}
          $searchBySite={$.$searchBySite}
          $searchByContract={$.$searchByContract}
          availableContracts={availableContracts}
        />
      </div>
      <KanbanList
        $gs={$gs}
        kanbans={kanbans}
        selectedStandId={selectedStandId}
        selectedImplantationId={selectedImplantationId}
        selectedCategoryId={selectedCategoryId}
        searchedLicense={searchByLicense}
        searchedSite={searchBySite}
        searchedContract={searchByContract}
      />
    </div>
  );
}

interface KanbanListFilterComponentProps extends AppProps<SubcontractorStore> {
  readonly $searchByLicense: StoreStateSelector<SubcontractorStore, string>;
  readonly $searchBySite: StoreStateSelector<SubcontractorStore, string>;
  readonly $searchByContract: StoreStateSelector<SubcontractorStore, string>;
  readonly availableContracts: readonly string[];
}

function displayFiltersForMobile(
  t: TFunction<string, undefined>,
  choiceAddOnButtonsDescs: readonly ChoiceButtonDef[],
  $searchByLicense: StoreStateSelector<SubcontractorStore, string>,
  $searchBySite: StoreStateSelector<SubcontractorStore, string>,
  $searchByContract: StoreStateSelector<SubcontractorStore, string>,
  availableContracts: readonly string[]
) {
  return (
    <>
      <div className="columns is-mobile is-vcentered">
        <div className="column is-narrow-mobile m-l-xs">
          <ChoiceAddOnButtons
            buttonsDesc={choiceAddOnButtonsDescs}
            $={$searchBySite}
            size="small"
          />
        </div>
        <div className="column is-narrow-mobile has-text-right">{t('selectKanban.contract')}</div>
        <div className="column is-auto-fill m-r-xs">
          <Select
            $={$searchByContract}
            entries={availableContracts}
            isEmptyValueAllowed
            isFullWidth
          />
        </div>
      </div>
      <Input $={$searchByLicense} placeholder={t('selectKanban.license')} type="search" />
    </>
  );
}

function displayFiltersForDesktop(
  t: TFunction<string, undefined>,
  choiceAddOnButtonsDescs: readonly ChoiceButtonDef[],
  $searchByLicense: StoreStateSelector<SubcontractorStore, string>,
  $searchBySite: StoreStateSelector<SubcontractorStore, string>,
  $searchByContract: StoreStateSelector<SubcontractorStore, string>,
  availableContracts: readonly string[]
) {
  return (
    <>
      <div className="columns is-vcentered">
        <div className="column is-narrow m-l-xs">
          <ChoiceAddOnButtons buttonsDesc={choiceAddOnButtonsDescs} $={$searchBySite} />
        </div>
        <div className="column is-auto-fill">
          <Input $={$searchByLicense} placeholder={t('selectKanban.license')} type="search" />
        </div>
        <div className="column is-narrow has-text-right">{t('selectKanban.contract')}</div>
        <div className="column is-3 m-r-xs">
          <Select
            $={$searchByContract}
            entries={availableContracts}
            isEmptyValueAllowed
            isFullWidth
          />
        </div>
      </div>
    </>
  );
}

function KanbanListFilterComponent({
  $gs,
  $searchByLicense,
  $searchBySite,
  $searchByContract,
  availableContracts,
}: KanbanListFilterComponentProps): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const isMobile = useScreenIsBulmaMobile($gs.$window);
  const choiceAddOnButtonsDescs = [
    {
      id: '',
      label: t('selectKanban.showAllKanbans'),
      selectedClass: 'is-primary',
      iconId: '',
    },
    {
      id: 'onSite',
      label: '',
      selectedClass: 'is-primary',
      iconId: 'home',
    },
    {
      id: 'offSite',
      label: '',
      selectedClass: 'is-primary',
      iconId: 'ban',
    },
  ];

  if (isMobile) {
    return displayFiltersForMobile(
      t,
      choiceAddOnButtonsDescs,
      $searchByLicense,
      $searchBySite,
      $searchByContract,
      availableContracts
    );
  }
  return displayFiltersForDesktop(
    t,
    choiceAddOnButtonsDescs,
    $searchByLicense,
    $searchBySite,
    $searchByContract,
    availableContracts
  );
}

interface KanbanListProps extends AppProps<SubcontractorStore> {
  readonly kanbans: readonly SubcontractorKanban[] | AllWorkshopSubcontractorKanbans;
  readonly selectedStandId: string;
  readonly searchedLicense: string;
  readonly searchedSite: string;
  readonly searchedContract: string;
  readonly selectedImplantationId?: string;
  readonly selectedCategoryId?: string;
}

function KanbanList({
  $gs,
  kanbans,
  searchedLicense,
  searchedSite,
  searchedContract,
  selectedStandId,
  selectedImplantationId,
  selectedCategoryId,
}: KanbanListProps): JSX.Element {
  const [t] = useTranslation('subcontractor');

  const {
    toSelect: allKanbansToSelect,
    onCategory: kanbansOnCategory,
    onPostsCount: kanbansOnPostsCount,
  } = useMemo(() => {
    let toSelect: SubcontractorKanban[] = [];
    let onCategory: Record<string, SubcontractorKanban | null> | undefined;
    let onPostsCount = 0;
    if (Array.isArray(kanbans)) {
      toSelect = kanbans;
    } else if (selectedImplantationId && selectedCategoryId) {
      const { waitingLine, ...rest } = (kanbans as AllWorkshopSubcontractorKanbans)[
        selectedImplantationId
      ][selectedCategoryId];
      toSelect = waitingLine;
      onCategory = rest;
      onPostsCount = keysOf(rest).reduce(
        (acc: number, key) => (rest[key] === null ? acc : acc + 1),
        0
      );
    }
    return {
      toSelect,
      onCategory,
      onPostsCount,
    };
  }, [kanbans, selectedImplantationId, selectedCategoryId]);

  const licenseFilter = (k: SubcontractorKanban, searchedLicense: string): boolean => {
    if (searchedLicense.length > 2) {
      const theSearch = searchedLicense.replace('-', '').toLowerCase();
      const currentLicense = k.infos.license.replace('-', '').toLowerCase();
      return currentLicense.includes(theSearch);
    }
    return true;
  };

  const siteFilter = (k: SubcontractorKanban, searchedSite: string): boolean => {
    switch (searchedSite) {
      case '':
        return true;
      case 'onSite':
        return k.iconId === 'home';
      case 'offSite':
        return k.iconId !== 'home';
      default:
        throw Error(`Unexpected searched site: ${searchedSite}`);
    }
  };

  const contractFilter = (k: SubcontractorKanban, searchedContract: string): boolean => {
    if (isTruthy(searchedContract) && searchedContract !== '') {
      return k.contractCode === searchedContract;
    }
    return true;
  };

  const kanbansToSelect = useMemo(() => {
    return allKanbansToSelect
      .filter((k) => licenseFilter(k, searchedLicense))
      .filter((k) => siteFilter(k, searchedSite))
      .filter((k) => contractFilter(k, searchedContract));
  }, [allKanbansToSelect, searchedLicense, searchedSite, searchedContract]);

  const availablePosts = isTruthy(kanbansOnCategory)
    ? keysOf(kanbansOnCategory)
        .slice()
        .sort()
        .map((p): SubcontractorWorkshopPost => {
          return {
            implantationId: nonnull(selectedImplantationId),
            categoryId: nonnull(selectedCategoryId),
            postName: p,
            isLoaded: kanbansOnCategory[p] !== null,
          };
        })
    : undefined;

  return (
    <div className="m-l-xxs m-r-xxs">
      {availablePosts && kanbansOnPostsCount > 0 && (
        <div>
          <h4 className="title is-4">{t('selectKanban.currentlyHandled')}</h4>
          {availablePosts.map((post) => {
            const kanbansOnPost = nonnull(kanbansOnCategory)[post.postName];
            if (kanbansOnPost && kanbansOnCategory) {
              return (
                <SubcontractorSelectItem
                  key={post.postName}
                  $gs={$gs}
                  kanban={kanbansOnPost}
                  workshopPost={{
                    implantationId: nonnull(selectedImplantationId),
                    categoryId: nonnull(selectedCategoryId),
                    postName: post.postName,
                  }}
                  standId={selectedStandId}
                  allAvailableWorkshopPosts={availablePosts}
                />
              );
            }
            return undefined;
          })}
        </div>
      )}
      {/* Only show titles for workshop stands */}
      {selectedImplantationId && (
        <h4 className="title is-4">{t('selectKanban.waitingToBeHandled')}</h4>
      )}
      {kanbansToSelect.length > 0 ? (
        kanbansToSelect.map((k) => (
          <SubcontractorSelectItem
            key={k.id}
            $gs={$gs}
            kanban={k}
            standId={selectedStandId}
            allAvailableWorkshopPosts={availablePosts}
          />
        ))
      ) : (
        <p>{t('selectKanban.noKanbans')}</p>
      )}
    </div>
  );
}

async function handleKanbanOnPostAction(
  {
    httpClient,
    getGlobalState,
    globalActionDispatch,
    navigate,
  }: ActionContext<SubcontractorStore, SubcontractorSelectKanbanState>,
  kanbanId: string,
  standId: string,
  workshopPost: SubcontractorWorkshopPostIdentification
): Promise<void> {
  const { implantationId, categoryId, postName } = workshopPost;
  const { selectedSite } = getGlobalState().session;
  try {
    await httpClient.httpGetAsText(
      SubcontractorBackendRoutes.HANDLE_WORKSHOP_SUBCONTRACTOR_KANBAN(
        selectedSite,
        kanbanId,
        standId,
        implantationId,
        categoryId,
        postName
      )
    );
  } catch {
    globalActionDispatch.setProperty('message', {
      type: 'error',
      title: i18next.t('subcontractor:selectKanban.kanbanHandlingErrorModalTitle'),
      content: i18next.t('subcontractor:selectKanban.kanbanHandlingErrorModalMessage'),
      redirectTo: SUBCONTRACTOR_SELECT_KANBAN_FULL_PATH,
    });
    return;
  }
  navigate(
    computeSubcontractorOperateKanbanPath(kanbanId, standId, implantationId, categoryId, postName)
  );
}

interface Props extends AppProps<SubcontractorStore> {
  readonly kanban: SubcontractorKanban;
  readonly standId: string;
  readonly allAvailableWorkshopPosts: readonly SubcontractorWorkshopPost[] | undefined;
  readonly workshopPost?: SubcontractorWorkshopPostIdentification;
}

function areSamePosts<T extends SubcontractorWorkshopPostIdentification>(
  post1: T | undefined,
  post2: T | undefined
): boolean {
  if (!post1 || !post2) {
    return false;
  }
  return (
    post1.implantationId === post2.implantationId &&
    post1.categoryId === post2.categoryId &&
    post1.postName === post2.postName
  );
}

function SubcontractorSelectItem({
  $gs,
  kanban,
  standId,
  workshopPost,
  allAvailableWorkshopPosts,
}: Props): JSX.Element {
  const [t] = useTranslation('subcontractor');
  const $ = $gs.$selectKanban;
  const attachmentUrl = useComputeSubcontractorAttachmentUrl($gs);

  const { operationsToDo, spareParts } = usePrepareDataForSubcontractorKanbanCard(kanban);

  const footerElements = useActionButtonDescs(
    () => {
      const elements: RawActionButtonDesc<SubcontractorStore, SubcontractorSelectKanbanState>[] =
        [];
      if (isTruthy(allAvailableWorkshopPosts)) {
        allAvailableWorkshopPosts.forEach((p) => {
          const isAlreadyHandledOnPost = areSamePosts(workshopPost, p);
          if (isAlreadyHandledOnPost || !isTruthy(workshopPost)) {
            const postLabel = globalHelpers.computeWorkshopPostId(p.categoryId, p.postName);
            elements.push({
              id: p.postName,
              label: isAlreadyHandledOnPost
                ? t('selectKanban.continueOnWorkshopPost', { postLabel })
                : postLabel,
              disabled: isTruthy(workshopPost) ? !isAlreadyHandledOnPost : p.isLoaded,
              index: 0,
              action: async ({ actionDispatch }) => {
                if (isTruthy(allAvailableWorkshopPosts) && allAvailableWorkshopPosts.length > 0) {
                  await actionDispatch.exec(handleKanbanOnPostAction, kanban.id, standId, p);
                } else {
                  throw Error('This action should not be accessible when not on a workshop post');
                }
              },
            });
          }
        });
        if (isTruthy(workshopPost)) {
          elements.push({
            id: 'eject',
            label: t('selectKanban.ejectButton'),
            action: async ({ actionDispatch }) => {
              if (isTruthy(workshopPost)) {
                await actionDispatch.exec(ejectKanbanAction, kanban.id, standId, workshopPost);
              } else {
                throw Error('This action should not be accessible when not on a workshop post');
              }
            },
            disabled: false,
          });
        }
      } else {
        elements.push({
          id: 'select',
          label: t('selectKanban.footerButton'),
          action: ({ navigate }): void => {
            navigate(computeSubcontractorOperateKanbanPath(kanban.id, standId));
          },
          disabled: false,
        });
      }
      return elements;
    },
    [allAvailableWorkshopPosts, kanban.id, standId, t, workshopPost],
    $
  );

  const [hasAMessage, requestMessageClassName] = useMemo(() => {
    let message = kanban.messages.find((m) => m.type === 'request' && m.status === 'pending');
    if (!message) {
      message = kanban.messages.find((m) => m.type === 'request' && m.status === 'rejected');
    }
    return [
      isTruthy(message),
      isTruthy(message) ? kanbanPriorityLevelHelpers.getKanbanMessageColorClassName(message) : '',
    ];
  }, [kanban]);

  const colorationCssClass = kanbanPriorityLevelHelpers.getCssIdFromKanbanPriorityLevel(
    kanban.priority
  );

  return (
    <div id={kanban.id} className="m-b-sm">
      <KanbanListItem
        infos={kanban.infos}
        kanbanId={kanban.id}
        contractCode={kanban.contractCode}
        locationHistory={kanban.logisticInfos.locationHistory}
        $imageModal={$gs.$imageModal}
        hasAMessage={hasAMessage}
        requestMessageClassName={requestMessageClassName}
        attachmentUrl={attachmentUrl}
        kanbanPositionIconId={kanban.iconId}
        kanbanColorationClassName={colorationCssClass}
        foldable={{
          foldedByDefault: false,
          content: (
            <SubcontractorSelectItemContent operations={operationsToDo} spareParts={spareParts} />
          ),
        }}
        actionDescs={footerElements}
        hasBoxBorder={isTruthy(workshopPost)}
        $expandedKanbanStatuses={$.$expandedKanbanStatuses}
      />
    </div>
  );
}

interface SubcontractorSelectItemContent {
  readonly operations: readonly SubcontractorUIOperation[];
  readonly spareParts: readonly string[];
}

function SubcontractorSelectItemContent({
  operations,
  spareParts,
}: SubcontractorSelectItemContent): JSX.Element {
  const [t] = useTranslation('subcontractor');
  return (
    <div className="m-t-sm m-l-sm m-r-sm">
      {operations.length === 0 ? (
        <div className="has-text-centered">
          <b>{t('selectKanban.allOperationsFinished')}</b>
        </div>
      ) : (
        operations.map((o) => (
          <div key={o.operationId}>{computeSubcontractorOperationDisplayedLabel(o)}</div>
        ))
      )}
      <SubcontractorKanbanCardSpareParts spareParts={spareParts} />
    </div>
  );
}
