import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentMetadata, StorageCategories } from '@stimcar/libs-base';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import { LoadingObject, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import { TruncableP } from '../misc/TruncableP.js';
import { ModalMessageDialog } from '../ModalMessageDialog.js';
import type { ComputeAttachmentUrlCallback } from './typings/attachment.js';
import type { ConfirmAttachmentDialogState } from './typings/store.js';

interface ConfirmAttachmentDialogProps<SD extends AnyStoreDef> {
  readonly category: StorageCategories;
  readonly objectId: string;
  readonly $: StoreStateSelector<SD, ConfirmAttachmentDialogState>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly onOkClicked: NoArgActionCallback<SD>;
  readonly shouldRestrictRemove?: (
    attachment: Attachment,
    metadata: AttachmentMetadata | undefined
  ) => boolean;
  readonly okLabel: string;
  readonly children: React.ReactNode;
}

export const CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE = 256;

export function ConfirmAttachmentRemovalDialog<SD extends AnyStoreDef>({
  category,
  objectId,
  $,
  okLabel,
  children,
  shouldRestrictRemove,
  onOkClicked,
  computeAttachmentUrl,
}: ConfirmAttachmentDialogProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');
  const folder = useGetState($.$folder);
  const name = useGetState($.$name);
  const id = useGetState($.$id);
  const metadata = useGetState($.$metadata);
  const thumbnailUrl = computeAttachmentUrl(category, folder, name, objectId, {
    mode: 'cover',
    size: `${CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE}x${CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE}`,
  });

  const isRemovable = useMemo(() => {
    return isTruthy(shouldRestrictRemove)
      ? !shouldRestrictRemove({ id, folder, name }, metadata)
      : true;
  }, [folder, id, name, shouldRestrictRemove, metadata]);

  if (!isRemovable) {
    return (
      <ModalMessageDialog $active={$.$active}>
        <>{t('attachments.confirmRemovalDialog.forbiddenRemovalContent')}</>
      </ModalMessageDialog>
    );
  }

  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('attachments.confirmRemovalDialog.title')}
      okLabel={okLabel}
      onOkClicked={onOkClicked}
    >
      {children}
      <figure className="image">
        <LoadingObject
          src={thumbnailUrl}
          height={CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE}
          width={CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE}
        />
        <figcaption>
          <TruncableP
            className="is-size-6"
            style={{ width: CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE }}
          >
            {name}
          </TruncableP>
        </figcaption>
      </figure>
    </ModalCardDialog>
  );
}

interface ConfirmLocalAttachmentDialogProps<SD extends AnyStoreDef> {
  readonly base64: string;
  readonly filename?: string;
  readonly $active: StoreStateSelector<SD, boolean>;
  readonly onOkClicked: NoArgActionCallback<SD>;
  readonly okLabel: string;
  readonly children: React.ReactNode;
}

export function ConfirmLocalAttachmentRemovalDialog<SD extends AnyStoreDef>({
  base64,
  $active,
  okLabel,
  filename,
  children,
  onOkClicked,
}: ConfirmLocalAttachmentDialogProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');

  return (
    <ModalCardDialog
      $active={$active}
      title={t('attachments.confirmRemovalDialog.title')}
      okLabel={okLabel}
      onOkClicked={onOkClicked}
    >
      {children}
      <figure className="image">
        <LoadingObject
          src={base64}
          height={CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE}
          width={CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE}
        />
        <figcaption>
          <TruncableP
            className="is-size-6"
            style={{ width: CONFIRM_ATTACHMENT_DIALOG_THUMBNAIL_SIZE }}
          >
            {filename || 'No name'}
          </TruncableP>
        </figcaption>
      </figure>
    </ModalCardDialog>
  );
}
