import type { SyntheticEvent } from 'react';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  preventNavigation: boolean;
  customNavigationAction?: (e: SyntheticEvent) => Promise<void>;
  showPreventNavigationModalAction: () => Promise<void>;
  className?: string;
  to: string;
  children: JSX.Element | string;
  activeClassName?: string;
}

export function NavLinkWithDirtyNavigationWarning({
  preventNavigation,
  customNavigationAction,
  showPreventNavigationModalAction,
  className,
  to,
  children,
  activeClassName,
}: Props): JSX.Element {
  const executeNavigateAction = async (e: SyntheticEvent): Promise<void> => {
    if (preventNavigation) {
      e.preventDefault();
      await showPreventNavigationModalAction();
    } else if (customNavigationAction) {
      await customNavigationAction(e);
    }
  };

  return (
    <NavLink
      className={({ isActive }) => `${className}${isActive ? ` ${activeClassName}` : ''}`}
      to={to}
      onClick={executeNavigateAction}
    >
      {children}
    </NavLink>
  );
}
