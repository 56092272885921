import React, { useEffect, useRef } from 'react';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { DeleteClickableIcon, documentEventListenerWrapper } from '@stimcar/libs-uitoolkit';
import { useDeactivateOnClickOutside } from '../../hooks/useDeactivateOnClickOutside.js';

interface Props<SD extends AnyStoreDef> {
  readonly $active: StoreStateSelector<SD, boolean>;
  readonly children: React.ReactNode;
  readonly footer: React.ReactNode;
  readonly title: string;
}

export function Quickview<SD extends AnyStoreDef>({
  $active,
  children,
  footer,
  title,
}: Props<SD>): JSX.Element {
  const active = useGetState($active);

  // Create the hook that will handle clicks inside / outside of the quickview
  // to show/hide it
  const quickviewDomElementRef = useRef<HTMLDivElement>(null);
  useDeactivateOnClickOutside({
    domElementRef: quickviewDomElementRef,
    deactivateOnInternalClick: false,
    $active,
  });

  const escapeKeyHandlerActionCallback = useActionCallback(
    ({ actionDispatch }, e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionDispatch.setValue(false);
      }
    },
    [],
    $active
  );

  useEffect((): (() => void) => {
    // Register a key listener
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    documentEventListenerWrapper.addEventListener('keydown', escapeKeyHandlerActionCallback);
    return (): void => {
      // Unregister the key listener
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      documentEventListenerWrapper.removeEventListener('keydown', escapeKeyHandlerActionCallback);
    };
  }, [escapeKeyHandlerActionCallback]);

  const closeButtonHandlerActionCallback = useActionCallback(
    ({ actionDispatch }) => actionDispatch.setValue(false),
    [],
    $active
  );

  return (
    <>
      {active && (
        <div ref={quickviewDomElementRef} className="quickview is-active">
          <header className="quickview-header">
            <p className="title">{title}</p>
            <DeleteClickableIcon handler={closeButtonHandlerActionCallback} />
          </header>

          <div className="quickview-body">
            <div className="quickview-block">{children}</div>
          </div>

          <footer className="quickview-footer">{footer}</footer>
        </div>
      )}
    </>
  );
}
