import React from 'react';

interface Props {
  readonly progressValue: number | undefined;
  readonly progressLabel: string | undefined;
  readonly progressMaxValue: number;
}

export function LoadingProgressView({
  progressValue,
  progressMaxValue,
  progressLabel,
}: Props): JSX.Element {
  return (
    <div className="modal is-active" style={{ zIndex: 50 }}>
      <div className="modal-background" />
      <div className="modal-content">
        {
          // if the progress is indeterminate, a label can be provided to give a feedback
          // to the user.
          // In addition to the label, the progressbar switches to indeterminate mode :
          // https://bulma.io/documentation/elements/progress/#indeterminate
        }
        <progress
          className="progress is-primary"
          value={
            typeof progressValue === 'number'
              ? progressValue
              : undefined /* indeterminate progress */
          }
          max={progressMaxValue}
        >
          {typeof progressValue === 'number' &&
            `${((Math.min(progressValue, progressMaxValue) / progressMaxValue) * 100).toFixed(0)}%`}
        </progress>
        <p className="is-italic has-text-centered">{!progressLabel ? ' ' : progressLabel}</p>
      </div>
    </div>
  );
}
