import React from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../app/state/typings/store.js';

export interface ShowHideBoxContainerProps {
  readonly title: string;
  readonly $: StoreStateSelector<Store, boolean>;
  readonly children: JSX.Element;
  readonly titleSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  readonly titleTextWeight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  readonly isMobile?: boolean;
}

export function ShowHideBoxContainer({
  $,
  title,
  children,
  titleSize = 5,
  titleTextWeight = 'bold',
  isMobile = false,
}: ShowHideBoxContainerProps): JSX.Element {
  const show = useGetState($);
  const toggleActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      actionDispatch.setValue(!getState());
    },
    [],
    $
  );

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <div className="box">
      <div className={`columns ${isMobile ? 'is-mobile' : ''}`} onClick={toggleActionCallback}>
        <div className="column is-narrow">
          <span className={`is-size-${titleSize} has-text-weight-${titleTextWeight}`}>{title}</span>
        </div>
        <div className="column" />
        <div className="column is-narrow">
          <FaIcon id={`caret-${show ? 'down' : 'right'}`} />
        </div>
      </div>
      {show && children}
    </div>
  );
}
