import React from 'react';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../app/state/typings/store.js';
import type { ConfirmAttachmentDialogState } from '../attachments/typings/store.js';
import type { PictureInputState } from '../typings/store.js';
import { useComputeAttachmentUrl } from '../../../app/utils/useComputeAttachmentUrl.js';
import { GenericPictureInput } from './GenericPictureInput.js';

interface PictureInputProps extends AppProps<Store> {
  readonly label?: string;
  readonly folder: string;
  readonly kanbanId: string;
  readonly filename: string;
  readonly isEditable: boolean;
  readonly placeholderPicturePath: string;
  readonly notSetPictureAdditionalLayer: string;
  readonly $: StoreStateSelector<Store, PictureInputState>;
  readonly $confirmAttachmentRemovalDialog: StoreStateSelector<Store, ConfirmAttachmentDialogState>;
  readonly overridingOnClickCallback?: NoArgActionCallback<Store>;
}

export function PictureInput({
  $,
  $gs,
  label,
  folder,
  kanbanId,
  filename,
  isEditable,
  placeholderPicturePath,
  notSetPictureAdditionalLayer,
  $confirmAttachmentRemovalDialog,
  overridingOnClickCallback,
}: PictureInputProps): JSX.Element {
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  return (
    <GenericPictureInput
      $={$}
      $gs={$gs}
      computeAttachmentUrlCallback={computeAttachmentUrl}
      label={label}
      folder={folder}
      kanbanId={kanbanId}
      filename={filename}
      isEditable={isEditable}
      placeholderPicturePath={placeholderPicturePath}
      notSetPictureAdditionalLayer={notSetPictureAdditionalLayer}
      $confirmAttachmentRemovalDialog={$confirmAttachmentRemovalDialog}
      overridingOnClickCallback={overridingOnClickCallback}
    />
  );
}
