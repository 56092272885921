import React from 'react';
import { Route, Routes } from 'react-router-dom';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { useStateIsDefined } from '@stimcar/libs-uikernel';
import { ProtectedRoute } from '../lib/components/misc/ProtectedRoute.js';
import { Register } from '../registeredapp/components/authentication/Register.js';
import { REGISTER_FULL_PATH, REGISTER_RELATIVE_PATH } from '../registeredapp/constants.js';
import type { DisplayStore } from './state/typings/store.js';
import { NavBar } from './NavBar.js';

export function DisplayApp({ $gs }: AppProps<DisplayStore>): JSX.Element {
  const isRegistered = useStateIsDefined($gs.$session.$infos);
  return (
    <>
      <Routes>
        <Route
          path={REGISTER_RELATIVE_PATH}
          element={
            <ProtectedRoute redirectTo="/" giveWay={!isRegistered}>
              <Register $gs={$gs} />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute redirectTo={REGISTER_FULL_PATH} giveWay={isRegistered}>
              <NavBar $gs={$gs} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}
