import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, WorkshopStandImplantation } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '@stimcar/libs-uitoolkit';
import {
  globalHelpers,
  kanbanHelpers,
  transverseHelpers,
  workshopHelpers,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { MoveForwardModalState } from '../typings/store.js';
import { SelectFormField } from '../../../bulma/form/SelectFormField.js';
import { MOVE_FORWARD_MODAL_EMPTY_STATE } from '../typings/store.js';

interface Props {
  readonly standId: string;
  readonly user: string;
  readonly implantation: WorkshopStandImplantation;
  readonly $: StoreStateSelector<Store, MoveForwardModalState>;
}

export function MoveForwardModal({ standId, user, implantation, $ }: Props): JSX.Element {
  const [t] = useTranslation('workshop');

  const nonnullImplantation = nonnull(implantation);

  const cancelMoveForwardCallback = useActionCallback(
    ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return MOVE_FORWARD_MODAL_EMPTY_STATE;
      });
    },
    [],
    $
  );

  const moveForwardCallback = useActionCallback(
    async ({ actionDispatch, kanbanRepository, httpClient, getState }) => {
      const { currentPostQualifiedId, kanban, selectedNextPostId, availableCategoriesAndPosts } =
        getState();
      if (isTruthyAndNotEmpty(currentPostQualifiedId) && availableCategoriesAndPosts.length > 0) {
        const theKanban = kanbanHelpers.closeCurrentHandling(
          nonnull(kanban),
          currentPostQualifiedId
        );

        const qualifiedNextPostId = globalHelpers.computeQualifiedWorkshopPostId(
          nonnullImplantation.id,
          selectedNextPostId
        );

        let theNewKanban: Kanban;
        if (globalHelpers.isQualifiedCategory(qualifiedNextPostId)) {
          theNewKanban = kanbanHelpers.openNewHandlingWithoutInterval(
            httpClient.getBrowserSequence(),
            theKanban,
            standId,
            qualifiedNextPostId,
            [user]
          );
        } else {
          theNewKanban = kanbanHelpers.openNewHandlingWithInterval(
            httpClient.getBrowserSequence(),
            theKanban,
            standId,
            qualifiedNextPostId,
            [user],
            'work'
          );
        }

        await kanbanRepository.updateEntity(theNewKanban);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return MOVE_FORWARD_MODAL_EMPTY_STATE;
      });
    },
    [nonnullImplantation.id, standId, user],
    $
  );
  const currentPostQualifiedId = useGetState($.$currentPostQualifiedId);
  const availableCategoriesAndPosts = useGetState($.$availableCategoriesAndPosts);

  const formEntries = useMemo(() => {
    const values: FormFieldEntry<string>[] = [];
    const { categoryId } =
      transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(currentPostQualifiedId);
    availableCategoriesAndPosts.forEach((category) => {
      if (category.id !== categoryId) {
        values.push({
          id: category.id,
          label: t('implantation.moveForwardModal.waitingPost', { categoryLabel: category.id }),
        });
      }

      category.posts.forEach((post) => {
        const name = workshopHelpers.getImplantationPostName(post);
        values.push({
          id: globalHelpers.computeWorkshopPostId(category.id, name),
          label: globalHelpers.computeWorkshopPostId(category.id, name),
        });
      });
    });

    return values;
  }, [availableCategoriesAndPosts, currentPostQualifiedId, t]);

  return (
    <ModalCardDialog
      title={t('implantation.moveForwardModal.title')}
      $active={$.$isActive}
      onOkClicked={moveForwardCallback}
      onCancelClicked={cancelMoveForwardCallback}
      okLabel={t('implantation.moveForwardModal.okLabel')}
    >
      <SelectFormField
        label={t('implantation.moveForwardModal.selectLabel')}
        $={$.$selectedNextPostId}
        entries={formEntries}
        horizontal
        isFullWidth
      />
    </ModalCardDialog>
  );
}
