import React from 'react';
import { useTranslation } from 'react-i18next';
import type { SparePart } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import {
  useActionCallback,
  useArrayItemSelector,
  useGetState,
  useStateMatches,
} from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../state/typings/store.js';
import type {
  OperatorSparePartState,
  SparePartEstimatedDateOfReceptionModalDialogState,
} from '../../../typings/store.js';
import { CalendarFormField } from '../../../../../lib/bulma/form/CalendarFormField.js';
import { CustomContentFormField } from '../../../../../lib/bulma/form/CustomContentFormField.js';
import { SwitchFormField } from '../../../../../lib/bulma/form/SwitchFormField.js';
import { EMPTY_SPARE_PART_ESTIMATED_DATE_OF_RECEPTION_MODAL_DIALOG_STATE } from '../../../typings/store.js';

export function openEstimatedDateOfReceptionModalSparePartAction(
  { actionDispatch }: ActionContext<Store, SparePartEstimatedDateOfReceptionModalDialogState>,
  sparePart: SparePart,
  containingPackageDealId: string
) {
  const isNoDelay = sparePart.estimatedDateOfReception === -1;
  let estimatedDateOfReception = NaN;
  if (isNoDelay) {
    estimatedDateOfReception = NaN;
  } else if (sparePart.estimatedDateOfReception !== null) {
    estimatedDateOfReception = sparePart.estimatedDateOfReception;
  }
  actionDispatch.applyPayload({
    active: true,
    sparePartId: sparePart.id,
    packageDealId: containingPackageDealId,
    noDelay: isNoDelay,
    estimatedDateOfReception,
  });
}

export async function onOkClickedAction(
  { actionDispatch, kanbanRepository, getState }: ActionContext<Store, OperatorSparePartState>,
  kanbanId: string
) {
  const { estimatedDateOfReceptionModal } = getState();
  const { sparePartId, packageDealId, noDelay, estimatedDateOfReception } =
    estimatedDateOfReceptionModal;
  await kanbanRepository.updateEntityFromPayload({
    entityId: kanbanId,
    payload: {
      packageDeals: [
        {
          id: packageDealId,
          spareParts: [
            {
              id: sparePartId,
              // eslint-disable-next-line no-nested-ternary
              estimatedDateOfReception: noDelay
                ? -1
                : Number.isNaN(estimatedDateOfReception)
                  ? null
                  : estimatedDateOfReception,
            },
          ],
        },
      ],
    },
  });
  actionDispatch.setProperty(
    'estimatedDateOfReceptionModal',
    EMPTY_SPARE_PART_ESTIMATED_DATE_OF_RECEPTION_MODAL_DIALOG_STATE
  );
}

interface SparePartEstimatedDateOfReceptionModalProps {
  readonly kanbanId: string;
  readonly $: StoreStateSelector<Store, OperatorSparePartState>;
}

export function SparePartEstimatedDateOfReceptionModal({
  $,
  kanbanId,
}: SparePartEstimatedDateOfReceptionModalProps): JSX.Element {
  const [t] = useTranslation('operators');

  const { $estimatedDateOfReceptionModal } = $;
  const sparePartId = useGetState($estimatedDateOfReceptionModal.$sparePartId);

  const $sparePart = useArrayItemSelector($.$spareParts, sparePartId);
  const sparePartLabel = useGetState($sparePart.$label);

  const isNoDelay = useStateMatches($estimatedDateOfReceptionModal.$noDelay, true);

  const onOkClickedActionCallback = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(onOkClickedAction, kanbanId),
    [kanbanId],
    $
  );

  return (
    <ModalCardDialog
      $active={$estimatedDateOfReceptionModal.$active}
      title={t('generalView.operations.editEstimatedDateOfReception.title', {
        label: sparePartLabel,
      })}
      onOkClicked={onOkClickedActionCallback}
    >
      <>
        <SwitchFormField
          switchId="noDelay"
          horizontal
          $={$estimatedDateOfReceptionModal.$noDelay}
          label={t('generalView.operations.editEstimatedDateOfReception.noDelay')}
        />
        {isNoDelay ? (
          <CustomContentFormField
            horizontal
            label={t(
              'generalView.operations.editEstimatedDateOfReception.estimatedDateOfReception'
            )}
          >
            <input className="input" disabled />
          </CustomContentFormField>
        ) : (
          <CalendarFormField
            horizontal
            $={$estimatedDateOfReceptionModal.$estimatedDateOfReception}
            isDisabled={isNoDelay}
            label={t(
              'generalView.operations.editEstimatedDateOfReception.estimatedDateOfReception'
            )}
          />
        )}
      </>
    </ModalCardDialog>
  );
}
