import React from 'react';
import { useTranslation } from 'react-i18next';
import type { NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { BaseStoreDefWithHttpClient } from '@stimcar/libs-uitoolkit';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { AttachmentsGalleryProps } from './AttachmentsGallery.js';
import type { AttachmentGalleryDialogState } from './typings/store.js';
import { AttachmentsGallery } from './AttachmentsGallery.js';

type AttachmentsGalleryDialogProps<SD extends BaseStoreDefWithHttpClient> = Omit<
  AttachmentsGalleryProps<SD>,
  'scopedState' | 'showImportButton'
> & {
  readonly $: StoreStateSelector<SD, AttachmentGalleryDialogState>;
  readonly onOkClicked: NoArgActionCallback<SD>;
};

export function AttachmentsGalleryDialog<SD extends BaseStoreDefWithHttpClient>({
  $,
  onOkClicked,
  ...props
}: AttachmentsGalleryDialogProps<SD>): JSX.Element {
  const [t] = useTranslation('libComponents');
  return (
    <ModalCardDialog
      $active={$.$active}
      title={t('attachments.galleryDialog.title')}
      onOkClicked={onOkClicked}
      noContentTag
    >
      <AttachmentsGallery $={$} {...props} />
    </ModalCardDialog>
  );
}
