import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import Keyboard from 'react-simple-keyboard';
import type { AnyStoreDef, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldProps } from '@stimcar/libs-uitoolkit';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FormField } from '@stimcar/libs-uitoolkit';
import 'react-simple-keyboard/build/css/index.css';
import { frenchLayout } from './layout.js';

export interface VirtualKeyboardInputProps<SD extends AnyStoreDef> {
  readonly $: StoreStateSelector<SD, string>;
  readonly placeholder?: string;
  readonly preSelectContent?: boolean;
  readonly children?: JSX.Element;
}

export function VirtualKeyboardInput<SD extends AnyStoreDef>({
  preSelectContent,
  placeholder,
  $,
  children,
}: VirtualKeyboardInputProps<SD>): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const keyboardRef = useRef(null);

  const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(false);

  const [layoutName, setLayoutName] = React.useState('default');

  const handleShift = useCallback((): void => {
    const newLayoutName = layoutName === 'default' ? 'shift' : 'default';
    setLayoutName(newLayoutName);
  }, [layoutName]);

  const onKeyPress = useCallback(
    (button: string): void => {
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') handleShift();
      if (button === '{enter}') {
        setIsKeyboardOpen(false);
      }
    },
    [handleShift]
  );

  const value = useGetState($);

  const onChangeInput = useActionCallback(
    ({ actionDispatch }, event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      actionDispatch.setValue(input);
      if (isTruthy(keyboardRef) && isTruthy(keyboardRef.current)) {
        // @ts-ignore
        keyboardRef.current.setInput(input);
      }
    },
    [],
    $
  );

  const setValueActionCallback = useActionCallback(
    ({ actionDispatch }, newValue: string) => {
      actionDispatch.setValue(newValue);
    },
    [],
    $
  );
  useEffect(() => {
    if (preSelectContent && isTruthy(inputRef.current)) {
      setIsKeyboardOpen(true);
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [inputRef, preSelectContent]);

  const openKeyboardHandler = useCallback((): void => {
    setIsKeyboardOpen(true);
  }, []);

  useEffect((): void => {
    if (inputRef.current) {
      inputRef.current.value = value || '';
    }
  }, [inputRef, value]);

  return (
    <>
      <div className="columns">
        <div className="column">
          <input
            placeholder={placeholder}
            ref={inputRef}
            className="input"
            type="text"
            onChange={onChangeInput}
            onMouseDown={openKeyboardHandler}
          />
        </div>
        {children}
      </div>
      {isKeyboardOpen && (
        <div className="m-b-sm">
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <Keyboard.default
            keyboardRef={(r): void => {
              keyboardRef.current = r;
            }}
            layout={frenchLayout}
            layoutName={layoutName}
            onKeyPress={onKeyPress}
            onChange={setValueActionCallback}
          />
        </div>
      )}
    </>
  );
}

type VirtualKeyboardInputFormFieldProps<SD extends AnyStoreDef> = Omit<FormFieldProps, 'children'> &
  VirtualKeyboardInputProps<SD>;

export function VirtualKeyboardInputFormField<SD extends AnyStoreDef>({
  $,
  label,
  preSelectContent = false,
  placeholder,
  children,
  ...props
}: VirtualKeyboardInputFormFieldProps<SD>): JSX.Element {
  return (
    <>
      <FormField label={label} {...props}>
        <VirtualKeyboardInput
          $={$}
          placeholder={placeholder || label}
          preSelectContent={preSelectContent}
        >
          {children}
        </VirtualKeyboardInput>
      </FormField>
    </>
  );
}
