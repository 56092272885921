import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import { CoreBackendRoutes } from '@stimcar/libs-base';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { Checkbox } from '../../../lib/bulma/form/Checkbox.js';
import type { InvoiceInfoDeletionModalState } from './typings/store.js';
import { INVOICE_INFO_DELETION_MODAL_EMPTY_STATE } from './typings/store.js';

export function openDeleteInvoiceInfosDeletionModalAction(
  { actionDispatch }: ActionContext<Store, InvoiceInfoDeletionModalState>,
  purchaseOrderId: string,
  invoiceInfoId: string | null
): void {
  actionDispatch.setValue({
    ...INVOICE_INFO_DELETION_MODAL_EMPTY_STATE,
    purchaseOrderId,
    invoiceInfoId: invoiceInfoId ?? '',
    deleteAssociatedFiles: true,
    deleteAssociatedEmailOperations: true,
    isActive: true,
  });
}

async function deleteInvoiceInfosAction(
  { httpClient, getState, actionDispatch }: ActionContext<Store, InvoiceInfoDeletionModalState>,
  kanbanId: string
): Promise<void> {
  const { purchaseOrderId, invoiceInfoId, deleteAssociatedFiles, deleteAssociatedEmailOperations } =
    getState();
  await httpClient.httpPostAsJSON(
    CoreBackendRoutes.DELETE_INVOICE_INFO,
    {
      purchaseOrderId,
      invoiceInfoId,
      kanbanId,
      deleteAssociatedFiles,
      deleteAssociatedEmailOperations,
    },
    'PUT'
  );
  actionDispatch.setValue({ ...INVOICE_INFO_DELETION_MODAL_EMPTY_STATE });
}

type DeleteInvoiceInfosModalProps = {
  readonly $: StoreStateSelector<Store, InvoiceInfoDeletionModalState>;
  readonly kanbanId: string;
};

export function DeleteInvoiceInfosModal({
  $,
  kanbanId,
}: DeleteInvoiceInfosModalProps): JSX.Element {
  const [t] = useTranslation('details');

  const deleteInvoiceInfosActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(deleteInvoiceInfosAction, kanbanId);
    },
    [kanbanId],
    $
  );

  return (
    <ModalCardDialog
      title={t('tabs.invoice.deleteInvoice.title')}
      $active={$.$isActive}
      okLabel={t('tabs.invoice.deleteInvoice.okButtonLabel')}
      onOkClicked={deleteInvoiceInfosActionCallback}
    >
      <p>{t('tabs.invoice.deleteInvoice.confirmDeletionMsg1')}</p>
      <p>{t('tabs.invoice.deleteInvoice.confirmDeletionMsg2')}</p>
      <div>
        <Checkbox
          $={$.$deleteAssociatedFiles}
          text={t('tabs.invoice.deleteInvoice.deleteAssociatedFiles')}
        />
      </div>
      <div>
        <Checkbox
          $={$.$deleteAssociatedEmailOperations}
          text={t('tabs.invoice.deleteInvoice.deleteAssociatedEmailOperations')}
        />
      </div>
    </ModalCardDialog>
  );
}
