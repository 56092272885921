/* eslint-disable react-hooks/exhaustive-deps */
import type { DependencyList } from 'react';
import React from 'react';
import type {
  AnyStoreDef,
  NoArgAction,
  NoArgActionCallback,
  State,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { nonnull } from '@stimcar/libs-kernel';
import { useObjectWithNestedActionCallbacks } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';

export const actionButtonDescIndexComparator = (
  a1: BaseActionButtonDesc,
  a2: BaseActionButtonDesc
): number => {
  if (!a1.index && !a2.index) {
    return 0;
  }
  if (a1.index && !a2.index) {
    return 1;
  }
  if (!a1.index && a2.index) {
    return -1;
  }
  return nonnull(a1.index) - nonnull(a2.index);
};

export interface BaseActionButtonDesc {
  readonly id: string;
  readonly icon?: string;
  readonly tooltip?: string;
  readonly label?: string;
  readonly disabled: boolean;
  readonly index?: number;
}

export interface RawActionButtonDesc<SD extends AnyStoreDef, S extends State>
  extends BaseActionButtonDesc {
  readonly action: NoArgAction<SD, S>;
}

export interface ActionButtonDesc<SD extends AnyStoreDef> extends BaseActionButtonDesc {
  readonly action: NoArgActionCallback<SD>;
}

export const useActionButtonDescs = <SD extends AnyStoreDef, S extends State>(
  rawButtonsProvider:
    | readonly RawActionButtonDesc<SD, S>[]
    | (() => readonly RawActionButtonDesc<SD, S>[]),
  deps: DependencyList,
  $: StoreStateSelector<SD, S>
): readonly ActionButtonDesc<SD>[] => {
  return useObjectWithNestedActionCallbacks(
    (toActionCallback) => {
      const rawButtons =
        typeof rawButtonsProvider === 'function' ? rawButtonsProvider() : rawButtonsProvider;
      return rawButtons.map((actionButtonDesc): ActionButtonDesc<SD> => {
        const { action, ...rest } = actionButtonDesc;
        return {
          ...rest,
          action: toActionCallback(action),
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ...(typeof rawButtonsProvider === 'function' ? [rawButtonsProvider] : rawButtonsProvider),
      ...deps,
    ],
    $
  );
};

interface ActionButtonComponentProps<SD extends AnyStoreDef> {
  readonly actionDesc: ActionButtonDesc<SD>;
  readonly isFullwidth?: boolean;
  readonly size?: 'normal' | 'small' | 'smaller';
}

export function ActionButtonComponent<SD extends AnyStoreDef>({
  actionDesc,
  isFullwidth,
  size = 'normal',
}: ActionButtonComponentProps<SD>): JSX.Element {
  return (
    <button
      type="button"
      className={`button is-primary${isFullwidth ? ' is-fullwidth' : ''} is-${size}`}
      disabled={actionDesc.disabled}
      onClick={actionDesc.action}
    >
      {actionDesc.icon ? (
        <FaIcon id={actionDesc.icon} tooltip={actionDesc.tooltip} label={actionDesc.label} />
      ) : (
        <span title={actionDesc.tooltip}>{actionDesc.label || actionDesc.tooltip}</span>
      )}
    </button>
  );
}
