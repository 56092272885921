import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { packageDealHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, TruncableTableTd } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { DefectsTabState } from '../../typings/store.js';
import type { DefectWithIndex } from './defectUtils.js';
import { openUpdateDefectDialogAction } from './modal/AddOrUpdateDefectModal.js';
import { openDeleteDefectModalAction } from './modal/DeleteDefectModal.js';

interface DefectsTableItemComponentProps {
  readonly $: StoreStateSelector<Store, DefectsTabState>;
  readonly $packageDeals: StoreStateSelector<Store, readonly PackageDeal[]>;
  readonly defect: DefectWithIndex;
  readonly isEditable: boolean;
}

export function DefectsTableItemComponent({
  $,
  $packageDeals,
  defect,
  isEditable,
}: DefectsTableItemComponentProps): JSX.Element {
  const { id, index, type, fullyRefurbishingPackageDealIds, partiallyRefurbishingPackageDealIds } =
    defect;
  const [t] = useTranslation('operators', { keyPrefix: 'defects.table' });
  const [tDefectsTypes] = useTranslation('operators', { keyPrefix: 'defects.types' });

  const packageDeals = useGetState($packageDeals);

  const fullyRefurbishingPackageDeals = useMemo(
    () => packageDeals.filter(({ id }) => fullyRefurbishingPackageDealIds.includes(id)),
    [packageDeals, fullyRefurbishingPackageDealIds]
  );
  const partiallyRefurbishingPackageDeals = useMemo(
    () => packageDeals.filter(({ id }) => partiallyRefurbishingPackageDealIds.includes(id)),
    [packageDeals, partiallyRefurbishingPackageDealIds]
  );

  const openUpdateDefectDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openUpdateDefectDialogAction, defect);
    },
    [defect],
    $.$addOrUpdateDefectDialogState
  );

  const openDeleteDefectDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openDeleteDefectModalAction, id);
    },
    [id],
    $.$deleteDefectDialogState
  );

  const fullyRefurbishingPrice = useMemo(
    () =>
      packageDealHelpers
        .getPackageDealsAndSparePartsTotalPriceWithVAT(fullyRefurbishingPackageDeals, 'all')
        .toFixed(2),
    [fullyRefurbishingPackageDeals]
  );

  const partiallyRefurbishingPrice = useMemo(
    () =>
      packageDealHelpers
        .getPackageDealsAndSparePartsTotalPriceWithVAT(partiallyRefurbishingPackageDeals, 'all')
        .toFixed(2),
    [partiallyRefurbishingPackageDeals]
  );

  return (
    <tr>
      <TruncableTableTd className="has-text-centered">{index}</TruncableTableTd>
      <TruncableTableTd>{tDefectsTypes(type)}</TruncableTableTd>
      <TruncableTableTd className="has-text-right">{fullyRefurbishingPrice}</TruncableTableTd>
      <TruncableTableTd className="has-text-right">{partiallyRefurbishingPrice}</TruncableTableTd>
      <td aria-label="actions" className="has-text-centered px-0" style={{ whiteSpace: 'nowrap' }}>
        <button
          key="update"
          aria-label="update"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.update')}
          onClick={openUpdateDefectDialogCallback}
          disabled={!isEditable}
        >
          <FaIcon id="edit" tooltip={t('actions.update')} />
        </button>
        <button
          key="delete"
          aria-label="delete"
          type="button"
          className="button is-small is-transparent"
          title={t('actions.delete')}
          onClick={openDeleteDefectDialogCallback}
          disabled={!isEditable}
        >
          <FaIcon id="trash" tooltip={t('actions.delete')} />
        </button>
      </td>
    </tr>
  );
}
